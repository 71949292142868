import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, styled, Box, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GraphProduct } from '../../modules/shopify/ShopifyService';
import PosScheduleSelector from './PosScheduleSelector';

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    height: '100%',
    width: '100%',
    margin: 8
  }
});

interface Props {
  open: boolean;
  onClose: () => void;
  onSelectTicket: (ticket: GraphProduct, date: Date, capacityLeft?: number | null) => void;
  defaultDate?: Date;
  defaultOrigin?: string;
  defaultDestination?: string;
  isReturnTrip?: boolean;
  onRemove?: () => void;
}

const PosScheduleSelectorDialog = (props: Props) => {
  const { open, onClose, onSelectTicket, defaultDate, defaultOrigin, defaultDestination, isReturnTrip, onRemove } = props;
  return (
    <StyledDialog fullWidth sx={{ height: '100%' }} open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', paddingLeft: 1, justifyContent: 'space-between', paddingTop: 2, paddingRight: 2 }}>
        {isReturnTrip && onRemove ? (
          <Button onClick={() => onRemove?.()} color="error">
            <FormattedMessage id="remove" defaultMessage="Remove" />
          </Button>
        ) : (
          <div></div>
        )}
        <IconButton onClick={() => onClose()}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Box>
      <PosScheduleSelector
        onSelectTicket={onSelectTicket}
        defaultDate={defaultDate}
        defaultOrigin={defaultOrigin}
        defaultDestination={defaultDestination}
        isReturnTrip={isReturnTrip}
      />
    </StyledDialog>
  );
};

export default PosScheduleSelectorDialog;
