import { FC, useState } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { ExtraState } from '../../types/BookingState';
import { cloneDeep, pick } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Offer } from '../../modules/extras/ExtrasService';

const styles = {
  textContainer: {
    padding: 2,
    paddingBottom: 1
  }
};

interface ExtraCardProps {
  state: ExtraState;
  setState: (newState: ExtraState) => void;
  cardContent: Offer;
}

const ExtraCard: FC<ExtraCardProps> = ({ state, setState, cardContent }) => {
  const [isSelected, setIsSelected] = useState(state.selectedExtras.some((extra) => extra['name'] === cardContent.name));
  const theme = useTheme();
  const handleSelect = () => {
    const localState = cloneDeep(state);
    const extra = pick(cardContent, ['name', 'price']);

    !isSelected ? (localState.totalPrice += extra.price) : (localState.totalPrice -= extra.price);

    !isSelected
      ? localState.selectedExtras.push(extra)
      : localState.selectedExtras.splice(
          localState.selectedExtras.findIndex((selected) => extra.name === selected.name),
          1
        );

    setState(localState);
    setIsSelected(!isSelected);
  };

  return (
    <Grid container item sx={{ backgroundColor: 'white', borderRadius: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          '& img': {
            height: '100%',
            width: '100%'
          }
        }}
      >
        <img src={cardContent.image} alt="extra option" />
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="h6" sx={styles.textContainer}>
          {cardContent.name}
        </Typography>
        <Typography sx={{ paddingLeft: 2, paddingRight: 2, textAlign: 'left' }}>{cardContent.description}</Typography>
      </Grid>
      <Grid container item xs={12} sx={{ ...styles.textContainer, justifyContent: 'space-between' }}>
        <Typography fontWeight={useTheme().typography.fontWeightMedium}>{`${cardContent.price} SEK`}</Typography>
        <Button
          variant={isSelected ? 'contained' : 'text'}
          sx={{ color: isSelected ? 'white' : theme.palette.primary.main, fontSize: 17 }}
          onClick={handleSelect}
        >
          {isSelected ? (
            <FormattedMessage id="booking.extra.selected" defaultMessage="Selected" />
          ) : (
            <FormattedMessage id="booking.extra.select" defaultMessage="Select" />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExtraCard;
