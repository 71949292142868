import { Box, Container, Typography } from '@mui/material';
import { FC, useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { inject } from 'saft-react';
import { StaticPageService } from '../modules/static-page-service/StaticPageService';
import Header from './Header';
import { ViewportContext } from '../context/ViewportContext';
import cssStyles from './DynamicContent.module.css';
import { Helmet } from 'react-helmet';

interface DynamicContentInjectedProps {
  staticPageService: StaticPageService;
}

interface Props {
  type: 'page' | 'blog';
  enableBooking?: boolean;
  footerHeight: number;
}

const DynamicContent: FC<Props & DynamicContentInjectedProps> = ({ staticPageService, type, footerHeight, enableBooking }) => {
  const { handle, blogHandle } = useParams();
  const { matchesBreakpoint } = useContext(ViewportContext);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<string>('');
  const [searchBarHeight] = useState(0);
  const [seo, setSeo] = useState<{ title: string; description: string } | undefined>();

  useEffect(() => {
    if (handle) {
      if (type === 'page') {
        (async () => {
          const { body, title, seo } = await staticPageService.getPageByHandle(handle);
          setTitle(title);
          setBody(body);
          setSeo(seo);
        })();
      } else if (type === 'blog') {
        (async () => {
          const { body, title } = await staticPageService.getBlogArticleByHandle(blogHandle ?? '', handle);
          setTitle(title);
          setBody(body);
        })();
      }
    }
  }, [handle, staticPageService, type, blogHandle]);

  const bodyElement = useMemo(() => <div dangerouslySetInnerHTML={{ __html: body }} />, [body]);

  const navbarHeight = useMemo(() => {
    return matchesBreakpoint('sm') ? 48 : 64;
  }, [matchesBreakpoint]);

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', paddingBottom: 8 }}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={seo?.description ?? title} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={seo?.description ?? title} />
        <meta name="og:image" content="/img/travel_image_splitexpress.jpg" />
      </Helmet>
      <Header />
      <Box
        sx={{
          height: '160px',
          width: '100%',
          position: 'relative',
          backgroundImage: `url('/img/blog_bg.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          top: 0,
          left: 0,
          '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: navbarHeight,
            height: `${160 - navbarHeight}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h1"
            fontWeight="medium"
            sx={{
              color: 'white',
              fontSize: matchesBreakpoint('sm') ? 24 : 20,
              letterSpacing: matchesBreakpoint('sm') ? '2.5px' : '1.5px',
              margin: 'auto',
              padding: 1
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ paddingTop: 3, marginBottom: `${searchBarHeight}px` }}>
        <Box className={cssStyles.dynamicContent}>{bodyElement}</Box>
      </Container>
    </Box>
  );
};

export default inject('staticPageService')(DynamicContent as FC<{}>) as React.ElementType;
