import moment from 'moment';
import { Customer, minimumDateOptions } from '../types/Customer';

export function getEnv() {
  const hostname = window.location.hostname;
  let env = 'dev';
  if (hostname.includes('fatikat')) {
    env = 'fat';
  } else if (hostname.includes('splitexpress.com') || hostname.includes('bylynx.com')) {
    env = 'prod';
  }
  return env;
}

export function getStorefrontAccessInfo(customer: Customer) {
  const hostname = window.location.href;
  const isDev = hostname.indexOf('localhost') > -1;
  const isTest = hostname.indexOf('fatikat.com') > -1;

  let domain;
  let accessToken;
  switch (customer) {
    case Customer.SplitExpress:
      // if (isDev || isTest) {
      //   domain = 'split-ex.myshopify.com';
      //   accessToken = '1483e69cf4479bab0b2c73d65798808f';
      // }
      if (isDev || isTest) {
        domain = 'split-ex-dev-3.myshopify.com';
        accessToken = '931b23c3f96505efa22523c1223d7bb8';
      } else {
        domain = 'splitexpress-ferries.myshopify.com';
        accessToken = '55e4e29b9ec0dded2db14ea2d608c0a0';
      }
      break;
    default:
      domain = 'lxferries.myshopify.com';
      accessToken = '5c78b5c96e7f4c74238d8b50d8878a7d';
  }

  return { domain, accessToken };
}

export const getCustomer = (): Customer => {
  const hostname = window.location.hostname;

  const params = new URLSearchParams(window.location.search);

  if (hostname.includes('splitexpress') || params.get('customer')?.includes('splitexpress')) {
    return Customer.SplitExpress;
  } else {
    return Customer.Default;
  }
};

export const CUSTOMER = getCustomer();

export const getFavIcon = () => {
  switch (CUSTOMER) {
    case Customer.SplitExpress:
      return '/favicon_splitexpress.ico';
    case Customer.Default:
    default:
      return '/favicon_meerkat.ico';
  }
};

interface SeoInfo {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
  ogImage?: string;
  ogUrl?: string;
}

export const getDefaultSeoInfo: (customer: Customer) => SeoInfo = (customer) => {
  if (customer === Customer.SplitExpress) {
    const title = 'Split Express: Buy ferry tickets from Split Airport to Brač/Hvar | Split Express';
    return {
      title,
      description: title,
      ogTitle: title,
      ogDescription: title,
      ogImage: '/img/travel_image_splitexpress.jpg'
    };
  } else {
    const title = 'Meerkat Ferries';
    const description =
      'Join the Meerkats on our newly launched ferry services from mainland Europe and the UK to Shell island and Sand Island. With our brand new fleet and our personalised customer app, your travel experience will be smooth and enjoyable every step of the way. Welcome onboard!';
    return {
      title,
      description: description,
      ogTitle: title,
      ogDescription: description,
      ogImage: '/img/travel.jfif'
    };
  }
};

interface IntlText {
  id: string;
  defaultMessage: string;
}

interface CustomizableText {
  travelHeader: {
    travel: IntlText;
    announcement?: IntlText;
  };
}

export const getTexts: () => CustomizableText = () => {
  switch (CUSTOMER) {
    case Customer.SplitExpress:
      return {
        travelHeader: {
          travel: {
            id: 'splitexpress.header.message.travel',
            defaultMessage: 'Fast Ferry Services in Split area'
          },
          announcement: {
            id: 'splitexpress.header.message.announcement',
            defaultMessage: 'We no longer provide scheduled services, but we are available for hire.'
          }
        }
      };
    case Customer.Default:
    default:
      return {
        travelHeader: {
          travel: {
            id: 'header.message.travel',
            defaultMessage: 'Welcome! Find the best trip for your next journey'
          }
        }
      };
  }
};

interface Config {
  hideCountryCode: boolean;
  useLogin?: boolean;
}

export const getConfigs: () => Config = () => {
  switch (CUSTOMER) {
    case Customer.SplitExpress:
      return {
        hideCountryCode: true
      };
    case Customer.Default:
    default:
      return {
        hideCountryCode: false
      };
  }
};

const HIGH_SEASON_START_DATE = moment('2022-06-16');

const getNextFriday = () => {
  let current = moment();
  while (current.days() !== 5) {
    current.add(1, 'day');
  }
  return current;
};

const getSutivanRouteDate = () => {
  const now = moment();
  if (![0, 5, 6].includes(now.day()) && now.isBefore(HIGH_SEASON_START_DATE)) {
    return getNextFriday().toDate();
  } else {
    return new Date();
  }
};

export const minimumBookingDate: Record<Customer, minimumDateOptions> = {
  [Customer.SplitExpress]: {
    defaultDate: new Date(),
    locationSpecific:
      getEnv() !== 'prod' ? {} : { Bol: new Date('05/12/2022'), Hvar: new Date('06/16/2022'), Airport: new Date('06/16/2022'), Sutivan: getSutivanRouteDate() }
  },
  [Customer.Default]: { defaultDate: new Date() }
};

export const disableBookingDateFunc: Record<Customer, (origin: string, destination: string, date: Date) => boolean> = {
  [Customer.SplitExpress]: (origin, destination, date) => {
    if (getEnv() !== 'prod') {
      return false;
    }
    if (origin === 'Sutivan' || destination === 'Sutivan') {
      if (moment(date).isSame(moment('2022-05-30'), 'date')) {
        return false;
      } else if (moment(date).isBefore(HIGH_SEASON_START_DATE)) {
        // only FRI, SAT, SUN
        return ![0, 5, 6].includes(date.getDay());
      }
      return false;
    }
    return false;
  },
  [Customer.Default]: () => false
};
