import { FC, useState, useContext, useMemo } from 'react';
import { Button, Popover, MenuList, MenuItem, ListItemText, Grid, Box, Typography, useTheme } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { VehicleTypeContext } from '../../context/VehicleTypeContext';
import { vehicles } from '../../modules/vehicles/Vehicles';
import { BookingStage, BookingStageContext } from '../../context/BookingStageContext';
import { DisabledTooltip } from '../DisabledTooltip';
import { FormattedMessage } from 'react-intl';

interface VehicleSelectorProps {
  onVehicleSelect: (newVehicleType: string) => void;
}

const VehicleSelector: FC<VehicleSelectorProps> = ({ onVehicleSelect }) => {
  const theme = useTheme();
  const { currentStage } = useContext(BookingStageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const disableVehicleSelector = useMemo(() => currentStage >= BookingStage.Vehicle, [currentStage]);

  return (
    <Grid item sm={12}>
      <DisabledTooltip
        title={<FormattedMessage id="tooltips.booking.vehicleSelector" defaultMessage="Restart booking to change vehicle" />}
        disabled={!disableVehicleSelector}
      >
        <Button
          fullWidth
          onClick={handleClick}
          variant="outlined"
          sx={{
            backgroundColor: '#b82a0b',
            paddingTop: 1.5,
            paddingBottom: 1.5,
            display: 'flex',
            justifyContent: 'space-between'
          }}
          disabled={disableVehicleSelector}
        >
          <Typography sx={{ margin: `0px ${theme.spacing(1.5)}` }}>{useContext(VehicleTypeContext).vehicleType}</Typography>
          <ArrowDropDown />
        </Button>
      </DisabledTooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {vehicles.map((category, index) => {
          return (
            <Box key={index} sx={{ paddingTop: 0.5 }}>
              <MenuItem disabled>
                <ListItemText>{category.category}</ListItemText>
              </MenuItem>
              <MenuList>
                {category.items.map((vehicle) => {
                  return (
                    <MenuItem
                      sx={{ '&:hover': { backgroundColor: '#A32308', border: '1px solid #999999' } }}
                      key={vehicle.name}
                      onClick={() => {
                        handleClose();
                        onVehicleSelect(vehicle.name);
                      }}
                    >
                      <ListItemText>{vehicle.name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Box>
          );
        })}
      </Popover>
    </Grid>
  );
};

export default VehicleSelector;
