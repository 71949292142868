import { inject } from 'saft-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { compact, map } from 'lodash';
import { FC, useContext, useEffect, useMemo } from 'react';
import { RouteSummary } from '../../modules/routing/Routes';
import SailingProductsSource, { ReturnTicket } from '../../modules/sailing-products/SailingProductsSource';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import TicketCard from './TicketCard';
import TicketSelector from './TicketSelector';
import { TravelBookingContext, TravelBookingRoute } from './TravelBookingContext';
import useScheduleEntries from './useScheduleEntries';
import { PackageBookingContext } from '../packages/PackageBookingContext';
import { FormattedMessage } from 'react-intl';
import { hasTicketDateAlreadyPassed } from './ScheduleHelper';

interface InjectedProps {
  sailingProductsSource: SailingProductsSource;
}

interface Props {
  travelBookingRoute: TravelBookingRoute;
  routeSummary: RouteSummary;
  passengerSummary: PassengerSummary;
  travelBookingRouteKey: number;
  isReturn?: boolean;
  returnTicket?: ReturnTicket | null;
}

const ScheduleSelector: FC<Props & InjectedProps> = (props) => {
  const { routeSummary, passengerSummary, travelBookingRoute, sailingProductsSource, isReturn } = props;
  const { moveTravelBookingRouteDate, setSelectedEntry, returnPrice } = useContext(TravelBookingContext);
  const { selectedPackage, setSelectedPackage } = useContext(PackageBookingContext);
  const { scheduleEntries, isLoading } = useScheduleEntries(travelBookingRoute, passengerSummary, sailingProductsSource, isReturn, returnPrice);

  const filteredScheduleEntries = useMemo(() => scheduleEntries.filter((entry) => !hasTicketDateAlreadyPassed(entry)), [scheduleEntries]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const candiateSelection = filteredScheduleEntries[0];
    if (!candiateSelection?.sailingLegs) {
      return;
    }

    const notAvailable = candiateSelection.sailingLegs.some((sailingLeg) => candiateSelection.origin.name === sailingLeg.origin && !sailingLeg.inventory.Seats?.availableForSale);

    if (notAvailable) {
      return;
    }

    if (filteredScheduleEntries.length === 1 && travelBookingRoute.selectedEntry === undefined) {
      setSelectedEntry(travelBookingRoute.identifier, candiateSelection);
    }
  }, [filteredScheduleEntries, setSelectedEntry, travelBookingRoute, isLoading]);

  const ticketCards = useMemo(
    () =>
      compact(
        map(filteredScheduleEntries, (entry, index) => {
          if (entry.sailingLegs?.some((sailingLeg) => entry.origin.name === sailingLeg.origin && !sailingLeg.inventory.Seats?.availableForSale))
            return undefined;
          return (
            <Box sx={{ padding: 1 }} key={index}>
              <TicketCard
                currentSelectedEntry={selectedPackage ? null : travelBookingRoute.selectedEntry}
                onSelectTicket={(entry) => {
                  setSelectedPackage(undefined);
                  setSelectedEntry(travelBookingRoute.identifier, entry);
                }}
                entry={entry}
              />
            </Box>
          );
        })
      ),
    [filteredScheduleEntries, selectedPackage, setSelectedEntry, setSelectedPackage, travelBookingRoute.identifier, travelBookingRoute.selectedEntry]
  );

  return (
    <div>
      <Box sx={{ marginBottom: 8, minHeight: 300 }}>
        <TicketSelector onDateChange={(amount) => moveTravelBookingRouteDate(travelBookingRoute.identifier, amount)} route={routeSummary} type="outbound" />
        {ticketCards.length > 0
          ? ticketCards
          : !isLoading && (
              <Typography variant="h6" fontWeight="medium" color="#999">
                <FormattedMessage id="booking.schedule.travel.noTickets" defaultMessage="Couldn't find any tickets for the selected date" />
              </Typography>
            )}
        {isLoading && (
          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default inject('sailingProductsSource')(ScheduleSelector as FC<{}>) as React.ElementType<Props>;
