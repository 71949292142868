import { CUSTOMER, getEnv, getStorefrontAccessInfo } from '../../settings/AppConstants';
import { endsWith } from 'lodash';

const IP_REGEXP = new RegExp(
  '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
    '\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
);

export default class UrlResolver {
  static getHazmatServiceUrl() {
    switch (getEnv()) {
      case 'dev':
        return '//localhost:3939';
      case 'fat':
        return '//hazmatdata.blx.fatikat.com';
      default:
        return '//localhost:3939';
    }
  }

  static getHomePageUrl() {
    return `${window.location.origin}/travel${window.location.search}`;
  }

  static getThankYouPageUrl() {
    return `${window.location.origin}/page/booking-confirm${window.location.search}`;
  }

  static getShopifyUrl() {
    const { domain } = getStorefrontAccessInfo(CUSTOMER);
    return `https://${domain}`;
  }

  static getAccountsUrl(clientLoc: Location) {
    let result;
    const apiOverride = this._getParameterByName('account', clientLoc);
    if (apiOverride) {
      // overridden scenario
      result = apiOverride;
    } else if (UrlResolver.validateIPaddress(clientLoc.hostname) || endsWith(clientLoc.hostname, 'xip.io') || clientLoc.hostname === 'localhost') {
      // localhost dev env scenario
      result = `${clientLoc.protocol}//${clientLoc.hostname}:3999`;
    } else if (endsWith(clientLoc.hostname, '.fatikat.com')) {
      // *.fatikat.com scenario
      result = '//accounts.blx.fatikat.com';
    } else if (endsWith(clientLoc.hostname, '.stagikat.com')) {
      // *.fatikat.com scenario
      result = '//accounts.blx.stagikat.com';
    } else if (endsWith(clientLoc.hostname, '.testikat.com')) {
      // *.testikat.com scenario
      result = '//accounts.testikat.com';
    } else {
      // prod scenario
      result = '//accounts.bylynx.com';
    }
    return result;
  }

  static validateIPaddress(ipaddress: string) {
    return IP_REGEXP.test(ipaddress);
  }

  static _getParameterByName(paramName: string, search: Location) {
    const qry = search;
    const name = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(qry.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
