import { FC } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { camelCase } from 'lodash';
import { useIntl } from 'react-intl';

interface StageHeaderProps {
  stageNames: string[];
  currentStage: number;
  onStageChange: (stage: number) => void;
  isMobileView: boolean;
}

const StageHeader: FC<StageHeaderProps> = ({ stageNames, currentStage, onStageChange, isMobileView }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Box sx={{ width: '100%', background: isMobileView ? '#212121' : 'transparent', marginTop: isMobileView ? 0 : 3 }}>
      <Grid container columnGap={0.5}>
        {stageNames.map((name, index) => {
          const stageNumber = index + 1;
          const isCurrentStage = stageNumber === currentStage;
          const isUpcomingStage = currentStage < stageNumber;
          return (
            <Box
              sx={{
                backgroundColor: isUpcomingStage ? '#505050' : isCurrentStage ? theme.palette.primary.main : theme.palette.secondary.main,
                padding: theme.spacing(1, 3),
                cursor: !isUpcomingStage ? 'pointer' : 'default',
                boxSizing: 'border-box',
                minWidth: isMobileView ? '62px' : '142px'
              }}
              key={index}
              onClick={() => !isUpcomingStage && onStageChange(stageNumber)}
            >
              <Typography color="white" fontWeight="medium">
                {isUpcomingStage
                  ? ''
                  : `${stageNumber}. ${
                      isCurrentStage || !isMobileView ? intl.formatMessage({ id: `booking.stage.${camelCase(name)}`, defaultMessage: name }) : ''
                    }`}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default StageHeader;
