import { FC, useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ShopifyService from '../modules/shopify/ShopifyService';
import { inject } from 'saft-react';
import { Customer, customersWithFooterNavigation } from '../types/Customer';

interface InjectedProps {
  customer: Customer;
}

interface FooterProps {
  setHeight: (newValue: number) => void;
  shopifyService: ShopifyService;
}

const Footer: FC<FooterProps & InjectedProps> = ({ setHeight, shopifyService, customer }) => {
  const theme = useTheme();
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (customer === Customer.SplitExpress) {
      (async () => {
        const desc = await shopifyService.getShopDescription();
        setDescription(desc);
      })();
    }
  }, [customer, shopifyService]);

  return (
    <Box
      sx={{ backgroundColor: theme.footer?.backgroundColor ?? '#292929', position: 'absolute', bottom: 0, left: 0, right: 0 }}
      ref={(el: HTMLDivElement | null) => {
        if (!el) {
          return;
        }
        const newHeight = el.getBoundingClientRect().height;
        setHeight(newHeight);
      }}
    >
      <Container maxWidth="xl" sx={{ color: 'rgba(255,255,255,0.87)', fontSize: 14, paddingTop: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs="auto">
            <img src={theme.footer?.logo} height={theme.footer?.logoHeight} alt="Meerkat Ferries Logo" />
          </Grid>
          <Grid item xs>
            <Box sx={{ textAlign: 'left' }}>
              {customer === Customer.SplitExpress ? (
                <Typography sx={{ fontSize: 14 }}>{description}</Typography>
              ) : (
                <>
                  <Typography
                    color={theme.footer?.titleColor}
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      letterSpacing: '1.25px'
                    }}
                  >
                    <FormattedMessage id="footer.title" defaultMessage="Who are we?" />
                  </Typography>
                  <Typography sx={{ fontSize: 14, lineHeight: 1.75 }}>
                    <FormattedMessage
                      id="footer.description"
                      defaultMessage="Join the Meerkats on our newly launched ferry services from mainland Europe and the UK to Shell island and Sand Island. With our brand new fleet and our personalised customer app, your travel experience will be smooth and enjoyable every step of the way. Welcome onboard!"
                    />
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          {customersWithFooterNavigation.includes(customer) && (
            <Grid item xs="auto">
              <Box sx={{ textAlign: 'left' }}>
                <Typography
                  color={theme.footer?.titleColor}
                  sx={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    letterSpacing: '1.25px'
                  }}
                >
                  <FormattedMessage id="footer.contact" defaultMessage="Contact" />
                </Typography>
                <Box>
                  <div>
                    <FormattedMessage id="footer.faq" defaultMessage="FAQ" />
                  </div>
                  <div>
                    <FormattedMessage id="footer.emailUs" defaultMessage="Email to us" />
                  </div>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default inject('customer')(Footer as FC<{}>) as React.ElementType<FooterProps>;
