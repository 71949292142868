import cabins_two_bed from '../../images/cabins_two_bed.png';
import cabins_four_bed from '../../images/cabins_four_bed.png';
import cabins_reserved_seat from '../../images/cabins_reserve_seat.png';

export interface AccommodationInfo {
  name: string;
  description: string;
  capacity: number;
  type: 'room' | 'seat';
  price: number;
  image: string;
}

const AccommodationsList: AccommodationInfo[] = [
  {
    name: 'Two Bed Cabin',
    description:
      'two beds cabins feature side by side 31" beds and have private washrooms complete with shower.' +
      'cabins also include writing desk and stool as well as a tv with remote control',
    capacity: 2,
    type: 'room',
    price: 300,
    image: cabins_two_bed
  },
  {
    name: 'Four Bed Cabin',
    description:
      'two beds cabins feature side by side 31" beds and have private washrooms complete with shower.' +
      'cabins also include writing desk and stool as well as a tv with remote control',
    capacity: 4,
    type: 'room',
    price: 720,
    image: cabins_four_bed
  },
  {
    name: 'Reserve Seat',
    description:
      'two beds cabins feature side by side 31" beds and have private washrooms complete with shower.' +
      'cabins also include writing desk and stool as well as a tv with remote control',
    capacity: 1,
    type: 'seat',
    price: 80,
    image: cabins_reserved_seat
  }
];

export default class Accommodations {
  getAvailableAccommodations = async (): Promise<AccommodationInfo[]> => {
    return Promise.resolve(AccommodationsList);
  };
}
