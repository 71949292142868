import { createContext, useState, ReactNode } from 'react';

export enum JourneyType {
  RoundTrip = 'roundTrip',
  OneWay = 'oneWay',
  MultiStop = 'MultiStop'
}

export const JourneyTypeContext = createContext({ journeyType: JourneyType.OneWay, setJourneyType: (journeyType: JourneyType) => {} });

const JourneyTypeContextProvider = ({ children }: { children: ReactNode }) => {
  const [journeyType, setJourneyType] = useState(JourneyType.OneWay);

  return <JourneyTypeContext.Provider value={{ journeyType: journeyType, setJourneyType: setJourneyType }}>{children}</JourneyTypeContext.Provider>;
};

export default JourneyTypeContextProvider;
