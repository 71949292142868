import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useContext, useEffect, useState, useMemo, ElementType, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { BookingType, BookingTypeContext } from '../../context/BookingTypeContext';
import { ViewportContext } from '../../context/ViewportContext';
import ShopifyService, { BlogArticle, ShopifyProduct } from '../../modules/shopify/ShopifyService';
import { Page, StaticPageService } from '../../modules/static-page-service/StaticPageService';
import Header from '../Header';
import TravelBooking from '../TravelBooking';
import TravelSelector from '../TravelSelector';
import TravelBookingDisplay from './TravelBookingDisplay';
import ArticleCarousel from '../ArticleCarousel';
import { inject } from 'saft-react';
import { Customer } from '../../types/Customer';
import { isEmpty, isEqual } from 'lodash';
import NoticeBox from '../NoticeBox';
import DefaultSeoMetadata from '../DefaultSeoMetadata';
import { getTexts } from '../../settings/AppConstants';
import ArticleCard from '../ArticleCard';
import GDPRCookiesDialog from '../GDPRCookiesDialog';
import { RouteSummary } from 'modules/routing/Routes';
import { TravelBookingContext, TravelBookingRoute } from './TravelBookingContext';
import { getRouteIdentifier } from './ScheduleHelper';
import { RouteSummaryContext } from 'context/RouteSummaryContext';
import { JourneyType } from 'context/JourneyTypeContext';

interface Props {
  footerHeight: number;
}

interface InjectedProps {
  shopifyService: ShopifyService;
  customer: Customer;
  staticPageService: StaticPageService;
}

const getReturnRouteSummary = (routeSummary: RouteSummary) => {
  const returnRouteSummary = {
    ...routeSummary,
    origin: { ...routeSummary.destination },
    destination: { ...routeSummary.origin },
    departureDate: routeSummary.returnDate!
  };
  return returnRouteSummary;
};

const texts = getTexts();

const config = {
  [BookingType.Travel]: {
    header: <FormattedMessage id="header.travel" defaultMessage="Travel" />,
    imageUrl: '/img/travel.jfif',
    messages: texts.travelHeader,
    imageOpacity: 0.6
  }
};

const TravelPage: FC<Props & InjectedProps> = ({ shopifyService, footerHeight, customer, staticPageService }) => {
  const [operationRoutes, setOperationRoutes] = useState<ShopifyProduct[]>([]);
  const [destinationArticles, setDestinationArticles] = useState<BlogArticle[]>([]);
  const { setRoutes } = useContext(RouteSummaryContext);
  const theme = useTheme();
  const { bookingType } = useContext(BookingTypeContext);
  const { matchesBreakpoint } = useContext(ViewportContext);
  const { setTravelBookingRoutes, travelBookingRoutes, fetchReturnTicket } = useContext(TravelBookingContext);
  const [uncompleteCheckout, setUncompleteCheckout] = useState(false);

  const [searchExpanded, setSearchExpanded] = useState(true);
  const [notice, setNotice] = useState<Page | null>(null);

  const {
    header,
    imageUrl,
    messages: { travel, announcement },
    imageOpacity
  } = config[BookingType.Travel];

  const clearCheckoutState = () => {
    localStorage.removeItem('cartId');
    localStorage.removeItem('checkoutState');
    setUncompleteCheckout(false);
  };

  useEffect(() => {
    const getOperationRoutes = async () => {
      const operationalRoutes = await shopifyService.getOperationSailings();
      setOperationRoutes(operationalRoutes);
    };
    getOperationRoutes();

    const getDestinationArticles = async () => {
      const articles = await shopifyService.getBlogArticles('destinations');
      setDestinationArticles(articles);
    };
    getDestinationArticles();

    const getCart = async () => {
      const cartId = localStorage.getItem('cartId');
      if (cartId) {
        const cart = await shopifyService.getCartById(cartId);
        if (!cart) {
          clearCheckoutState();
        } else {
          setUncompleteCheckout(true);
        }
      }
    };
    getCart();
  }, [shopifyService]);

  useEffect(() => {
    if (uncompleteCheckout) {
      const { travelBookingRoutes: savedTravelBookingRoutes, routes: savedRoutes } = JSON.parse(localStorage.getItem('checkoutState') ?? '{}');
      if (isEmpty(travelBookingRoutes)) {
        setTravelBookingRoutes(savedTravelBookingRoutes);
      } else if (!isEqual(savedTravelBookingRoutes, travelBookingRoutes)) {
        clearCheckoutState();
      }
      setRoutes(savedRoutes);
    }
  }, [uncompleteCheckout, travelBookingRoutes, setTravelBookingRoutes, setRoutes]);

  useEffect(() => {
    const getNotice = async () => {
      const notice = await staticPageService.getNotice();
      setNotice(notice);
    };
    getNotice();
  }, [staticPageService]);

  const handleRouteSubmit = useCallback(
    (routeSummary: RouteSummary[], journeyType: JourneyType) => {
      clearCheckoutState();
      setTravelBookingRoutes([]);
      let travelBookingRoutes: TravelBookingRoute[] = [];
      if (routeSummary.length > 1) {
        travelBookingRoutes = routeSummary.map((r) => ({ routeSummary: r, identifier: getRouteIdentifier(r) }));
      } else {
        const route = routeSummary[0];
        const routes: RouteSummary[] = [];
        routes.push(route);
        if (route.returnDate) {
          routes.push(getReturnRouteSummary(route));
        }
        travelBookingRoutes = routes.map((r) => ({ routeSummary: r, identifier: getRouteIdentifier(r) }));
      }
      setSearchExpanded(false);
      setTravelBookingRoutes(travelBookingRoutes);
      fetchReturnTicket(travelBookingRoutes, journeyType);
    },
    [setTravelBookingRoutes, setSearchExpanded, fetchReturnTicket]
  );

  const carouselColumns = useMemo(() => {
    return !matchesBreakpoint('sm') ? 1 : !matchesBreakpoint('md') ? 2 : 3;
  }, [matchesBreakpoint]);

  const hasRoutes = useMemo(() => !isEmpty(travelBookingRoutes), [travelBookingRoutes]);

  return (
    <div>
      <DefaultSeoMetadata />
      <Header
        hideTabs={customer === Customer.SplitExpress}
        onLogoClick={() => {
          setTravelBookingRoutes([]);
        }}
        hideBookNow
      />
      <Box sx={{ ...theme.typography.body1, position: 'relative' }}>
        {(matchesBreakpoint('sm') || !hasRoutes) && (
          <Box sx={{ position: 'relative', width: '100%', paddingBottom: 4 }}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                backgroundImage: `url(${theme.headerImage?.[bookingType] ?? imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                backgroundColor: 'black',
                '&:before': {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'black',
                  opacity: imageOpacity
                }
              }}
            ></Box>
            <Container
              maxWidth="xl"
              sx={{
                paddingTop: matchesBreakpoint('sm') ? 15 : 10,
                zIndex: 2,
                position: 'relative',
                minHeight: matchesBreakpoint('sm') ? 300 : 180
              }}
            >
              {!hasRoutes && (
                <Box
                  sx={{
                    '& .MuiInputBase-root': { fontWeight: 'medium', backgroundColor: 'info.light' },
                    '& .MuiSelect-root': { backgroundColor: 'primary.light' },
                    display: 'flex',
                    height: '100%'
                  }}
                >
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontSize: matchesBreakpoint('sm') ? 20 : 14 }} className="header">
                      {header}
                    </Typography>
                    <Box component="span" sx={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                      <Typography sx={{ fontSize: matchesBreakpoint('sm') ? 34 : 22 }} className="message">
                        <FormattedMessage id={travel.id} defaultMessage={travel.defaultMessage} />
                      </Typography>
                      {announcement && (
                        <Typography sx={{ fontSize: matchesBreakpoint('sm') ? 34 : 22 }} className="message">
                          <FormattedMessage id={announcement.id} defaultMessage={announcement.defaultMessage} />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>
          </Box>
        )}
      </Box>
      <Container maxWidth="xl" disableGutters={!matchesBreakpoint('sm')}>
        {!matchesBreakpoint('sm') && !searchExpanded && hasRoutes && <TravelBookingDisplay onExpand={() => setSearchExpanded(true)} />}
        {!matchesBreakpoint('sm') && searchExpanded && hasRoutes && (
          <Box sx={{ padding: 2, paddingTop: 14, position: 'relative', background: theme.background?.main ?? '#212121', boxSizing: 'border-box' }}>
            <IconButton onClick={() => setSearchExpanded(false)} sx={{ position: 'absolute', right: 16, zIndex: 50, top: 76 }}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <TravelBooking onRouteSearch={handleRouteSubmit} uncompleteCheckout={uncompleteCheckout} />
          </Box>
        )}
        {(matchesBreakpoint('sm') || !hasRoutes) && (
          <Box sx={{ marginTop: matchesBreakpoint('sm') ? -14 : 0, zIndex: 4, position: 'relative' }}>
            <TravelBooking onRouteSearch={handleRouteSubmit} uncompleteCheckout={uncompleteCheckout} />
          </Box>
        )}
        <Box sx={{ paddingBottom: 8 }}>
          <Grid container spacing={2}>
            {!hasRoutes && notice?.body && (
              <Grid item xs={12} sm={12} md={4} xl={3}>
                <Box sx={{ marginTop: 3.5 }}>
                  <NoticeBox notice={notice} />
                </Box>
              </Grid>
            )}
            {!hasRoutes && destinationArticles.length > 0 && matchesBreakpoint('sm') && (
              <Grid item xs={12} sm={12} md={notice?.body ? 8 : 12} xl={notice?.body ? 9 : 12} sx={{ marginTop: 2 }}>
                <ArticleCarousel blogHandle="destinations" articles={destinationArticles} columns={carouselColumns} />
              </Grid>
            )}
            {!hasRoutes && destinationArticles.length > 0 && !matchesBreakpoint('sm') && (
              <Grid item xs={12}>
                {destinationArticles.map((article) => (
                  <Box sx={{ height: theme.article?.card?.height ?? 'auto', padding: 0.5, marginTop: 2 }}>
                    <ArticleCard blogHandle="destinations" article={article} />
                  </Box>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              {bookingType === BookingType.Travel && hasRoutes && (
                <TravelSelector operationRoutes={operationRoutes} footerHeight={footerHeight} hasRoutes={hasRoutes} uncompleteCheckout={uncompleteCheckout} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <GDPRCookiesDialog />
    </div>
  );
};

export default inject('shopifyService', 'customer', 'staticPageService')(TravelPage as FC<{}>) as ElementType<Props>;
