import { FC, useEffect, useRef, useContext, useMemo } from 'react';
import PrefillHeader from '../PrefillHeader';
import PassengerCard from './PassengerCard';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { PassengerState, PassengerInfo } from '../../types/BookingState';
import { capitalize } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { RouteSummaryContext } from '../../context/RouteSummaryContext';

const styles = {
  root: {
    padding: 24
  }
};

interface PassengerInfoStageProps {
  passengerSummary: PassengerSummary;
  state: PassengerState;
  setState: (newState: PassengerState) => void;
  setIsFormValid: (newState: boolean) => void;
}

const getPassengerList = (passengerSummary: Record<string, number>) => {
  const passengerList = [];
  for (const [key, value] of Object.entries(passengerSummary)) {
    for (let i = 0; i < value; i++) {
      const passengerType = capitalize(key).replace('count', '');
      passengerList.push(passengerType);
    }
  }
  return passengerList;
};

const defaultPassenger = {
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  phoneNumber: '',
  id: uuid()
} as PassengerInfo;

const PassengerInfoStage: FC<PassengerInfoStageProps> = ({ state, setState, setIsFormValid }) => {
  const { control, handleSubmit, formState, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      passengers: getPassengerList(state.passengerSummary).map(() => defaultPassenger)
    }
  });

  const { routes } = useContext(RouteSummaryContext);
  const { fields } = useFieldArray({ control, name: 'passengers' });

  const requiresPassport = useMemo(
    () => !!routes?.some((r1) => routes.some((r2) => r1.destination?.passportObligated?.includes(r2.origin.countryCode))),
    [routes]
  );

  useEffect(() => {
    if (state.passengers?.length > 0) {
      setValue('passengers', state.passengers);
    } else {
      reset({
        passengers: getPassengerList(state.passengerSummary).map(() => defaultPassenger)
      });
    }
  }, [state, setState, setValue, reset]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid, setIsFormValid]);

  const save = useRef({ state, setState, handleSubmit });

  useEffect(() => {
    save.current = { state, setState, handleSubmit };
  }, [state, setState, handleSubmit]);

  useEffect(() => {
    return () => {
      const { state, setState, handleSubmit } = save.current;

      handleSubmit((data) => {
        setState(Object.assign(state, data));
      })();
    };
  }, []);

  return (
    <div style={styles.root}>
      <PrefillHeader />
      {fields.map((field, index) => (
        <PassengerCard
          key={field.id}
          control={control}
          type={getPassengerList(state.passengerSummary)[index]}
          index={index}
          formState={formState}
          requiresPassport={requiresPassport}
        />
      ))}
    </div>
  );
};

export default PassengerInfoStage;
