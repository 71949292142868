import { VehicleType } from '../../context/VehicleTypeContext';
import { Dimensions } from '../../types/BookingState';

export const vehicles = [
  {
    category: 'Driver accompanied',
    items: [
      {
        name: 'Lorry',
        dimensions: {
          length: 22,
          height: 4,
          width: 2.5
        },
        weight: { tare: 4000 }
      },
      {
        name: 'Small Freight Vehicle',
        dimensions: {
          length: 4.6,
          height: 2,
          width: 2.2,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Military Vehicle',
        dimensions: {
          length: 4.6,
          height: 2,
          width: 2.2,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Van',
        dimensions: {
          length: 4.6,
          height: 2,
          width: 2.2,
          weight: { tare: 3000 }
        }
      }
    ]
  },
  {
    category: 'Wheeled and motorized',
    items: [
      {
        name: 'Car',
        dimensions: {
          length: 4.2,
          height: 1.5,
          width: 1.8,
          weight: { tare: 1500 }
        }
      },
      {
        name: 'Export Machinery',
        dimensions: {
          length: 3.2,
          height: 2.4,
          width: 1.8,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Chassis',
        dimensions: {
          length: 3.2,
          height: 2.4,
          width: 1.8,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Harvester',
        dimensions: {
          length: 3.2,
          height: 2.4,
          width: 1.8,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Tractor',
        dimensions: {
          length: 3.2,
          height: 2.4,
          width: 1.8,
          weight: { tare: 3000 }
        }
      },
      {
        name: 'Unaccompanied Lorry',
        dimensions: {
          length: 22,
          height: 4,
          width: 2.5,
          weight: { tare: 4000 }
        }
      },
      {
        name: 'Unaccompanied Small Freight Vehicle',
        dimensions: {
          length: 4.6,
          height: 2,
          width: 2.2,
          weight: { tare: 3000 }
        }
      }
    ]
  },
  {
    category: 'Wheeled and pulled',
    items: [
      { name: 'Trailer', weight: { tare: 7000 }, dimensions: { length: 13.6, width: 2.6, height: 4.2 } },
      { name: 'Caravan', weight: { tare: 7000 }, dimensions: { length: 13.6, width: 2.6, height: 4.2 } },
      { name: 'Tanker', weight: { tare: 7000 }, dimensions: { length: 13.6, width: 2.6, height: 4.2 } }
    ]
  },
  {
    category: 'Lift',
    items: [
      { name: 'Container', weight: { tare: 4500 }, dimensions: { length: 13.6, width: 2.6, height: 4.2 } },
      { name: 'Lift Tank', weight: { tare: 4500 }, dimensions: { length: 6, width: 2.6, height: 4.2 } },
      {
        name: 'Swap Body',
        weight: { tare: 4500 },
        dimensions: {
          length: 13.6,
          height: 4.2,
          width: 2.6
        }
      },
      {
        name: 'Flat',
        weight: { tare: 4500 },
        dimensions: {
          length: 13.6,
          height: 4.2,
          width: 2.6
        }
      }
    ]
  },
  {
    category: 'General cargo',
    items: [
      {
        name: 'General',
        dimensions: {
          length: 6.06,
          height: 2.59,
          width: 2.43
        },
        weight: { tare: 3000 }
      }
    ]
  }
];

export default class Vehicles {
  private getVehicle(vehicleType: VehicleType) {
    return vehicles.find((vehicleGroup) => vehicleGroup.items.map((item) => item.name).includes(vehicleType))?.items.find((item) => item.name === vehicleType);
  }

  isAccompanied(vehicleType: VehicleType): Promise<boolean> {
    return Promise.resolve(
      Boolean(
        vehicles
          .find((vehicleGroup) => vehicleGroup.category === 'Driver accompanied')
          ?.items.map((item) => item.name)
          .includes(vehicleType)
      )
    );
  }

  hasTrailerReg(vehicleType: VehicleType): Promise<boolean> {
    switch (vehicleType) {
      case VehicleType.TRL:
        return Promise.resolve(true);
      case VehicleType.TNK:
        return Promise.resolve(true);
      case VehicleType.FLT:
        return Promise.resolve(true);
      case VehicleType.SDV:
        return Promise.resolve(true);
      case VehicleType.SFV:
        return Promise.resolve(true);
      case VehicleType.MIL:
        return Promise.resolve(true);
      case VehicleType.UNNSDV:
        return Promise.resolve(true);
      case VehicleType.UNNSFV:
        return Promise.resolve(true);
      default:
        return Promise.resolve(false);
    }
  }

  hasVehicleReg(vehicleType: VehicleType): Promise<boolean> {
    switch (vehicleType) {
      case VehicleType.TRL:
        return Promise.resolve(false);
      case VehicleType.TNK:
        return Promise.resolve(false);
      case VehicleType.CNT:
        return Promise.resolve(false);
      case VehicleType.LT:
        return Promise.resolve(false);
      case VehicleType.SB:
        return Promise.resolve(false);
      case VehicleType.FLT:
        return Promise.resolve(false);
      case VehicleType.GEN:
        return Promise.resolve(false);
      default:
        return Promise.resolve(true);
    }
  }

  getDefaultDimensions(vehicleType: VehicleType): Promise<Dimensions> {
    return Promise.resolve(this.getVehicle(vehicleType)?.dimensions ?? { length: 0, width: 0, height: 0 });
  }

  getDefaultTareWeight(vehicleType: VehicleType): Promise<number> {
    return Promise.resolve(this.getVehicle(vehicleType)?.weight?.tare ?? 0);
  }
}
