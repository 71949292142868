import { createContext, ReactNode, useCallback, useState } from 'react';
import { CartAttribute, GraphProduct, GraphProductVariant } from '../../modules/shopify/ShopifyService';
import { ProductType } from '../../types/PosTypes';

export interface Trip {
  date: Date;
  ticket: GraphProduct;
  sailingLegs?: GraphProduct[];
  capacityLeft?: number | null;
}

export interface CartItem {
  variant: GraphProductVariant;
  quantity: number;
  productType: ProductType;
}

export enum PaymentMethod {
  Cash = 'Cash',
  BankPOS = 'Bank POS',
  CreditCard = 'Credit Card'
}

export interface PaymentSummary {
  method: PaymentMethod;
  currencyCode: string;
  price: number | string;
}

type SetTrip = (index: number, trip: Trip) => void;
type UpdateCartItem = (cartItem: CartItem) => void;
type ClearCart = () => void;
type RemoveTrip = (index: number) => void;
type SetCartAttributes = (cartAttributes: CartAttribute[]) => void;
type SetPaymentSummary = (paymentSummary: PaymentSummary | undefined) => void;

export interface TicketSelectorState {
  trips: Trip[];
  cartItems: CartItem[];
  setTrip: SetTrip;
  updateCartItem: UpdateCartItem;
  clearCart: ClearCart;
  removeTrip: RemoveTrip;
  cartAttributes: CartAttribute[];
  setCartAttributes: SetCartAttributes;
  paymentSummary: PaymentSummary | undefined;
  setPaymentSummary: SetPaymentSummary;
}

export const PosContext = createContext<TicketSelectorState>(undefined!);

export const PosProvider = ({ children }: { children: ReactNode }) => {
  const [trips, setTrips] = useState<Trip[]>([]);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [cartAttributes, setCartAttributes] = useState<CartAttribute[]>([]);
  const [paymentSummary, setPaymentSummary] = useState<PaymentSummary>();

  const setTrip: SetTrip = useCallback((index, trip) => {
    setTrips((prevTrips) => {
      const newTrips = [...prevTrips];
      newTrips.splice(index, 1, trip);
      return newTrips;
    });
  }, []);

  const updateCartItem: UpdateCartItem = useCallback((cartItem) => {
    setCartItems((cartItems) => {
      const newCartItems = [...cartItems];
      const index = cartItems.findIndex((ct) => ct.variant.id === cartItem.variant.id);
      if (index > -1) {
        newCartItems.splice(index, 1, cartItem);
      } else {
        newCartItems.push(cartItem);
      }
      return newCartItems;
    });
  }, []);

  const removeTrip: RemoveTrip = useCallback((index) => {
    setTrips((prevTrips) => {
      const newTrips = [...prevTrips];
      newTrips.splice(index, 1);
      return newTrips;
    });
  }, []);

  const clearCart: ClearCart = useCallback(() => {
    setCartItems([]);
  }, []);

  return (
    <PosContext.Provider
      value={{
        trips,
        cartItems,
        setTrip,
        updateCartItem,
        clearCart,
        removeTrip,
        cartAttributes,
        setCartAttributes,
        paymentSummary,
        setPaymentSummary
      }}
    >
      {children}
    </PosContext.Provider>
  );
};
