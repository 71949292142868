import { createContext, useState, ReactNode } from 'react';

export const LocaleContext = createContext({ locale: 'en', setLocale: (locale: string) => {} });

const LocaleContextProvider = ({ children }: { children: ReactNode }) => {
  const [locale, setLocale] = useState('en');

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};

export default LocaleContextProvider;
