import { createContext, useState, ReactNode } from 'react';

export enum BookingType {
  Travel = 'travel',
  Freight = 'freight',
  DoorToDoorFreight = 'doorToDoorFreight'
}

export const BookingTypeContext = createContext({ bookingType: BookingType.Travel, setBookingType: (bookingType: BookingType) => {} });

const BookingTypeContextProvider = ({ children }: { children: ReactNode }) => {
  const [bookingType, setBookingType] = useState(BookingType.Travel);

  return <BookingTypeContext.Provider value={{ bookingType: bookingType, setBookingType: setBookingType }}>{children}</BookingTypeContext.Provider>;
};

export default BookingTypeContextProvider;
