import { HazardousGoods, TemperatureOptions, VehicleState, BookingState } from '../types/BookingState';

export const emptyHazardousGoods: HazardousGoods = {
  unNumber: '',
  imoCode: '',
  class: '',
  flashpoint: '',
  goodsUnitType: '',
  packageGroup: '',
  packageInfo: '',
  technicalName: '',
  weight: 0,
  limitedQuantity: false,
  marinePollutant: false
};

export const emptyTemperatureOptions: TemperatureOptions = {
  originQuay: false,
  vessel: false,
  destinationQuay: false,
  plugin: false,
  engineRunning: false
};

export const emptyVehicleState: VehicleState = {
  outboundVehicle: {
    vehicleType: '',
    vehicleRegistration: '',
    trailerRegistration: '',
    dimensions: {
      length: 0,
      width: 0,
      height: 0
    },
    measurements: {
      grossWeight: 0,
      netWeight: 0,
      tareWeight: 0
    },
    goodsDescription: '',
    hazardous: false,
    temperatureControlled: false,
    livestock: false
  }
};

export const emptyBookingState: BookingState = {
  scheduleState: {
    totalPrice: 0
  },
  vehicleState: emptyVehicleState,
  cabinState: {
    selectedAccommodations: { outboundRoute: [] },
    totalPrice: 0
  },
  passengerState: {
    passengerSummary: {},
    passengers: []
  },
  extraState: {
    selectedExtras: [],
    emailEnabled: false,
    smsEnabled: false,
    totalPrice: 0,
    email: '',
    phoneNumber: ''
  }
};

export const emptyTravelBookingState: BookingState = {
  scheduleState: {
    totalPrice: 0
  },
  vehicleState: emptyVehicleState,
  cabinState: {
    selectedAccommodations: { outboundRoute: [] },
    totalPrice: 0
  },
  passengerState: {
    passengerSummary: {
      adult: 1
    },
    passengers: []
  },
  extraState: {
    selectedExtras: [],
    emailEnabled: false,
    smsEnabled: false,
    totalPrice: 0,
    email: '',
    phoneNumber: ''
  }
};
