import { FC, useMemo, useContext } from 'react';
import { TextField, MenuItem, Grid, Typography, TextFieldProps } from '@mui/material';
import { PassengerInfo } from '../../types/BookingState';
import { isEmailValid, isPhoneNumberValid } from '../../utils/TextValidation';
import { ViewportContext } from '../../context/ViewportContext';
import { Control, Controller, FormState } from 'react-hook-form';
import { DatePicker } from '@mui/lab';
import { useIntl, FormattedMessage } from 'react-intl';
import { RouteSummaryContext } from '../../context/RouteSummaryContext';
import { differenceInDays } from 'date-fns';

const styles = {
  root: {
    textAlign: 'left' as 'left',
    marginBottom: 4
  },
  header: {
    borderBottom: '1px solid',
    borderColor: '#dadada',
    marginBottom: 4
  }
};

interface PassengerCardStageProps {
  type: string;
  index: number;
  control: Control<{ passengers: PassengerInfo[] }, object>;
  formState: FormState<{ passengers: PassengerInfo[] }>;
  requiresPassport: boolean;
}

const PassengerCardStage: FC<PassengerCardStageProps> = ({ type, index, control, formState, requiresPassport }) => {
  const { errors } = formState;
  const { formatMessage } = useIntl();

  const { routes } = useContext(RouteSummaryContext);
  const { matchesBreakpoint } = useContext(ViewportContext);
  const shouldRenderBuffer = useMemo(() => {
    return matchesBreakpoint('md');
  }, [matchesBreakpoint]);

  const latestRouteDeparture = useMemo(() => {
    return routes
      ? routes.reduce(
          (min, curr) =>
            (curr.departureDate || new Date()).valueOf() > min.departureDate.valueOf() ? { departureDate: curr.departureDate || new Date() } : min,
          { departureDate: new Date(0) }
        ).departureDate
      : new Date();
  }, [routes]);

  return (
    <Grid container sx={styles.root}>
      <Grid container item xs sx={styles.header}>
        <Typography variant="h6" gutterBottom>{`Passenger ${index + 1} (${type})`}</Typography>
      </Grid>
      <Grid
        container
        item
        spacing={{
          xs: 2,
          sm: 4
        }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            key="firstName"
            control={control}
            name={`passengers.${index}.firstName`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField {...field} autoFocus required label={<FormattedMessage id="booking.passengerInfo.firstName" defaultMessage="First name" />} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            key="lastName"
            control={control}
            name={`passengers.${index}.lastName`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField {...field} required label={<FormattedMessage id="booking.passengerInfo.lastName" defaultMessage="Last name" />} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Controller
            key="gender"
            control={control}
            name={`passengers.${index}.gender`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label={<FormattedMessage id="booking.passengerInfo.gender" defaultMessage="Gender" />}
                select
                onChange={(event) => {
                  field.onChange(event.target.value as PassengerInfo['gender']);
                }}
              >
                <MenuItem key={'Woman'} value={'woman'}>
                  <FormattedMessage id="booking.passengerInfo.woman" defaultMessage="Woman" />
                </MenuItem>
                <MenuItem key={'Man'} value={'man'}>
                  <FormattedMessage id="booking.passengerInfo.man" defaultMessage="Man" />
                </MenuItem>
                <MenuItem key={'Non-binary'} value={'non-binary'}>
                  <FormattedMessage id="booking.passengerInfo.nonBinary" defaultMessage="Non-binary" />
                </MenuItem>
                <MenuItem key={'Prefer not to say'} value={'undisclosed'}>
                  <FormattedMessage id="booking.passengerInfo.undisclosed" defaultMessage="Prefer not to say" />
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
        {shouldRenderBuffer && <Grid item md={3} />}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            key="email"
            control={control}
            name={`passengers.${index}.email`}
            rules={{ required: true, validate: (email: string) => isEmailValid(email) }}
            render={({ field }) => (
              <TextField
                {...field}
                label={<FormattedMessage id="booking.passengerInfo.email" defaultMessage="Email" />}
                required
                helperText={
                  errors?.passengers?.[index]?.email && formatMessage({ id: 'booking.passengerInfo.emailError', defaultMessage: 'Must be a valid email' })
                }
                error={!!errors?.passengers?.[index]?.email}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            key="phoneNumber"
            control={control}
            name={`passengers.${index}.phoneNumber`}
            rules={{ required: true, validate: (phoneNumber: string) => isPhoneNumberValid(phoneNumber) }}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value.replace(/ /g, ''));
                }}
                label={<FormattedMessage id="booking.passengerInfo.phoneNumber" defaultMessage="Phone number" />}
                required
                error={!!errors?.passengers?.[index]?.phoneNumber}
                helperText={
                  errors?.passengers?.[index]?.phoneNumber && (
                    <FormattedMessage id="booking.passengerInfo.phoneError" defaultMessage="Must be a valid phone number" />
                  )
                }
              />
            )}
          />
        </Grid>
        {requiresPassport && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                key="passportNumber"
                control={control}
                name={`passengers.${index}.passportNumber`}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField {...field} label={<FormattedMessage id="booking.passengerInfo.passportNumber" defaultMessage="Passport number" />} required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                key="passportExpiryDate"
                control={control}
                name={`passengers.${index}.passportExpiryDate`}
                rules={{
                  required: true,
                  validate: (expiryDate: Date | null | undefined) => !!(expiryDate && differenceInDays(expiryDate, latestRouteDeparture) > 0)
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value || null}
                    disableOpenPicker
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        label={<FormattedMessage id="booking.passengerInfo.passportExpiryDate" defaultMessage="Passport expiry date" />}
                        error={!!errors?.passengers?.[index]?.passportExpiryDate}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PassengerCardStage;
