import { Box, Container, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { BookingType, BookingTypeContext } from '../../context/BookingTypeContext';
import { ViewportContext } from '../../context/ViewportContext';
import { Route, Routes } from 'react-router-dom';
import './BookingForm.css';
import FreightBooking from '../FreightBooking';
import { FormattedMessage } from 'react-intl';
import { RouteSummaryContext } from '../../context/RouteSummaryContext';

const config = {
  [BookingType.Travel]: {
    header: <FormattedMessage id="header.travel" defaultMessage="Travel" />,
    imageUrl: '/img/travel.jfif',
    message: <FormattedMessage id="booking.header.message.travel" defaultMessage="Welcome! Find the best trip for your next journey" />,
    imageOpacity: 0.4
  },
  [BookingType.Freight]: {
    header: <FormattedMessage id="header.freight" defaultMessage="Freight" />,
    imageUrl: '/img/freight.jfif',
    message: <FormattedMessage id="booking.header.message.freight" defaultMessage="Find your best freight transporation" />,
    imageOpacity: 0.4
  },
  [BookingType.DoorToDoorFreight]: {
    header: <FormattedMessage id="header.doorToDoor" defaultMessage="Door to door freight" />,
    imageUrl: '/img/door-to-door.jfif',
    message: <FormattedMessage id="booking.header.message.doorToDoor" defaultMessage="Find your best and easy way to receive your packages" />,
    imageOpacity: 0.4
  }
};

const BookingForm: FC = () => {
  const theme = useTheme();
  const { bookingType } = useContext(BookingTypeContext);
  const { header, imageUrl, message, imageOpacity } = config[bookingType ?? BookingType.Travel];
  const { matchesBreakpoint } = useContext(ViewportContext);
  const { routes } = useContext(RouteSummaryContext);

  return (
    <Box sx={theme.typography.body1}>
      {(matchesBreakpoint('sm') || !routes) && (
        <Box sx={{ position: 'relative', width: '100%', paddingBottom: 4 }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              backgroundImage: `url(${theme.headerImage?.[bookingType] ?? imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: 'black',
              '&:before': {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'black',
                opacity: imageOpacity
              }
            }}
          ></Box>
          <Container
            maxWidth="xl"
            sx={{
              paddingTop: matchesBreakpoint('sm') ? 10 : 15,
              zIndex: 2,
              position: 'relative'
            }}
          >
            <Box
              sx={{
                '& .MuiInputBase-root': { fontWeight: 'medium', backgroundColor: 'info.light' },
                '& .MuiSelect-root': { backgroundColor: 'primary.light' }
              }}
            >
              <Box sx={{ textAlign: 'left' }}>
                <div className="header">{header}</div>
                <div className="message">{message}</div>
              </Box>
              {matchesBreakpoint('sm') && (
                <Routes>
                  <Route path="freight" element={<FreightBooking />} />
                </Routes>
              )}
            </Box>
          </Container>
        </Box>
      )}
      {!matchesBreakpoint('sm') && (
        <Routes>
          <Route path="freight" element={<FreightBooking />} />
        </Routes>
      )}
    </Box>
  );
};

export default BookingForm;
