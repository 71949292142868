import { List, ListItem, ListItemButton, ListItemText, ListItemIcon, Box } from '@mui/material';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GraphProduct, GraphProductEdge } from '../../modules/shopify/ShopifyService';
import { useCallback } from 'react';
import { compact, min } from 'lodash';
import { getDisplayLocation } from '../../utils/PosUtils';
import { FormattedMessage } from 'react-intl';

interface Props {
  tickets: GraphProductEdge[];
  sailingLegs: GraphProductEdge[];
  onSelectTicket: (ticket: GraphProduct, capacityLeft?: number | null) => void;
  origin: string;
}

const findSeatVariants = (ticket: GraphProductEdge, sailingLegs: GraphProductEdge[]) => {
  const allSeatVariants = compact(sailingLegs.map((edge) => edge.node.variants.edges.find((variantEdge) => variantEdge.node.title.toLowerCase() === 'seats')));
  try {
    const affectedInventoryProductIds = JSON.parse(ticket.node.affectedInventoryProductIds?.value ?? '[]') as string[];
    return allSeatVariants.filter((variant) => affectedInventoryProductIds.includes(variant.node.id));
  } catch (e) {
    return [];
  }
};

const TicketList = (props: Props) => {
  const { tickets, onSelectTicket, sailingLegs } = props;

  const getTicketAvailability = useCallback(
    (ticket: GraphProductEdge) => {
      const variants = findSeatVariants(ticket, sailingLegs);
      return min(variants.map(({ node }) => (!node.availableForSale ? 0 : node.quantityAvailable ?? 0)));
    },
    [sailingLegs]
  );

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <List component="nav" aria-label="main mailbox folders">
        {tickets.map((ticket) => {
          const primaryText = `${getDisplayLocation(ticket.node.origin?.value)} - ${getDisplayLocation(ticket.node.destination?.value)}`;
          const timeText = `${ticket.node.departureTime?.value} - ${ticket.node.arrivalTime?.value}`;
          const capacityLeft = getTicketAvailability(ticket);
          const isDisabled = !capacityLeft;
          return (
            <ListItem key={ticket.node.id} component="nav" aria-label="main mailbox folders" sx={{ borderBottom: '1px solid #ddd', padding: 0 }}>
              <ListItemButton disabled={isDisabled} onClick={() => onSelectTicket(ticket.node, capacityLeft)} sx={{ padding: 2 }}>
                <ListItemText primary={primaryText} secondary={timeText} />
                {isDisabled ? (
                  <Box sx={{ color: '#C62828' }}>
                    <FormattedMessage id="no_seat_available" defaultMessage="No seat available" />
                  </Box>
                ) : (
                  <>
                    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                      {capacityLeft} <FormattedMessage id="seats" defaultMessage="seats" />
                    </Box>
                    <ListItemIcon sx={{ minWidth: 16 }}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </ListItemIcon>
                  </>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TicketList;
