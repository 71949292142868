import { FC, createContext } from 'react';
import Authenticator from './Authenticator';

export const AuthenticatorContext = createContext({ authenticator: new Authenticator() });

const AuthenticatorProvider: FC = ({ children }) => {
  const authenticator = new Authenticator();

  return <AuthenticatorContext.Provider value={{ authenticator }}>{children}</AuthenticatorContext.Provider>;
};

export default AuthenticatorProvider;
