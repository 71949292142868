import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BookingTypeContextProvider from './context/BookingTypeContext';
import RouteSummaryContextProvider from './context/RouteSummaryContext';
import ViewportContextProvider from './context/ViewportContext';
import ThemeContextProvider from './context/ThemeContext';
import LocaleContextProvider from './context/LocaleContext';
import { CUSTOMER } from './settings/AppConstants';

ReactDOM.render(
  <React.StrictMode>
    <BookingTypeContextProvider>
      <RouteSummaryContextProvider>
        <ThemeContextProvider customer={CUSTOMER}>
          <ViewportContextProvider>
            <LocaleContextProvider>
              <App />
            </LocaleContextProvider>
          </ViewportContextProvider>
        </ThemeContextProvider>
      </RouteSummaryContextProvider>
    </BookingTypeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
