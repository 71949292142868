import { FC, useContext } from 'react';
import { Button, Grid, styled, Typography, Theme, Box } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { ResetStatesContext } from '../../context/ResetStatesContext';
import { FormattedMessage } from 'react-intl';
import { ViewportContext } from '../../context/ViewportContext';
import { BookingStateContext } from '../../context/BookingStateContext';
import { inject } from 'saft-react';
import Header from '../Header';

const ActionButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  width: '100%'
}));

interface InjectedProps {
  customer: string;
}

const BookingConfirmation: FC<InjectedProps> = ({ customer }) => {
  const { matchesBreakpoint } = useContext(ViewportContext);
  const resetStates = useContext(ResetStatesContext);
  const { resetBookingState } = useContext(BookingStateContext);

  const params = new URLSearchParams(window.location.search);
  const orderId = params.get('orderId') ?? '';

  return (
    <Box paddingTop={4}>
      {customer === 'splitexpress' && <Header />}
      <Grid container rowSpacing={4} sx={{ color: 'white', marginTop: 4, marginBottom: 36, padding: 2, justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Typography fontSize={32} fontWeight="bold">
            <FormattedMessage id="booking.confirmation.completed" defaultMessage="Your booking is completed!" />
          </Typography>
        </Grid>
        <Grid container item xs={12} columnSpacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item>
            <Typography fontSize={24} fontWeight="medium">
              <FormattedMessage id="portal.bookings.bookingReference" defaultMessage="Booking Reference" />:
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={24} fontWeight="medium">
              {customer && orderId ? orderId : 'MKF007495'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={18}>
            <FormattedMessage id="booking.confirmation.success" defaultMessage="You have successfully booked your next trip with us!" />
          </Typography>
          <Typography fontSize={18}>
            <FormattedMessage id="booking.confirmation.email" defaultMessage="A booking confirmation has been sent to your email." />
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={10}
          sm={6}
          md={6}
          lg={9}
          gap={[2, 2]}
          sx={{
            display: 'flex',
            flexDirection: matchesBreakpoint('md') ? 'row' : 'column',
            '& .MuiGrid-root': { flex: '1 1 0', flexWrap: matchesBreakpoint('lg') ? 'nowrap' : 'wrap' }
          }}
        >
          <Grid item container gap={[2, 2]}>
            <ActionButton variant="contained" startIcon={<PrintIcon />}>
              <FormattedMessage id="booking.confirmation.printBtn" defaultMessage="PRINT YOUR BOOKING" />
            </ActionButton>
            <ActionButton variant="contained" startIcon={<DownloadIcon />}>
              <FormattedMessage id="booking.confirmation.download" defaultMessage="DOWNLOAD BOARDING CARD" />
            </ActionButton>
          </Grid>
          <Grid item container gap={[2, 2]}>
            <ActionButton variant="contained">
              <FormattedMessage id="booking.confirmation.detailsBtn" defaultMessage="ORDER DETAILS" />
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={() => {
                resetStates();
                resetBookingState();
              }}
            >
              <FormattedMessage id="booking.confirmation.createNewBtn" defaultMessage="CREATE NEW ORDER" />
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default inject('customer')(BookingConfirmation as FC<{}>) as React.ElementType;
