import { Box, Paper, Typography } from '@mui/material';
import React, { ElementType, FC, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject } from 'saft-react';
import { ViewportContext } from '../context/ViewportContext';
import { Page, StaticPageService } from '../modules/static-page-service/StaticPageService';

interface Props {
  notice: Page;
}

interface InjectedProps {
  staticPageService: StaticPageService;
}

const NoticeBox: FC<Props & InjectedProps> = (props) => {
  const { notice } = props;
  const { matchesBreakpoint } = useContext(ViewportContext);

  const bodyElement = useMemo(() => <div dangerouslySetInnerHTML={{ __html: notice?.body ?? '' }} />, [notice?.body]);

  return (
    <Paper
      sx={{
        backgroundColor: '#3B9FC3',
        color: 'white',
        maxHeight: matchesBreakpoint('sm') ? 500 : 'auto',
        marginTop: matchesBreakpoint('sm') ? 0 : 4,
        overflowY: 'auto'
      }}
    >
      <Box sx={{ padding: 2}}>
        <Typography sx={{ borderBottom: '1px solid white', letterSpacing: '1.5px' }} variant="h6">
          <FormattedMessage id="notice.notice" defaultMessage="Notice" />
        </Typography>
        <Box sx={{ textAlign: 'left' }}>{bodyElement}</Box>
      </Box>
    </Paper>
  );
};

export default inject('staticPageService')(NoticeBox as FC<{}>) as ElementType<Props>;
