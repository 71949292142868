import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import ShopifyService from '../shopify/ShopifyService';
import { Customer } from '../../types/Customer';
import { uniqBy } from 'lodash';
import { filterDestinationBasedOnDefinedRoute, SailingLocation } from 'components/travel/ScheduleHelper';

export type LocationCode = string;
type CountryCode = string;
export type LocationSummary = {
  name: string;
  code: LocationCode;
  countryCode: CountryCode;
  passportObligated?: CountryCode[];
  title?: string;
};

export type RouteSummary = {
  origin: LocationSummary;
  destination: LocationSummary;
  departureDate: Date | null;
  returnDate?: Date | null;
};

const locations: LocationSummary[] = [
  { countryCode: 'UK', code: 'NPB', name: 'North Pudding Bay' },
  { countryCode: 'UK', code: 'SRH', name: 'South Rock Harbour' },
  { countryCode: 'FR', code: 'MKT', name: 'Meerkat Town' },
  { countryCode: 'FR', code: 'PCD', name: 'Port Cod' },
  { countryCode: 'NL', code: 'TPH', name: 'Tulip Haven', passportObligated: ['UK', 'FR'] },
  { countryCode: 'FR', code: 'CVB', name: 'Chocolate Ville' },
  { countryCode: 'FR', code: 'PDF', name: 'Port de Fromage' },
  { countryCode: 'FR', code: 'MCV', name: 'Mussel Cove' }
];

type RouteLink = {
  origin: LocationCode;
  destination: LocationCode;
};

const routes: RouteLink[] = [
  { origin: 'NPB', destination: 'MKT' },
  { origin: 'MKT', destination: 'NPB' },
  { origin: 'NPB', destination: 'MCV' },
  { origin: 'MCV', destination: 'NPB' },
  { origin: 'NPB', destination: 'TPH' },
  { origin: 'TPH', destination: 'NPB' },

  { origin: 'SRH', destination: 'MCV' },
  { origin: 'MCV', destination: 'SRH' },
  { origin: 'SRH', destination: 'PDF' },
  { origin: 'PDF', destination: 'SRH' },
  { origin: 'PDF', destination: 'MCV' },
  { origin: 'MCV', destination: 'PDF' },

  { origin: 'NPB', destination: 'CVB' },
  { origin: 'CVB', destination: 'NPB' },
  { origin: 'PCD', destination: 'MCV' },
  { origin: 'MCV', destination: 'PCD' },
  { origin: 'MKT', destination: 'CVB' },
  { origin: 'CVB', destination: 'MKT' },

  { origin: 'TPH', destination: 'PCD' },
  { origin: 'PCD', destination: 'TPH' }
];

export default class Routes {
  constructor(private customer: Customer, private shopifyService: ShopifyService) {}

  async findOrigins(): Promise<LocationSummary[]> {
    if (this.customer === Customer.SplitExpress) {
      return this.shopifyService.getOperationSailings().then((products) => {
        const origins = products?.map((product) => {
          return {
            countryCode: 'HR',
            code: product.origin.value,
            name: product.origin.value,
            title: product.origin.value === 'Airport' ? 'Split Airport' : product.origin.value
          };
        });

        return uniqBy(origins, 'name');
      });
    }
    const origins = uniq(routes.map((route) => route.origin));
    return sortBy(
      locations.filter((loc) => origins.indexOf(loc.code) >= 0),
      (loc) => loc.countryCode + loc.name
    );
  }

  async findDestinations(originCode?: LocationCode) {
    if (this.customer === Customer.SplitExpress) {
      return this.shopifyService.getOperationSailings().then((products) => {
        const destinations = products?.map((product) => {
          return {
            countryCode: 'HR',
            code: product.destination.value,
            name: product.destination.value,
            title: product.destination.value === 'Airport' ? 'Split Airport' : product.destination.value
          };
        });

        const suggestedDestinations = filterDestinationBasedOnDefinedRoute(originCode as string);
        return uniqBy(
          destinations.filter((dest) => suggestedDestinations.includes(dest.name as SailingLocation)),
          'name'
        );
      });
    }

    const destinations = originCode
      ? uniq(routes.filter((route) => route.origin === originCode).map((route) => route.destination))
      : uniq(routes.map((route) => route.destination));
    return sortBy(
      locations.filter((loc) => destinations.indexOf(loc.code) >= 0),
      (loc) => loc.countryCode + loc.name
    );
  }
}
