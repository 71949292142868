import extra_driver_lounge from '../../images/extra_driver_lounge.jpg';
import extra_lofo from '../../images/extra_lofo.jpg';
import extra_meal_voucher from '../../images/extra_meal_voucher.jpg';
import extraLuggage from '../../images/extra_luggage.jpg';
import extraPet from '../../images/extra_pet.jpg';
import extraBike from '../../images/extra_bike.jpg';
import extraChild from '../../images/extra_child.jpg';

// old types: lofo | driverLounge | mealVoucher

export type Offer = {
  name: string;
  description: string;
  image: string;
  price: number;
  currencyCode?: string;
};

const extraOffers: Offer[] = [
  {
    name: 'LOFO - Last In First Out',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extra_lofo,
    price: 100
  },
  {
    name: 'Driver Lounge',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extra_driver_lounge,
    price: 200
  },
  {
    name: 'Meal Voucher',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extra_meal_voucher,
    price: 300
  }
];

const extraTravelOffer: Offer[] = [
  {
    name: 'Luggage',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extraLuggage,
    price: 100
  },
  {
    name: 'Pet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extraPet,
    price: 150
  },
  {
    name: 'Bike',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali',
    image: extraBike,
    price: 200
  }
];

export default class Extras {
  getAvailableOffers = async (bookingType?: string): Promise<Offer[]> => {
    if (bookingType === 'travel') {
      return extraTravelOffer;
    }
    return Promise.resolve(extraOffers);
  };

  getExtraImage = (title: string) => {
    switch (title.toLowerCase()) {
      case 'luggage':
        return extraLuggage;
      case 'pet':
      case 'pet on lap':
        return extraPet;
      case 'bike':
        return extraBike;
      case 'extra child':
        return extraChild;
      default:
        return '';
    }
  }
}
