import { ApolloClient, gql } from '@apollo/client';
import { map } from 'lodash';
import { Moment } from 'moment';
import { InventoryLevel, Product } from '../../types/PosTypes';

export interface ShopifyProduct {
  availableForSale: boolean;
  createdAt: string;
  description: string;
  descriptionHtml: string;
  handle: string;
  id: string;
  updatedAt: string;
  title: string;
  productType: string;
  tags: string[];
  origin: Metafield;
  destination: Metafield;
  ticketReference?: Metafield;
  extraReference?: Metafield;
  routeReferenceA?: Metafield;
  date: Metafield;
  departureTime: Metafield;
  arrivalTime: Metafield;
  variants: ProductVariant[];
  minimumChild?: Metafield;
  minimumAdult?: Metafield;
  sailingLegId?: Metafield;
  reference?: Metafield;
  routes?: Metafield;
}

export interface ShopifyPage {
  id?: string;
  handle?: string;
  body?: string;
  title?: string;
}

export interface ProductVariant {
  id: string;
  title: string;
  quantityAvailable: number;
  priceV2: Price;
  availableForSale: boolean;
}

export enum ProductType {
  SailingLeg = 'Sailing Leg',
  Ticket = 'Ticket',
  TravelExtra = 'Travel Extra',
  ReturnTicket = 'Return Ticket',
  Package = 'Package',
  ExtraPax = 'Extra Pax'
}

interface Price {
  amount: string;
  currencyCode: string;
}

interface Metafield {
  value: string;
  type: string;
}

export interface CartLineItem {
  quantity: number;
  merchandiseId: string; // Product Variant Id
  attributes?: CartAttribute[];
}

export interface CartAttribute {
  key: string;
  value: string;
}

export interface CartInput {
  lines: CartLineItem[];
  attributes?: CartAttribute[];
  note?: string;
}

export interface ProductFilter {
  productMetafield?: {
    namespace: string;
    key: string;
    value: string;
  };
  productType?: string;
}

interface CreateCartResponse {
  data: {
    cartCreate: {
      cart: {
        id: string;
      };
    };
  };
}

export interface BlogArticle {
  id: string;
  content?: string;
  excerpt?: string;
  title: string;
  image: ArticleImage;
  handle: string;
}

interface ArticleImage {
  id: string;
  altText: string;
  url: string;
}

export interface GraphProducts {
  edges: GraphProductEdge[];
}

export interface GraphProductEdge {
  cursor?: string;
  node: GraphProduct;
}

export interface GraphProduct extends Omit<Product, 'variants'> {
  variants: GraphProductVariants;
}

export interface GraphProductVariants {
  edges: {
    node: GraphProductVariant;
  }[];
}

export interface GraphProductVariant extends Omit<ProductVariant, 'inventoryItem'> {
  inventoryItem: {
    id: string;
    inventoryLevels: {
      edges: {
        node: InventoryLevel;
      }[];
    };
    tracked: boolean;
  };
}

export const GET_PRODUCTS_BY_QUERY = gql`
  query ($query: String) {
    products(first: 20, query: $query) {
      edges {
        node {
          id
          handle
          productType
          title
          variants(first: 10) {
            edges {
              node {
                id
                title
                quantityAvailable
                availableForSale
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
          origin: metafield(namespace: "sailing", key: "origin") {
            value
          }
          destination: metafield(namespace: "sailing", key: "destination") {
            value
          }
          departureTime: metafield(namespace: "sailing", key: "departure_time") {
            value
          }
          arrivalTime: metafield(namespace: "sailing", key: "arrival_time") {
            value
          }
          routes: metafield(namespace: "sailing", key: "routes") {
            value
          }
          sailingLegId: metafield(namespace: "sailing", key: "sailing_leg_id") {
            value
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_FILTERS = gql`
  query ($cursor: String, $query: String) {
    products(first: 10, after: $cursor, query: $query) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          handle
          productType
          title
          variants(first: 10) {
            edges {
              node {
                id
                title
                price
                inventoryItem {
                  id
                  tracked
                  inventoryLevels(first: 1) {
                    edges {
                      node {
                        id
                        available
                        location {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          origin: metafield(namespace: "sailing", key: "origin") {
            value
          }
          destination: metafield(namespace: "sailing", key: "destination") {
            value
          }
          departureTime: metafield(namespace: "sailing", key: "departure_time") {
            value
          }
          arrivalTime: metafield(namespace: "sailing", key: "arrival_time") {
            value
          }
          routes: metafield(namespace: "sailing", key: "routes") {
            value
          }
          sailingLegId: metafield(namespace: "sailing", key: "sailing_leg_id") {
            value
          }
        }
      }
    }
  }
`;

type Filter = { origin?: string; destination?: string; productType?: string };

class ShopifyService {
  private client: ApolloClient<any>;
  private operationRoutes: ShopifyProduct[];
  constructor(client: ApolloClient<any>) {
    this.client = client;
    this.operationRoutes = [];
  }

  productsWithoutEdgesNode = (edges: any[]): ShopifyProduct[] => {
    return map(edges, (edge) => ({
      ...edge.node,
      variants: map(edge.node.variants.edges, (edge) => edge.node)
    })) as ShopifyProduct[];
  };

  getOperationSailings(): Promise<ShopifyProduct[]> {
    if (this.operationRoutes.length > 0) {
      return Promise.resolve(this.operationRoutes);
    }

    const query = gql`
      query ProductType($product_filters: [ProductFilter!]) {
        collection(handle: "operation-sailing-legs") {
          id
          handle
          products(first: 30, filters: $product_filters) {
            edges {
              node {
                id
                title
                tags
                handle
                productType
                vendor
                origin: metafield(namespace: "sailing", key: "origin") {
                  value
                  type
                }
                destination: metafield(namespace: "sailing", key: "destination") {
                  value
                  type
                }
                departureTime: metafield(namespace: "sailing", key: "departure_time") {
                  value
                  type
                }
                arrivalTime: metafield(namespace: "sailing", key: "arrival_time") {
                  value
                  type
                }
              }
            }
          }
        }
      }
    `;

    return this.client
      .query({
        query
      })
      .then((res) => {
        const products = map(res?.data?.collection?.products?.edges, (edge) => edge?.node);
        this.operationRoutes = [...products];
        return products;
      });
  }

  getProductsByDateQuery(date: Moment) {
    const collectionHandle = date.format('YYYY-MM-DD');
    return gql`query ProductType($productFilters: [ProductFilter!]) {
      collection(handle: "${collectionHandle}") {
        id
        handle
        products(first: 20, filters: $productFilters) {
          edges {
            node {
              id
              title
              tags
              handle
              productType
              vendor
              variants(first: 20) {
                edges {
                  node {
                    id
                    title
                    quantityAvailable
                    availableForSale
                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
              origin: metafield(namespace: "sailing", key: "origin") {
                value
                type
              }
              destination: metafield(namespace: "sailing", key: "destination") {
                value
                type
              }
              date: metafield(namespace: "sailing", key: "date") {
                value
                type
              }
              departureTime: metafield(namespace: "sailing", key: "departure_time") {
                value
                type
              }
              arrivalTime: metafield(namespace: "sailing", key: "arrival_time") {
                value
                type
              }
              routes: metafield(namespace: "sailing", key: "routes") {
                value
                type
              }
              minimumChild: metafield(namespace: "sailing", key: "minimum_child") {
                value
                type
              }
              minimumAdult: metafield(namespace: "sailing", key: "minimum_adult") {
                value
                type
              }
              sailingLegId: metafield(namespace: "sailing", key: "sailing_leg_id") {
                value
                type
              }
              reference: metafield(namespace: "sailing", key: "reference") {
                value
                type
              }
              affectedInventoryProductIds: metafield(namespace: "sailing", key: "affected_inventory_product_ids") {
                value
                type
              }
            }
          }
        }
      }
    }
    `;
  }

  getBlogArticles(handle: string, withContent?: boolean) {
    const query = gql`
      query getBlogArticles($handle: String!) {
        blog(handle: $handle) {
          id
          handle
          title
          articles(first: 10) {
            edges {
              node {
                image {
                  id
                  altText
                  url
                }
                id
                excerpt
                title
                handle
                ${withContent ? 'content' : ''}
              }
            }
          }
        }
      }
    `;

    return this.client
      .query({
        query,
        variables: {
          handle
        }
      })
      .then((res) => {
        return map(res?.data?.blog?.articles?.edges, (edge) => edge?.node);
      });
  }

  getBlogArticleByHandle(blogName: string, articleHandle: string) {
    const query = gql`
      query getBlogArticleByHandle($blogName: String!, $articleHandle: String!) {
        blog(handle: $blogName) {
          id
          articleByHandle(handle: $articleHandle) {
            contentHtml
            title
            handle
          }
        }
      }
    `;

    return this.client
      .query({ query, variables: { blogName, articleHandle } })
      .then((res) => {
        if (!res.data.blog?.articleByHandle) {
          throw new Error('Article not found');
        }
        return res.data.blog?.articleByHandle;
      })
      .then(({ contentHtml, title, handle }) => ({ body: contentHtml, title, handle }));
  }

  getProductFilter(filters?: { origin?: string; destination?: string; productType?: string }) {
    const productFilters = [];

    if (filters?.origin) {
      productFilters.push({
        productMetafield: {
          namespace: 'sailing',
          key: 'origin',
          value: filters.origin
        }
      });
    }

    if (filters?.destination) {
      productFilters.push({
        productMetafield: {
          namespace: 'sailing',
          key: 'destination',
          value: filters.destination
        }
      });
    }

    if (filters?.productType) {
      productFilters.push({
        productType: filters.productType
      });
    }

    return productFilters;
  }

  getSailingsByDate(date: Moment, noCache = false) {
    const query = this.getProductsByDateQuery(date);
    const productFilters = this.getProductFilter({ productType: ProductType.SailingLeg });
    return this.client.query({
      query,
      variables: {
        productFilters
      },
      fetchPolicy: noCache ? 'no-cache' : 'cache-first'
    });
  }

  createCart(cartInput: CartInput): Promise<CreateCartResponse> {
    const mutation = gql`
      mutation createCart($cartInput: CartInput) {
        cartCreate(input: $cartInput) {
          cart {
            id
            createdAt
            updatedAt
            lines(first: 10) {
              edges {
                node {
                  id
                  merchandise {
                    ... on ProductVariant {
                      id
                    }
                  }
                }
              }
            }
            attributes {
              key
              value
            }
          }
        }
      }
    `;

    return this.client.mutate({
      mutation,
      variables: {
        cartInput
      }
    }) as unknown as Promise<CreateCartResponse>;
  }

  getCheckoutUrl(cartId: string): Promise<string> {
    const query = gql`
      query checkoutURL($cartId: ID!) {
        cart(id: $cartId) {
          checkoutUrl
        }
      }
    `;

    return this.client
      .query({
        query,
        variables: {
          cartId
        }
      })
      .then((res) => {
        return res.data?.cart?.checkoutUrl;
      });
  }

  getPageInformation(first: number) {
    const query = gql`
      query pageHandles($first: Int!) {
        pages(first: $first) {
          edges {
            node {
              handle
              title
            }
          }
        }
      }
    `;

    return this.client
      .query({
        query,
        variables: {
          first
        }
      })
      .then((res) => res.data?.pages?.edges?.map((edge: any) => edge.node));
  }

  getPageByHandle(handle: string) {
    const query = gql`
      query pageByHandle($handle: String!) {
        page(handle: $handle) {
          id
          body
          handle
          title
          seo {
            description
            title
          }
        }
      }
    `;

    return this.client
      .query({
        query,
        variables: {
          handle
        }
      })
      .then((res) => res.data?.page);
  }

  getReturnTickets() {
    const query = GET_PRODUCTS_BY_QUERY;
    return this.client
      .query<{ products: { edges: any } }>({
        query,
        variables: {
          query: 'tag:"return-ticket"'
        }
      })
      .then((res) => this.productsWithoutEdgesNode(res.data.products.edges));
  }

  getReturnTicketsWithEdges() {
    const query = GET_PRODUCTS_BY_QUERY;
    return this.client
      .query<{ products: { edges: any } }>({
        query,
        variables: {
          query: 'tag:"return-ticket"'
        }
      })
      .then((res) => (res.data.products?.edges as GraphProductEdge[]) ?? []);
  }

  getPackages(date: Moment, origin: string, destination: string) {
    const query = this.getProductsByDateQuery(date);
    const productFilter = this.getProductFilter({
      origin,
      destination,
      productType: ProductType.Package as string
    });
    return this.client
      .query<{ collection: { products: { edges: any } } }>({
        query,
        variables: {
          productFilters: productFilter
        }
      })
      .then((res) => res.data?.collection?.products && this.productsWithoutEdgesNode(res.data.collection.products.edges));
  }

  getPackageExtras(date: Moment, reference: string, productType: string): Promise<ShopifyProduct[]> {
    const query = this.getProductsByDateQuery(date);
    const productFilters: ProductFilter[] = [
      {
        productMetafield: {
          namespace: 'sailing',
          key: 'reference',
          value: reference
        }
      },
      { productType }
    ];

    return this.client
      .query<{ collection: { products: { edges: any } } }>({
        query,
        variables: {
          productFilters
        }
      })
      .then((res) => res.data?.collection?.products && this.productsWithoutEdgesNode(res.data.collection.products.edges));
  }

  getExtraPax() {
    const query = GET_PRODUCTS_BY_QUERY;
    return this.client
      .query<{ products: { edges: any } }>({
        query,
        variables: {
          query: 'tag:"extra-pax"'
        }
      })
      .then((res) => this.productsWithoutEdgesNode(res.data.products.edges));
  }

  getShopDescription() {
    const query = gql`
      {
        shop {
          description
        }
      }
    `;

    return this.client
      .query({
        query
      })
      .then((res) => res.data?.shop?.description);
  }

  getCartById(id: string) {
    const query = gql`
      query cart($id: ID!) {
        cart(id: $id) {
          id
        }
      }
    `;

    return this.client.query({ query, variables: { id } }).then((res) => res.data?.cart);
  }

  getProductsByFilters(cursor: string | null, filter: string): Promise<GraphProducts> {
    const query = GET_PRODUCTS_BY_FILTERS;
    return this.client.query({ query, variables: { cursor, query: filter } }).then((res) => res.data?.products);
  }

  getProductsByFilter = (date: Moment, filters?: Filter): Promise<GraphProductEdge[]> => {
    const productFilters = this.getProductFilter({ ...filters });
    const query = this.getProductsByDateQuery(date);
    return this.client
      .query({
        query,
        variables: {
          productFilters
        }
      })
      .then((res) => res.data.collection?.products?.edges ?? []);
  };
}

export default ShopifyService;
