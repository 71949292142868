import React from 'react';
import splitExpressLogo from './logo/logo_splitexpress_red.png';

const OperatorLogo = () => {
  return (
    <div>
      <img src={splitExpressLogo} height={24} alt="logo" />
    </div>
  );
};

export default OperatorLogo;
