import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { isNil, min } from 'lodash';
import { useContext, useMemo } from 'react';
import { getTicketsByVariantMapping, getTotalSelectedAmount, isNoSeatCount } from '../../utils/PosUtils';
import { GraphProduct } from '../../modules/shopify/ShopifyService';
import { ProductType } from '../../types/PosTypes';
import { PosContext, Trip } from './PosContext';
import QuantitySelector from '../QuantitySelector';

interface Props {
  ticket: GraphProduct;
  trips: Trip[];
}

const PosTicketTypeSelector = (props: Props) => {
  const { ticket, trips } = props;
  const { cartItems, updateCartItem } = useContext(PosContext);
  const theme = useTheme();

  const capacityLeft = useMemo(() => min(trips.map((trip) => trip.capacityLeft)), [trips]);

  const ticketTypeQuantity = useMemo(() => getTicketsByVariantMapping(cartItems), [cartItems]);

  const totalSelectedAmount = useMemo(() => {
    if (!ticketTypeQuantity) {
      return 0;
    }
    return getTotalSelectedAmount(ticketTypeQuantity);
  }, [ticketTypeQuantity]);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1, padding: 2, paddingBottom: 0, color: theme.palette.primary.main }}>
        <div>
          <FontAwesomeIcon icon={faTicket} />
        </div>
        <Typography fontWeight="bold" variant="h6">
          Tickets
        </Typography>
      </Box>
      {ticket?.variants.edges.map((edge) => {
        const variant = edge.node;
        const cartItem = cartItems.find((ct) => ct.variant.id === variant.id);
        const isBaby = isNoSeatCount(variant.title);
        const babyAmount = ticketTypeQuantity['Baby'] ?? 0;
        const adultAmount = ticketTypeQuantity['Adult'] ?? 0;
        const babyWithAdult = isBaby && babyAmount >= adultAmount;
        const willExceedCapacity = !isBaby && !isNil(capacityLeft) && totalSelectedAmount >= (capacityLeft ?? 0);
        return (
          <Box key={variant.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, borderBottom: '1px solid #ddd' }}>
            <div>
              <Typography fontWeight="bold" variant="subtitle1">
                {variant.title}
              </Typography>
              <Typography variant="body1">
                {variant.priceV2?.amount} {variant.priceV2?.currencyCode}
              </Typography>
            </div>
            <div>
              <QuantitySelector
                quantity={cartItem?.quantity ?? 0}
                setQuantity={(newValue) => {
                  updateCartItem({
                    variant: variant,
                    quantity: newValue,
                    productType: ticket.productType as ProductType
                  });
                }}
                isAddDisabled={willExceedCapacity || babyWithAdult}
                isRemoveDisabled={(cartItem?.quantity ?? 0) === 0}
                size={6}
              />
            </div>
          </Box>
        );
      })}
    </div>
  );
};

export default PosTicketTypeSelector;
