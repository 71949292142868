import { useState, createContext, FC, useCallback } from 'react';
import { BookingState } from '../types/BookingState';
import { emptyBookingState } from '../utils/EmptyFreightBookingStates';
import { cloneDeep } from 'lodash';

export const BookingStateContext = createContext({
  defaultBookingState: emptyBookingState,
  setDefaultBookingState: (bookingState: BookingState) => {},
  resetBookingState: () => {}
});

const BookingStateContextProvider: FC = ({ children }) => {
  const [defaultBookingState, setDefaultBookingState] = useState(cloneDeep(emptyBookingState));

  const resetBookingState = useCallback(() => setDefaultBookingState(cloneDeep(emptyBookingState)), []);

  return <BookingStateContext.Provider value={{ defaultBookingState, setDefaultBookingState, resetBookingState }}>{children}</BookingStateContext.Provider>;
};

export default BookingStateContextProvider;
