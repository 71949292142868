import moment from 'moment';
import { createContext, useState, ReactNode, useContext, useCallback, useMemo, FC } from 'react';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import { RouteSummary } from '../../modules/routing/Routes';
import SailingProductsSource, { Extra, Inventory, ReturnTicket } from '../../modules/sailing-products/SailingProductsSource';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { calculatePrice, getRouteIdentifier } from './ScheduleHelper';
import { isBefore } from 'date-fns';
import { JourneyType } from 'context/JourneyTypeContext';
import { inject } from 'saft-react';

export interface TravelBookingType {
  routes: RouteSummary[];
  passengerSummary?: PassengerSummary;
  travelBookingRoutes: TravelBookingRoute[];
  moveTravelBookingRouteDate: (key: string, amount: number) => void;
  setSelectedEntry: (key: string, entry: ScheduleEntry | null) => void;
  returnPrice: ReturnPrice | null;
  setReturnTicket: (returnTicket: ReturnTicket | null) => void;
  selectedExtras: Record<string, SelectedExtra>; // id: { quantity, extra }
  setSelectedExtra: (inventory: Inventory, quantity: number, extra: Extra) => void;
  setSelectedExtras: (selectedExtras: Record<string, SelectedExtra>) => void;
  resetSelectedExtras: () => void;
  currentJourneyType: JourneyType;
  setCurrentJourneyType: (journeyType: JourneyType) => void;
  setTravelBookingRoutes: (travelBookingRoutes: TravelBookingRoute[]) => void;
  fetchReturnTicket: (travelBookingRoutes: TravelBookingRoute[], currentJourneyType: JourneyType) => void;
  isFetchingReturnTicket: boolean;
}

export interface SelectedExtra {
  quantity: number;
  inventory: Inventory;
  extra: Extra;
}

export interface ReturnPrice {
  ticket: ReturnTicket;
  price: {
    amount: number;
    currencyCode: string;
  };
}

export interface TravelBookingRoute {
  identifier: string;
  routeSummary: RouteSummary;
  selectedEntry?: ScheduleEntry | null;
}

export const TravelBookingContext = createContext<TravelBookingType>(undefined!);

const TravelBookingContextProvider = ({ children, sailingProductsSource }: { children: ReactNode; sailingProductsSource: SailingProductsSource }) => {
  const { passengerSummary } = useContext(PassengerSummaryContext);

  const [routes] = useState([]);
  const [travelBookingRoutes, setTravelBookingRoutes] = useState<TravelBookingRoute[]>([]);
  const [returnPrice, setReturnPrice] = useState<ReturnPrice | null>(null);
  const [selectedExtras, setSelectedExtras] = useState<Record<string, SelectedExtra>>({});
  const [currentJourneyType, setCurrentJourneyType] = useState<JourneyType>(JourneyType.OneWay);
  const [isFetchingReturnTicket, setIsFetchingReturnTicket] = useState(false);

  const earliestPossibleRoute = useMemo(() => (travelBookingRoutes?.length > 0 ? travelBookingRoutes[0] : null), [travelBookingRoutes]);

  const setReturnTicket = useCallback(
    (returnTicket: ReturnTicket | null) => {
      if (!returnTicket) {
        setReturnPrice(null);
        return;
      }
      const totalPrice = calculatePrice([returnTicket], passengerSummary);
      setReturnPrice({
        ticket: returnTicket,
        price: totalPrice
      });
    },
    [passengerSummary]
  );

  const fetchReturnTicket = useCallback(
    async (travelBookingRoutes, currentJourneyType) => {
      setIsFetchingReturnTicket(true);
      setReturnTicket(null);
      const isRoundTrip = currentJourneyType === JourneyType.RoundTrip;
      if (!isRoundTrip) {
        setIsFetchingReturnTicket(false);
        return;
      }
      if (!travelBookingRoutes?.[0]) {
        setIsFetchingReturnTicket(false);
        return;
      }
      const returnTicket = await sailingProductsSource.getReturnTicketByRouteSummary(travelBookingRoutes[0].routeSummary);
      setReturnTicket(returnTicket ?? null);
      setIsFetchingReturnTicket(false);
    },
    [sailingProductsSource, setReturnTicket]
  );

  const moveTravelBookingRouteDate = useCallback(
    (key: string, amount: number) => {
      const index = travelBookingRoutes.findIndex((r) => r.identifier === key);
      if (index < 0) {
        return;
      }
      const travelBookingRoute = { ...travelBookingRoutes[index] };
      const newDate = moment(travelBookingRoute.routeSummary.departureDate).add(amount, 'day').toDate();
      if (travelBookingRoute.identifier !== earliestPossibleRoute?.identifier && isBefore(newDate, earliestPossibleRoute?.routeSummary?.departureDate || 0)) {
        return;
      }

      travelBookingRoute.routeSummary.departureDate = newDate;
      travelBookingRoute.identifier = getRouteIdentifier(travelBookingRoute.routeSummary);
      travelBookingRoute.selectedEntry = null;

      const newBookingRoutes = [...travelBookingRoutes];
      newBookingRoutes.splice(index, 1, travelBookingRoute);

      newBookingRoutes.forEach((bookingRoute) => {
        if (
          earliestPossibleRoute &&
          moment(bookingRoute.routeSummary.departureDate).isBefore(moment(earliestPossibleRoute.routeSummary.departureDate), 'date')
        ) {
          bookingRoute.routeSummary.departureDate = earliestPossibleRoute.routeSummary.departureDate;
          bookingRoute.identifier = getRouteIdentifier(bookingRoute.routeSummary);
          bookingRoute.selectedEntry = null;
        }
      });

      setTravelBookingRoutes(newBookingRoutes);
      fetchReturnTicket(newBookingRoutes, currentJourneyType);
    },
    [earliestPossibleRoute, travelBookingRoutes, fetchReturnTicket, currentJourneyType]
  );

  const setSelectedEntry = useCallback(
    (key: string, entry: ScheduleEntry | null) => {
      const index = travelBookingRoutes.findIndex((r) => r.identifier === key);
      if (index < 0) {
        return;
      }
      const foundBookingRoute = { ...travelBookingRoutes[index] };
      foundBookingRoute.selectedEntry = entry;
      setTravelBookingRoutes((travelBookingRoutes) => {
        const newBookingRoutes = [...travelBookingRoutes];
        newBookingRoutes.splice(index, 1, foundBookingRoute);
        return newBookingRoutes;
      });
    },
    [travelBookingRoutes]
  );

  const setSelectedExtra = useCallback((inventory: Inventory, quantity: number, extra: Extra) => {
    setSelectedExtras((selectedExtras) => ({
      ...selectedExtras,
      [inventory.id]: {
        quantity,
        inventory,
        extra
      }
    }));
  }, []);

  const resetSelectedExtras = useCallback(() => {
    setSelectedExtras({});
  }, []);

  return (
    <TravelBookingContext.Provider
      value={{
        passengerSummary,
        routes,
        travelBookingRoutes,
        setTravelBookingRoutes,
        moveTravelBookingRouteDate,
        returnPrice,
        setSelectedEntry,
        setReturnTicket,
        setSelectedExtra,
        setSelectedExtras,
        selectedExtras,
        resetSelectedExtras,
        currentJourneyType,
        setCurrentJourneyType,
        fetchReturnTicket,
        isFetchingReturnTicket
      }}
    >
      {children}
    </TravelBookingContext.Provider>
  );
};

export default inject('sailingProductsSource')(TravelBookingContextProvider as FC<{}>);
