import { createContext, useEffect, useState, ReactNode } from 'react';
import { useTheme } from '@mui/material';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const ViewportContext = createContext({ matchesBreakpoint: (breakpoint: Breakpoint): boolean => true, getBreakpoint: (): Breakpoint => 'xs' });

const ViewportProvider = ({ children }: { children: ReactNode }) => {
  const [screenWidth, setscreenWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setscreenWidth(window.innerWidth);
  };
  const breakpoints = useTheme().breakpoints.values;

  const matchesBreakpoint = (breakpoint: Breakpoint) => {
    return screenWidth >= breakpoints[breakpoint];
  };

  const getBreakpoint = () => {
    if (matchesBreakpoint('xxl')) {
      return 'xxl';
    }
    if (matchesBreakpoint('xl')) {
      return 'xl';
    }
    if (matchesBreakpoint('lg')) {
      return 'lg';
    }
    if (matchesBreakpoint('md')) {
      return 'md';
    }
    if (matchesBreakpoint('sm')) {
      return 'sm';
    }
    return 'xs';
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ matchesBreakpoint, getBreakpoint }}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;
