import { FC, useState, useEffect, useContext, useMemo } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { DateRangePicker, DatePicker, DateRange } from '@mui/lab';
import JourneyTypeSelector from './JourneyTypeSelector';
import PassengerSelector from './header-selectors/PasssengerSelector';
import VehicleSelector from './header-selectors/VehicleSelector';
import RouteSelector from './header-selectors/RouteSelector';
import { LocationSummary, RouteSummary } from '../modules/routing/Routes';
import { RouteSummaryContext } from '../context/RouteSummaryContext';
import { BookingType, BookingTypeContext } from '../context/BookingTypeContext';
import { JourneyType, JourneyTypeContext } from '../context/JourneyTypeContext';
import { BookingStageContext, BookingStage } from '../context/BookingStageContext';
import { ViewportContext } from '../context/ViewportContext';
import { VehicleType, VehicleTypeContext } from '../context/VehicleTypeContext';
import Vehicles from '../modules/vehicles/Vehicles';
import { useInjects } from 'saft-react';
import useAsync from '../useAsync';
import { usePrevious } from '../utils/hooks/usePrevious';
import { Controller, useForm, FieldValues } from 'react-hook-form';
import { every } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { DisabledTooltip } from './DisabledTooltip';
import { BookingStateContext } from '../context/BookingStateContext';

const FreightBooking: FC = () => {
  const [selectedVehicleType, setSelectedVehicleType] = useState('Lorry');
  const [openPicker, setOpenPicker] = useState(false);

  const { routes, setRoutes } = useContext(RouteSummaryContext);
  const { vehicleType, setVehicleType } = useContext(VehicleTypeContext);
  const { journeyType, setJourneyType } = useContext(JourneyTypeContext);
  const { setBookingType } = useContext(BookingTypeContext);
  const { currentStage, setCurrentStage, hasPastLastStage } = useContext(BookingStageContext);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitted }
  } = useForm({ shouldUnregister: true });
  const watchOrigin: LocationSummary = watch('origin', {});
  const previousRoute = usePrevious(routes);

  const {
    defaultBookingState: {
      scheduleState: { routeSummaries }
    }
  } = useContext(BookingStateContext);

  useEffect(() => {
    if (routeSummaries && !isSubmitted) {
      setValue('origin', routeSummaries?.[0]?.origin);
      setValue('destination', routeSummaries?.[0]?.destination);
      setOpenPicker(true);
    }
  }, [routeSummaries, setValue, isSubmitted]);

  useEffect(() => {
    if (!routes && previousRoute) {
      reset(['origin, destination']);
      setValue('dateRange', [null, null]);
      setValue('departureDate', null);
    }
  }, [routes, previousRoute, setValue, reset]);

  const deps = useInjects<{ lxVehicleService: Vehicles }>({
    lxVehicleService: undefined
  });
  const isAccompanied = useAsync<boolean>(
    () => (deps === undefined ? Promise.resolve(true) : deps.lxVehicleService.isAccompanied(vehicleType)),
    [deps, vehicleType]
  ).res;

  useEffect(() => {
    setBookingType(BookingType.Freight);
  });

  useEffect(() => {
    setVehicleType(selectedVehicleType as VehicleType);
  }, [selectedVehicleType, setVehicleType]);

  const disableControls = useMemo(() => {
    return currentStage > BookingStage.Schedule;
  }, [currentStage]);

  const onSubmit = handleSubmit((data) => {
    let routeSummary: RouteSummary = {
      origin: data['origin'],
      destination: data['destination'],
      departureDate: data['departureDate'] || data['dateRange'][0],
      returnDate: data['dateRange']?.[1] || null
    };
    setRoutes([routeSummary]);
  });

  const { matchesBreakpoint } = useContext(ViewportContext);
  return !(routes && !matchesBreakpoint('sm')) ? (
    <Grid container sx={{ justifyContent: `${matchesBreakpoint('sm') ? 'inherit' : 'center'}`, marginTop: 1 }}>
      <Grid container item xs={12} md={9} columnSpacing={2} rowSpacing={1} sx={{ marginBottom: 1.5, textAlign: 'left', alignItems: 'flex-end' }}>
        <Grid item xs={12} sm={4} md={5}>
          <VehicleSelector onVehicleSelect={setSelectedVehicleType} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <JourneyTypeSelector onJourneyTypeSelect={setJourneyType} journeyType={journeyType} bookingType={BookingType.Freight} />
        </Grid>
        {isAccompanied && currentStage < BookingStage.Cabins && (
          <Grid item xs={12} sm={3}>
            <PassengerSelector />
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={1} sx={{ paddingBottom: 2 }}>
        <Grid item xs={12} sm={10}>
          <Paper sx={{ padding: 2 }}>
            <Grid container item columnGap={2} xs sx={{ fontWeight: 'medium' }}>
              <Grid item xs={12} sm={6}>
                <RouteSelector<FieldValues> control={control} setValue={setValue} getValues={getValues} watchOrigin={watchOrigin} />
              </Grid>
              <Grid container item xs={12} sm={5} sx={{ paddingBottom: 1, '& > div': { width: '100%' } }}>
                {journeyType === JourneyType.RoundTrip ? (
                  <Controller
                    key="dateRange"
                    control={control}
                    name="dateRange"
                    defaultValue={[null, null]}
                    rules={{
                      required: true,
                      validate: (dateRange: DateRange<Date>) => every(dateRange, (date) => date !== null && date.toString() !== 'Invalid Date')
                    }}
                    render={({ field }) => (
                      <DateRangePicker
                        calendars={matchesBreakpoint('sm') ? 2 : 1}
                        disabled={disableControls}
                        startText={<FormattedMessage id="booking.freight.departure" defaultMessage="Departure" />}
                        endText={<FormattedMessage id="booking.freight.return" defaultMessage="Return" />}
                        {...field}
                        open={openPicker}
                        onOpen={() => setOpenPicker(true)}
                        onClose={() => setOpenPicker(false)}
                        renderInput={(startProps: any, endProps: any) => (
                          <Grid container item xs={12} spacing={1} sx={{ height: '100%', alignItems: 'space-between' }}>
                            <Grid item xs={12} md={6}>
                              <DisabledTooltip
                                title={<FormattedMessage id="tooltips.booking.departure" defaultMessage="Restart booking to change departure date" />}
                                disabled={!disableControls}
                              >
                                <TextField variant="filled" InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }} fullWidth {...startProps} />
                              </DisabledTooltip>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: `${matchesBreakpoint('md') ? 'flex-start' : 'flex-end'}` }}>
                              <DisabledTooltip
                                title={<FormattedMessage id="tooltips.booking.return" defaultMessage="Restart booking to change return date" />}
                                disabled={!disableControls}
                              >
                                <TextField variant="filled" InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }} fullWidth {...endProps} />
                              </DisabledTooltip>
                            </Grid>
                          </Grid>
                        )}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    key="departureDate"
                    control={control}
                    name="departureDate"
                    defaultValue={null}
                    rules={{ required: true, validate: (date: Date) => date !== null && date.toString() !== 'Invalid Date' }}
                    render={({ field }) => (
                      <DatePicker
                        disabled={disableControls}
                        label={<FormattedMessage id="booking.freight.departure" defaultMessage="Departure" />}
                        {...field}
                        disableOpenPicker
                        open={openPicker}
                        onOpen={() => setOpenPicker(true)}
                        onClose={() => setOpenPicker(false)}
                        InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }}
                        renderInput={(params: any) => (
                          <Grid item xs={12} md={6}>
                            <DisabledTooltip
                              title={<FormattedMessage id="tooltips.booking.departure" defaultMessage="Restart booking to change departure date" />}
                              disabled={!disableControls}
                            >
                              <TextField variant="filled" onClick={() => setOpenPicker(true)} {...params} />
                            </DisabledTooltip>
                          </Grid>
                        )}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          {!disableControls && (
            <Button
              onClick={onSubmit}
              sx={{
                height: '100%',
                backgroundColor: 'primary',
                paddingTop: 1.5,
                paddingBottom: 1.5
              }}
              variant="contained"
              fullWidth
            >
              <FormattedMessage id="booking.tripSearch.search" defaultMessage="Search" />
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      {!hasPastLastStage && (
        <Grid container sx={{ backgroundColor: 'info.dark' }}>
          <Grid item sx={{ padding: 1 }}>
            <Button
              onClick={() => {
                setRoutes(undefined);
                setCurrentStage(0);
              }}
            >
              <Typography variant="body2" sx={{ color: 'white' }}>
                {'< '}
                <FormattedMessage id="booking.tripSearch.change" defaultMessage="Change unit type" />
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FreightBooking;
