import { FC, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { TextField, FormControlLabel, Checkbox, Collapse, Grid } from '@mui/material';
import { Vehicle, Dimensions } from '../../types/BookingState';
import HazardousGoodsInformationForm from './HazardousGoodsInformationForm';
import VehicleIllustrationService from '../../service/vehicle-illustration/VehicleIllustrationService';
import { VehicleTypeContext } from '../../context/VehicleTypeContext';
import TemperatureControlForm from './TemperatureControlForm';
import { useInjects } from 'saft-react';
import Vehicles from '../../modules/vehicles/Vehicles';
import useAsync from '../../useAsync';
import { Controller, useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';

interface VehicleInformationFormProps {
  state: Vehicle;
  setState: (newState: Vehicle) => void;
  setIsValid: (isValid: boolean) => void;
}

const VehicleInformationForm: FC<VehicleInformationFormProps> = ({ state, setState, setIsValid }) => {
  const { vehicleType } = useContext(VehicleTypeContext);
  const intl = useIntl();

  const { control, setValue, getValues, handleSubmit, formState, trigger, unregister, watch } = useForm({ mode: 'onBlur' });

  const [hazardous, setHazardous] = useState<boolean | undefined>();
  const [temperatureControlled, setTemperatureControlled] = useState(false);
  const [livestock, setLivestock] = useState(false);

  const deps = useInjects<{ lxVehicleService: Vehicles }>({
    lxVehicleService: undefined
  });
  const defaultDimensions = useAsync<Dimensions>(
    () => (deps === undefined ? Promise.resolve({ length: 0, width: 0, height: 0 }) : deps.lxVehicleService.getDefaultDimensions(vehicleType)),
    [deps]
  ).res;
  const defaultTareWeight = useAsync<number>(
    () => (deps === undefined ? Promise.resolve(0) : deps.lxVehicleService.getDefaultTareWeight(vehicleType)),
    [deps]
  ).res;
  const showVehicleReg = useAsync<boolean>(() => (deps === undefined ? Promise.resolve(true) : deps.lxVehicleService.hasVehicleReg(vehicleType)), [deps]).res;
  const showTrailerReg = useAsync<boolean>(() => (deps === undefined ? Promise.resolve(true) : deps.lxVehicleService.hasTrailerReg(vehicleType)), [deps]).res;
  const isAccompanied = useAsync<boolean>(() => (deps === undefined ? Promise.resolve(true) : deps.lxVehicleService.isAccompanied(vehicleType)), [deps]).res;

  // this should only trigger once the defaults are loaded and never after that hence the eslint disable warning.
  useEffect(() => {
    if (defaultDimensions && defaultTareWeight) {
      setState({ ...state, dimensions: defaultDimensions, measurements: { ...state.measurements, tareWeight: defaultTareWeight } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDimensions, defaultTareWeight]);

  useEffect(() => {
    for (const [key, value] of Object.entries(state)) {
      value && setValue(key, parseInt(value) || value);
    }
    state['hazardous'] && setHazardous(true);
    state['temperatureControlled'] && setTemperatureControlled(true);
    state['livestock'] && setLivestock(true);
  }, [state, setState, setValue]);

  //componentMount
  useEffect(() => {
    setState({
      ...state,
      vehicleType: state.vehicleType || vehicleType
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = useRef({ state, setState, handleSubmit });

  useEffect(() => {
    save.current = { state, setState, handleSubmit };
  }, [state, setState, handleSubmit]);

  useEffect(() => {
    return () => {
      const { state, setState, handleSubmit } = save.current;

      handleSubmit((data) => {
        setState(Object.assign(state, data));
      })();
    };
  }, []);

  useEffect(() => {
    setIsValid(formState.isValid);
  }, [formState.isValid, setIsValid]);

  const validateMeasurement = useCallback((value: number) => value > 0, []);

  return (
    <>
      <Grid container item spacing={4} xs={12}>
        {showVehicleReg && (
          <Grid item md={6} xs={12}>
            <Controller
              key="vehicleRegistration"
              control={control}
              name="vehicleRegistration"
              rules={{ required: showVehicleReg }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={field.onChange}
                  value={field.value}
                  autoFocus
                  label={<FormattedMessage id="booking.vehicle.vehicleRegistration" defaultMessage="Vehicle registration" />}
                  required
                />
              )}
            />
          </Grid>
        )}
        {!isAccompanied && showTrailerReg && (
          <Grid item md={6} xs={12}>
            <Controller
              key="trailerRegistration"
              control={control}
              name="trailerRegistration"
              rules={{ required: showTrailerReg }}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} label={<FormattedMessage id="booking.vehicle.trailerRegistration" defaultMessage="Trailer registration" />} required />
              )}
            />
          </Grid>
        )}
        <Grid container item md={6} sm={12}>
          <Grid item xs sx={{ padding: 2 }}>
            <img src={VehicleIllustrationService.getIllustrationByVehicleType(vehicleType)} alt={vehicleType} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Controller
            key="isEmpty"
            control={control}
            name="isEmpty"
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label={<FormattedMessage id="booking.vehicle.unitIsEmpty" defaultMessage="Unit is empty" />}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                      event.target.checked && setValue('measurements.netWeight', 0);
                      trigger('measurements.netWeight');
                    }}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid container item md={6} sm={12} spacing={2}>
          <Grid xs={4} item>
            <Controller
              key="length"
              control={control}
              name="dimensions.length"
              defaultValue={0}
              rules={{ required: true, validate: validateMeasurement }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<FormattedMessage id="booking.vehicle.length" defaultMessage="Length" />}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value) || 0);
                  }}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={4} item>
            <Controller
              key="width"
              control={control}
              name="dimensions.width"
              defaultValue={0}
              rules={{ required: true, validate: validateMeasurement }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<FormattedMessage id="booking.vehicle.width" defaultMessage="Width" />}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value) || 0);
                  }}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={4} item>
            <Controller
              key="height"
              control={control}
              name="dimensions.height"
              defaultValue={0}
              rules={{ required: true, validate: validateMeasurement }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<FormattedMessage id="booking.vehicle.height" defaultMessage="Height" />}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value) || 0);
                  }}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={4} item>
            <Controller
              key="grossWeight"
              control={control}
              name="measurements.grossWeight"
              defaultValue={0}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    value={getValues('measurements.netWeight') + getValues('measurements.tareWeight') || 0}
                    disabled
                    label={<FormattedMessage id="booking.vehicle.grossWeight" defaultMessage="Gross weight" />}
                  />
                </>
              )}
            />
          </Grid>
          <Grid xs={4} item>
            <Controller
              key="netWeight"
              control={control}
              name="measurements.netWeight"
              defaultValue={0}
              rules={{ required: true, validate: (value: number) => value > 0 || getValues('isEmpty') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value) || 0);
                    trigger('measurements.grossWeight');
                  }}
                  disabled={getValues('isEmpty')}
                  label={<FormattedMessage id="booking.vehicle.netWeight" defaultMessage="Net weight" />}
                  required
                />
              )}
            />
          </Grid>
          <Grid xs={4} item>
            <Controller
              key="tareWeight"
              control={control}
              name="measurements.tareWeight"
              defaultValue={0}
              rules={{ required: true, validate: validateMeasurement }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value) || 0);
                    trigger('measurements.grossWeight');
                  }}
                  label={<FormattedMessage id="booking.vehicle.tareWeight" defaultMessage="Tare weight" />}
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <Controller
            key="goodsDescription"
            control={control}
            name="goodsDescription"
            rules={{ required: !watch('isEmpty') }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={<FormattedMessage id="booking.vehicle.goodsDescription" defaultMessage="Goods description" />}
                required={!watch('isEmpty')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid container item>
          <Controller
            key="hazardous"
            control={control}
            name="hazardous"
            defaultValue={false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                label={`🔶 ${intl.formatMessage({ id: 'booking.vehicle.hazardousGoods', defaultMessage: 'Hazardous Goods' })}`}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                      setHazardous(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Collapse
            in={hazardous}
            unmountOnExit
            onExited={() => unregister('hazardousGoods')}
            children={<HazardousGoodsInformationForm control={control} setValue={setValue} />}
          />
        </Grid>
        <Grid container item>
          <Controller
            key="temperatureControlled"
            control={control}
            name="temperatureControlled"
            defaultValue={false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                label={<FormattedMessage id="booking.vehicle.temperatureControl" defaultMessage="Temperature Control" />}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                      setTemperatureControlled(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
          <Grid item xs={12}>
            <Collapse
              in={temperatureControlled}
              unmountOnExit
              onExited={() => unregister('temperatureOptions')}
              children={<TemperatureControlForm control={control} />}
            ></Collapse>
          </Grid>
        </Grid>
        <Grid container item>
          <Controller
            key="livestock"
            control={control}
            name="livestock"
            defaultValue={false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                label={<FormattedMessage id="booking.vehicle.livestock" defaultMessage="Livestock" />}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                      setLivestock(event.target.checked);
                    }}
                  />
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VehicleInformationForm;
