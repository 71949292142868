import { DatePicker } from '@mui/lab';
import { Button, Grid, Paper, TextField, IconButton, TextFieldProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import JourneyTypeSelector from './JourneyTypeSelector';
import PassengerSelector from './header-selectors/PasssengerSelector';
import RouteSelector from './header-selectors/RouteSelector';
import { BookingType, BookingTypeContext } from '../context/BookingTypeContext';
import { JourneyType, JourneyTypeContext } from '../context/JourneyTypeContext';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { RouteSummary } from '../modules/routing/Routes';
import { RouteSummaryContext } from '../context/RouteSummaryContext';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { ViewportContext } from '../context/ViewportContext';
import { TravelBookingContext } from './travel/TravelBookingContext';
import { isBefore } from 'date-fns';
import { inject } from 'saft-react';
import { Customer } from '../types/Customer';
import { disableBookingDateFunc, minimumBookingDate } from '../settings/AppConstants';
import moment from 'moment';

interface InjectedProps {
  customer: Customer;
}

interface Props {
  onRouteSearch: (routeSummary: RouteSummary[], journeyType: JourneyType) => void;
  uncompleteCheckout?: boolean;
}
export type TravelBookingFields = {
  travel: RouteSummary[];
};

const TravelBooking: FC<Props & InjectedProps> = ({ customer, uncompleteCheckout, onRouteSearch, ...props }) => {
  const { journeyType, setJourneyType } = useContext(JourneyTypeContext);
  const { setBookingType } = useContext(BookingTypeContext);
  const { routes, setRoutes } = useContext(RouteSummaryContext);
  const [openPickers, setOpenPickers] = useState<boolean[]>([false]);
  const [returnPicker, setReturnPicker] = useState(false);
  const { matchesBreakpoint } = useContext(ViewportContext);
  const { resetSelectedExtras, setCurrentJourneyType } = useContext(TravelBookingContext);

  const { control, handleSubmit, setValue, getValues, watch } = useForm<TravelBookingFields>({ mode: 'onChange' });
  const { fields, append, remove, replace } = useFieldArray({ control, name: 'travel' });
  const fieldArray = watch('travel');
  const origin = watch('travel.0.origin');
  const destination = watch('travel.0.destination');
  const departureDate = watch('travel.0.departureDate');
  const returnDate = watch('travel.0.returnDate');

  const minimumDate = useMemo(() => {
    const { defaultDate, locationSpecific } = minimumBookingDate[customer];
    const foundMinimumDate = locationSpecific?.[origin?.code || ''] || locationSpecific?.[destination?.code || ''];

    return foundMinimumDate || defaultDate;
  }, [customer, destination?.code, origin?.code]);

  const shouldDisableDateFunc = useMemo(() => disableBookingDateFunc[customer], [customer]);

  useEffect(() => {
    setBookingType(BookingType.Travel);
  });

  const onSubmit = handleSubmit((data) => {
    let routeArray = data['travel'];

    const routeSummary = routeArray.map(({ origin, destination, departureDate, returnDate }) => ({
      origin,
      destination,
      departureDate: departureDate,
      returnDate: (journeyType === JourneyType.RoundTrip && returnDate) || null
    }));
    setRoutes(routeSummary);
    setCurrentJourneyType(journeyType);
    resetSelectedExtras();
    onRouteSearch(routeSummary, journeyType);
  });

  const populateFields = useCallback(
    (amount: number, defaultDate?: boolean) => {
      const today = new Date();
      const chosenDefaultDate = isBefore(minimumDate, today) ? today : minimumDate;

      for (let i = 0; i < amount; i++)
        append({
          origin: (i === 0 && fieldArray?.[fieldArray.length - 1]?.destination) || undefined,
          destination: undefined,
          departureDate: defaultDate ? chosenDefaultDate : null,
          returnDate: defaultDate ? chosenDefaultDate : null
        });
    },
    [append, fieldArray, minimumDate]
  );

  useEffect(() => {
    const length = fieldArray?.length ?? 0;
    if (length === 0) {
      if (routes) {
        routes.forEach(({ origin, destination, departureDate, returnDate }, index) => {
          append({ origin, destination, departureDate, returnDate: returnDate || departureDate || null });
        });
      } else {
        populateFields(1, true);
      }
    }
  }, [populateFields, fieldArray, routes, append]);

  useEffect(() => {
    if (uncompleteCheckout && routes) {
      remove();
      routes.forEach(({ origin, destination, departureDate, returnDate }, index) => {
        append({ origin, destination, departureDate, returnDate: returnDate || departureDate || null });
      });
    }
  }, [append, remove, routes, uncompleteCheckout]);

  useEffect(() => {
    const route = fieldArray?.[0];
    if (route && minimumDate !== route?.departureDate && minimumDate > (route?.departureDate ?? 0)) {
      replace([{ ...route, departureDate: minimumDate, returnDate: minimumDate }]);
    }
  }, [fieldArray, minimumDate, replace]);

  const addTrip = useCallback(() => {
    append({ origin: fieldArray?.[fieldArray.length - 1]?.destination, destination: undefined, departureDate: null, returnDate: null });
  }, [fieldArray, append]);

  const clearTrips = useCallback(() => {
    fieldArray?.length && replace(new Array(fieldArray.length).fill({ origin: '', destination: '', departureDate: null, returnDate: null }, 0));
  }, [fieldArray?.length, replace]);

  const handleJourneyTypeChange = useCallback(
    (newJourneyType: JourneyType) => {
      const length = fieldArray?.length ?? 0;
      if (newJourneyType === JourneyType.MultiStop) {
        populateFields(2);
      } else if (length > 1) {
        for (let i = length - 1; i >= 1; i--) {
          remove(i);
        }
      }
      setJourneyType(newJourneyType);
    },
    [fieldArray?.length, populateFields, remove, setJourneyType]
  );

  const isMultiStop = useMemo(() => journeyType === JourneyType.MultiStop, [journeyType]);

  const setOpenPicker = useCallback(
    (index: number, open: boolean) => {
      let clone = [...openPickers];
      clone[index] = open;
      setOpenPickers(clone);
    },
    [openPickers, setOpenPickers]
  );

  return (
    <Grid container sx={{ justifyContent: `${matchesBreakpoint('sm') ? 'inherit' : 'center'}`, marginTop: 1 }}>
      <Grid item xs={12} md={9} columnSpacing={2} rowSpacing={1} sx={{ marginBottom: 1.5, textAlign: 'left', alignItems: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexDirection: matchesBreakpoint('sm') ? 'row' : 'column', padding: matchesBreakpoint('sm') ? 0 : 1 }}>
          <Box>
            <PassengerSelector />
          </Box>
          <Box sx={{ width: 16, display: { xs: 'none', sm: 'inherit' } }} />
          <Box sx={{ height: 16, display: { xs: 'inherit', sm: 'none' } }} />
          <Box>
            <JourneyTypeSelector onJourneyTypeSelect={handleJourneyTypeChange} journeyType={journeyType} bookingType={BookingType.Travel} />
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper sx={{ padding: 2 }}>
            {fields.map((field, index) => {
              const watchOrigin = watch('travel', [])[index]?.origin;
              return (
                <Grid key={field.id} container item columnGap={2} xs sx={{ fontWeight: 'medium' }}>
                  <Grid item xs={12} sm={6}>
                    <RouteSelector<TravelBookingFields>
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      watchOrigin={watchOrigin}
                      index={index}
                      hideSwapButton={!matchesBreakpoint('sm')}
                    />
                  </Grid>
                  <Box sx={{ height: 16, display: { xs: 'inherit', sm: 'none' } }} />
                  <Grid container item xs={12} sm={5} sx={{ paddingBottom: 1 }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <Controller
                        control={control}
                        name={`travel.${index}.departureDate`}
                        rules={{ required: true, validate: (date: Date | null) => date !== null && date.toString() !== 'Invalid Date' }}
                        render={({ field }) => (
                          <DatePicker
                            label={<FormattedMessage id="booking.freight.departure" defaultMessage="Departure" />}
                            onOpen={() => setOpenPicker(index, true)}
                            onClose={() => setOpenPicker(index, false)}
                            minDate={minimumDate}
                            disableOpenPicker
                            open={!!openPickers[index]}
                            {...field}
                            onChange={(date) => {
                              field.onChange(date);
                              if (journeyType === JourneyType.OneWay) {
                                setValue('travel.0.returnDate', date);
                              } else if (moment(returnDate).isBefore(moment(date))) {
                                setValue('travel.0.returnDate', date);
                              }
                            }}
                            InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }}
                            shouldDisableDate={(date) => shouldDisableDateFunc(origin?.code, destination?.code, date)}
                            renderInput={(params: TextFieldProps) => (
                              <Box sx={{ width: '100%' }}>
                                <TextField
                                  variant="filled"
                                  onClick={() => setOpenPicker(index, true)}
                                  style={{ float: 'left', maxWidth: matchesBreakpoint('sm') ? 200 : '100%' }}
                                  fullWidth
                                  {...params}
                                />
                              </Box>
                            )}
                          />
                        )}
                      />
                      {journeyType === JourneyType.RoundTrip && (
                        <Controller
                          control={control}
                          name={`travel.${index}.returnDate`}
                          rules={{
                            required: true,
                            validate: (date: Date | null | undefined) =>
                              date !== null && date?.toString() !== 'Invalid Date' && !isBefore(date ?? 0, getValues(`travel.${index}.departureDate`) ?? 0)
                          }}
                          render={({ field }) => (
                            <DatePicker
                              label={<FormattedMessage id="booking.freight.return" defaultMessage="Return" />}
                              onOpen={() => setReturnPicker(true)}
                              onClose={() => setReturnPicker(false)}
                              minDate={moment.max(moment(departureDate), moment(minimumDate)).toDate()}
                              disableOpenPicker
                              open={returnPicker}
                              {...field}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }}
                              shouldDisableDate={(date) => shouldDisableDateFunc(origin?.code, destination?.code, date)}
                              renderInput={(params: TextFieldProps) => (
                                <Box sx={{ width: '100%' }}>
                                  <TextField
                                    variant="filled"
                                    onClick={() => setReturnPicker(true)}
                                    style={{ float: 'left', maxWidth: matchesBreakpoint('sm') ? 200 : '100%' }}
                                    fullWidth
                                    {...params}
                                  />
                                </Box>
                              )}
                            />
                          )}
                        />
                      )}
                      {isMultiStop && index > 0 && (
                        <Grid item xs="auto">
                          <IconButton sx={{ marginTop: 1 }} onClick={() => remove(index)}>
                            <CloseIcon color="error" />
                          </IconButton>
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
            {isMultiStop && (
              <Grid item sx={{ display: 'flex' }} columnGap={2}>
                <Button variant="contained" onClick={addTrip}>
                  <FormattedMessage id="booking.travel.addTrip" defaultMessage="Add trip" />
                </Button>
                <Button variant="contained" onClick={clearTrips}>
                  <FormattedMessage id="booking.travel.clear" defaultMessage="Clear" />
                </Button>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button onClick={onSubmit} sx={{ height: '100%' }} size="large" variant="contained" color="primary" fullWidth>
            <FormattedMessage id="booking.tripSearch.search" defaultMessage="Search" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default inject('customer')(TravelBooking as FC<{}>) as React.ElementType<Props>;
