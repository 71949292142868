import { createContext, useState, ReactNode, useMemo } from 'react';

export enum BookingStage {
  Schedule = 0,
  Vehicle = 1,
  Cabins = 2,
  Info = 3,
  Extra = 4
}

export const BookingStageContext = createContext({
  currentStage: 0,
  setCurrentStage: (currentStage: number) => {},
  bookingStages: BookingStage,
  hasPastLastStage: false
});

const BookingStageContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentStage, setCurrentStage] = useState(0);
  const hasPastLastStage = useMemo(() => {
    return currentStage > BookingStage.Extra;
  }, [currentStage]);

  return (
    <BookingStageContext.Provider value={{ currentStage, setCurrentStage, bookingStages: BookingStage, hasPastLastStage }}>
      {children}
    </BookingStageContext.Provider>
  );
};

export default BookingStageContextProvider;
