import { Moment } from 'moment';
import { PassengerSummary } from '../../components/header-selectors/PasssengerSelector';
import { SailingLocation } from '../../components/travel/ScheduleHelper';
import { RouteSummary } from '../routing/Routes';

export type SailingLegInventory = { [value in keyof typeof SailingLegInventoryType]?: Inventory };
export type TicketInventory = { [value in keyof typeof TicketInventoryType]?: Inventory };
export type ExtraInventory = { [value in keyof typeof ExtraInventoryType]?: Inventory };
export type PackageInventory = { [value in keyof typeof PackageInventoryType]?: Inventory };

export interface SailingLeg {
  origin: SailingLocation;
  destination: SailingLocation;
  departureTime: string;
  arrivalTime: string;
  date: string;
  title: string;
  id: string;
  inventory: SailingLegInventory;
  tags: string[];
  description?: string;
  route: string;
}

export enum SailingLegInventoryType {
  Seats = 'Seats',
  Luggages = 'Luggages',
  Pets = 'Pets',
  Bikes = 'Bikes'
}

export interface Inventory {
  id: string;
  title: string;
  amount: number;
  availableForSale: boolean;
  price?: {
    amount: number;
    currencyCode?: string;
  };
  image?: string;
}

export enum TicketInventoryType {
  Adult = 'Adult',
  Child = 'Child'
}

export interface Ticket {
  origin: SailingLocation;
  destination: SailingLocation;
  departureTime: string;
  arrivalTime: string;
  date: string;
  title: string;
  id: string;
  inventory: TicketInventory;
  tags: string[];
  description?: string;
  // TODO: replace origin and destination to increase flexibility
  // routes: SailingLocation[][]
  /* 
    in ticket [[{ loc: Split, depTime: X }, { loc: Bol, depTime: Y }, { loc: Hvar }]]
    in package [[Split, Bol, Hvar], [Hvar, Bol]]
    in return ticket [[Split, Bol, Hvar], [Hvar, Bol, Split]]
  */
}

export enum ExtraInventoryType {
  Luggages = 'Luggages',
  Pets = 'Pets',
  Bikes = 'Bikes',
  ExtraAdult = 'Extra adult',
  ExtraChild = 'Extra child'
}

export type Extra = {
  origin: SailingLocation;
  destination: SailingLocation;
  departureTime: string;
  arrivalTime: string;
  date: string;
  title: string;
  id: string;
  inventory: ExtraInventory;
  tags: string[];
  description?: string;
  image: string;
  type: string;
};

export enum PackageInventoryType {
  Ticket = 'Ticket'
}

export interface Package {
  origin: SailingLocation;
  destination: SailingLocation;
  departureTime?: string;
  arrivalTime?: string;
  requirement: {
    minimumAdult: number;
    minimumChild: number;
  };
  date?: string;
  title: string;
  inventory: PackageInventory;
  tags?: string[];
  description?: string;
  image: string;
  extras?: Extra[];
  reference: string;
  routes: string;
}

export interface ReturnTicket {
  id: string;
  origin: SailingLocation;
  destination: SailingLocation;
  departureTime?: string;
  arrivalTime?: string;
  date?: string;
  title: string;
  inventory: TicketInventory;
  tags: string[];
}

interface SailingProductsSource {
  getSailingLegs(): Promise<SailingLeg[]>;
  getTickets(): Promise<Ticket[]>;
  getExtras(): Promise<Extra[]>;
  getPackages(): Promise<Package[]>;
  getPackagesByRouteSummary(routeSummary: RouteSummary, passengerSummary: PassengerSummary): Promise<Package[]>;
  getReturnTicketByRouteSummary(routeSummary: RouteSummary): Promise<ReturnTicket | undefined>;
  getPackageExtras(date: Moment, travelPackage: Package, productType?: string): Promise<Extra[]>;

  // aim for generating needed data for getScheduleEntries
  getUseProductsSourceHook(): (
    routeSummary: RouteSummary,
    passengerSummary: PassengerSummary
  ) => { sailingLegs: SailingLeg[]; tickets: Ticket[]; extras: Extra[]; isLoading: boolean };
}

export default SailingProductsSource;
