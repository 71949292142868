import React, { FC, useEffect, useState } from 'react';
import { Box, Button, DialogContent, styled, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { FormattedMessage } from 'react-intl';

interface Props {}

const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    margin: 8
  }
}));

const GDPRCookiesDialog: FC<Props> = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const acceptedCookies = !localStorage.getItem('acceptedCookies');
    setOpen(acceptedCookies);
  }, []);

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Typography variant="h6">
          <FormattedMessage id="cookies.title" defaultMessage="This website uses cookies" />
        </Typography>
        <p>
          <FormattedMessage
            id="cookies.content"
            defaultMessage="We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use
          of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that
          they’ve collected from your use of their services."
          />
        </p>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem('acceptedCookies', 'true');
              setOpen(false);
            }}
          >
            <FormattedMessage id="actions.ok" defaultMessage="Ok" />
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default GDPRCookiesDialog;
