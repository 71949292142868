import { HazardousGoodsSearchResult } from '../../types/HazardousGoodsSearch';
import UrlResolver from '../server-communication/UrlResolver';

export default class HazardousGoodsService {
  private baseUrl: string;
  private fetchOptions: { headers: { 'Content-Type': string }; method: string };
  constructor() {
    this.baseUrl = UrlResolver.getHazmatServiceUrl();
    this.fetchOptions = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }

  async getByUnNumber(unNumber: string): Promise<HazardousGoodsSearchResult[]> {
    const path = '/v1/query/unNumber';
    const uri = `${this.baseUrl}${path}/${unNumber}`;
    return fetch(uri, this.fetchOptions)
      .then((res) => {
        return res.json();
      })
      .catch((e) => console.log(`HazardousGoodsService: ${e.message}`));
  }
}
