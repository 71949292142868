import { FC, useState, useCallback, useEffect, useContext } from 'react';
import { BlogArticle } from '../modules/shopify/ShopifyService';
import { Grid, IconButton, keyframes, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ViewportContext } from '../context/ViewportContext';
import ArticleCard from './ArticleCard';

interface Props {
  articles: BlogArticle[];
  columns: number;
  blogHandle: string;
}

const ArticleCarousel: FC<Props> = ({ articles, columns, blogHandle }) => {
  const [animationEnded, setAnimationEnded] = useState(true);
  const [direction, setDirection] = useState<string | null>(null);
  const [indices, setIndices] = useState(articles.map((a, i) => i));
  const spacing = 1.5;
  const theme = useTheme();
  const { matchesBreakpoint } = useContext(ViewportContext);

  const handleOverflow = useCallback(
    (newIndex: number) => {
      const allowedOverflow = articles.length - columns;

      if (newIndex < 0) {
        return newIndex < -allowedOverflow ? columns - 1 : newIndex;
      } else {
        return newIndex >= columns + allowedOverflow ? 0 : newIndex;
      }
    },
    [articles.length, columns]
  );

  const setNext = useCallback(() => {
    setDirection('left');
    setIndices((indices) => {
      return indices.map((index) => handleOverflow(index - 1));
    });
  }, [handleOverflow]);

  const setPrev = useCallback(() => {
    setDirection('right');
    setIndices((indices) => {
      return indices.map((index) => handleOverflow(index + 1));
    });
  }, [handleOverflow]);

  const slideLeftKeyframes = useCallback((transformPercentage: number) => {
    return keyframes`
      from {
        transform: translateX(${transformPercentage + 100}%)
      }
      to {
        transform: translateX(${transformPercentage}%)
      }
    `;
  }, []);

  const slideRightKeyframes = useCallback((transformPercentage: number) => {
    return keyframes`
      from {
        transform: translateX(${transformPercentage - 100}%)
      }
      to {
        transform: translateX(${transformPercentage}%)
      }
    `;
  }, []);

  useEffect(() => {
    const intervalId = columns < articles.length && setInterval(setNext, 5000);
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [articles.length, columns, setNext]);

  useEffect(() => {
    setIndices((indices) => {
      return indices.map((index) => handleOverflow(index));
    });
  }, [columns, handleOverflow]);

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', paddingTop: matchesBreakpoint('sm') ? spacing : 0.5, overflow: 'hidden' }}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          position: 'relative',
          overflow: 'hidden',
          boxSizing: 'border-box'
        }}
        height={theme.article?.card?.height ?? 'auto'}
        columnSpacing={matchesBreakpoint('sm') ? spacing : 1}
        columns={columns}
      >
        {articles.map((article, i) => {
          const { title } = article;
          const order = indices[i];
          const transformPercentage = 100 * order;
          const keyframe =
            direction === 'left' ? slideLeftKeyframes(transformPercentage) : direction === 'right' ? slideRightKeyframes(transformPercentage) : undefined;
          return (
            <Grid
              item
              xs={1}
              onAnimationStart={() => setAnimationEnded(false)}
              onAnimationEnd={() => setAnimationEnded(true)}
              key={title}
              width="100%"
              height="100%"
              flexShrink={0}
              sx={{
                transform: `translateX(${transformPercentage}%)`,
                position: 'absolute',
                ...(keyframe ? { animation: `${keyframe} 350ms ease-in-out` } : {})
              }}
            >
              <ArticleCard blogHandle={blogHandle} article={article} />
            </Grid>
          );
        })}
        {articles.length > 1 && columns < articles.length && (
          <>
            <IconButton
              size="large"
              onClick={() => animationEnded && setPrev()}
              sx={{ color: 'white', position: 'absolute', top: '50%', left: theme.spacing(spacing), transform: 'translateY(-50%)' }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={() => animationEnded && setNext()}
              sx={{ color: 'white', position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ArticleCarousel;
