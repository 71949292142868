import { map } from 'lodash';
import React, { FC } from 'react';
import { TravelBookingRoute } from './TravelBookingContext';
import TravelExtraSelector from './TravelExtraSelector';

interface Props {
  travelBookingRoutes: TravelBookingRoute[];
}

const TravelExtraContainer: FC<Props> = (props) => {
  const { travelBookingRoutes } = props;

  return (
    <div>
      {map(travelBookingRoutes, (travelBookingRoute, i) => (
        <TravelExtraSelector key={i} travelBookingRoute={travelBookingRoute} />
      ))}
    </div>
  );
};

export default TravelExtraContainer;
