import car from './images/CAR_TEST_right.svg';
import chassis from './images/Chassis_right_final.svg';
import trailer from './images/TR_right_final.svg';
import box from './images/Box_right.svg';
import lorry from './images/Lorry_right.svg';
import caravan from './images/Caravan_right.svg';
import van from './images/Van_right.svg';
import tractor from './images/Right_tractor.svg';
import { VehicleType } from '../../context/VehicleTypeContext';

const svgSetFromVehicleType: Record<VehicleType, string> = {
  Lorry: lorry,
  'Small Freight Vehicle': box,
  'Military Vehicle': box,
  Van: van,
  Car: car,
  'Export Machinery': box,
  Chassis: chassis,
  Harvester: box,
  Tractor: tractor,
  'Unaccompanied Lorry': box,
  'Unaccompanied Small Freight Vehicle': box,
  Trailer: trailer,
  Caravan: caravan,
  Tanker: box,
  Container: box,
  'Lift Tank': box,
  'Swap Body': box,
  Flat: box,
  General: box
};

class VehicleIllustrationService {
  static getIllustrationByVehicleType(templateVehicle: VehicleType) {
    let set: string = svgSetFromVehicleType[templateVehicle];
    if (!set) {
      set = box;
    }
    return set;
  }
}

export default VehicleIllustrationService;
