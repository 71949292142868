import { FC, useContext, useMemo } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { BookingType } from '../context/BookingTypeContext';
import { JourneyType } from '../context/JourneyTypeContext';
import { BookingStageContext, BookingStage } from '../context/BookingStageContext';
import { FormattedMessage } from 'react-intl';
import { DisabledTooltip } from './DisabledTooltip';
import { Customer, customersWithMultiStop } from '../types/Customer';
import { inject } from 'saft-react';

const JourneyTypeButton = styled(ToggleButton)(() => ({
  color: 'white',
  width: '100%',
  whiteSpace: 'nowrap' as 'nowrap',
  '&.Mui-selected': {
    color: '#005575',
    backgroundColor: 'rgba(255,255,255,0.9)'
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255,255,255,1) !important'
  }
}));

interface InjectedProps {
  customer: Customer;
}

interface JourneyTypeSelectorProps {
  onJourneyTypeSelect: (journeyType: JourneyType) => void;
  journeyType: string;
  bookingType: BookingType;
}

const JourneyTypeSelector: FC<JourneyTypeSelectorProps & InjectedProps> = ({ onJourneyTypeSelect, journeyType, bookingType, customer }) => {
  const { currentStage } = useContext(BookingStageContext);
  const handleChange = (event: React.MouseEvent<HTMLElement>, newJourneyType: JourneyType) => {
    newJourneyType && onJourneyTypeSelect(newJourneyType);
  };

  const disableSelector = useMemo(() => currentStage > BookingStage.Schedule, [currentStage]);

  return (
    <Box
      sx={{
        border: '1px solid white',
        borderRadius: 1
      }}
    >
      <DisabledTooltip
        title={<FormattedMessage id="tooltips.booking.journeyTypeSelector" defaultMessage="Restart booking to change journey type" />}
        disabled={!disableSelector}
      >
        <ToggleButtonGroup
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            '& .MuiButtonBase-root': { '&:hover': { color: '#005575', backgroundColor: 'white', opacity: 1 } }
          }}
          color="primary"
          value={journeyType}
          exclusive
          onChange={handleChange}
          disabled={disableSelector}
        >
          <JourneyTypeButton value={JourneyType.RoundTrip}>
            <FormattedMessage id="journeyType.roundTrip" defaultMessage="Round trip" />
          </JourneyTypeButton>
          <JourneyTypeButton value={JourneyType.OneWay}>
            <FormattedMessage id="journeyType.oneWay" defaultMessage="One way" />
          </JourneyTypeButton>
          {bookingType === BookingType.Travel && customersWithMultiStop.includes(customer) && (
            <JourneyTypeButton value={JourneyType.MultiStop}>
              <FormattedMessage id="journeyType.multiStop" defaultMessage="Multi-stop" />
            </JourneyTypeButton>
          )}
        </ToggleButtonGroup>
      </DisabledTooltip>
    </Box>
  );
};

export default inject('customer')(JourneyTypeSelector as FC<{}>) as React.ElementType<JourneyTypeSelectorProps>;
