import React, { FC, useContext } from 'react';
import { Box, Button, Typography, SvgIcon, useTheme } from '@mui/material';
import { PosContext } from './PosContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';

interface Props {}

const PosPaymentSummary: FC<Props> = (props) => {
  const { paymentSummary, setPaymentSummary } = useContext(PosContext);
  const theme = useTheme();

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: theme.spacing(5) }}>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <SvgIcon component={CheckCircleIcon} color="success" fontSize="large" />
        <Typography variant="h5">Purchase successful</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: theme.spacing(2) }}>
        <Typography>Order reference</Typography>
        <Typography variant="h5" fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
          #1029
        </Typography>
      </Box>
      <Box sx={{ width: '100%', padding: theme.spacing(2), boxSizing: 'border-box' }}>
        <Button variant="contained" startIcon={<PrintIcon />} fullWidth sx={{ padding: theme.spacing(2) }} color="primary">
          Print QR Code
        </Button>
      </Box>
      <Box sx={{ padding: theme.spacing(2), borderTop: '1px solid #ddd', width: '100%', boxSizing: 'border-box' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight="medium">Payment summary</Typography>
          <Typography fontWeight="medium">{`${paymentSummary?.currencyCode} ${paymentSummary?.price}`}</Typography>
        </Box>
        <Typography variant="body1">{paymentSummary?.method}</Typography>
      </Box>
      <Button variant="contained" fullWidth sx={{ padding: theme.spacing(2), marginTop: 'auto' }} onClick={() => setPaymentSummary(undefined)} color="primary">
        Done
      </Button>
    </Box>
  );
};

export default PosPaymentSummary;
