import { FC, useState, useContext, ReactElement, useEffect, useCallback, useMemo } from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PassengerSummaryContext } from '../context/PassengerSummaryContext';
import { BookingStage } from '../context/BookingStageContext';
import ScheduleSelectorList from './travel/ScheduleSelectorList';
import { TravelBookingContext, TravelBookingRoute } from './travel/TravelBookingContext';
import CheckoutService from '../modules/checkout/CheckoutService';
import { inject } from 'saft-react';
import { ShopifyProduct } from '../modules/shopify/ShopifyService';
import TravelPassengerDetails, { Passenger } from './travel/TravelPassengerDetails';
import { ViewportContext } from '../context/ViewportContext';
import StageHeader from './StageHeader';
import TravelBookingFooter from './TravelBookingFooter';
import { DisabledTooltip } from './DisabledTooltip';
import { FormattedMessage } from 'react-intl';
import { PackageBookingContext } from './packages/PackageBookingContext';
import TravelBookingFooterContainer from './TravelBookingFooterContainer';
import TravelExtraContainer from './travel/TravelExtraContainer';
import { forEach, isEqual } from 'lodash';
import { usePrevious } from '../utils/hooks/usePrevious';
import { JourneyTypeContext } from '../context/JourneyTypeContext';
import { RouteSummaryContext } from 'context/RouteSummaryContext';

export interface Stage {
  name: BookingStage;
  stepperName: string;
  content: ReactElement<any, any>;
  skip: boolean;
  isValid: boolean;
}

interface InjectedProps {
  checkoutService: CheckoutService;
}

interface Props {
  footerHeight: number;
  operationRoutes: ShopifyProduct[];
  uncompleteCheckout?: boolean;
  hasRoutes: boolean;
}

interface PassengerDetails {
  passengerList: Passenger[];
  note?: string;
}

const TravelSelector: FC<Props & InjectedProps> = (props) => {
  const { checkoutService, hasRoutes, footerHeight, uncompleteCheckout } = props;
  const { passengerSummary, setPassengerSummary } = useContext(PassengerSummaryContext);
  const { setJourneyType } = useContext(JourneyTypeContext);
  const { routes } = useContext(RouteSummaryContext);
  const { selectedPackage, resetPackageStates, setSelectedPackage } = useContext(PackageBookingContext);
  const {
    travelBookingRoutes,
    returnPrice,
    selectedExtras,
    resetSelectedExtras,
    setSelectedExtras,
    setSelectedEntry,
    setReturnTicket,
    setCurrentJourneyType,
    currentJourneyType
  } = useContext(TravelBookingContext);
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedState, setLoadedState] = useState(false);

  const [bookingFooterHeight, setBookingFooterHeight] = useState(0);
  const stageNames = ['Schedule', 'Extra', 'Passenger', 'Payment'];
  const { matchesBreakpoint } = useContext(ViewportContext);
  const [submit, setSubmit] = useState(false);
  const [scheduleStageValid, setScheduleStageValid] = useState(false);
  const previousLoadedState = usePrevious(loadedState);
  const previousTravelBookingRoutes = usePrevious(travelBookingRoutes);
  const [passengerDetails, setPassengerDetails] = useState<PassengerDetails | undefined>();
  const [openInventoryAlertDialog, setOpenInventoryAlertDialog] = useState(false);

  useEffect(() => {
    if (!isEqual(travelBookingRoutes, previousTravelBookingRoutes) && (!uncompleteCheckout || previousLoadedState)) {
      resetSelectedExtras();
    }
  }, [travelBookingRoutes, resetPackageStates, resetSelectedExtras, uncompleteCheckout, previousLoadedState, previousTravelBookingRoutes]);

  const saveStates = useCallback(() => {
    const { passengerList, note } = passengerDetails ?? {};
    const checkoutState = {
      travelBookingRoutes,
      selectedPackage,
      selectedExtras,
      passengerSummary,
      passengerList,
      journeyType: currentJourneyType,
      note,
      routes
    };
    localStorage.setItem('checkoutState', JSON.stringify(checkoutState));
  }, [passengerDetails, travelBookingRoutes, selectedPackage, selectedExtras, passengerSummary, currentJourneyType, routes]);

  const handleConfirm = useCallback(async () => {
    saveStates();
    const { passengerList, note } = passengerDetails ?? {};

    const checkInventoryResult = await checkoutService.verifyAvailability(travelBookingRoutes, passengerSummary);
    if (!checkInventoryResult) {
      setOpenInventoryAlertDialog(true);
      return;
    }

    if (passengerList) {
      checkoutService.travelCheckout(travelBookingRoutes, passengerSummary, selectedExtras, passengerList, selectedPackage, returnPrice, note);
    }
  }, [saveStates, passengerDetails, checkoutService, travelBookingRoutes, passengerSummary, selectedExtras, selectedPackage, returnPrice]);

  useEffect(() => {
    if (uncompleteCheckout && !loadedState) {
      const { travelBookingRoutes, selectedPackage, selectedExtras, passengerSummary, passengerList, journeyType, note } = JSON.parse(
        localStorage.getItem('checkoutState') ?? '{}'
      );

      setLoadedState(true);
      journeyType && setJourneyType(journeyType);
      forEach(travelBookingRoutes, ({ identifier, selectedEntry }: TravelBookingRoute) => {
        if (selectedEntry) {
          const returnTicket = selectedEntry.returnPrice?.ticket;
          returnTicket && setReturnTicket(returnTicket);
          setSelectedEntry(identifier, selectedEntry);
          setScheduleStageValid(true);
        }
      });
      selectedPackage && setSelectedPackage(selectedPackage);
      selectedExtras && setSelectedExtras(selectedExtras);
      passengerSummary && setPassengerSummary(passengerSummary);
      passengerList && setPassengerDetails({ passengerList, note });
      journeyType && setCurrentJourneyType(journeyType);
      setStage(3);
    }
  }, [
    loadedState,
    setJourneyType,
    setPassengerSummary,
    setReturnTicket,
    setSelectedEntry,
    setSelectedExtras,
    setSelectedPackage,
    uncompleteCheckout,
    setCurrentJourneyType
  ]);

  useEffect(() => {
    if (Object.entries(passengerDetails?.passengerList ?? []).length && submit) {
      handleConfirm();
      setSubmit(false);
    }
  }, [handleConfirm, passengerDetails?.passengerList, submit]);

  useEffect(() => {
    if (!uncompleteCheckout) {
      setStage(1);
    }
  }, [uncompleteCheckout]);

  const handlePassengerSubmit = (passengerList: Passenger[], isGdprAccepted: boolean, note?: string) => {
    setPassengerDetails({ passengerList, note });
  };

  const nextStage = () => {
    setStage(stage + 1);
  };

  const canContinue = useMemo(() => scheduleStageValid || !!selectedPackage, [scheduleStageValid, selectedPackage]);

  return (
    <>
      {hasRoutes && !matchesBreakpoint('sm') && (
        <StageHeader isMobileView={true} stageNames={stageNames} currentStage={stage} onStageChange={(stageNumber) => setStage(stageNumber)} />
      )}
      <Container maxWidth="xl" disableGutters>
        {hasRoutes && matchesBreakpoint('sm') && (
          <StageHeader isMobileView={false} stageNames={stageNames} currentStage={stage} onStageChange={(stageNumber) => setStage(stageNumber)} />
        )}
        {stage === 1 && (
          <>
            <Box sx={{ height: 24 }} />
            <ScheduleSelectorList setIsValid={setScheduleStageValid} />
          </>
        )}
        <Box sx={{ height: 24 }} />
        {stage === 2 && (
          <Box sx={{ padding: 1 }}>
            <TravelExtraContainer travelBookingRoutes={travelBookingRoutes} />
          </Box>
        )}

        {stage === 3 && (
          <TravelPassengerDetails
            onSubmit={handlePassengerSubmit}
            submit={submit}
            setSubmit={setSubmit}
            setLoading={setLoading}
            defaultPassengerDetails={{ defaultPassengers: passengerDetails?.passengerList ?? [], defaultNote: passengerDetails?.note }}
          />
        )}

        <Box sx={{ height: matchesBreakpoint('sm') ? bookingFooterHeight + 20 : 0 }} />
        <TravelBookingFooterContainer setHeight={setBookingFooterHeight} footerHeight={footerHeight}>
          <TravelBookingFooter
            passengerSummary={passengerSummary}
            selectedExtras={selectedExtras}
            routes={travelBookingRoutes}
            selectedPackage={selectedPackage}
          />
          {stage < 3 ? (
            <DisabledTooltip
              disabled={canContinue}
              title={<FormattedMessage id="tooltips.splitexpress.selectTicket" defaultMessage="Select ticket(s) to continue" />}
              placement="top"
            >
              <Button
                disabled={!canContinue}
                onClick={() => {
                  nextStage();
                }}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                <FormattedMessage id="booking.footer.continue" defaultMessage="Continue" />
              </Button>
            </DisabledTooltip>
          ) : (
            <LoadingButton onClick={() => setSubmit(true)} variant="contained" color="primary" fullWidth size="large" loading={loading}>
              <FormattedMessage id="booking.footer.confirm" defaultMessage="Confirm booking" />
            </LoadingButton>
          )}
        </TravelBookingFooterContainer>
        <Dialog open={openInventoryAlertDialog}>
          <DialogContent>
            <FormattedMessage id="booking.inventory_alert.title" defaultMessage="Your selected route(s) are no longer available" />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: 'white !important' }}
              variant="contained"
              onClick={() => {
                localStorage.removeItem('cartId');
                window.location.reload();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default inject('checkoutService')(TravelSelector as FC<{}>) as React.ElementType<Props>;
