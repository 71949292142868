import { FC, useContext } from 'react';
import { Box } from '@mui/system';
import { ViewportContext } from '../context/ViewportContext';

interface Props {
  setHeight: (newHeight: number) => void;
  footerHeight: number;
}

const TravelBookingFooterContainer: FC<Props> = (props) => {
  const { children, footerHeight, setHeight } = props;
  const { matchesBreakpoint } = useContext(ViewportContext);
  return (
    <>
      {matchesBreakpoint('sm') ? (
        <Box sx={{ marginBottom: `${footerHeight - 108}px` }}>{children}</Box>
      ) : (
        <Box
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, zIndex: 2000 }}
          ref={(el: HTMLDivElement | null) => {
            if (!el) {
              return;
            }
            setHeight(el.getBoundingClientRect().height);
          }}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default TravelBookingFooterContainer;
