import { FC, useState, useMemo, useEffect, useCallback, useContext, ReactElement } from 'react';
import { Container, Paper } from '@mui/material';
import BookingFooter from './booking-footer/BookingFooter';
import BookingConfirmation from './confirmation-stage/BookingConfirmation';
import ScheduleStage from './schedule-stage/ScheduleStage';
import VehicleStage from './vehicle-stage/VehicleStage';
import CabinsStage from './cabin-stage/CabinsStage';
import PassengerInfoStage from './passenger-info-stage/PassengerInfoStage';
import ExtrasStage from './extra-stage/ExtrasStage';
import StageIconFlag from './StageIconFlag';
import { RouteSummary } from '../modules/routing/Routes';
import { JourneyType, JourneyTypeContext } from '../context/JourneyTypeContext';
import { PassengerSummaryContext } from '../context/PassengerSummaryContext';
import { every, isEqual, cloneDeep } from 'lodash';
import { BookingStage, BookingStageContext } from '../context/BookingStageContext';
import { VehicleTypeContext } from '../context/VehicleTypeContext';
import { useInjects } from 'saft-react';
import useAsync from '../useAsync';
import Vehicles from '../modules/vehicles/Vehicles';
import { ViewportContext } from '../context/ViewportContext';
import { CircularProgress } from '@mui/material';
import LoadingContextProvider, { LoadingContext } from '../context/LoadingContext';
import { useIntl } from 'react-intl';
import StagesHeader from './StagesHeader';
import { usePrevious } from '../utils/hooks/usePrevious';
import Bookings from '../modules/bookings/BookingsService';
import { v4 as uuid } from 'uuid';
import { BookingStateContext } from '../context/BookingStateContext';

export interface Stage {
  name: BookingStage;
  stepperName: string;
  content: ReactElement<any, any>;
  skip: boolean;
  isValid: boolean;
}

interface FreightSelectorProps {
  routes: RouteSummary[];
  footerHeight: number;
}

const FreightSelector: FC<FreightSelectorProps> = ({ routes, footerHeight }) => {
  const { currentStage, setCurrentStage, hasPastLastStage } = useContext(BookingStageContext);
  const { journeyType } = useContext(JourneyTypeContext);
  const { passengerSummary } = useContext(PassengerSummaryContext);
  const { vehicleType } = useContext(VehicleTypeContext);
  const { defaultBookingState } = useContext(BookingStateContext);
  const previousVehicle = usePrevious(vehicleType);
  const intl = useIntl();

  const deps = useInjects<{ lxVehicleService: Vehicles }>({
    lxVehicleService: undefined
  });

  const bookingDeps = useInjects<{ lxBookingsService: Bookings }>({ lxBookingsService: undefined });
  const isAccompanied = useAsync<boolean>(() => (deps === undefined ? Promise.resolve(true) : deps.lxVehicleService.isAccompanied(vehicleType)), [deps]).res;

  const [isScheduleStageValid, setIsScheduleStageValid] = useState(true);
  const [isVehicleStageValid, setIsVehicleStageValid] = useState(true);
  const [isCabinStageValid, setIsCabinStageValid] = useState(true);
  const [isPassengerStageValid, setIsPassengerStageValid] = useState(true);
  const [isExtraStageValid, setIsExtraStageValid] = useState(true);

  const [scheduleState, setScheduleState] = useState(defaultBookingState.scheduleState);
  const [vehicleState, setVehicleState] = useState(cloneDeep(defaultBookingState.vehicleState));
  const [cabinState, setCabinState] = useState(defaultBookingState.cabinState);
  const [passengerState, setPassengerState] = useState(defaultBookingState.passengerState);
  const [extraState, setExtraState] = useState(defaultBookingState.extraState);
  const bookingState = useMemo(() => {
    return { scheduleState, vehicleState, cabinState, passengerState, extraState };
  }, [scheduleState, vehicleState, cabinState, passengerState, extraState]);
  const isReturnJourney = useCallback(() => {
    return journeyType === JourneyType.RoundTrip;
  }, [journeyType]);

  const stages: Stage[] = useMemo(() => {
    return [
      {
        name: BookingStage.Schedule,
        stepperName: intl.formatMessage({ id: 'booking.freight.stepper.schedule', defaultMessage: 'Choose schedule' }),
        content: (
          <ScheduleStage state={scheduleState} setIsStageValid={setIsScheduleStageValid} setState={setScheduleState} isReturnJourney={isReturnJourney()} />
        ),
        skip: false,
        isValid: isScheduleStageValid
      },
      {
        name: BookingStage.Vehicle,
        stepperName: intl.formatMessage({ id: 'booking.freight.stepper.vehicle', defaultMessage: 'Vehicle info' }),
        content: (
          <VehicleStage
            isStageValid={isVehicleStageValid}
            setIsStageValid={setIsVehicleStageValid}
            state={vehicleState}
            setState={setVehicleState}
            isReturnJourney={isReturnJourney()}
          />
        ),
        skip: false,
        isValid: isVehicleStageValid
      },
      {
        name: BookingStage.Cabins,
        stepperName: intl.formatMessage({ id: 'booking.freight.stepper.cabins', defaultMessage: 'Cabins' }),
        content: (
          <CabinsStage
            isReturnJourney={isReturnJourney()}
            setIsStageValid={setIsCabinStageValid}
            passengerState={passengerState}
            state={cabinState}
            setState={setCabinState}
          />
        ),
        skip: !isAccompanied,
        isValid: isCabinStageValid
      },
      {
        name: BookingStage.Info,
        stepperName: intl.formatMessage({ id: 'booking.freight.stepper.passenger', defaultMessage: 'Passenger Information' }),
        content: (
          <PassengerInfoStage
            setIsFormValid={setIsPassengerStageValid}
            state={passengerState}
            setState={setPassengerState}
            passengerSummary={passengerSummary}
          />
        ),
        skip: !isAccompanied,
        isValid: isPassengerStageValid
      },
      {
        name: BookingStage.Extra,
        stepperName: intl.formatMessage({ id: 'booking.freight.stepper.extra', defaultMessage: 'Extra' }),
        content: <ExtrasStage state={extraState} setState={setExtraState} setIsStageValid={setIsExtraStageValid} passengers={passengerState.passengers} />,
        skip: !isAccompanied,
        isValid: isExtraStageValid
      }
    ];
  }, [
    cabinState,
    extraState,
    isCabinStageValid,
    isExtraStageValid,
    isPassengerStageValid,
    isReturnJourney,
    isScheduleStageValid,
    isVehicleStageValid,
    passengerState,
    passengerSummary,
    scheduleState,
    vehicleState,
    isAccompanied,
    intl
  ]);

  const isBookingValid = useMemo(() => {
    return every(stages.map((stage) => stage.isValid));
  }, [stages]);

  const showValidationWarning = useMemo(() => {
    if (currentStage >= stages.length - 1) {
      return !isBookingValid;
    }
    return !stages[currentStage].isValid;
  }, [currentStage, isBookingValid, stages]);

  useEffect(() => {
    if (!isEqual(passengerState.passengerSummary, passengerSummary)) {
      setPassengerState({ ...passengerState, passengerSummary });
    }
  }, [passengerSummary, setPassengerState, passengerState]);

  useEffect(() => {
    if (!isEqual(scheduleState.routeSummaries, routes)) {
      setScheduleState({ ...scheduleState, routeSummaries: routes });
    }
  }, [routes, setScheduleState, scheduleState]);

  useEffect(() => {
    if (!cabinState.selectedAccommodations.returnRoute && scheduleState.routes?.some((leg) => leg.isReturn)) {
      setCabinState({ ...cabinState, selectedAccommodations: { ...cabinState.selectedAccommodations, returnRoute: [] } });
    }
  }, [scheduleState, setCabinState, cabinState]);

  useEffect(() => {
    if (vehicleType !== previousVehicle) {
      setVehicleState(cloneDeep(defaultBookingState.vehicleState));
    }
  }, [vehicleType, previousVehicle, setVehicleState, defaultBookingState]);

  useEffect(() => {
    if (hasPastLastStage && scheduleState.routes) {
      bookingDeps &&
        bookingDeps.lxBookingsService.save({
          bookingReference: uuid().split('-')[0].toUpperCase(),
          licensePlate: vehicleState.outboundVehicle.vehicleRegistration || vehicleState.outboundVehicle.trailerRegistration || 'N/A',
          bookingDate: new Date(),
          vehicle: vehicleState.outboundVehicle,
          routes: scheduleState.routes,
          passengerInfo: passengerState,
          routeSummaries: scheduleState.routeSummaries
        });
    }
  }, [hasPastLastStage, bookingDeps, scheduleState, vehicleState, passengerState]);

  const [furthestStageVisited, setFurthestStageVisisted] = useState(0);

  const goToStage = (index: number) => {
    if (!(index > furthestStageVisited)) {
      setCurrentStage(index);
    }
  };

  const getNextStage = () => {
    for (let nextStage = currentStage + 1; nextStage < stages.length; nextStage++) {
      if (!stages[nextStage].skip) {
        return nextStage;
      }
    }
    return stages.length;
  };

  const handleNextStage = () => {
    if (!stages[currentStage].isValid) {
      return;
    }
    const nextStage = getNextStage();
    if (nextStage > furthestStageVisited) {
      setFurthestStageVisisted(nextStage);
    }
    setCurrentStage(nextStage);
  };

  const { matchesBreakpoint } = useContext(ViewportContext);
  const shouldShowStageFlag = useMemo(() => {
    return matchesBreakpoint('xxl');
  }, [matchesBreakpoint]);

  const [bookingFooterHeight, setBookingFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <>
      {(matchesBreakpoint('sm') || !hasPastLastStage) && <StagesHeader stages={stages} goToStage={goToStage} setHeight={setHeaderHeight} />}
      <>
        {hasPastLastStage ? (
          <BookingConfirmation />
        ) : (
          <>
            <Container
              maxWidth="xl"
              disableGutters={matchesBreakpoint('sm') ? false : true}
              sx={{
                position: 'relative',
                marginBottom: `${(!matchesBreakpoint('sm') ? 0 : footerHeight) + bookingFooterHeight + 32}px`,
                marginTop: `${headerHeight + (matchesBreakpoint('sm') ? 16 : 44)}px`
              }}
            >
              {shouldShowStageFlag && <StageIconFlag name={stages[currentStage].name} />}
              <Paper
                {...(stages[currentStage].name === BookingStage.Schedule ? { elevation: 0 } : {})}
                sx={{ backgroundColor: `${[BookingStage.Schedule, BookingStage.Extra].includes(stages[currentStage].name) ? 'transparent' : 'white'}` }}
              >
                <LoadingContextProvider>
                  <LoadingContext.Consumer>
                    {({ loading }) => loading && <CircularProgress sx={{ position: 'absolute', top: 5, right: 5 }} />}
                  </LoadingContext.Consumer>
                  {stages[currentStage].content}
                </LoadingContextProvider>
              </Paper>
            </Container>
            <BookingFooter
              setHeight={setBookingFooterHeight}
              pageFooterHeight={footerHeight}
              showValidationWarning={showValidationWarning}
              bookingState={bookingState}
              onContinue={handleNextStage}
            />
          </>
        )}
      </>
    </>
  );
};

export default FreightSelector;
