import { useQuery } from '@apollo/client';
import { map } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { PassengerSummary } from '../../components/header-selectors/PasssengerSelector';
import { SailingLocation } from '../../components/travel/ScheduleHelper';
import { RouteSummary } from '../routing/Routes';

import SailingProductsSource, { Extra, Package, ReturnTicket, SailingLeg, Ticket } from '../sailing-products/SailingProductsSource';
import ShopifyAdapter from './ShopifyAdapter';
import ShopifyService, { ProductType } from './ShopifyService';

type GetProductsSourceHook = (
  shopifyService: ShopifyService,
  shopifyAdapter: ShopifyAdapter
) => (routeSummary: RouteSummary, passengerSummary: PassengerSummary) => { sailingLegs: SailingLeg[]; tickets: Ticket[]; extras: Extra[]; isLoading: boolean };
const getUseProductsSourceHook: GetProductsSourceHook = (shopifyService: ShopifyService, shopifyAdapter: ShopifyAdapter) => {
  return (routeSummary: RouteSummary, passengerSummary: PassengerSummary) => {
    const [query, setQuery] = useState(shopifyService.getProductsByDateQuery(moment(routeSummary.departureDate)));
    useEffect(() => {
      const query = shopifyService.getProductsByDateQuery(moment(routeSummary.departureDate));
      setQuery(query);
    }, [routeSummary.departureDate]);

    const sailingLegFilter = shopifyService.getProductFilter({ productType: ProductType.SailingLeg });
    const ticketFilter = shopifyService.getProductFilter({ productType: ProductType.Ticket });
    const extraFilter = shopifyService.getProductFilter({ productType: ProductType.TravelExtra });

    const { loading: loadingSailing, data: sailingLegData } = useQuery(query, {
      variables: { productFilters: sailingLegFilter }
    });

    const { loading: loadingTicket, data: ticketData } = useQuery(query, {
      variables: { productFilters: ticketFilter }
    });

    const { loading: loadingExtra, data: extraData } = useQuery(query, {
      variables: { productFilters: extraFilter }
    });

    const allSailingLegs = shopifyService.productsWithoutEdgesNode(sailingLegData?.collection?.products.edges);
    const allTickets = shopifyService.productsWithoutEdgesNode(ticketData?.collection?.products.edges);
    const allExtras = shopifyService.productsWithoutEdgesNode(extraData?.collection?.products.edges);

    const isLoading = loadingSailing || loadingTicket || loadingExtra;

    return {
      sailingLegs: map(allSailingLegs, shopifyAdapter.mapToSailingLeg),
      tickets: map(allTickets, shopifyAdapter.mapToTicket),
      extras: map(allExtras, shopifyAdapter.mapToExtra),
      isLoading
    };
  };
};

class ShopifySailingProductsSource implements SailingProductsSource {
  private shopifyAdapter: ShopifyAdapter;

  constructor(private shopifyService: ShopifyService) {
    this.shopifyAdapter = new ShopifyAdapter();
  }

  private getDiscount = (routeSummary: RouteSummary): Promise<number> => {
    const discountedRoutes = [
      {
        discount: 0.8,
        route: ['Split', 'Bol']
      }
    ];

    const discounts = [];
    const origin = routeSummary.origin.code;
    const destination = routeSummary.destination.code;

    for (const discountedRoute of discountedRoutes) {
      if ([origin, destination].every((i) => discountedRoute.route.includes(i))) {
        discounts.push(discountedRoute.discount);
      }
    }

    return Promise.resolve(discounts.length > 0 ? Math.min(...discounts) : 1);
  };

  async getReturnTicketByRouteSummary(routeSummary: RouteSummary): Promise<ReturnTicket | undefined> {
    const products = await this.shopifyService.getReturnTickets();
    const allReturnTickets = products.map(this.shopifyAdapter.mapToReturnTicket);

    const returnTicket = allReturnTickets.find(
      (returnTicket) =>
        (returnTicket.origin === routeSummary.origin.name && returnTicket.destination === routeSummary.destination.name) ||
        (returnTicket.origin === routeSummary.destination.name && returnTicket.destination === routeSummary.origin.name)
    );

    return returnTicket;
  }

  getSailingLegs(): Promise<SailingLeg[]> {
    return Promise.resolve([]);
  }

  getTickets(): Promise<Ticket[]> {
    return Promise.resolve([]);
  }

  getExtras(): Promise<Extra[]> {
    return Promise.resolve([]);
  }

  getPackages(): Promise<Package[]> {
    return Promise.resolve([]);
  }

  async getPackagesByRouteSummary(routeSummary: RouteSummary, passengerSummary: PassengerSummary): Promise<Package[]> {
    const origin = routeSummary.origin.name as SailingLocation;
    const destination = routeSummary.destination.name as SailingLocation;
    const date = moment(routeSummary.departureDate);
    const products = await this.shopifyService.getPackages(date, origin, destination);
    const packages = products?.map(this.shopifyAdapter.mapToPackage) ?? [];
    const validPackages = packages.filter(
      (eachPackage) => eachPackage.requirement.minimumAdult === passengerSummary['adult'] && eachPackage.requirement.minimumChild <= passengerSummary['child']
    );

    return validPackages;
  }

  async getPackageExtras(date: Moment, travelPackage: Package, productType?: string): Promise<Extra[]> {
    if (!productType) {
      const extras = await this.shopifyService.getPackageExtras(date, travelPackage.reference, ProductType.TravelExtra);
      const extraPaxes = await this.shopifyService.getPackageExtras(date, travelPackage.reference, ProductType.ExtraPax);
      return [...extras.map(this.shopifyAdapter.mapToExtra), ...extraPaxes.map(this.shopifyAdapter.mapToExtra)];
    }
    const extras = await this.shopifyService.getPackageExtras(date, travelPackage.reference, productType);
    return extras.map(this.shopifyAdapter.mapToExtra);
  }

  getUseProductsSourceHook(): (
    routeSummary: RouteSummary,
    passengerSummary: PassengerSummary
  ) => { sailingLegs: SailingLeg[]; tickets: Ticket[]; extras: Extra[]; isLoading: boolean } {
    return getUseProductsSourceHook(this.shopifyService, this.shopifyAdapter);
  }
}

export default ShopifySailingProductsSource;
