import { FC } from 'react';
import { Typography, Grid, useTheme } from '@mui/material';
import { TicketType } from '../../modules/scheduling/Schedules';

interface PriceContainerProps {
  price: number;
  currency: string;
  ticketType: TicketType;
}

const PriceContainer: FC<PriceContainerProps> = ({ price, currency, ticketType }) => {
  const theme = useTheme();
  return (
    <Grid container direction="column" sx={{ flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#ededed', borderRadius: 1 }}>
      <Grid item>
        <Typography fontWeight="bold" sx={{ fontSize: 28, padding: 1 }}>{`${price} ${currency}`}</Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            padding: 0.5,
            backgroundColor: 'primary.dark',
            color: 'white',
            borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
          }}
        >
          {ticketType}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PriceContainer;
