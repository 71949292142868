import { FC, useContext } from 'react';
import { RadioGroup, Grid, FormControlLabel, Radio, Box, Avatar, useTheme, Typography } from '@mui/material';
import LegCard from './LegCard';
import PriceContainer from './PriceContainer';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import { ControllerRenderProps, FieldValues, UseFormGetValues } from 'react-hook-form';
import { ViewportContext } from '../../context/ViewportContext';

interface LegGroupProps {
  field: ControllerRenderProps<FieldValues, 'outboundRoute'> | ControllerRenderProps<FieldValues, 'returnRoute'>;
  getValues: UseFormGetValues<FieldValues>;
  legs: ScheduleEntry[] | undefined;
  routeType: 'outboundRoute' | 'returnRoute';
  onLegSelected?: (scheduleEntry?: ScheduleEntry) => void;
}

const LegGroup: FC<LegGroupProps> = ({ field, getValues, legs, routeType, onLegSelected }) => {
  const theme = useTheme();
  const { matchesBreakpoint } = useContext(ViewportContext);
  return (
    <RadioGroup
      {...field}
      value={getValues(routeType) ?? -1}
      onChange={(event) => {
        field.onChange(event.target.value);
        onLegSelected?.(legs?.[parseInt(event.target.value)]);
      }}
    >
      {legs?.map((entry, index) => (
        <Box
          key={index}
          sx={{
            marginLeft: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: '#fafafa'
            }
          }}
        >
          <Grid container item xs>
            <FormControlLabel
              sx={{ width: '100%', '& > span:last-child': { width: '100%' }, margin: 0 }}
              value={index}
              control={
                <Radio
                  disabled={!entry.isBookable}
                  sx={{
                    margin: `${theme.spacing(1)} ${matchesBreakpoint('sm') ? theme.spacing(1) : 0}`,
                    alignSelf: matchesBreakpoint('sm') ? 'center' : 'flex-start'
                  }}
                />
              }
              label={
                <Grid container item sx={{ width: '100%' }}>
                  <Grid item xs={7} sm={9} sx={{ alignItems: 'center', display: 'flex' }}>
                    <LegCard origin={entry.origin} destination={entry.destination} scheduleEntry={entry} />
                  </Grid>
                  <Grid container item xs={5} sm={3} sx={{ alignItems: 'center', paddingRight: matchesBreakpoint('sm') ? 2 : 0 }}>
                    {!matchesBreakpoint('sm') && (
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: 1, paddingBottom: 1 }}>
                        <Avatar>S</Avatar>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {!entry.isBookable ? (
                        <Typography fontWeight="bold" color="error">
                          Unavailable
                        </Typography>
                      ) : (
                        <PriceContainer price={entry?.price ?? 0} currency={'SEK'} ticketType={entry.ticketType} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Box>
      ))}
    </RadioGroup>
  );
};

export default LegGroup;
