import { Box, Grid } from '@mui/material';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { inject } from 'saft-react';
import SailingProductsSource, { Extra, Package } from '../../modules/sailing-products/SailingProductsSource';
import { ProductType } from '../../modules/shopify/ShopifyService';
import { PackageBookingContext } from '../packages/PackageBookingContext';
import { TravelBookingContext, TravelBookingRoute } from './TravelBookingContext';
import TravelExtraCard from './TravelExtraCard';

interface Props {
  travelBookingRoute: TravelBookingRoute;
}

interface InjectedProps {
  sailingProductsSource: SailingProductsSource;
}

const TravelExtraSelector: FC<Props & InjectedProps> = (props) => {
  const { travelBookingRoute, sailingProductsSource } = props;
  const { routeSummary } = travelBookingRoute;

  const [extras, setExtras] = useState<Extra[]>([]);

  const { selectedExtras, setSelectedExtra } = useContext(TravelBookingContext);
  const { selectedPackage } = useContext(PackageBookingContext);

  useEffect(() => {
    const getPackageExtras = async (travelPackage: Package) => {
      const extras = await sailingProductsSource.getPackageExtras(moment(routeSummary.departureDate), travelPackage, ProductType.TravelExtra);
      setExtras(extras);
    };
    if (selectedPackage) {
      getPackageExtras(selectedPackage);
    } else if (travelBookingRoute.selectedEntry?.extras) {
      setExtras(travelBookingRoute.selectedEntry.extras);
    }
  }, [travelBookingRoute.selectedEntry, selectedPackage, routeSummary.departureDate, sailingProductsSource]);

  if (extras.length === 0) {
    return null;
  }

  return (
    <Box sx={{ marginTop: 1 }}>
      <Box sx={{ marginBottom: 1, borderRadius: 1, backgroundColor: '#777', color: 'white', fontWeight: 500, padding: 1 }}>
        {routeSummary.origin.name} - {routeSummary.destination.name}
      </Box>
      <Grid container spacing={2}>
        {extras.map((extra) =>
          Object.entries(extra?.inventory ?? []).map(([extraType, inventory]) => {
            if (!inventory.availableForSale) {
              return null;
            }
            return (
              <Grid item xs={12} sm={6} md={6} key={extraType}>
                <TravelExtraCard
                  type={extraType}
                  inventory={inventory}
                  onQuantityChange={(extraType, quantity) => {
                    setSelectedExtra(inventory, quantity, extra);
                  }}
                  quantity={selectedExtras[inventory.id]?.quantity ?? 0}
                />
                <Box sx={{ height: 8 }} />
              </Grid>
            );
          })
        )}
      </Grid>
    </Box>
  );
};

export default inject('sailingProductsSource')(TravelExtraSelector as FC<{}>) as React.ElementType<Props>;
