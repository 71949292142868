import { Box, Grid, Paper } from '@mui/material';
import React, { FC } from 'react';
import { Inventory } from '../../modules/sailing-products/SailingProductsSource';
import QuantitySelector from '../QuantitySelector';
import { inject } from 'saft-react';
import Extras from '../../modules/extras/ExtrasService';

interface Props {
  type: string;
  inventory: Inventory;
  onQuantityChange: (extraType: string, quantity: number) => void;
  quantity: number;
}

interface InjectedProps {
  lxExtrasService: Extras;
}

const TravelExtraCard: FC<Props & InjectedProps> = (props) => {
  const { type, inventory, onQuantityChange, quantity, lxExtrasService } = props;

  return (
    <Paper sx={{ boxShadow: '0px 3px 12px rgba(172,172,172,0.1)', borderRadius: 1 }}>
      <Grid container>
        <Grid item xs={5}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <img
              style={{ borderBottomLeftRadius: 4, borderTopLeftRadius: 4, objectFit: 'cover', overflow: 'hidden', height: '100%', width: '100%' }}
              src={lxExtrasService.getExtraImage(inventory.title)}
              alt={inventory.title}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box sx={{ padding: 2, paddingTop: 3, paddingBottom: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',  fontSize: 18, fontWeight: 500 }}>
              <Box>{type}</Box>
              <Box>
                {inventory.price?.amount ?? 0} {inventory.price?.currencyCode ?? 'SEK'}
              </Box>
            </Box>
            <Box sx={{ height: 24 }} />
            <Box sx={{ textAlign: 'right' }}>
              <QuantitySelector
                quantity={quantity}
                setQuantity={(newValue) => onQuantityChange(inventory.title, newValue)}
                isRemoveDisabled={quantity <= 0}
                isAddDisabled={false}
                size={5}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default inject('lxExtrasService')(TravelExtraCard as FC<{}>) as React.ElementType<Props>;
