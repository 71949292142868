import { faChevronDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, useTheme } from '@mui/material';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import { TravelBookingContext } from './TravelBookingContext';
import { size } from 'lodash';
import { useIntl } from 'react-intl';

interface Props {
  onExpand?: () => void;
}

const TravelBookingDisplay: FC<Props> = (props) => {
  const { onExpand } = props;
  const intl = useIntl();

  const { travelBookingRoutes } = useContext(TravelBookingContext);
  const { passengerSummary } = useContext(PassengerSummaryContext);
  const theme = useTheme();
  const routeSummary = travelBookingRoutes?.[0]?.routeSummary;

  const sum = Object.entries(passengerSummary).reduce((sum, value) => {
    const [, amount] = value;
    return sum + amount;
  }, 0);

  return (
    <Box sx={{ width: '100%', height: 64, paddingTop: 8, paddingBottom: 1, background: theme.background?.main ?? '#ededed' }}>
      <Box sx={{ display: 'flex', padding: 2, color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              <FontAwesomeIcon style={{ color: '#3B9FC3' }} icon={faMapMarkerAlt} /> {routeSummary?.origin?.name}
            </Box>
            <Box>&nbsp;&nbsp;---&nbsp;&nbsp;</Box>
            <Box>
              <FontAwesomeIcon style={{ color: '#3B9FC3' }} icon={faMapMarkerAlt} /> {routeSummary?.destination?.name}
            </Box>
          </Box>
          <Box>
            {moment(routeSummary?.departureDate).format('D MMM')} | {sum}{' '}
            {intl
              .formatMessage(
                {
                  id: 'booking.passenger.traveller',
                  defaultMessage: '{count, plural, one {Traveller} other {Travellers}}'
                },
                { count: sum }
              )
              .toLowerCase()}{' '}
            |{' '}
            {intl.formatMessage(
              { id: 'booking.tripSearch.trips', defaultMessage: '{trips, plural, one {1 trip} other {# spaces}}' },
              { trips: size(travelBookingRoutes) }
            )}
          </Box>
        </Box>
        <Box onClick={() => onExpand?.()} sx={{ position: 'absolute', right: 16 }}>
          <IconButton size="small">
            <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronDown} />
          </IconButton>
        </Box>
      </Box>
      <Box></Box>
    </Box>
  );
};

export default TravelBookingDisplay;
