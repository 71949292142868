import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Paper, Divider } from '@mui/material';
import React, { FC } from 'react';
import { Package } from '../../modules/sailing-products/SailingProductsSource';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import TicketInfo from './TicketInfo';

interface Props {
  departureEntry: ScheduleEntry;
  returnEntry?: ScheduleEntry;
  onSelectTicket: (select: boolean) => void;
  selected: boolean;
  price?: number;
  currencyCode?: string;
  packageItem: Package;
  extraPaxPrice: number;
}

const PackageTicket: FC<Props> = (props) => {
  const { departureEntry, returnEntry, onSelectTicket, selected, price, currencyCode, extraPaxPrice = 0 } = props;

  const selectedStyle = {
    backgroundColor: '#3B9FC3',
    color: 'white'
  };

  return (
    <Box
      onClick={() => {
        onSelectTicket(!selected);
      }}
      sx={{ minHeight: 128, width: '100%' }}
    >
      <Grid container spacing={0.25}>
        <Grid item xs={12}>
          <Paper sx={{ boxShadow: '0px 3px 12px rgba(172,172,172,0.2)', height: '100%', textAlign: 'left' }}>
            <Grid container columnSpacing={2} padding={2}>
              <Grid item xs>
                <TicketInfo entry={departureEntry} />
              </Grid>
              {returnEntry && (
                <>
                  <Divider orientation="vertical" flexItem sx={{ paddingLeft: 2 }} />
                  <Grid item xs>
                    <TicketInfo entry={returnEntry} />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        {
          <Grid item xs={12}>
            <Paper
              sx={Object.assign(
                {},
                { boxShadow: '0px 3px 12px rgba(172,172,172,0.2)', borderBottom: '3px solid #1F7BC2', height: '100%' },
                selected ? selectedStyle : {}
              )}
            >
              <Box sx={{ fontSize: 22, fontWeight: 'bold', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ padding: 1 }}>
                  {selected && <FontAwesomeIcon style={{ fontSize: 14 }} icon={faCheck} />}{' '}
                  {(price || (departureEntry.price ?? 0) + (returnEntry?.price ?? 0)) + extraPaxPrice} {currencyCode}
                </Box>
              </Box>
            </Paper>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default PackageTicket;
