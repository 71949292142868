import { CUSTOMER } from '../../settings/AppConstants';
import { Customer } from '../../types/Customer';
import UrlResolver from '../server-communication/UrlResolver';
import { Node, StaticPageService, Page } from '../static-page-service/StaticPageService';
import ShopifyService from './ShopifyService';

enum BlogHandle {
  SocialMedias = 'social-medias',
  Notices = 'notices'
}

class ShopifyStaticPageService implements StaticPageService {
  constructor(private shopifyService: ShopifyService) {
    this.shopifyService = shopifyService;
  }

  async getPageInformation(first: number): Promise<Node[]> {
    const pages = (await this.shopifyService.getPageInformation(first)) as Node[];
    return pages.filter((page) => page.handle !== 'contact');
  }

  getPageByHandle(handle: string): Promise<Page> {
    return this.shopifyService.getPageByHandle(handle);
  }

  getBlogArticleByHandle(blogName: string, articleHandle: string): Promise<Page> {
    return this.shopifyService.getBlogArticleByHandle(blogName, articleHandle);
  }

  showContactUs(): boolean {
    return CUSTOMER === Customer.SplitExpress;
  }

  onClickContactUs() {
    const baseUrl = UrlResolver.getShopifyUrl();
    const contactUrl = `${baseUrl}/pages/contact`;
    window.open(contactUrl, 'popup', 'width=700,height=600,scrollbars=no,resizable=no');
    return false;
  }

  async getSocialMediaLinks(): Promise<Record<string, string>> {
    const articles = (await this.shopifyService.getBlogArticles(BlogHandle.SocialMedias, true)) as (Node & { content: string })[];
    const socialMediaLinks = articles.reduce<Record<string, string>>((result, article) => {
      result[article.handle] = article.content;
      return result;
    }, {});
    return socialMediaLinks;
  }

  async getNotice(): Promise<Page | null> {
    try {
      const page = await this.getBlogArticleByHandle(BlogHandle.Notices, 'notice');
      return page;
    } catch (e) {
      return null;
    }
  }
}

export default ShopifyStaticPageService;
