import { useCallback, useContext, useEffect, useState } from 'react';
import Header from '../Header';
import PosCartDetails from './PosCartDetails';
// import LanguageSelector from './LanguageSelector';
import { PosContext } from './PosContext';
import PosPaymentSummary from './PosPaymentSummary';
import PosScheduleSelector from './PosScheduleSelector';
import PosScheduleSelectorDialog from './PosScheduleSelectorDialog';
import PosTicketSelector from './PosTicketSelector';

const PosTicketContainer = () => {
  const { trips, removeTrip, setTrip, cartItems, paymentSummary, clearCart } = useContext(PosContext);

  const [changeOutboundDialogOpen, setChangeOutboundDialogOpen] = useState(false);
  const [tripIndex, setTripIndex] = useState(-1);
  const [showCartDetails, setShowCartDetails] = useState(false);

  const [defaultOrigin, setDefaultOrigin] = useState<string | undefined>(undefined);

  useEffect(() => {
    const lastSelectedOrigin = localStorage.getItem('lastSelectedOrigin');
    if (lastSelectedOrigin) {
      setDefaultOrigin(lastSelectedOrigin);
    }
  }, []);

  const rememberLastOrigin = useCallback((value: string) => {
    localStorage.setItem('lastSelectedOrigin', value);
  }, []);

  useEffect(() => {
    if (paymentSummary) {
      clearCart();
      trips.forEach((trip, i) => removeTrip(i));
      setShowCartDetails(false);
    }
  }, [clearCart, paymentSummary, removeTrip, showCartDetails, trips]);

  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#F8F8F8',
        paddingTop: '104px',
        zIndex: 100,
        textAlign: 'initial'
      }}
    >
      <div style={{ height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Header />
        {/* <LanguageSelector /> */}
        {paymentSummary ? (
          <PosPaymentSummary />
        ) : (
          <>
            {trips.length === 0 && (
              <PosScheduleSelector
                defaultDate={new Date()}
                defaultOrigin={defaultOrigin}
                onOriginChange={(value) => {
                  rememberLastOrigin(value);
                }}
                onSelectTicket={(ticket, date, capacityLeft) => {
                  setTrip(0, {
                    date,
                    ticket,
                    capacityLeft
                  });
                }}
              />
            )}

            {trips.length > 0 && !showCartDetails && (
              <PosTicketSelector
                onChangeClick={(tripIndex) => {
                  setTripIndex(tripIndex);
                  setChangeOutboundDialogOpen(true);
                }}
                trips={trips}
                showCartDetails={() => setShowCartDetails(true)}
              />
            )}

            {showCartDetails && trips.length > 0 && cartItems.length > 0 && <PosCartDetails />}

            {tripIndex > -1 && (
              <PosScheduleSelectorDialog
                open={changeOutboundDialogOpen}
                onClose={() => setChangeOutboundDialogOpen(false)}
                onSelectTicket={(ticket, date, capacityLeft) => {
                  setChangeOutboundDialogOpen(false);
                  setTrip(tripIndex, {
                    date,
                    ticket,
                    capacityLeft
                  });

                  if (tripIndex === 0) {
                    removeTrip(1);
                  }
                }}
                defaultDate={trips[tripIndex].date}
                defaultOrigin={trips[tripIndex].ticket?.origin?.value}
                defaultDestination={tripIndex === 1 ? trips[tripIndex].ticket?.destination?.value : undefined}
                isReturnTrip={tripIndex === 1}
                onRemove={
                  trips[tripIndex]
                    ? () => {
                        setChangeOutboundDialogOpen(false);
                        const index = tripIndex;
                        setTripIndex(-1);
                        removeTrip(index);
                      }
                    : undefined
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PosTicketContainer;
