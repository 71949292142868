import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import { map } from 'lodash';
import moment from 'moment';
import React, { FC, useState, useContext } from 'react';
import { ViewportContext } from '../../context/ViewportContext';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import OperatorLogo from '../travel/OperatorLogo';
import { FormattedMessage } from 'react-intl';

interface Props {
  entry: ScheduleEntry;
}

const TicketInfo: FC<Props> = (props) => {
  const { matchesBreakpoint } = useContext(ViewportContext);
  const { entry } = props;
  const [expanded, setExpanded] = useState(false);

  const departureTime = moment(entry.sailingDateTime).format('HH:mm');
  const arrivalTime = moment(entry.arrivalDateTime).format('HH:mm');
  const duration = moment.duration(moment(entry.arrivalDateTime).diff(moment(entry.sailingDateTime)));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
        <Box sx={{ fontSize: 16, color: '#666666', marginBottom: 1 }}>{entry.operationName}</Box>
        {matchesBreakpoint('sm') && <OperatorLogo />}
      </Box>
      <Box sx={{ fontSize: 22, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          {departureTime} - {arrivalTime}
        </Box>
      </Box>
      <Box sx={{ fontSize: 16, color: '#666666' }}>
        <FormattedMessage
          id="booking.schedule.travelTime"
          defaultMessage="Travel time: {hours}h {minutes}m, {stops} stops"
          values={{
            hours: Math.floor(duration.asHours()),
            minutes: Math.floor(duration.asMinutes()) % 60,
            stops: (entry.sailingLegs?.length ?? 1) - 1
          }}
        />
        <IconButton
          onClick={(e) => {
            setExpanded(!expanded);
            e.stopPropagation();
          }}
          size="small"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </IconButton>
      </Box>
      <Box>
        {expanded && (
          <Box>
            {map(entry.sailingLegs, (sailingLeg, index) => {
              const departureTime = sailingLeg.departureTime;
              const arrivalTime = sailingLeg.arrivalTime;
              const origin = sailingLeg.origin;
              const destination = sailingLeg.destination;
              return (
                <Box key={index}>
                  {origin} - {destination} {departureTime} - {arrivalTime}
                </Box>
              );
            })}
          </Box>
        )}
        {!matchesBreakpoint('sm') && <OperatorLogo />}
      </Box>
    </>
  );
};

export default TicketInfo;
