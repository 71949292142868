import { useState, useEffect, useMemo, FC, useContext } from 'react';
import { Control, Controller, FieldValues, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import { Grid, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { uniq, isEqual } from 'lodash';
import LegGroup from './LegGroup';
import LegsHeader from './LegsHeader';
import { useIntl } from 'react-intl';
import { shortenDate } from '../../utils/DateUtils';
import { RouteSummaryContext } from '../../context/RouteSummaryContext';
import { closestTo } from 'date-fns';

const DateSelector = styled(ToggleButton)(() => ({
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#171c26',
    '&:hover': {
      backgroundColor: '#171c26'
    }
  },
  backgroundColor: '#fafafa',
  border: 'none',
  color: '#000',
  margin: '1px'
}));

interface ScheduleCardProps {
  control: Control<FieldValues, object>;
  routeType: 'outboundRoute' | 'returnRoute';
  legs: ScheduleEntry[] | undefined;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  initialRoute: ScheduleEntry | undefined;
}

export const ScheduleCard: FC<ScheduleCardProps> = ({ control, routeType, legs, getValues, watch, setValue, initialRoute }) => {
  const intl = useIntl();
  const [sailingDates, setSailingDates] = useState<Array<string>>([]);
  const { routes } = useContext(RouteSummaryContext);
  const selectedDate = watch(`selectedDate.${routeType}`);

  useEffect(() => {
    legs && legs.length > sailingDates.length && setSailingDates(legs.map((entry) => shortenDate(entry.sailingDateTime)));
  }, [legs, sailingDates, setSailingDates]);

  useEffect(() => {
    if (sailingDates) {
      if (initialRoute) {
        setValue(`selectedDate.${routeType}`, shortenDate(initialRoute.sailingDateTime));
        return;
      }

      const selectedDate = routes?.[0]?.departureDate || new Date();
      const closestDate = sailingDates.includes(shortenDate(selectedDate))
        ? selectedDate
        : closestTo(
            selectedDate,
            sailingDates.map((date) => new Date(date))
          );
      closestDate && setValue(`selectedDate.${routeType}`, shortenDate(closestDate));
    }
  }, [sailingDates, setValue, routes, routeType, initialRoute]);

  const filteredLegs = useMemo(() => {
    return legs?.filter((entry) => {
      return shortenDate(entry.sailingDateTime) === selectedDate;
    });
  }, [legs, selectedDate]);

  const defaultIndex = useMemo(() => {
    if (filteredLegs && filteredLegs.length === 1 && filteredLegs[0].isBookable) return 0;

    return (
      filteredLegs?.findIndex((leg) => {
        if (!leg.isBookable) return false;

        return isEqual(initialRoute?.origin, leg.origin) && isEqual(initialRoute?.destination, leg.destination);
      }) ?? -1
    );
  }, [filteredLegs, initialRoute]);

  useEffect(() => {
    if (defaultIndex >= 0) {
      setValue(routeType, defaultIndex);
    }
  }, [defaultIndex, setValue, routeType]);

  return (
    <>
      <Controller
        key={`selectedDate.${routeType}`}
        control={control}
        name={`selectedDate.${routeType}`}
        render={({ field }) => (
          <Grid textAlign="left">
            <ToggleButtonGroup {...field} exclusive>
              {uniq(sailingDates).map((date, index) => {
                return (
                  <DateSelector key={index} value={date}>
                    {date}
                  </DateSelector>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
        )}
      />
      {routeType === 'outboundRoute' ? (
        <LegsHeader leftLabel={intl.formatMessage({ id: `booking.schedule.label.outbound`, defaultMessage: 'OUTBOUND ROUTE' })} />
      ) : (
        <LegsHeader leftLabel={intl.formatMessage({ id: `booking.schedule.label.return`, defaultMessage: 'RETURN ROUTE' })} />
      )}
      <Controller
        key={routeType}
        control={control}
        name={routeType}
        rules={{ required: true, validate: (routeIndex: number) => routeIndex >= 0 }}
        render={({ field }) => <LegGroup field={field} getValues={getValues} legs={filteredLegs} routeType={routeType} />}
      />
    </>
  );
};
