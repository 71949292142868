import { FC, useContext } from 'react';
import { Box, Typography, Grid, useTheme } from '@mui/material';
import { BookingStageContext } from '../context/BookingStageContext';
import { Stage } from './FreightSelector';
import { ViewportContext } from '../context/ViewportContext';

interface StagesHeaderProps {
  stages: Stage[];
  goToStage: (newStage: number) => void;
  setHeight: (newValue: number) => void;
}

const StagesHeader: FC<StagesHeaderProps> = ({ stages, goToStage, setHeight }) => {
  const theme = useTheme();

  const { currentStage } = useContext(BookingStageContext);
  const { matchesBreakpoint } = useContext(ViewportContext);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: matchesBreakpoint('sm') ? 'auto' : 92,
        right: 0,
        left: 0,
        padding: matchesBreakpoint('sm') ? theme.spacing(1.5, 8) : theme.spacing(1.5, 3),
        backgroundColor: '#002a3a'
      }}
      ref={(el: HTMLDivElement | null) => {
        if (!el) {
          return;
        }
        const newHeight = el.getBoundingClientRect().height;
        setHeight(newHeight);
      }}
    >
      <Grid container>
        {stages.map((step, index) => {
          return (
            <Grid item xs={12} sm="auto" key={index}>
              <Typography
                onClick={() => {
                  goToStage(index);
                }}
                sx={{
                  marginRight: matchesBreakpoint('sm') ? 4 : 0,
                  cursor: 'pointer',
                  color: currentStage === index ? 'white' : '#5f7a84',
                  textAlign: 'left'
                }}
              >{`${index + 1}. ${step.stepperName}`}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default StagesHeader;
