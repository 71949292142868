import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { RouteSummary } from '../../modules/routing/Routes';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

interface Props {
  route: RouteSummary;
  type: string;
  onDateChange: (amount: number) => void;
}

const TicketSelector: FC<Props> = (props) => {
  const { route, type, onDateChange } = props;
  const date = useMemo(() => moment(type === 'outbound' ? route.departureDate : route.returnDate), [route.departureDate, route.returnDate, type]);
  const routeName = `${route.origin.name} - ${route.destination.name}`;
  const disablePrevDate = useMemo(() => moment(date).add(-1, 'days').startOf('day').isBefore(moment().startOf('day')), [date]);

  return (
    <Box>
      <Box sx={{ position: 'relative', padding: 1, borderBottom: '1px solid #ddd', fontSize: 16 }}>
        {routeName}
        <Box
          sx={{
            textTransform: 'capitalize',
            position: 'absolute',
            top: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            color: '#0074AF',
            fontWeight: 500
          }}
        >
          {/* {type} */}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', padding: 2, alignItems: 'center' }}>
        <Box>
          <IconButton disabled={disablePrevDate} onClick={() => onDateChange(-1)} size="small">
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        </Box>
        <Box sx={{ flex: 1, fontWeight: 500, fontSize: 16 }}>{date.format('ddd DD MMM YYYY')}</Box>
        <Box>
          <IconButton onClick={() => onDateChange(1)} size="small">
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketSelector;
