import React from 'react';
import { Helmet } from 'react-helmet';
import { CUSTOMER, getDefaultSeoInfo } from '../settings/AppConstants';

const DefaultSeoMetadata = () => {
  const seoMetadata = getDefaultSeoInfo(CUSTOMER);

  return (
    <Helmet>
      <title>{seoMetadata.title}</title>
      <meta name="description" content={seoMetadata.description} />
      <meta name="og:title" content={seoMetadata.ogTitle} />
      <meta name="og:description" content={seoMetadata.ogDescription} />
      {seoMetadata.ogImage && <meta name="og:image" content={seoMetadata.ogImage} />}
      {seoMetadata.ogUrl && <meta name="og:url" content={seoMetadata.ogUrl} />}
    </Helmet>
  );
};

export default DefaultSeoMetadata;
