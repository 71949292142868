import { FC, ReactElement } from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { BookingStage } from '../context/BookingStageContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HotelIcon from '@mui/icons-material/Hotel';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';

const icons: Record<BookingStage, ReactElement<any, any>> = {
  [BookingStage.Schedule]: <AccessTimeIcon />,
  [BookingStage.Vehicle]: <LocalShippingIcon />,
  [BookingStage.Cabins]: <HotelIcon />,
  [BookingStage.Info]: <AddIcon />,
  [BookingStage.Extra]: <PeopleIcon />
};

interface StageIconFlagProps {
  name: BookingStage;
}

const StageIconFlag: FC<StageIconFlagProps> = ({ name }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        padding: theme.spacing(1.5, 0),
        backgroundColor: '#171c26',
        position: 'absolute',
        width: 130,
        left: -130 + 24,
        '*': {
          color: 'white'
        }
      }}
      elevation={5}
      square
    >
      <Box sx={{ svg: { fontSize: 48 } }}>{icons[name]}</Box>
      <Typography>{BookingStage[name]}</Typography>
    </Paper>
  );
};

export default StageIconFlag;
