import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useInjects } from 'saft-react';
import Routes, { LocationSummary } from '../../modules/routing/Routes';
import { ReactComponent as SwitchIcon } from '../../switch.svg';
import useAsync from '../../useAsync';
import LocationName from '../LocationName';
import { BookingStageContext, BookingStage } from '../../context/BookingStageContext';
import { Controller, Control, FieldValues, UseFormReturn, Path, UnpackNestedValue, PathValue } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { DisabledTooltip } from '../DisabledTooltip';
import { isEmpty } from 'lodash';

interface RouteSelectorProps {
  watchOrigin: LocationSummary;
  index?: number;
  hideSwapButton?: boolean;
}

const RouteSelector = <TFieldValues extends FieldValues>({
  control,
  setValue,
  getValues,
  watchOrigin,
  index,
  hideSwapButton
}: Pick<UseFormReturn<TFieldValues>, 'control' | 'setValue' | 'getValues'> & RouteSelectorProps) => {
  const deps = useInjects<{ lxRouteService: Routes }>({
    lxRouteService: undefined
  });
  let origins = useAsync<LocationSummary[]>(() => (deps === undefined ? Promise.resolve([]) : deps.lxRouteService.findOrigins()), [deps]);

  const intl = useIntl();

  const destinations = useAsync<LocationSummary[]>(
    () => (deps === undefined || isEmpty(watchOrigin) ? Promise.resolve([]) : deps.lxRouteService.findDestinations(watchOrigin?.code)),
    [deps, watchOrigin]
  );

  const { currentStage } = useContext(BookingStageContext);

  const disableControls = useMemo(() => {
    return currentStage > BookingStage.Schedule;
  }, [currentStage]);

  const originName = useMemo(() => `${index !== undefined ? `travel.${index}.` : ''}origin` as Path<TFieldValues>, [index]);
  const destinationName = useMemo(() => `${index !== undefined ? `travel.${index}.` : ''}destination` as Path<TFieldValues>, [index]);

  if (deps) {
    return (
      <Grid container item textAlign="left" xs={12}>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth disabled={disableControls}>
            <InputLabel variant="filled" id="lx-booking-origin-select-label">
              <FormattedMessage id="booking.route.origin" defaultMessage="Origin" />
            </InputLabel>
            <Controller
              key={originName}
              control={control as Control<FieldValues, object>}
              name={originName}
              rules={{ required: true }}
              render={({ field }) => (
                <Grid item xs={12}>
                  <DisabledTooltip
                    title={<FormattedMessage id="tooltips.booking.routeSelector" defaultMessage="Restart booking to change route" />}
                    disabled={!disableControls}
                  >
                    <Select
                      {...field}
                      fullWidth
                      variant="filled"
                      disableUnderline
                      sx={{ borderRadius: '4px' }}
                      autoFocus={!index}
                      labelId="lx-booking-origin-select-label"
                      id="lx-booking-origin-select"
                      label={intl.formatMessage({ id: 'booking.route.origin', defaultMessage: 'Origin' })}
                      value={origins.res?.find((entry) => entry.code === watchOrigin?.code)?.code || ''}
                      onChange={(event) => field.onChange(origins.res?.find((entry) => entry.code === event.target.value) || '')}
                    >
                      {origins.res?.map((origin) => (
                        <MenuItem value={origin.code} key={origin.code}>
                          <LocationName location={origin} />
                        </MenuItem>
                      ))}
                    </Select>
                  </DisabledTooltip>
                </Grid>
              )}
            />
          </FormControl>
        </Grid>
        {!hideSwapButton && (
          <Grid item xs={12} sm="auto" sx={{ position: 'relative' }}>
            <IconButton
              disabled={disableControls}
              color="primary"
              aria-label="switch route direction"
              component="span"
              size="large"
              sx={{
                marginBottom: 2
              }}
              onClick={() => {
                const temp = getValues(destinationName) as LocationSummary;
                // @ts-ignore TODO: fix this
                setValue(destinationName, watchOrigin as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>);
                // @ts-ignore TODO: fix this
                setValue(originName, temp as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>);
              }}
            >
              <SwitchIcon width="30" />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          <FormControl fullWidth disabled={disableControls}>
            <InputLabel variant="filled" id="lx-booking-destination-select-label">
              <FormattedMessage id="booking.route.destination" defaultMessage="Destination" />
            </InputLabel>
            <Controller
              key={destinationName}
              control={control}
              name={destinationName}
              rules={{ required: true }}
              render={({ field }) => (
                <Grid item xs={12}>
                  <DisabledTooltip
                    title={<FormattedMessage id="tooltips.booking.routeSelector" defaultMessage="Restart booking to change route" />}
                    disabled={!disableControls}
                  >
                    <Select
                      fullWidth
                      variant="filled"
                      disableUnderline
                      sx={{ borderRadius: '4px' }}
                      labelId="lx-booking-destination-select-label"
                      id="lx-booking-destination-select"
                      label={intl.formatMessage({ id: 'booking.route.destination', defaultMessage: 'Destination' })}
                      {...field}
                      value={destinations.res?.find((entry) => entry.code === (getValues(destinationName) as LocationSummary)?.code)?.code || ''}
                      onChange={(event) => {
                        const newDestination = destinations.res?.find((entry) => entry.code === event.target.value) || '';
                        field.onChange(newDestination);
                        index !== undefined &&
                          getValues(`travel.${index + 1}.origin` as Path<TFieldValues>) === '' &&
                          setValue(
                            `travel.${index + 1}.origin` as Path<TFieldValues>,
                            // @ts-ignore TODO: fix this
                            newDestination as UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
                          );
                      }}
                    >
                      {(destinations?.res ?? [])
                        .filter((destination) => destination.name !== watchOrigin.name)
                        .map((destination) => (
                          <MenuItem value={destination.code} key={destination.code}>
                            <LocationName location={destination} />
                          </MenuItem>
                        ))}
                    </Select>
                  </DisabledTooltip>
                </Grid>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <FormattedMessage id="booking.route.loading" defaultMessage="Loading..." />
      </div>
    );
  }
};

export default RouteSelector;
