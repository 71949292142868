import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { map, every } from 'lodash';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import ScheduleSelector from './ScheduleSelector';
import { TravelBookingContext } from './TravelBookingContext';
import SailingProductsSource, { Package } from '../../modules/sailing-products/SailingProductsSource';
import { inject } from 'saft-react';
import PackageCardList from '../packages/PackageCardList';
import { JourneyType } from 'context/JourneyTypeContext';

interface InjectedProps {
  sailingProductsSource: SailingProductsSource;
}

interface Props {
  setIsValid: (isValid: boolean) => void;
}

const ScheduleSelectorList: FC<Props & InjectedProps> = (props) => {
  const { sailingProductsSource, setIsValid } = props;
  const { passengerSummary } = useContext(PassengerSummaryContext);
  const { travelBookingRoutes, isFetchingReturnTicket, currentJourneyType } = useContext(TravelBookingContext);
  const [packages, setPackages] = useState<Package[]>([]);

  const isValid = useMemo(() => every(travelBookingRoutes, (travelBookingRoute) => !!travelBookingRoute.selectedEntry), [travelBookingRoutes]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  const isRoundTrip = useMemo(() => currentJourneyType === JourneyType.RoundTrip, [currentJourneyType]);

  useEffect(() => {
    const getPackages = async () => {
      if (!travelBookingRoutes?.[0]) {
        return;
      }
      const packages = await sailingProductsSource.getPackagesByRouteSummary(travelBookingRoutes[0].routeSummary, passengerSummary);
      setPackages(packages);
    };
    getPackages();
  }, [sailingProductsSource, travelBookingRoutes, passengerSummary]);

  return (
    <Box>
      {packages.length > 0 && <PackageCardList packages={packages} />}
      {isFetchingReturnTicket && (
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetchingReturnTicket && (
        <div>
          {map(travelBookingRoutes, (travelBookingRoute, index) => (
            <ScheduleSelector
              key={index}
              travelBookingRouteKey={index}
              travelBookingRoute={travelBookingRoute}
              routeSummary={travelBookingRoute.routeSummary}
              passengerSummary={passengerSummary}
              returnTicket={null}
              isReturn={isRoundTrip && index === 1}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

export default inject('sailingProductsSource')(ScheduleSelectorList as FC<{}>) as React.ElementType<Props>;
