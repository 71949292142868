import { createContext, useState, FC } from 'react';
import { RouteSummary } from '../modules/routing/Routes';

type RouteSummaryType = {
  routes: RouteSummary[] | undefined;
  setRoutes: (routes: RouteSummary[] | undefined) => void;
};

export const RouteSummaryContext = createContext<RouteSummaryType>(undefined!);

const RouteSummaryContextProvider: FC = ({ children }) => {
  const [routes, setRoutes] = useState<RouteSummary[] | undefined>(undefined);

  return (
    <RouteSummaryContext.Provider
      value={{
        routes,
        setRoutes
      }}
    >
      {children}
    </RouteSummaryContext.Provider>
  );
};

export default RouteSummaryContextProvider;
