import { FC, useEffect, useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Autocomplete, Grid, TextFieldProps } from '@mui/material';
import { HazardousGoods } from '../../types/BookingState';
import { HazardousGoodsSearchResult } from '../../types/HazardousGoodsSearch';
import HazardousGoodsService from '../../modules/hazardous-goods/HazardousGoodsService';
import { useInjects } from 'saft-react';
import useAsync from '../../useAsync';
import { Control, FieldValues, Controller, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const styles = {
  root: {
    marginTop: 1
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  }
};

interface HazardousGoodsInformationFormProps {
  control: Control<FieldValues, object>;
  setValue: UseFormSetValue<FieldValues>;
}

const HazardousGoodsInformationForm: FC<HazardousGoodsInformationFormProps> = ({ control, setValue }) => {
  const [unSearchTerm, setUnSearchTerm] = useState('');

  const deps = useInjects<{ lxHazardousGoodsService: HazardousGoodsService }>({
    lxHazardousGoodsService: undefined
  });
  const searchResults = useAsync<HazardousGoodsSearchResult[]>(() => {
    return deps === undefined ? Promise.resolve([]) : deps.lxHazardousGoodsService.getByUnNumber(unSearchTerm || 'un');
  }, [deps, unSearchTerm]).res;

  const [hazardousGood, setHazardousGood] = useState<HazardousGoodsSearchResult | null>();

  useEffect(() => {
    if (!hazardousGood) {
      return;
    }
    const newHazardousGoods: Partial<HazardousGoods> = {
      unNumber: hazardousGood.UnNumber,
      imoCode: hazardousGood.class,
      class: hazardousGood.class,
      technicalName: hazardousGood.description,
      flashpoint: hazardousGood.flashPoint ?? '',
      marinePollutant: hazardousGood.marinePollutant,
      packageGroup: hazardousGood.packageGroup
    };
    setValue('hazardousGoods', newHazardousGoods);
    setValue('hazardousGoods.unSearch', hazardousGood);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hazardousGood]);

  return (
    <Grid container xs={12} spacing={2} sx={styles.root}>
      <Grid item xs={12} md={6} lg={4}>
        <Controller
          key="unSearch"
          control={control}
          name="hazardousGoods.unSearch"
          defaultValue={null}
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoHighlight
              onChange={(event, value: HazardousGoodsSearchResult | null) => {
                field.onChange(value);
                setHazardousGood(value);
              }}
              onInputChange={(event, value) => {
                setUnSearchTerm(value);
              }}
              isOptionEqualToValue={(option: HazardousGoodsSearchResult, value: HazardousGoodsSearchResult) => option.UnNumber === value.UnNumber}
              getOptionLabel={(option) => `${option.UnNumber} - Class: ${option.class}${option.UnSuffix} - PG: ${option.packageGroup} ${option.description}`}
              options={searchResults ?? []}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={<FormattedMessage id="booking.hazardous.unNumber" defaultMessage="UN Number" />}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={8} />
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="imoCode"
          control={control}
          name="hazardousGoods.imoCode"
          rules={{ required: true }}
          render={({ field }) => <TextField {...field} label={<FormattedMessage id="booking.hazardous.imoCode" defaultMessage="IMO Code" />} required />}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="class"
          control={control}
          name="hazardousGoods.class"
          rules={{ required: true }}
          render={({ field }) => <TextField {...field} label={<FormattedMessage id="booking.hazardous.class" defaultMessage="Class" />} required />}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="flashpoint"
          control={control}
          name="hazardousGoods.flashpoint"
          rules={{ required: true }}
          render={({ field }) => <TextField {...field} label={<FormattedMessage id="booking.hazardous.flashPoint" defaultMessage="Flash Point" />} required />}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="goodsUnitType"
          control={control}
          name="hazardousGoods.goodsUnitType"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField {...field} label={<FormattedMessage id="booking.hazardous.goodsUnitType" defaultMessage="Goods Unit Type" />} required />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="packageGroup"
          control={control}
          name="hazardousGoods.packageGroup"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField {...field} label={<FormattedMessage id="booking.hazardous.packageGroup" defaultMessage="Package group" />} required />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="packageInfo"
          control={control}
          name="hazardousGoods.packageInfo"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField {...field} label={<FormattedMessage id="booking.hazardous.packageInfo" defaultMessage="Package info" />} required />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Controller
          key="technicalName"
          control={control}
          name="hazardousGoods.technicalName"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField {...field} label={<FormattedMessage id="booking.hazardous.technicalName" defaultMessage="Technical name" />} required />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Controller
          key="weight"
          control={control}
          name="hazardousGoods.weight"
          rules={{ required: true }}
          render={({ field }) => <TextField {...field} label={<FormattedMessage id="booking.vehicle.weight" defaultMessage="Weight" />} required />}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} sx={styles.checkboxContainer}>
        <FormControlLabel
          label={<FormattedMessage id="booking.hazardous.limitedQuantity" defaultMessage="Limited Quantity" />}
          control={
            <Controller
              key="limitedQuantity"
              control={control}
              name="hazardousGoods.limitedQuantity"
              rules={{ required: false }}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} sx={styles.checkboxContainer}>
        <FormControlLabel
          label={<FormattedMessage id="booking.hazardous.marinePollutant" defaultMessage="Marine Pollutant" />}
          control={
            <Controller
              key="marinePollutant"
              control={control}
              name="hazardousGoods.marinePollutant"
              rules={{ required: false }}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default HazardousGoodsInformationForm;
