import { createContext, useState, ReactNode } from 'react';
import getPassengerTypes from '../config/PassengerTypes';
import { PassengerSummary } from '../components/header-selectors/PasssengerSelector';
import { Customer } from '../types/Customer';

export const getDefaultPassengerSummary = () => {
  let summary: Record<string, number> = {};
  const passengerTypes = getPassengerTypes(Customer.Default);
  const types = Object.keys(passengerTypes) as Array<keyof typeof passengerTypes>;
  for (const type of types) {
    summary[`${type}`] = passengerTypes[type].minimumAmount;
  }
  return summary;
};

const defaultPassengerSummary = getDefaultPassengerSummary();

export const PassengerSummaryContext = createContext({
  passengerSummary: defaultPassengerSummary,
  setPassengerSummary: (passengerSummary: PassengerSummary) => {}
});

const PassengerSummaryContextProvider = ({ children }: { children: ReactNode }) => {
  const [passengerSummary, setPassengerSummary] = useState(defaultPassengerSummary);

  return (
    <PassengerSummaryContext.Provider value={{ passengerSummary: passengerSummary, setPassengerSummary: setPassengerSummary }}>
      {children}
    </PassengerSummaryContext.Provider>
  );
};

export default PassengerSummaryContextProvider;
