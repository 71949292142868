import { FC, useMemo, useContext, useEffect, useState, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid, Tooltip, Box, styled, tooltipClasses, TooltipProps, Theme, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import TravelPassengerCard from './TravelPassengerCard';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { LocaleContext } from '../../context/LocaleContext';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }: { theme: Theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ba0505',
      color: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      marginTop: '0px!important'
    }
  })
);

export interface Passenger {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isAddressSameAsFirstPassenger: boolean;
  id: string;
  type?: string;
}

export interface FormTypes {
  passengers: Passenger[];
  isGdprAccepted: boolean;
  note?: string;
}

interface DefaultPassengerDetails {
  defaultPassengers: Passenger[];
  defaultNote?: string;
}

interface Props {
  submit: boolean;
  setSubmit: (submit: boolean) => void;
  onSubmit: (passengerDetails: Passenger[], isGdprAccepted: boolean, note?: string) => void;
  setLoading: (loading: boolean) => void;
  defaultPassengerDetails?: DefaultPassengerDetails;
}

const TravelPassengerDetails: FC<Props> = ({ submit, onSubmit, setLoading, setSubmit, defaultPassengerDetails }) => {
  const { locale } = useContext(LocaleContext);
  const { passengerSummary } = useContext(PassengerSummaryContext);
  const { control, handleSubmit, formState, getValues, trigger, setValue } = useForm<FormTypes>({
    mode: 'onChange',
    defaultValues: {
      isGdprAccepted: false,
      ...(defaultPassengerDetails ? { passengers: defaultPassengerDetails.defaultPassengers, note: defaultPassengerDetails.defaultNote ?? '' } : {})
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (submit) {
      if (!isEmpty(formState.errors)) {
        setShowTooltip(true);
        setSubmit(false);
        return;
      }

      handleSubmit(({ passengers, isGdprAccepted, note }) => {
        setLoading(true);
        onSubmit(passengers, isGdprAccepted, note);
      })();
    }
  }, [submit, handleSubmit, onSubmit, setLoading, setSubmit, formState.errors]);

  useEffect(() => {
    trigger('isGdprAccepted');
  }, [submit, trigger]);

  useEffect(() => {
    if (!formState.errors?.isGdprAccepted) {
      setShowTooltip(false);
    }
  }, [formState.errors?.isGdprAccepted]);

  const passengerList = useMemo(() => {
    const list = [];
    for (const [key, value] of Object.entries(passengerSummary)) {
      for (let i = 0; i < value; i++) {
        list.push({ type: key, number: i });
      }
    }
    return list;
  }, [passengerSummary]);

  const getTermsLink = useCallback(() => {
    return `/blogs/terms/privacy-policy-${locale}`;
  }, [locale]);

  const termsLabel = <FormattedMessage id="booking.passengerInfo.agreeTo" defaultMessage="I agree to the" />;
  const termsHref = (
    <a href={getTermsLink()} target="_blank" rel="noreferrer">
      <FormattedMessage id="booking.passengerInfo.terms_and_conditions" defaultMessage="terms and conditions" />
    </a>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: 1, padding: 1 }}>
      {passengerList.map((passenger, index) => {
        if (passenger.type === 'baby') return undefined;

        return (
          <Grid item xs={12} key={index}>
            <TravelPassengerCard passenger={passenger} index={index} control={control} formState={formState} getValues={getValues} setValue={setValue} />
          </Grid>
        );
      })}
      <Grid container item rowGap={3} sx={{ '& .MuiGrid-root': { display: 'flex', justifyContent: 'left' } }}>
        {/* <Grid item xs sm={6} md={3}>
          <Controller
            key="orderNote"
            control={control}
            name="note"
            rules={{ required: false }}
            render={({ field }) => <TextField {...field} label={<FormattedMessage id="booking.passengerInfo.note" defaultMessage="Note" />} />}
          />
        </Grid> */}
        <Grid item xs={12}>
          <StyledTooltip
            title={<FormattedMessage id="booking.passengerInfo.required" defaultMessage="Required" />}
            placement="bottom-start"
            PopperProps={{ open: showTooltip }}
          >
            <Box component="span">
              <Controller
                key="privacy"
                control={control}
                name="isGdprAccepted"
                rules={{ required: 'true', validate: (isGdprAccepted) => isGdprAccepted }}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    label={
                      <span>
                        {termsLabel} {termsHref}
                      </span>
                    }
                    control={<Checkbox required />}
                  />
                )}
              />
            </Box>
          </StyledTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TravelPassengerDetails;
