import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { compact, isNil, min } from 'lodash';
import moment from 'moment';
import { FC, useCallback, useContext, useEffect, useState, ElementType, useMemo } from 'react';
import ShopifyService, { GraphProduct } from '../../modules/shopify/ShopifyService';
import { ProductType } from '../../types/PosTypes';
import { PosContext } from './PosContext';
import { getFixedNumber, getTicketsByVariantMapping, getTotalSelectedAmount, isNoSeatCount } from '../../utils/PosUtils';
import { inject } from 'saft-react';
import QuantitySelector from '../QuantitySelector';
import { isEmailValid } from '../../utils/TextValidation';
import PosPaymentSelectionDialog from './PosPaymentSelectionDialog';

interface InjectedProps {
  shopifyService: ShopifyService;
}

interface Props {}

const PosCartDetails: FC<Props & InjectedProps> = (props) => {
  const { shopifyService } = props;

  const { cartAttributes, clearCart, trips, updateCartItem, cartItems } = useContext(PosContext);
  const theme = useTheme();
  const [extras, setExtras] = useState<GraphProduct[] | null>([]);
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const capacityLeft = useMemo(() => min(trips.map((trip) => trip.capacityLeft)), [trips]);

  const ticketTypeQuantity = useMemo(() => getTicketsByVariantMapping(cartItems), [cartItems]);

  const totalSelectedAmount = useMemo(() => {
    if (!ticketTypeQuantity) {
      return 0;
    }
    return getTotalSelectedAmount(ticketTypeQuantity);
  }, [ticketTypeQuantity]);

  useEffect(() => {
    const getExtras = async () => {
      trips.forEach(async (trip) => {
        const { ticket, date } = trip;
        const extras = await shopifyService.getProductsByFilter(moment(date), {
          origin: ticket.origin?.value,
          destination: ticket.destination?.value,
          productType: ProductType.TravelExtra
        });
        setExtras((prevExtras) => {
          return [...(prevExtras ?? []), ...compact(extras.map((extra) => (extra.node.title.includes('Family day') ? undefined : extra.node)))];
        });
      });
    };
    getExtras();
  }, [shopifyService, trips]);

  const tickets = useMemo(() => trips.map((trip) => trip.ticket), [trips]);

  const items = useMemo(() => [...tickets, ...(extras ?? [])], [extras, tickets]);

  const handleCheckout = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const requestCheckout = useCallback(() => {
    if (isEmailValid(email)) {
      handleCheckout();
    } else {
      setShowError(true);
    }
  }, [email, handleCheckout]);

  const handleEmailChange = useCallback((e) => {
    const email = e.target.value;
    if (isEmailValid(email)) {
      setShowError(false);
    }
    setEmail(email);
  }, []);

  const totalPrice = useMemo(
    () =>
      cartItems.reduce((sum, cartItem) => {
        const ticketVariant = items
          .find((item) => item.productType === cartItem.productType)
          ?.variants.edges.find((edge) => edge.node.title === cartItem.variant.title)?.node;

        return sum + Number(ticketVariant?.priceV2.amount ?? 0) * ticketTypeQuantity[cartItem.variant.title];
      }, 0),
    [cartItems, items, ticketTypeQuantity]
  );

  const currencyCode = useMemo(() => items[0].variants.edges[0].node.priceV2.currencyCode, [items]);

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: 1 }}>
        <Button size="large" onClick={clearCart} variant="outlined" fullWidth sx={{ color: theme.palette.primary.main }}>
          Clear cart
        </Button>
      </Box>
      {items.map((ticket, i) => {
        const words = ticket.title.split(' ');
        const wrappedTitle = `${words.slice(0, 2).join(' ')}\n${words.slice(2).join(' ')}`;
        return ticket.variants.edges.map((edge, j) => {
          const variant = edge.node;
          const cartItem = cartItems.find((ct) => ct.variant.id === variant.id);
          if (!cartItem) {
            return undefined;
          }
          const isBaby = isNoSeatCount(variant.title);
          const babyAmount = ticketTypeQuantity['Baby'] ?? 0;
          const adultAmount = ticketTypeQuantity['Adult'] ?? 0;
          const babyWithAdult = isBaby && babyAmount >= adultAmount;
          const willExceedCapacity = !isBaby && !isNil(capacityLeft) && totalSelectedAmount >= (capacityLeft ?? 0);
          const { currencyCode, amount } = variant.priceV2;
          return (
            <Box
              key={`${i}-${j}`}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: theme.spacing(2), borderBottom: '1px solid #ddd' }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight="bold" variant="subtitle1" whiteSpace="pre-line">
                  {wrappedTitle}
                </Typography>
                <Typography fontWeight="bold" variant="subtitle1">{`${currencyCode} ${getFixedNumber(amount)}`}</Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Typography variant="body1">{variant.title}</Typography>
                <QuantitySelector
                  quantity={cartItem?.quantity ?? 0}
                  setQuantity={(newValue) => {
                    updateCartItem({
                      variant: variant,
                      quantity: newValue,
                      productType: ticket.productType as ProductType
                    });
                  }}
                  isAddDisabled={willExceedCapacity || babyWithAdult}
                  isRemoveDisabled={(cartItem?.quantity ?? 0) === 0}
                  size={6}
                />
              </Box>
            </Box>
          );
        });
      })}
      {cartAttributes && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: theme.spacing(2), borderBottom: '1px solid #ddd' }}>
          <Typography fontWeight="bold" variant="body1">
            Additional details
          </Typography>
          {cartAttributes.map(({ key, value }) => (
            <Typography key={key} variant="subtitle2">{`${key}: ${value}`}</Typography>
          ))}
        </Box>
      )}
      <TextField
        variant="outlined"
        label="Email (optional) - for receiving qrcode"
        sx={{ margin: theme.spacing(2), display: 'block', width: 'auto' }}
        onChange={handleEmailChange}
        error={showError}
        helperText={showError && 'Invalid Email'}
      />
      <Box
        sx={{
          width: '100%',
          justifySelf: 'flex-end',
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.common.white,
          borderTop: '1px solid #ddd'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(2)
          }}
        >
          <Box>
            <Typography fontWeight="bold">Total</Typography>
            <Typography>{`${totalSelectedAmount} items`}</Typography>
          </Box>
          <Typography fontWeight="bold">{`${currencyCode} ${getFixedNumber(totalPrice)}`}</Typography>
        </Box>
        <Button sx={{ padding: theme.spacing(2) }} variant="contained" color="primary" fullWidth onClick={requestCheckout}>
          Checkout
        </Button>
      </Box>
      <PosPaymentSelectionDialog price={getFixedNumber(totalPrice)} currencyCode={currencyCode} open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </Box>
  );
};

export default inject('shopifyService')(PosCartDetails as FC<{}>) as ElementType<Props>;
