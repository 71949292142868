import { FC, useEffect, useRef } from 'react';
import useAsync from '../../useAsync';
import { Checkbox, TextField, Grid, Typography, FormControlLabel, useTheme } from '@mui/material';
import ExtraCard from './ExtraCard';
import { ExtraState, PassengerInfo } from '../../types/BookingState';
import { isEmailValid, isPhoneNumberValid } from '../../utils/TextValidation';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInjects } from 'saft-react';
import Extras, { Offer } from '../../modules/extras/ExtrasService';

const styles = {
  formControlLabel: {
    display: 'flex',
    gap: 4,
    marginLeft: 1
  }
};

interface ExtrasStageProps {
  state: ExtraState;
  setState: (newState: ExtraState) => void;
  passengers: PassengerInfo[];
  setIsStageValid: (isValid: boolean) => void;
  hideHowTicketReceived?: boolean;
  bookingType?: string;
}

const smsCost = 10;

const ExtrasStage: FC<ExtrasStageProps> = ({ state, setState, passengers, setIsStageValid, hideHowTicketReceived, bookingType }) => {
  const { formatMessage } = useIntl();

  const deps = useInjects<{ lxExtrasService: Extras }>({ lxExtrasService: undefined });
  let extras = useAsync<Offer[]>(() => (!deps ? Promise.resolve([]) : deps.lxExtrasService.getAvailableOffers(bookingType)), [deps]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm({ mode: 'onBlur' });
  const theme = useTheme();

  useEffect(() => {
    for (const [key, value] of Object.entries(state)) {
      value && setValue(key, value);
    }
  }, [state, setValue]);

  const save = useRef({ handleSubmit, state, setState });

  useEffect(() => {
    save.current = { handleSubmit, state, setState };
  }, [handleSubmit, state, setState]);

  useEffect(() => {
    return () => {
      const { handleSubmit, state, setState } = save.current;

      handleSubmit((data) => {
        setState(Object.assign(state, data));
      })();
    };
  }, []);

  useEffect(() => {
    setIsStageValid(isValid);
  }, [isValid, setIsStageValid]);

  return (
    <Grid container>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        {extras?.res &&
          extras.res.map((offer) => (
            <Grid item xs={12} sm={6} md={4} key={offer.name}>
              <ExtraCard state={state} setState={setState} cardContent={offer} />
            </Grid>
          ))}
      </Grid>
      {!hideHowTicketReceived && (
        <Grid container item xs={12} sx={{ backgroundColor: 'white', padding: 3, paddingBottom: 2, borderRadius: 1, textAlign: 'left' }}>
          <Typography variant="h6">
            <FormattedMessage id="booking.extra.ticketInfo" defaultMessage="Select how you want to recieve your tickets" />
          </Typography>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Controller
              key="emailEnabled"
              control={control}
              name="emailEnabled"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={styles.formControlLabel}
                  label={
                    <Typography fontWeight={theme.typography.fontWeightMedium} fontSize="18px">
                      <FormattedMessage id="booking.passengerInfo.email" defaultMessage="Email" />
                    </Typography>
                  }
                  value={state.smsEnabled}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  }
                />
              )}
            />
          </Grid>
          {watch('emailEnabled') && (
            <Grid item xs={8} sm={6} md={4} lg={3} ml={{ sx: 2, sm: 3 }} mt={1}>
              <Controller
                key="email"
                control={control}
                name="email"
                defaultValue={passengers[0]?.email || ''}
                shouldUnregister
                rules={{ required: true, validate: (email: string) => isEmailValid(email) }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="booking.passengerInfo.email" defaultMessage="Email" />}
                    helperText={errors?.email && formatMessage({ id: 'booking.passengerInfo.emailError', defaultMessage: 'Must be a valid email' })}
                    error={!!errors?.email}
                  />
                )}
              />
            </Grid>
          )}
          <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
            <Controller
              key="smsEnabled"
              control={control}
              name="smsEnabled"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={styles.formControlLabel}
                  label={
                    <Typography fontWeight={theme.typography.fontWeightMedium} fontSize="18px">
                      <FormattedMessage id="booking.extra.sms" defaultMessage="SMS" />
                    </Typography>
                  }
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                        setState({ ...state, totalPrice: state.totalPrice + (event.target.checked ? smsCost : -smsCost) });
                      }}
                    />
                  }
                />
              )}
            />
            <Typography fontWeight={theme.typography.fontWeightMedium} fontSize="18px">{`${smsCost} SEK`}</Typography>
          </Grid>
          {watch('smsEnabled') && (
            <Grid item xs={8} sm={6} md={4} lg={3} ml={{ sx: 2, sm: 3 }} mt={1}>
              <Controller
                key="phoneNumber"
                control={control}
                name="phoneNumber"
                defaultValue={passengers[0].phoneNumber || ''}
                shouldUnregister
                rules={{ required: true, validate: (phoneNumber: string) => isPhoneNumberValid(phoneNumber) }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="booking.passengerInfo.phoneNumber" defaultMessage="Phone number" />}
                    error={!!errors?.phoneNumber}
                    helperText={
                      errors?.phoneNumber && formatMessage({ id: 'booking.passengerInfo.phoneError', defaultMessage: 'Must be a valid phone number' })
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value.replace(/ /g, ''));
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ExtrasStage;
