import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, IconButton, Paper } from '@mui/material';
import { map } from 'lodash';
import moment from 'moment';
import React, { FC, useContext, useMemo, useState } from 'react';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import OperatorLogo from './OperatorLogo';
import { calculatePrice } from './ScheduleHelper';
import { FormattedMessage } from 'react-intl';

interface Props {
  entry: ScheduleEntry;
  onSelectTicket: (entry: ScheduleEntry | null) => void;
  currentSelectedEntry?: ScheduleEntry | null;
}

const TicketCard: FC<Props> = (props) => {
  const { entry, onSelectTicket, currentSelectedEntry } = props;
  const [expanded, setExpanded] = useState(false);
  const { passengerSummary } = useContext(PassengerSummaryContext);

  const totalPrice = useMemo(() => calculatePrice(entry.tickets ?? [], passengerSummary), [entry, passengerSummary]);

  const departureTime = moment(entry.sailingDateTime).format('HH:mm');
  const arrivalTime = moment(entry.arrivalDateTime).format('HH:mm');
  const duration = moment.duration(moment(entry.arrivalDateTime).diff(moment(entry.sailingDateTime)));

  const isSelected = entry.id === currentSelectedEntry?.id;
  const selectedStyle = {
    backgroundColor: '#3B9FC3',
    color: 'white'
  };

  return (
    <Box onClick={() => onSelectTicket(isSelected ? null : entry)} sx={{ minHeight: 128, width: '100%' }}>
      <Grid container spacing={0.25}>
        <Grid item xs={12}>
          <Paper sx={{ boxShadow: '0px 3px 12px rgba(172,172,172,0.2)', height: '100%', textAlign: 'left' }}>
            <Box sx={{ padding: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                <Box sx={{ fontSize: 16, color: '#666666', marginBottom: 1 }}>{entry.operationName}</Box>
                <OperatorLogo />
              </Box>
              <Box sx={{ fontSize: 22, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  {departureTime} - {arrivalTime}
                </Box>
              </Box>
              <Box sx={{ fontSize: 16, color: '#666666' }}>
                <FormattedMessage
                  id="booking.schedule.travelTime"
                  defaultMessage="Travel time: {hours}h {minutes}m, {stops} stops"
                  values={{
                    hours: Math.floor(duration.asHours()),
                    minutes: Math.floor(duration.asMinutes()) % 60,
                    stops: (entry.sailingLegs?.length ?? 1) - 1
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    setExpanded(!expanded);
                    e.stopPropagation();
                  }}
                  size="small"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              </Box>
              <Box>
                {expanded && (
                  <Box>
                    {map(entry.sailingLegs, (sailingLeg, index) => {
                      const departureTime = sailingLeg.departureTime;
                      const arrivalTime = sailingLeg.arrivalTime;
                      const origin = sailingLeg.origin;
                      const destination = sailingLeg.destination;
                      return (
                        <Box key={index}>
                          {origin} - {destination} {departureTime} - {arrivalTime}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
        {
          <Grid item xs={12}>
            <Paper
              sx={Object.assign(
                {},
                { boxShadow: '0px 3px 12px rgba(172,172,172,0.2)', borderBottom: '3px solid #1F7BC2', height: '100%' },
                isSelected ? selectedStyle : {}
              )}
            >
              <Box sx={{ fontSize: 22, fontWeight: 'bold', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ padding: 1 }}>
                  {isSelected && <FontAwesomeIcon style={{ fontSize: 14 }} icon={faCheck} />} {totalPrice.amount} {totalPrice.currencyCode}
                </Box>
              </Box>
            </Paper>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default TicketCard;
