import SailingProductsSource from '../../modules/sailing-products/SailingProductsSource';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { getScheduleEntries } from './ScheduleHelper';
import { ReturnPrice, TravelBookingRoute } from './TravelBookingContext';

const useScheduleEntries = (
  travelBookingRoute: TravelBookingRoute,
  passengerSummary: PassengerSummary,
  sailingProductsSource: SailingProductsSource,
  isReturn?: boolean,
  returnPrice?: ReturnPrice | null,
  departureTime?: string
) => {
  const useProductsSource = sailingProductsSource.getUseProductsSourceHook();
  const { sailingLegs, tickets, extras, isLoading } = useProductsSource(travelBookingRoute.routeSummary, passengerSummary);
  const scheduleEntries = getScheduleEntries(travelBookingRoute.routeSummary, passengerSummary, sailingLegs, tickets, extras, isReturn, returnPrice, departureTime);

  return { scheduleEntries, isLoading };
};

export default useScheduleEntries;
