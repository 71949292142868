import { FC } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';

const styles = {
  root: {
    margin: 1,
    '& .MuiFormControlLabel-root': {
      marginRight: 4
    }
  }
};

interface TemperatureControlFormProps {
  control: Control<FieldValues, object>;
}

const TemperatureControlForm: FC<TemperatureControlFormProps> = ({ control }) => {
  const intl = useIntl();
  return (
    <Grid container xs={12} spacing={1} sx={styles.root}>
      <Grid container item xs={12} md="auto">
        <FormControlLabel
          label={intl.formatMessage({ id: 'booking.hazardous.originQuay', defaultMessage: 'Origin Quay' })}
          control={
            <Controller
              key="originQuay"
              control={control}
              name="temperatureOptions.originQuay"
              rules={{ required: false }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} md="auto">
        <FormControlLabel
          label={intl.formatMessage({ id: 'booking.hazardous.vessel', defaultMessage: 'Vessel' })}
          control={
            <Controller
              key="vessel"
              control={control}
              name="temperatureOptions.vessel"
              rules={{ required: false }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} md="auto">
        <FormControlLabel
          label={intl.formatMessage({ id: 'booking.hazardous.destinationQuay', defaultMessage: 'Destination Quay' })}
          control={
            <Controller
              key="destinationQuay"
              control={control}
              name="temperatureOptions.destinationQuay"
              rules={{ required: false }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} md="auto">
        <FormControlLabel
          label={intl.formatMessage({ id: 'booking.hazardous.plugin', defaultMessage: 'Plugin' })}
          control={
            <Controller
              key="plugin"
              control={control}
              name="temperatureOptions.plugin"
              rules={{ required: false }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} md="auto">
        <FormControlLabel
          label={intl.formatMessage({ id: 'booking.hazardous.engineRunning', defaultMessage: 'Engine running' })}
          control={
            <Controller
              key="engineRunning"
              control={control}
              name="temperatureOptions.engineRunning"
              rules={{ required: false }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default TemperatureControlForm;
