import { FC, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { CabinState, Accommodation } from '../../types/BookingState';
import { flatten } from 'lodash';
import PersonIcon from '@mui/icons-material/Person';
import { AccommodationInfo } from './CabinsStage';
import { ViewportContext } from '../../context/ViewportContext';
import AccomodationQuantitySelect from './AccomodationQuantitySelect';

const styles = {
  root: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: '#dadada'
  },
  imageContainer: {
    paddingLeft: 1,
    paddingTop: 1,
    '& img': {
      width: '100%'
    }
  },
  infoContainer: {
    padding: 2,
    textAlign: 'left' as 'left'
  },
  optionName: {
    display: 'flex',
    gap: 1
  }
};

const getPassengerIcons = (number: number) => {
  return Array(number)
    .fill(0)
    .map((_, i) => <PersonIcon key={i} />);
};

interface AccommodationCardProps {
  isReturnJourney: boolean;
  state: CabinState;
  setState: (newState: CabinState) => void;
  option: AccommodationInfo;
  passengerCount: number;
  unallocatedPassengers: (key: 'outboundRoute' | 'returnRoute') => number;
}

const AccommodationCard: FC<AccommodationCardProps> = ({ isReturnJourney, state, setState, option, passengerCount, unallocatedPassengers }) => {
  const nonNegativeIndex = useCallback(
    (property: 'outboundRoute' | 'returnRoute') => {
      const index = state.selectedAccommodations[property]?.findIndex((el: Accommodation) => el.name === option.name) ?? -1;
      return index < 0 ? 0 : state.selectedAccommodations?.[property]?.[index]?.quantity ?? 0;
    },
    [state.selectedAccommodations, option.name]
  );

  const [selectedQuantity, setSelectedQuantity] = useState({ outboundRoute: nonNegativeIndex('outboundRoute'), returnRoute: nonNegativeIndex('returnRoute') });
  const { matchesBreakpoint } = useContext(ViewportContext);
  const [imageHeight, setImageHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [descriptionHeight, setDescriptionHeight] = useState(0);

  useEffect(() => {
    if (state.selectedAccommodations.outboundRoute.length > 0) {
      const newTotalPrice = flatten(Object.values(state.selectedAccommodations))
        .map((el) => el.price * el.quantity)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });

      if (state.totalPrice !== newTotalPrice) {
        setState({ ...state, totalPrice: newTotalPrice });
      }
    }
  }, [state.selectedAccommodations, setState, state]);

  const setQuantity = useCallback(
    (newQuantity: number, key: 'outboundRoute' | 'returnRoute') => {
      const selectedAccommodations = state.selectedAccommodations[key];
      if (selectedAccommodations) {
        let index = selectedAccommodations.findIndex((el) => el.name === option.name);
        index = index < 0 ? selectedAccommodations.length : index;
        selectedAccommodations[index] = { ...option, quantity: newQuantity };

        setState({ ...state, selectedAccommodations: { ...state.selectedAccommodations, [key]: selectedAccommodations } });
        setSelectedQuantity({ ...selectedQuantity, [key]: newQuantity });
      }
    },
    [state, option, setState, setSelectedQuantity, selectedQuantity]
  );

  const shouldSelectBeInInfo = useMemo(() => {
    return titleHeight + descriptionHeight < imageHeight && !matchesBreakpoint('lg');
  }, [imageHeight, titleHeight, descriptionHeight, matchesBreakpoint]);

  const accomodationQuantitySelect = (
    <AccomodationQuantitySelect
      isReturnJourney={isReturnJourney}
      option={option}
      selectedQuantity={selectedQuantity}
      setSelectedQuantity={setQuantity}
      unallocatedPassengers={unallocatedPassengers}
    />
  );

  return (
    <Grid container item sx={styles.root}>
      <Grid item xs={4} lg={2} sx={styles.imageContainer}>
        <img
          src={option.image}
          alt="accommodation option"
          ref={(el) => {
            if (!el) {
              return;
            }
            const newHeight = el.getBoundingClientRect().height;
            if (newHeight !== imageHeight) {
              setImageHeight(newHeight);
            }
          }}
        />
      </Grid>
      <Grid item xs={8} lg={5} sx={styles.infoContainer}>
        <Grid container sx={{ justifyContent: 'flex-start' }}>
          <Typography
            sx={{ ...styles.optionName, color: useTheme().palette.primary.main }}
            ref={(el) => {
              if (!el) {
                return;
              }
              const newHeight = el.getBoundingClientRect().height;
              if (newHeight !== titleHeight) {
                setTitleHeight(newHeight);
              }
            }}
          >
            {option.name}
          </Typography>
          <Grid item xs="auto" sx={{ color: 'black', marginLeft: 2 }}>
            {getPassengerIcons(option.capacity)}
          </Grid>
        </Grid>

        <Typography
          ref={(el) => {
            if (!el) {
              return;
            }
            const newHeight = el.getBoundingClientRect().height;
            if (newHeight !== descriptionHeight) {
              setDescriptionHeight(newHeight);
            }
          }}
        >
          {option.description}
        </Typography>
        {shouldSelectBeInInfo && accomodationQuantitySelect}
      </Grid>
      {!shouldSelectBeInInfo && accomodationQuantitySelect}
    </Grid>
  );
};

export default AccommodationCard;
