import { FC, useContext, useState, useMemo, useCallback } from 'react';
import { Button, Grid, Popover, Box, Typography } from '@mui/material';
import { clamp, clone, omit } from 'lodash';
import { ArrowDropDown } from '@mui/icons-material';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import getPassengerTypes from '../../config/PassengerTypes';
import QuantitySelector from '../QuantitySelector';
import { ViewportContext } from '../../context/ViewportContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { inject } from 'saft-react';
import { Customer } from '../../types/Customer';

export type PassengerSummary = Record<string, number>;

interface InjectedProps {
  customer: Customer;
}

const PassengerSelector: FC<InjectedProps> = ({ customer }) => {
  const { passengerSummary, setPassengerSummary } = useContext(PassengerSummaryContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { matchesBreakpoint } = useContext(ViewportContext);
  const passengerTypes = useMemo(() => getPassengerTypes(customer), [customer]);
  const intl = useIntl();

  const handlePopClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSummaryChange = (type: keyof typeof passengerTypes, newValue: number) => {
    const minimumAmount = getMinimumAmount(type);
    const newSummary = clone(passengerSummary);
    newSummary[type] = clamp(newValue, minimumAmount, passengerTypes[type].maximumAmount);
    setPassengerSummary(newSummary);
  };

  const passengerCount = Object.values(passengerSummary).reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  });

  const isOnlyAdults = useMemo(() => {
    const nonAdults = omit(passengerSummary, ['adult']);
    return Object.values(nonAdults).reduce((prev, current) => prev + current) <= 0;
  }, [passengerSummary]);

  const getMinimumAmount = useCallback(
    (passengerType: keyof typeof passengerTypes) => {
      const defaultMinimum = passengerTypes[passengerType].minimumAmount;
      if (customer === Customer.SplitExpress) {
        if (passengerType === 'baby') {
          return defaultMinimum;
        }

        if (passengerType === 'adult') {
          return passengerSummary['child'] > 0 ? 0 : defaultMinimum;
        } else {
          return passengerSummary['adult'] > 0 ? 0 : defaultMinimum;
        }
      }

      return defaultMinimum;
    },
    [customer, passengerSummary, passengerTypes]
  );

  return (
    <Grid item>
      <Button
        variant="outlined"
        onClick={(event) => handlePopClick(event)}
        sx={{
          backgroundColor: 'rgba(255,255,255,1) !important',
          border: '1px solid white',
          borderRadius: 1,
          color: '#005575',
          whiteSpace: 'nowrap',
          paddingTop: 1.5,
          paddingBottom: 1.5,
          width: `${!matchesBreakpoint('sm') ? '100%' : 'auto'}`,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ marginLeft: 1.5 }}>
          {`${passengerCount} `}
          {isOnlyAdults ? (
            <FormattedMessage
              id="booking.passenger.adult"
              defaultMessage="{count, plural, one {Adult} other {Adults}}"
              values={{
                count: passengerCount
              }}
            />
          ) : (
            <FormattedMessage
              id="booking.passenger.traveller"
              defaultMessage="{count, plural, one {Traveller} other {Travellers}}"
              values={{
                count: passengerCount
              }}
            />
          )}
        </Typography>
        <ArrowDropDown />
      </Button>
      <Popover open={anchorEl !== null} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Grid container width="110%" paddingRight={8}>
          <Grid item xs={11} sx={{ flexDirection: 'column', flexGrow: 1 }}>
            {Object.entries(passengerTypes).map(([type, value]) => {
              const customBabyText = `${intl.formatMessage({ id: 'booking.passenger.under', defaultMessage: 'under' })} ${value.upperAgeLimit} `;
              const defaultText = value.upperAgeLimit >= 0 ? `${value.lowerAgeLimit} - ${value.upperAgeLimit} ` : `${value.lowerAgeLimit}+ `;

              return (
                <Grid
                  key={type}
                  item
                  container
                  xs
                  sx={{ flexDirection: 'row', flexWrap: 'nowrap', margin: 3, alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Grid item>
                    {value?.customTitle 
                    ? <FormattedMessage id={value?.customTitle.id} defaultMessage={value?.customTitle.message} values={{ count: 2 }} />
                    : <FormattedMessage id={`booking.passenger.${type}`} defaultMessage={type} values={{ count: 2 }} />}
                    <Box sx={{ fontSize: 12, whiteSpace: 'nowrap', minWidth: 80 }}>
                      {'('}
                      {customer === Customer.SplitExpress && type === 'baby' ? customBabyText : defaultText}
                      <FormattedMessage id="booking.passenger.years" defaultMessage="years" />
                      {')'}
                    </Box>
                  </Grid>
                  <Grid item>
                    <QuantitySelector
                      quantity={passengerSummary[type]}
                      setQuantity={(newValue) => handleSummaryChange(type as keyof typeof passengerTypes, newValue)}
                      isRemoveDisabled={passengerSummary[type] <= getMinimumAmount(type as keyof typeof passengerTypes)}
                      isAddDisabled={passengerSummary[type] >= value.maximumAmount}
                      size={1}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default inject('customer')(PassengerSelector as FC<{}>);
