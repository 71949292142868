import { SailingLocation } from '../../components/travel/ScheduleHelper';
import { Extra, ExtraInventory, ExtraInventoryType, Package, PackageInventory, PackageInventoryType, ReturnTicket, SailingLeg, SailingLegInventory, SailingLegInventoryType, Ticket, TicketInventory, TicketInventoryType } from '../sailing-products/SailingProductsSource';
import { ShopifyProduct } from './ShopifyService';

class ShopifyAdapter {
  mapToSailingLeg(product: ShopifyProduct): SailingLeg {
    const inventory = product.variants.reduce<SailingLegInventory>((acc, variant) => {
      const inventoryType = variant.title as SailingLegInventoryType;
      return {
        ...acc,
        [inventoryType]: {
          id: variant.id,
          title: inventoryType,
          amount: variant.quantityAvailable,
          availableForSale: variant.availableForSale
        }
      };
    }, {});

    return {
      origin: product.origin.value as SailingLocation,
      destination: product.destination.value as SailingLocation,
      departureTime: product.departureTime?.value,
      arrivalTime: product.arrivalTime?.value,
      date: product.date?.value,
      title: product.title,
      id: product.id,
      inventory,
      tags: product.tags ?? [],
      description: product.description,
      route: product.sailingLegId?.value ?? product.id
    };
  }

  mapToTicket(product: ShopifyProduct): Ticket {
    const inventory = product.variants.reduce<TicketInventory>((acc, variant) => {
      const inventoryType = variant.title as TicketInventoryType;
      return {
        ...acc,
        [inventoryType]: {
          id: variant.id,
          title: inventoryType,
          amount: variant.quantityAvailable,
          availableForSale: variant.availableForSale,
          price: {
            amount: variant.priceV2.amount,
            currencyCode: variant.priceV2.currencyCode
          }
        }
      };
    }, {});

    return {
      origin: product.origin.value as SailingLocation,
      destination: product.destination.value as SailingLocation,
      departureTime: product.departureTime?.value,
      arrivalTime: product.arrivalTime?.value,
      date: product.date?.value,
      title: product.title,
      id: product.id,
      inventory,
      tags: product.tags ?? [],
      description: product.description
    };
  }

  mapToExtra(product: ShopifyProduct): Extra {
    const inventory = product.variants.reduce<ExtraInventory>((acc, variant) => {
      const inventoryType = variant.title as ExtraInventoryType;
      return {
        ...acc,
        [inventoryType]: {
          id: variant.id,
          title: inventoryType,
          amount: variant.quantityAvailable,
          availableForSale: variant.availableForSale,
          price: {
            amount: variant.priceV2.amount,
            currencyCode: variant.priceV2.currencyCode
          }
          // image: variant.i
        }
      };
    }, {});

    return {
      origin: product.origin.value as SailingLocation,
      destination: product.destination.value as SailingLocation,
      departureTime: product.departureTime?.value,
      arrivalTime: product.arrivalTime?.value,
      date: product.date?.value,
      title: product.title,
      id: product.id,
      inventory,
      tags: product.tags ?? [],
      description: product.description,
      image: '',
      type: product.productType
    };
  }

  mapToReturnTicket(product: ShopifyProduct): ReturnTicket {
    const inventory = product.variants.reduce<TicketInventory>((acc, variant) => {
      const inventoryType = variant.title as TicketInventoryType;
      return {
        ...acc,
        [inventoryType]: {
          id: variant.id,
          title: inventoryType,
          amount: variant.quantityAvailable,
          availableForSale: variant.availableForSale,
          price: {
            amount: variant.priceV2.amount,
            currencyCode: variant.priceV2.currencyCode
          }
        }
      };
    }, {});

    return {
      id: product.id,
      origin: product.origin.value as SailingLocation,
      destination:  product.destination.value as SailingLocation,
      title: product.title,
      inventory,
      tags: []
    };
  }

  mapToPackage(product: ShopifyProduct): Package {
    const inventory = product.variants.reduce<PackageInventory>((acc, variant) => {
      const inventoryType = variant.title as PackageInventoryType;
      return {
        ...acc,
        [inventoryType]: {
          id: variant.id,
          title: inventoryType,
          amount: variant.quantityAvailable,
          availableForSale: variant.availableForSale,
          price: {
            amount: parseInt(variant.priceV2.amount),
            currencyCode: variant.priceV2.currencyCode
          }
        }
      };
    }, {});

    return {
      origin: product.origin.value as SailingLocation,
      destination: product.destination.value as SailingLocation,
      requirement: { minimumAdult: parseInt(product.minimumAdult?.value ?? '1'), minimumChild: parseInt(product.minimumChild?.value ?? '1') },
      title: 'Family day at the beach',
      inventory: inventory,
      description: 'Travel to Split - Bol and back within the same day 2 adult, 2 children (400 HRK, extra child + 50 HRK)',
      image: 'https://media.istockphoto.com/photos/couple-pushing-trolley-with-their-child-at-airport-picture-id1325212877?s=612x612',
      reference: product.reference?.value ?? '',
      routes: product.routes?.value ?? ''
    }
  }
}

export default ShopifyAdapter;
