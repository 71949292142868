import { faFacebookSquare, faInstagram, faSnapchat, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  socialLinks: Record<string, string>;
  columnGap?: number;
}

const getIcons = (type: string) => {
  switch (type) {
    case 'facebook':
      return <FontAwesomeIcon icon={faFacebookSquare} />;
    case 'instagram':
      return <FontAwesomeIcon icon={faInstagram} />;
    case 'twitter':
      return <FontAwesomeIcon icon={faTwitter} />;
    case 'tiktok':
      return <FontAwesomeIcon icon={faTiktok} />;
    case 'snapchat':
      return <FontAwesomeIcon icon={faSnapchat} />;
    case 'youtube':
      return <FontAwesomeIcon icon={faYoutube} />;
  }
};

const SocialMediaLink: FC<Props> = ({ socialLinks, columnGap }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: columnGap ?? 2, fontSize: '1.5rem', '& a': { color: 'white' } }}>
      {Object.entries(socialLinks).map(([type, link]) => {
        const icon = getIcons(type);
        if (!icon) {
          return null;
        }
        return (
          <a key={type} href={link} target="_blank" rel="noreferrer">
            {icon}
          </a>
        );
      })}
    </Box>
  );
};

export default SocialMediaLink;
