import { LocationCode } from '../modules/routing/Routes';

export enum Customer {
  SplitExpress = 'splitexpress',
  Default = ''
}

export const customersWithMultiStop = [Customer.Default];
export const customersWithFooterNavigation = [Customer.Default];

export type minimumDateOptions = {
  defaultDate: Date;
  locationSpecific?: Record<LocationCode, Date>;
};
