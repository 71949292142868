import { FC, useMemo, useEffect, useContext, useCallback } from 'react';
import { CabinState, PassengerState } from '../../types/BookingState';
import AccommodationCard from './AccommodationCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, styled, Theme, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ViewportContext } from '../../context/ViewportContext';
import useAsync from '../../useAsync';
import Accommodations from '../../modules/accommodations/AccommodationsService';
import { useInjects } from 'saft-react';

export const DropdownLabel = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main
}));

const styles = {
  allocationHeader: {
    padding: 2,
    borderRadius: 1
  },
  inProgress: {
    backgroundColor: '#ffefd2',
    color: '#a95f1b'
  },
  complete: {
    backgroundColor: '#e8f1e4',
    color: '#6eb56e',
    display: 'flex',
    alignItems: 'center',
    gap: 2
  }
};

export interface AccommodationInfo {
  name: string;
  description: string;
  capacity: number;
  type: 'room' | 'seat';
  price: number;
  image: string;
}

interface CabinsStageProps {
  isReturnJourney: boolean;
  passengerState: PassengerState;
  state: CabinState;
  setState: (newState: CabinState) => void;
  setIsStageValid: (isStageValid: boolean) => void;
}

const CabinsStage: FC<CabinsStageProps> = ({ isReturnJourney, passengerState, state, setState, setIsStageValid }) => {
  const deps = useInjects<{ lxAccommodationsService: Accommodations }>({ lxAccommodationsService: undefined });
  let options = useAsync<AccommodationInfo[]>(() => (!deps ? Promise.resolve([]) : deps.lxAccommodationsService.getAvailableAccommodations()), [deps]).res;

  const { formatMessage } = useIntl();

  const passengerCount = useMemo(() => {
    return Object.values(passengerState.passengerSummary).reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    });
  }, [passengerState.passengerSummary]);

  const unallocatedPassengers = useCallback(
    (key: 'outboundRoute' | 'returnRoute') => {
      const selectedAccommodations = state.selectedAccommodations[key];
      if (!selectedAccommodations) return 0;

      const allocatedPassengers = selectedAccommodations.length
        ? selectedAccommodations
            .map((el) => el.capacity * el.quantity)
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue;
            })
        : 0;

      return passengerCount - allocatedPassengers;
    },
    [passengerCount, state.selectedAccommodations]
  );

  const allUnallocatedPassengers = useMemo(() => unallocatedPassengers('outboundRoute') + unallocatedPassengers('returnRoute'), [unallocatedPassengers]);

  useEffect(() => {
    setIsStageValid(!allUnallocatedPassengers);
  }, [allUnallocatedPassengers, setIsStageValid]);

  const allocationText = useMemo(() => {
    if (allUnallocatedPassengers > 0) {
      return `${formatMessage({ id: 'booking.cabins.allocationText.first', defaultMessage: 'You have' })} ${formatMessage(
        {
          id: 'booking.cabins.allocationText.space',
          defaultMessage: `${allUnallocatedPassengers} ${allUnallocatedPassengers === 1 ? 'space' : 'spaces'}`
        },
        { allUnallocatedPassengers }
      )} ${formatMessage({ id: 'booking.cabins.allocationText.second', defaultMessage: 'left to allocate' })}`;
    }
  }, [allUnallocatedPassengers, formatMessage]);

  const { matchesBreakpoint } = useContext(ViewportContext);
  const smallerThanLarge = useMemo(() => {
    return !matchesBreakpoint('lg');
  }, [matchesBreakpoint]);

  return (
    <Grid container>
      <Grid container item sx={{ justifyContent: 'center' }}>
        <Grid container item columnSpacing={1} xs={12} sx={{ margin: 2, textAlign: 'left' }}>
          <Grid item>
            <Typography variant="h6">
              <FormattedMessage id="booking.cabins.numberOfPassengers" defaultMessage="Number of passengers" />:
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{passengerCount}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'left', margin: 2 }}>
          {allUnallocatedPassengers > 0 ? (
            <Typography sx={{ ...styles.allocationHeader, ...styles.inProgress }}>{allocationText}</Typography>
          ) : (
            <Typography sx={{ ...styles.allocationHeader, ...styles.complete }}>
              {<CheckCircleIcon fontSize="small" />}
              <FormattedMessage id="booking.cabins.success" defaultMessage="You have successfully selected cabins / seats for all passengers" />
            </Typography>
          )}
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-end', marginBottom: 2, textAlign: 'right', paddingRight: 2 }}>
          <Grid container item xs={5} sx={{ justifyContent: 'flex-end' }}>
            {!smallerThanLarge && (
              <>
                <Grid item xs={5} sx={{ paddingRight: 5 }}>
                  <DropdownLabel>
                    <FormattedMessage id="booking.cabins.outbound" defaultMessage="Outbound" />
                  </DropdownLabel>
                </Grid>
                {isReturnJourney && (
                  <Grid item xs={5} sx={{ paddingRight: 5 }}>
                    <DropdownLabel>
                      <FormattedMessage id="booking.cabins.return" defaultMessage="Return" />
                    </DropdownLabel>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {options?.map((option) => (
          <AccommodationCard
            state={state}
            setState={setState}
            option={option}
            passengerCount={passengerCount}
            unallocatedPassengers={unallocatedPassengers}
            isReturnJourney={isReturnJourney}
            key={option.name}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default CabinsStage;
