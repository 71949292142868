import { useInjects } from 'saft-react';
import { Grid, Card, CardActionArea, CardContent, Typography, Container, styled, Theme, CardActions } from '@mui/material';
import BookingsService, { Booking } from '../modules/bookings/BookingsService';
import useAsync from '../useAsync';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useCallback, useContext, useMemo } from 'react';
import { BookingStateContext } from '../context/BookingStateContext';
import { cloneDeep, isEqual, pick, uniqWith } from 'lodash';
import { PassengerSummaryContext } from '../context/PassengerSummaryContext';
import { JourneyTypeContext, JourneyType } from '../context/JourneyTypeContext';
import { VehicleTypeContext, VehicleType } from '../context/VehicleTypeContext';
import { FormattedMessage } from 'react-intl';

const IconText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

export const PresetBooking = () => {
  const deps = useInjects<{ lxBookingsService: BookingsService }>({ lxBookingsService: undefined });
  const bookings = useAsync(() => (!deps ? Promise.resolve([]) : deps.lxBookingsService.getAllBookings()), [deps]);
  const { defaultBookingState, setDefaultBookingState } = useContext(BookingStateContext);
  const { setPassengerSummary } = useContext(PassengerSummaryContext);
  const { setJourneyType } = useContext(JourneyTypeContext);
  const { setVehicleType } = useContext(VehicleTypeContext);

  const updateDefaultBookings = useCallback(
    ({ vehicle, passengerInfo, routes }: Partial<Booking>, isOneWay: boolean) => {
      let clonedState = cloneDeep(defaultBookingState);

      if (vehicle) {
        clonedState.vehicleState.outboundVehicle = vehicle;
        setVehicleType(vehicle?.vehicleType as VehicleType);
      }

      if (passengerInfo) {
        clonedState.passengerState = passengerInfo;
        setPassengerSummary(passengerInfo.passengerSummary);
      }

      if (routes?.[0]) {
        clonedState.scheduleState.routeSummaries = [{ origin: routes[0].origin, destination: routes[0].destination, departureDate: null }];
      }

      setJourneyType(isOneWay ? JourneyType.OneWay : JourneyType.RoundTrip);
      setDefaultBookingState(clonedState);
    },
    [defaultBookingState, setDefaultBookingState, setJourneyType, setPassengerSummary, setVehicleType]
  );

  const getPartialBooking = useCallback(
    (booking: Booking) => ({
      ...pick(booking, ['vehicle', 'licensePlate', 'passengerInfo']),
      ...pick(booking.routeSummaries?.[0], ['origin', 'destination'])
    }),
    []
  );

  const comparator = useCallback(
    (a: Booking, b: Booking) => {
      return isEqual(getPartialBooking(a), getPartialBooking(b));
    },
    [getPartialBooking]
  );

  const uniqueBookings = useMemo(() => uniqWith(bookings?.res, comparator), [bookings?.res, comparator]);

  return (
    <Container maxWidth="lg">
      <Grid container flexDirection="row" gap={[3, 3]} marginTop={3} justifyContent={uniqueBookings.length > 1 ? 'initial' : 'center'}>
        {uniqueBookings.map((booking) => {
          const isOneWay = booking.routes.length === 1;
          return (
            <Grid item xs={5} key={booking.bookingReference}>
              <Card>
                <CardActionArea sx={{ padding: 3 }} onClick={() => updateDefaultBookings(booking, isOneWay)}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="subtitle1" fontWeight="medium">
                      {isOneWay ? (
                        <FormattedMessage id="journeyType.oneWay" defaultMessage="One way" />
                      ) : (
                        <FormattedMessage id="journeyType.roundTrip" defaultMessage="Round trip" />
                      )}
                    </Typography>
                    <IconText variant="h6" fontWeight="bold">
                      {booking.routes[0].origin.name}
                      {isOneWay ? <ArrowRightAltIcon fontSize="large" /> : <AutorenewIcon fontSize="large" />}
                      {booking.routes[0].destination.name}
                    </IconText>
                    <IconText variant="subtitle2">
                      {booking.vehicle?.vehicleType} • {booking.vehicle?.vehicleRegistration} • {booking.vehicle?.dimensions.length}x
                      {booking.vehicle?.dimensions.width}x{booking.vehicle?.dimensions.height}{' '}
                      {booking.passengerInfo &&
                        Object.entries(booking.passengerInfo.passengerSummary).map(([string, number]) => (number ? ` • ${number} ${string}` : ''))}
                    </IconText>
                  </CardContent>
                  {booking.passengerInfo && (
                    <CardActions>
                      <Typography variant="subtitle2">{`${booking.passengerInfo?.passengers[0].firstName} ${booking.passengerInfo?.passengers[0].lastName}`}</Typography>
                      <Typography variant="subtitle2">• {booking.passengerInfo?.passengers[0].phoneNumber} •</Typography>
                      <Typography variant="subtitle2">{booking.passengerInfo?.passengers[0].email}</Typography>
                    </CardActions>
                  )}
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};
