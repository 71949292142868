// TODO: change this to be configurable per customer

import { Customer } from '../types/Customer';

const getPassengerTypes = (customer: Customer) =>
  ({
    splitexpress: {
      adult: {
        customTitle: {
          id: 'splitexpress.passenger.standard_fare',
          defaultMessage: 'Standard fare'
        },
        lowerAgeLimit: 12,
        upperAgeLimit: -1,
        minimumAmount: 1,
        maximumAmount: 300
      },
      child: {
        customTitle: {
          id: 'splitexpress.passenger.children_in_own_seat',
          defaultMessage: 'Children in own seat'
        },
        lowerAgeLimit: 2,
        upperAgeLimit: 11,
        minimumAmount: 1,
        maximumAmount: 299
      },
      baby: {
        customTitle: {
          id: 'splitexpress.passenger.infants_on_lap',
          defaultMessage: 'Infants on lap'
        },
        lowerAgeLimit: 0,
        upperAgeLimit: 2,
        minimumAmount: 0,
        maximumAmount: 299
      }
    },
    '': {
      adult: {
        lowerAgeLimit: 18,
        upperAgeLimit: -1,
        minimumAmount: 1,
        maximumAmount: 8
      },
      child: {
        lowerAgeLimit: 2,
        upperAgeLimit: 16,
        minimumAmount: 0,
        maximumAmount: 7
      },
      baby: {
        lowerAgeLimit: 0,
        upperAgeLimit: 2,
        minimumAmount: 0,
        maximumAmount: 7
      }
    }
  }[customer] || {});

export default getPassengerTypes;
