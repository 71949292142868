import React, { FC, useEffect, useState, useCallback, useContext, useMemo } from 'react';
//@ts-ignore
import Lock from '@surikat/surikat-lock';
import UrlResolver from '../modules/server-communication/UrlResolver';
import { TextField, Button, Dialog, DialogProps, DialogContent, DialogTitle, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { AuthenticatorContext } from './login/AuthenticatorProvider';

const FAILED_TO_CONNECT_ERROR = (
  <FormattedMessage id="trip_summary.failed_to_connect_to_login_server" defaultMessage="Failed to connect to login-server. Make sure you are online" />
);

interface Props {
  onLoggedIn?: () => void;
}

const LoginDialog: FC<Props & DialogProps> = (props) => {
  const { children, onLoggedIn } = props;
  const [connection, setConnection] = useState<string>('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailedText, setLoginFailedText] = useState<JSX.Element | undefined>();

  const { authenticator } = useContext(AuthenticatorContext);

  const getLock = useCallback(
    () => new Lock(UrlResolver.getAccountsUrl(window.location), 'lynx', 'splitexpress', { centerView: true, localsignInStrategy: true }, 'localhost'),
    []
  );

  const lock = useMemo(() => getLock(), [getLock]);

  const login = useCallback(
    (token: string) => {
      authenticator.authenticate(token);
      window.sessionStorage.setItem('token', token);
      onLoggedIn?.();
    },
    [authenticator, onLoggedIn]
  );

  const loginFailed = (loginFailedText?: JSX.Element) => {
    if (loginFailedText) {
      setLoginFailedText(loginFailedText);
    }
    setPassword('');
  };

  const handleLockResponse = useCallback(
    (err: any, res: any) => {
      if (res && res === 'restored') {
        setConnection('successful');
        return;
      }
      if (err && (err.message === 'Connection failed' || err === 'Connection failed')) {
        loginFailed(FAILED_TO_CONNECT_ERROR);
        setConnection('failed');
      } else if (err) {
        const loginErrorText = <FormattedMessage id="trip_summary.incorrect_login_information" defaultMessage="Incorrect login information" />;
        loginFailed(loginErrorText);
      } else if (!isEmpty(password)) {
        login(res.token);
      } else {
        login(res.token);
      }
    },
    [login, password]
  );

  const showLock = useCallback(() => {
    lock.show('loginBox', handleLockResponse, () => {
      if (connection !== 'successful') {
        setConnection('successful');
      }
    });
  }, [connection, handleLockResponse, lock]);

  const credentialsFilled = () => {
    const isPasswordOk = password.length > 0;
    const isUsernameOk = username.length > 0;
    return isPasswordOk && isUsernameOk;
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const key = e.key;
    if (key === 'Enter' && credentialsFilled()) {
      const loginButton: HTMLButtonElement | null = document.querySelector('#lynx-local-login-strategy-button button');
      loginButton?.click();
    }
  };

  useEffect(() => {
    showLock();
  }, [showLock]);

  return (
    <Dialog {...props}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <div id="loginBox"></div>
        <Typography style={{ color: '#F44336', fontWeight: 500 }}>{loginFailedText}</Typography>
        <div>
          <div id="lynx-local-login-strategy-username">
            <TextField
              label={<FormattedMessage id="ui.username" defaultMessage="Username" />}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              variant="outlined"
              autoFocus
              inputProps={{ autoCapitalize: 'off' }}
              onKeyPress={onKeyPress}
              fullWidth
            />
          </div>
          <div id="lynx-local-login-strategy-password">
            <TextField
              type="password"
              label={<FormattedMessage id="ui.password" defaultMessage="Password" />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              variant="outlined"
              onKeyPress={onKeyPress}
              fullWidth
            />
          </div>
          <div id="lynx-local-login-strategy-button">
            <Button disabled={!credentialsFilled()} type="submit" fullWidth={true} variant="contained" sx={{ backgroundColor: 'primary' }}>
              LOGIN
            </Button>
          </div>
        </div>
      </DialogContent>
      {children}
    </Dialog>
  );
};

export default LoginDialog;
