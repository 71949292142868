import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { BlogArticle } from '../modules/shopify/ShopifyService';
import { LinkWithQuery } from './LinkWithQuery';

interface Props {
  blogHandle: string;
  article: BlogArticle;
}

const ArticleCard: FC<Props> = (props) => {
  const { blogHandle, article } = props;
  const { handle, title, image } = article;

  return (
    <LinkWithQuery to={`/blogs/${blogHandle}/${handle}`}>
      <Box
        title={title}
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundImage: `url(${image.url})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          top: 0,
          left: 0,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 0
          }}
        >
          <Typography
            variant="h6"
            fontWeight="medium"
            sx={{
              color: 'white',
              fontSize: 16,
              fontWeight: 300,
              padding: 1,
              boxSizing: 'border-box',
              width: '100%',
              background: 'rgba(0,0,0,0.5)',
              borderBottomLeftRadius: 2,
              borderBottomRightRadius: 2
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </LinkWithQuery>
  );
};

export default ArticleCard;
