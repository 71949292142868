import { FC, useContext, useMemo } from 'react';
import { Avatar, Grid, styled, Typography, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { LocationSummary } from '../../modules/routing/Routes';
import LocationName from '../LocationName';
import { ViewportContext } from '../../context/ViewportContext';
import { ScheduleEntry } from '../../modules/scheduling/Schedules';
import moment from 'moment';

const TimeText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 20,
  fontWeight: theme.typography.fontWeightBold
}));

const StopsText = styled(Typography)(() => ({
  fontSize: 16,
  color: 'rgba(0,0,0,0.60)'
}));

const LocationText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 16,
  fontWeight: theme.typography.fontWeightMedium,
  color: 'rgba(0,0,0,0.60)'
}));

const DurationText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 16,
  color: '#B82A0B',
  fontWeight: theme.typography.fontWeightMedium
}));

type LegCardProps = {
  origin: LocationSummary;
  destination: LocationSummary;
  scheduleEntry?: ScheduleEntry;
  onClick?: (scheduleEntry?: ScheduleEntry) => void;
};

const LegCard: FC<LegCardProps> = (props) => {
  const { scheduleEntry, origin, destination, onClick = () => {} } = props;
  const { matchesBreakpoint } = useContext(ViewportContext);

  const duration = useMemo(() => {
    if (scheduleEntry?.duration) {
      const duration = moment.duration(scheduleEntry?.duration);
      return `${duration.hours()}h ${duration.minutes()}m`;
    } else {
      return 'N/A';
    }
  }, [scheduleEntry?.duration]);

  return (
    <Grid
      onClick={() => onClick(scheduleEntry)}
      container
      item
      sx={{ marginTop: 1, marginBottom: 1, textAlign: 'left', alignItems: 'center', justifyContent: 'space-around' }}
    >
      {matchesBreakpoint('sm') && (
        <Grid item xs={1}>
          <Avatar>S</Avatar>
        </Grid>
      )}
      <Grid container item xs={9} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={4} sx={{ paddingRight: 1 }}>
          <TimeText>{scheduleEntry ? moment(scheduleEntry?.sailingDateTime).format('HH:mm') : 'N/A'}</TimeText>
          <LocationText>
            <LocationName location={origin} />
          </LocationText>
        </Grid>
        <Grid container item xs={12} sm={4} sx={{ position: 'relative', textAlign: matchesBreakpoint('sm') ? 'center' : 'left' }}>
          {matchesBreakpoint('sm') && (
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                height: '1px',
                backgroundColor: '#DDDDDD',
                top: '22px'
              }}
            ></Box>
          )}
          <Grid container columnSpacing={1}>
            <Grid item xs="auto" sm={12}>
              <DurationText>{duration}</DurationText>
            </Grid>
            <Grid item xs="auto" sm={12}>
              <StopsText>{scheduleEntry?.isDirect ? 'direct' : 'indirect'}</StopsText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ paddingLeft: matchesBreakpoint('sm') ? 3 : 0 }}>
          <TimeText>{scheduleEntry ? moment(scheduleEntry?.arrivalDateTime).format('HH:mm') : 'N/A'}</TimeText>
          <LocationText>
            <LocationName location={destination} />
          </LocationText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LegCard;
