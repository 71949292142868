import { FC } from 'react';
import { Grid, styled, Theme, Typography, useTheme } from '@mui/material';

const SubLabel = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  color: '#666'
}));

interface Label {
  main?: string;
  sub?: string;
}

interface Props {
  leftLabel?: Label;
  middleLabel?: Label;
  rightLabel?: Label;
}

const DetailRow: FC<Props> = (props) => {
  const { leftLabel, middleLabel, rightLabel } = props;
  const theme = useTheme();

  return (
    <Grid container item xs={12} sx={{ '& .MuiGrid-root': { flexDirection: 'column' }, padding: theme.spacing(1, 2) }}>
      <Grid item xs={4} sx={{ textAlign: 'left' }}>
        {leftLabel?.main && <Typography>{leftLabel.main}</Typography>}
        {leftLabel?.sub && <SubLabel>{leftLabel.sub}</SubLabel>}
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'center' }}>
        {middleLabel?.main && <Typography fontWeight="medium">{middleLabel.main}</Typography>}
        {middleLabel?.sub && <SubLabel>{middleLabel.sub}</SubLabel>}
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'right' }}>
        {rightLabel?.main && <Typography fontWeight="bold">{rightLabel.main}</Typography>}
        {rightLabel?.sub && <SubLabel>{rightLabel.sub}</SubLabel>}
      </Grid>
    </Grid>
  );
};

export default DetailRow;
