import { createContext, FC, useState, useCallback, useEffect, useContext } from 'react';
import { Package } from '../../modules/sailing-products/SailingProductsSource';
import { TravelBookingContext } from '../travel/TravelBookingContext';
import { forEach, some } from 'lodash';

interface contextType {
  selectedPackage: Package | undefined;
  setSelectedPackage: (selectedPackage: Package | undefined) => void;
  resetPackageStates: () => void;
}

export const PackageBookingContext = createContext<contextType>({
  selectedPackage: undefined,
  setSelectedPackage: (selectedPackage: Package | undefined) => {},
  resetPackageStates: () => {}
});

const PackageBookingContextProvider: FC = ({ children }) => {
  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>();
  const { setSelectedEntry, travelBookingRoutes } = useContext(TravelBookingContext);

  const resetPackageStates = useCallback(() => {
    setSelectedPackage(undefined);
  }, [setSelectedPackage]);

  useEffect(() => {
    if (selectedPackage && some(travelBookingRoutes, ({ selectedEntry }) => selectedEntry)) {
      forEach(travelBookingRoutes, (travelBookingRoute) => {
        setSelectedEntry(travelBookingRoute.identifier, null);
      });
    }
  }, [selectedPackage, setSelectedEntry, travelBookingRoutes]);

  return <PackageBookingContext.Provider value={{ selectedPackage, setSelectedPackage, resetPackageStates }}>{children}</PackageBookingContext.Provider>;
};

export default PackageBookingContextProvider;
