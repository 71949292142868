import { Grid, Typography } from '@mui/material';

export const BookingTitle = ({ subtitle, title }: { subtitle: string; title: string }) => (
  <Grid item margin={1}>
    <Typography variant="subtitle2" fontWeight="regular" sx={{ color: '#00000099' }}>
      {subtitle}
    </Typography>
    <Typography variant="h5" fontWeight="medium">
      {title}
    </Typography>
  </Grid>
);
