import { FC } from 'react';
import { Typography, useTheme, styled, Box, Theme } from '@mui/material';

const Label = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightMedium
}));

interface LegsHeaderProps {
  leftLabel?: string;
  rightLabel?: string;
}

const LegsHeader: FC<LegsHeaderProps> = ({ leftLabel, rightLabel }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#f5f5f5',
        padding: theme.spacing(1, 2)
      }}
    >
      {leftLabel && <Label sx={{ color: '#4a9bbb' }}>{leftLabel}</Label>}
      {rightLabel && <Label>{rightLabel}</Label>}
    </Box>
  );
};

export default LegsHeader;
