import { Box, Button, Paper, styled, Typography, useTheme } from '@mui/material';
import { isEmpty, isNil, min } from 'lodash';
import moment from 'moment';
import { FC, useCallback, useContext, useEffect, useState, ElementType } from 'react';
import ShopifyService, { GraphProduct, GraphProductEdge } from '../../modules/shopify/ShopifyService';
import { ProductType } from '../../types/PosTypes';
import { CartItem, PosContext, Trip } from './PosContext';
import PosExtraSelector from './PosExtraSelector';
import PosScheduleSelectorDialog from './PosScheduleSelectorDialog';
import PosTicketTypeSelector from './PosTicketTypeSelector';
import { getDisplayLocation, getTicketsByVariantMapping, getTotalSelectedAmount } from '../../utils/PosUtils';
import { green } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { inject } from 'saft-react';

const NO_SELECT_BY_DEFAULT = true;

const ToggleButton = styled(Button)({
  '&.MuiButton-outlined': {
    border: `1px solid ${green[500]}`,
    color: green[500]
  },
  '&.MuiButton-contained': {
    backgroundColor: green[500]
  }
});

export interface CartAttribute {
  key: string;
  value: string;
}

interface InjectedProps {
  shopifyService: ShopifyService;
}

interface Props {
  trips: Trip[];
  onChangeClick: (tripIndex: number) => void;
  showCartDetails: () => void;
}

const getDefaultSelection = (ticket: GraphProduct, currentCartItems: CartItem[], trips: Trip[]) => {
  const cartItems: CartItem[] = [];

  const capacityLeft = min(trips.map((trip) => trip.capacityLeft));

  const ticketTypeQuantity = getTicketsByVariantMapping(currentCartItems);
  const totalAmount = getTotalSelectedAmount(ticketTypeQuantity);

  const isAmountExceed = !isNil(capacityLeft) && totalAmount > (capacityLeft ?? 0);

  if (isEmpty(ticketTypeQuantity) || isAmountExceed) {
    const variant = ticket?.variants?.edges.find((edge) => edge.node.title === 'Adult')?.node;
    if (variant && !NO_SELECT_BY_DEFAULT) {
      cartItems.push({
        quantity: 1,
        variant,
        productType: ticket.productType as ProductType
      });
    }
  } else {
    Object.entries(ticketTypeQuantity).forEach(([title, quantity]) => {
      const variant = ticket?.variants?.edges.find((edge) => edge.node.title === title)?.node;
      if (variant) {
        cartItems.push({
          quantity,
          variant,
          productType: ticket.productType as ProductType
        });
      }
    });
  }

  return cartItems;
};

const PosTicketSelector: FC<Props & InjectedProps> = (props) => {
  const { setTrip, clearCart, updateCartItem, cartItems, setCartAttributes } = useContext(PosContext);

  const { trips, onChangeClick, shopifyService, showCartDetails } = props;
  const [ticket, setTicket] = useState<GraphProduct | null>(null);
  const [relatedTickets, setRelatedTickets] = useState<GraphProduct[]>([]);
  const [returnTripSelectorDialogOpen, setReturnTripSelectorDialogOpen] = useState(false);
  const [doAutomaticCheckIn, setDoAutomaticCheckIn] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const theme = useTheme();

  const setDefaultSelection = () => {
    if (!ticket) {
      return;
    }
    const defaultCartItems = getDefaultSelection(ticket, cartItems, trips);
    clearCart();
    defaultCartItems.forEach((cartItem) => {
      updateCartItem(cartItem);
    });
  };

  useEffect(() => {
    setDefaultSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    setTicket(null);
    const isWithReturnTrip = trips.length === 2;
    if (trips.length === 1) {
      const { ticket } = trips[0];
      setTicket(ticket);
      setRelatedTickets([ticket]);
    } else if (isWithReturnTrip) {
      const getReturnTickets = async () => {
        const { ticket } = trips[0];
        const returnTickets = (await shopifyService.getReturnTicketsWithEdges()) as GraphProductEdge[];
        const returnTicket = returnTickets.find(
          (returnTicket) => returnTicket.node?.origin?.value === ticket.origin?.value && returnTicket.node?.destination?.value === ticket.destination?.value
        );
        if (returnTicket) {
          setTicket(returnTicket.node);
          setRelatedTickets([returnTicket.node]);
        } else {
          setTicket(ticket);
          setRelatedTickets(trips.map((trip) => trip.ticket));
        }
      };
      getReturnTickets();
    }
  }, [trips, shopifyService]);

  const getReturnTicketCartAttributes = useCallback(() => {
    const cartAttributes: CartAttribute[] = [];

    const seatsVariantIds = trips.flatMap((trip) => {
      const { ticket } = trip;
      let seatsVariantIds: string[] = [];
      try {
        seatsVariantIds = JSON.parse(ticket.affectedInventoryProductIds?.value ?? '[]');
      } catch (e) {
        // do nothing
      }
      return seatsVariantIds;
    });

    const seatsVariantIdsAttribute: CartAttribute = {
      key: 'seatsVariantIds',
      value: JSON.stringify(seatsVariantIds)
    };

    const routes = trips.map((trip) => {
      const origin = trip.ticket.origin?.value;
      const departureTime = trip.ticket.departureTime?.value;
      const destination = trip.ticket.destination?.value;
      const arrivalTime = trip.ticket.arrivalTime?.value;
      if (origin && departureTime && destination && arrivalTime) {
        return `${origin}@${departureTime}->${destination}@${arrivalTime}`;
      }
      return '';
    });

    const routesCode = routes.filter((route) => !!route).join('|');
    const dates = trips.map((trip) => moment(trip.date).format('YYYY-MM-DD'));
    const datesCode = dates.join('|');
    cartAttributes.push(seatsVariantIdsAttribute);
    cartAttributes.push({
      key: 'routes',
      value: routesCode
    });
    cartAttributes.push({
      key: 'dates',
      value: datesCode
    });

    return cartAttributes;
  }, [trips]);

  const handleConfirm = useCallback(async () => {
    setConfirmLoading(true);
    const cartAttributes: CartAttribute[] = [];

    if (doAutomaticCheckIn) {
      cartAttributes.push({
        key: 'autoCheckIn',
        value: 'true'
      });
    }

    if (ticket?.productType === ProductType.ReturnTicket) {
      const returnCartAttributes = getReturnTicketCartAttributes();
      cartAttributes.push(...returnCartAttributes);
    }

    setCartAttributes(cartAttributes);
    setConfirmLoading(false);
    showCartDetails();
  }, [doAutomaticCheckIn, ticket?.productType, setCartAttributes, showCartDetails, getReturnTicketCartAttributes]);

  return (
    <div>
      {trips.map((trip, index) => {
        const { ticket, date } = trip;
        return (
          <Paper
            onClick={() => onChangeClick(index)}
            key={index}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2, paddingTop: 1, paddingBottom: 1 }}
          >
            <Typography fontWeight="bold" variant="h6">
              {getDisplayLocation(ticket.origin?.value)} - {getDisplayLocation(ticket.destination?.value)}
            </Typography>
            <div>
              <Typography variant="subtitle1">{moment(date).format('DD MMM YYYY')}</Typography>
              <Typography fontWeight="bold" variant="body1">
                {ticket.departureTime?.value} - {ticket.arrivalTime?.value}
              </Typography>
            </div>
          </Paper>
        );
      })}
      {trips.length === 1 && (
        <Box sx={{ padding: 1 }}>
          <Button size="large" onClick={() => setReturnTripSelectorDialogOpen(true)} variant="outlined" sx={{ color: theme.palette.primary.main }} fullWidth>
            Add return trip
          </Button>
        </Box>
      )}

      {ticket && <PosTicketTypeSelector trips={trips} ticket={ticket} />}

      {ticket && trips.map((trip, index) => <PosExtraSelector showDate={trips.length > 1} key={index} trip={trip} />)}

      <Box sx={{ marginTop: 3 }} />

      <Box sx={{ padding: 2 }}>
        <ToggleButton
          onClick={() => setDoAutomaticCheckIn(!doAutomaticCheckIn)}
          variant={doAutomaticCheckIn ? 'contained' : 'outlined'}
          fullWidth
          startIcon={doAutomaticCheckIn ? <FontAwesomeIcon icon={faCheckCircle} /> : null}
        >
          Automatic check-in
        </ToggleButton>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Button
          disabled={!ticket || confirmLoading}
          onClick={() => handleConfirm()}
          sx={{ padding: 3 }}
          size="large"
          variant="contained"
          color="primary"
          fullWidth
        >
          Confirm and add to cart
        </Button>
      </Box>

      <PosScheduleSelectorDialog
        open={returnTripSelectorDialogOpen}
        onClose={() => setReturnTripSelectorDialogOpen(false)}
        onSelectTicket={(ticket, date, capacityLeft) => {
          setReturnTripSelectorDialogOpen(false);
          setTrip(1, {
            date,
            ticket,
            capacityLeft
          });
        }}
        defaultDate={trips[0].date}
        defaultOrigin={trips[0].ticket?.destination?.value}
        defaultDestination={trips[0].ticket?.origin?.value}
        isReturnTrip
      />
      {/* {confirmLoading && <Loading />} */}
    </div>
  );
};

export default inject('shopifyService')(PosTicketSelector as FC<{}>) as ElementType<Props>;
