import { ThemeOptions } from '@mui/material';
import { Customer } from './types/Customer';

class ThemeService {
  private customer?: Customer;

  constructor(customer?: Customer) {
    this.customer = customer;
  }

  getTheme(): ThemeOptions | undefined {
    if (!this.customer) {
      return {};
    }
    if (this.customer === Customer.SplitExpress) {
      return {
        palette: {
          primary: {
            main: '#c62828',
            light: '#ffebee'
          },
          secondary: {
            main: '#3996b9'
          }
        },
        background: {
          main: 'linear-gradient(#2F5173, #293A54)',
          content: '#f3f5f7',
          appbar: 'rgba(41,58,84,0.5)'
        },
        logo: {
          img: '/img/logo_splitexpress_text_white.png',
          width: 150
        },
        footer: {
          backgroundColor: '#293A54',
          titleColor: '#fff',
          logo: '/img/splitexpress_logo_with_text.png',
          logoHeight: 64
        },
        headerImage: {
          travel: '/img/travel_image_splitexpress.jpg'
        }
      } as ThemeOptions;
    }
  }
}

export default ThemeService;
