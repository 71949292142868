export interface Attribute {
  key: string;
  value: string;
}

export interface Product {
  id: string;
  productType: string;
  tags: string[];
  affectedSailingLegs: Metafield;
  affectedInventoryProductIds: Metafield;
  minimumAdult: Metafield;
  minimumChild: Metafield;
  variants: ProductVariant[];
  title: string;
  origin?: Metafield;
  destination?: Metafield;
  departureTime?: Metafield;
  arrivalTime?: Metafield;
  ticketReference?: Metafield;
  extraReference?: Metafield;
  sailingLegId?: Metafield;
  routes?: Metafield;
  date?: Metafield;
}

export interface ProductVariant {
  id: string;
  price: string;
  priceV2: {
    amount: string;
    currencyCode: string;
  };
  title: string;
  inventoryItem?: {
    id: string;
    inventoryLevels: InventoryLevel[];
    tracked: boolean;
  };
  inventoryQuantity: number;
  portTax?: Metafield;
  quantityAvailable?: number;
  availableForSale?: boolean;
}

export interface InventoryLevel {
  available: number;
  id: string;
  location: {
    id: string;
  };
}

export interface Metafield {
  value: string;
  type: string;
}

export enum SailingLegInventoryType {
  Seats = 'Seats',
  Luggages = 'Luggages',
  Pets = 'Pets',
  Bikes = 'Bikes'
}

export enum TicketType {
  Adult = 'Adult',
  Child = 'Child',
  Baby = 'Baby',
  Islander = 'Islander'
}

export enum ExtraType {
  Luggage = 'Luggage',
  Pet = 'Pet',
  Bike = 'Bike',
  ExtraChild = 'Extra Child'
}

export enum ProductType {
  SailingLeg = 'Sailing Leg',
  TravelExtra = 'Travel Extra',
  Ticket = 'Ticket',
  ReturnTicket = 'Return Ticket',
  Package = 'Package',
  ExtraPax = 'Extra Pax'
}

export enum PackageTicketType {
  Ticket = 'Ticket'
}
