import { PassengerState, Vehicle } from '../../types/BookingState';
import { RouteSummary } from '../routing/Routes';
import { ScheduleEntry } from '../scheduling/Schedules';

export type Booking = {
  bookingReference: string;
  licensePlate: string;
  bookingDate: Date;
  routes: ScheduleEntry[];
  vehicle?: Vehicle;
  status?: string;
  passengerInfo?: PassengerState;
  routeSummaries?: RouteSummary[];
};

export default class Bookings {
  private key = 'bookings';
  private getLocal = (bookingRef?: string): Booking[] => {
    const storage: Booking[] = JSON.parse(localStorage.getItem(this.key) ?? '[]');
    return bookingRef ? storage.filter((entry) => entry.bookingReference === bookingRef) : storage;
  };

  private setLocal = (value: Booking) => {
    let current = this.getLocal();

    if (current.length) {
      current = current.filter(({ bookingReference }) => bookingReference !== value.bookingReference);
    }

    localStorage.setItem(this.key, JSON.stringify([...current, value]));
    return value;
  };

  save = (value: Booking) => Promise.resolve(this.setLocal(value));

  getAllBookings = () => Promise.resolve(this.getLocal());

  getBookingByRef = (bookingRef: string) => Promise.resolve(this.getLocal(bookingRef));
}
