import { ReactNode } from 'react';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material';
import '../fonts.css';
import ThemeService from '../ThemeService';
import { BookingType } from './BookingTypeContext';
import { Customer } from '../types/Customer';

declare module '@mui/material' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

export interface CustomThemeOptions {
  background?: {
    main?: string;
    appbar?: string;
    footer?: string;
    content?: string;
  };
  logo?: {
    img: any;
    width?: number;
    height?: number;
  };
  footer?: {
    backgroundColor?: string;
    titleColor?: string;
    logo?: any;
    logoHeight?: number;
  };
  article?: {
    card?: {
      height?: number
    }
  };
  headerImage?: {
    [BookingType.Travel]?: string;
    [BookingType.Freight]?: string;
    [BookingType.DoorToDoorFreight]?: string;
  };
}

declare module '@mui/system' {
  interface Theme extends CustomThemeOptions {}
  interface ThemeOptions extends CustomThemeOptions {}
}

const defaultTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  },
  palette: {
    primary: {
      light: '#D4E8EF',
      main: '#00A2E1',
      dark: '#1F7BC2'
    },
    info: {
      light: '#E5E5E5',
      main: '#0075A2',
      dark: '#005575'
    },
    secondary: {
      main: '#B82A0B'
    }
  },
  background: {
    main: '#005575',
    content: '#005575',
    appbar: '#292929'
  },
  logo: {
    img: 'img/logo.png'
  },
  article: {
    card: {
      height: 360
    }
  },
  footer: {
    backgroundColor: '#292929',
    titleColor: '#B82A0B',
    logo: 'img/logo.png'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          '& .MuiButton-root': {
            color: '#00A2E1'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: { shrink: true },
        sx: { '& .MuiInputBase-root': { backgroundColor: '#fafafa' } }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1750
    },
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
  }
};

const ThemeContextProvider = ({ children, customer }: { children: ReactNode; customer: string }) => {
  const themeService = new ThemeService(customer as Customer);
  const customerTheme = themeService.getTheme();
  const theme = createTheme({
    ...defaultTheme,
    ...customerTheme
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContextProvider;
