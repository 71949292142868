import { createContext, useState, ReactNode } from 'react';

export enum VehicleType {
  SDV = 'Lorry',
  SFV = 'Small Freight Vehicle',
  MIL = 'Military Vehicle',
  VAN = 'Van',
  CAR = 'Car',
  EXM = 'Export Machinery',
  CHA = 'Chassis',
  HAR = 'Harvester',
  TRA = 'Tractor',
  UNNSDV = 'Unaccompanied Lorry',
  UNNSFV = 'Unaccompanied Small Freight Vehicle',
  TRL = 'Trailer',
  CVN = 'Caravan',
  TNK = 'Tanker',
  CNT = 'Container',
  LT = 'Lift Tank',
  SB = 'Swap Body',
  FLT = 'Flat',
  GEN = 'General'
}

export const VehicleTypeContext = createContext({ vehicleType: VehicleType.SDV, setVehicleType: (vehicleType: VehicleType) => {} });

const VehicleTypeContextProvider = ({ children }: { children: ReactNode }) => {
  const [vehicleType, setVehicleType] = useState(VehicleType.SDV);

  return <VehicleTypeContext.Provider value={{ vehicleType: vehicleType, setVehicleType: setVehicleType }}>{children}</VehicleTypeContext.Provider>;
};

export default VehicleTypeContextProvider;
