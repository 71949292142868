import { createContext, ReactNode, useContext } from 'react';
import { BookingStageContext } from './BookingStageContext';
import { BookingStateContext } from './BookingStateContext';
import { BookingTypeContext, BookingType } from './BookingTypeContext';
import { JourneyTypeContext, JourneyType } from './JourneyTypeContext';
import { PassengerSummaryContext, getDefaultPassengerSummary } from './PassengerSummaryContext';
import { RouteSummaryContext } from './RouteSummaryContext';
import { VehicleTypeContext, VehicleType } from './VehicleTypeContext';
import { emptyBookingState } from '../utils/EmptyFreightBookingStates';

export const ResetStatesContext = createContext(() => {});

const ResetStatesContextProvider = ({ children }: { children: ReactNode }) => {
  const { setCurrentStage } = useContext(BookingStageContext);
  const { setBookingType } = useContext(BookingTypeContext);
  const { setJourneyType } = useContext(JourneyTypeContext);
  const { setPassengerSummary } = useContext(PassengerSummaryContext);
  const { setRoutes } = useContext(RouteSummaryContext);
  const { setVehicleType } = useContext(VehicleTypeContext);
  const { setDefaultBookingState } = useContext(BookingStateContext);

  const resetStates = () => {
    setCurrentStage(0);
    setBookingType(BookingType.Travel);
    setJourneyType(JourneyType.OneWay);
    setPassengerSummary(getDefaultPassengerSummary());
    setRoutes(undefined);
    setVehicleType(VehicleType.SDV);
    setDefaultBookingState(emptyBookingState);
  };

  return <ResetStatesContext.Provider value={resetStates}>{children}</ResetStatesContext.Provider>;
};

export default ResetStatesContextProvider;
