import { FC, useMemo, useEffect, useRef, useCallback, useContext } from 'react';
import { Grid, Paper } from '@mui/material';
import useAsync from '../../useAsync';
import { useInjects } from 'saft-react';
import Schedules, { ScheduleEntry } from '../../modules/scheduling/Schedules';
import { ScheduleState } from '../../types/BookingState';
import { shortenDate } from '../../utils/DateUtils';
import { useForm } from 'react-hook-form';
import { isEmpty, uniqBy } from 'lodash';
import { ScheduleCard } from './ScheduleCard';
import { RouteSummaryContext } from '../../context/RouteSummaryContext';

interface ScheduleStageProps {
  isReturnJourney?: boolean;
  state: ScheduleState;
  setState: (newState: ScheduleState) => void;
  setIsStageValid: (isStageValid: boolean) => void;
}

const ScheduleStage: FC<ScheduleStageProps> = ({ isReturnJourney, state, setState, setIsStageValid }) => {
  const deps = useInjects<{ lxScheduleService: Schedules }>({
    lxScheduleService: undefined
  });
  let scheduleEntries = useAsync<ScheduleEntry[]>(
    () =>
      deps === undefined || state.routeSummaries === undefined
        ? Promise.resolve([])
        : deps.lxScheduleService.findSchedules(
            state.routeSummaries[0].origin,
            state.routeSummaries[0].destination,
            state.routeSummaries[0].departureDate ?? new Date(),
            state.routeSummaries[0].returnDate ?? state.routeSummaries[0].departureDate ?? new Date()
          ),
    [deps]
  );

  const filterLegs = useCallback((legs: ScheduleEntry[] | undefined, isReturn: boolean) => legs?.filter((leg) => leg.isReturn === isReturn) ?? [], []);

  const outboundLegs = useMemo(() => filterLegs(scheduleEntries.res ?? [], false), [scheduleEntries.res, filterLegs]);
  const returnLegs = useMemo(() => filterLegs(scheduleEntries.res ?? [], true), [scheduleEntries.res, filterLegs]);

  const { control, formState, setValue, getValues, trigger, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const save = useRef({ outboundLegs, returnLegs, state, handleSubmit, setState });

  useEffect(() => {
    save.current = { outboundLegs, returnLegs, state, handleSubmit, setState };
  }, [outboundLegs, returnLegs, state, handleSubmit, setState]);

  const dynamicIndex = useCallback(
    (legs: ScheduleEntry[], type: 'outboundRoute' | 'returnRoute') => {
      const data = getValues();
      return legs.findIndex((leg) => shortenDate(leg.sailingDateTime) === data['selectedDate'][type]) + parseInt(data[type]);
    },
    [getValues]
  );

  const outboundRoute = watch('outboundRoute');
  const returnRoute = watch('returnRoute');
  const selectedDate = watch(['selectedDate.outboundRoute', 'selectedDate.returnRoute']);

  useEffect(() => {
    const { outboundLegs, returnLegs, state, setState } = save.current;

    const outboundLeg = outboundLegs[dynamicIndex(outboundLegs, 'outboundRoute')];
    const returnLeg = returnLegs[dynamicIndex(returnLegs, 'returnRoute')];

    const totalPrice = (outboundLeg?.price ?? 0) + (returnLeg?.price ?? 0);
    if (state.totalPrice !== totalPrice) {
      setState({ ...state, totalPrice });
    }
  }, [outboundRoute, returnRoute, dynamicIndex, selectedDate]);

  const { setRoutes } = useContext(RouteSummaryContext);

  useEffect(() => {
    return () => {
      const { outboundLegs, returnLegs, state, handleSubmit, setState } = save.current;

      handleSubmit(() => {
        if (outboundLegs) {
          const outboundLeg = outboundLegs[dynamicIndex(outboundLegs, 'outboundRoute')];
          const returnLeg = returnLegs[dynamicIndex(returnLegs, 'returnRoute')];
          setState({
            ...state,
            routes: uniqBy(
              [...(state.routes?.length ? state.routes : []), outboundLeg, ...(!isEmpty(returnLeg) ? [returnLeg] : [])],
              (leg) => `${leg.origin.code} - ${leg.destination.code}`
            )
          });
          setRoutes([
            {
              origin: outboundLeg.origin,
              destination: outboundLeg.destination,
              departureDate: outboundLeg.sailingDateTime,
              returnDate: returnLeg?.sailingDateTime
            }
          ]);
        }
      })();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trigger('outboundRoute');
    setIsStageValid(formState.isValid);
  }, [formState, setIsStageValid, trigger]);

  return (
    <Grid container rowGap={4} sx={{ flexDirection: 'column' }}>
      <Paper>
        <ScheduleCard
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          routeType={'outboundRoute'}
          legs={outboundLegs}
          initialRoute={filterLegs(state.routes, false)?.[0]}
        />
      </Paper>
      {isReturnJourney && (
        <Paper>
          <ScheduleCard
            control={control}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            routeType={'returnRoute'}
            legs={returnLegs}
            initialRoute={filterLegs(state.routes, true)?.[0]}
          />
        </Paper>
      )}
    </Grid>
  );
};

export default ScheduleStage;
