export const isEmailValid = (email: string) => {
  if (email === '') {
    return true;
  }
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const isPhoneNumberValid = (phoneNumber: string) => {
  if (phoneNumber === '') {
    return true;
  }
  if (phoneNumber.length < 5 || phoneNumber.length > 13) {
    return false;
  }
  const phoneNumberPattern = /^[\d+]+$/;
  return phoneNumberPattern.test(phoneNumber);
};
