import { FC, useState, useEffect } from 'react';
import { Divider, Checkbox, FormControlLabel } from '@mui/material';
import VehicleInformationForm from './VehicleInformationForm';
import PrefillHeader from '../PrefillHeader';
import { emptyVehicleState } from '../../utils/EmptyFreightBookingStates';
import { VehicleState } from '../../types/BookingState';
import { isEqual } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

const styles = {
  root: {
    padding: 24
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 12
  },
  header: {
    color: '#4a9bbb',
    fontSize: 14,
    fontWeight: 500
  },
  divider: {
    marginBottom: 12,
    marginTop: 12,
    marginLeft: -24,
    textAlign: 'left' as 'left'
  }
};

interface VehicleStageProps {
  isReturnJourney: boolean;
  state: VehicleState;
  setState: (newState: VehicleState) => void;
  isStageValid: boolean;
  setIsStageValid: (isValid: boolean) => void;
}

const VehicleStage: FC<VehicleStageProps> = ({ isReturnJourney, state, setState, isStageValid, setIsStageValid }) => {
  const [isReturnVehicleSame, setIsReturnVehicleSame] = useState(true);
  const intl = useIntl();

  const [outboundVehicle, setOutboundVehicle] = useState(emptyVehicleState.outboundVehicle);
  const [returnVehicle, setReturnVehicle] = useState(isReturnJourney ? emptyVehicleState.outboundVehicle : undefined);

  // componentMount
  useEffect(() => {
    if (isReturnJourney) {
      setState({ ...state, returnVehicle: returnVehicle ?? emptyVehicleState.outboundVehicle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(state.outboundVehicle, outboundVehicle)) {
      setState({ ...state, outboundVehicle });
    }
    if (isReturnJourney) {
      if (isReturnVehicleSame) {
        if (!isEqual(state.returnVehicle, outboundVehicle)) {
          setState({ ...state, returnVehicle: outboundVehicle });
        }
      } else {
        if (!isEqual(state.returnVehicle, returnVehicle)) {
          setState({ ...state, returnVehicle });
        }
      }
    }
  }, [state, setState, returnVehicle, outboundVehicle, isReturnJourney, isReturnVehicleSame]);

  const [isOutboundValid, setIsOutboundValid] = useState(true);
  const [isReturnValid, setIsReturnValid] = useState(true);

  useEffect(() => {
    if ((isOutboundValid && isReturnValid) !== isStageValid) {
      setIsStageValid(isOutboundValid && isReturnValid);
    }
  }, [isOutboundValid, isReturnValid, isStageValid, setIsStageValid]);

  useEffect(() => {
    if (isReturnVehicleSame && !isReturnValid) {
      setIsReturnValid(true);
    }
  }, [isReturnVehicleSame, isReturnValid]);

  return (
    <div style={styles.root}>
      <div style={styles.checkboxContainer}>
        <span style={styles.header}>
          <FormattedMessage id="booking.vehicle.outbound" defaultMessage="OUTBOUND VEHICLE" />
        </span>
      </div>
      <PrefillHeader />
      <VehicleInformationForm state={state.outboundVehicle} setState={setOutboundVehicle} setIsValid={setIsOutboundValid} />
      {isReturnJourney && (
        <>
          <Divider style={styles.divider} variant="fullWidth" />
          <div style={styles.checkboxContainer}>
            <span style={styles.header}>
              <FormattedMessage id="booking.vehicle.inbound" defaultMessage="INBOUND VEHICLE" />
            </span>
          </div>
          <FormControlLabel
            style={styles.checkboxContainer}
            label={intl.formatMessage({ id: 'booking.vehicle.sameReturn', defaultMessage: 'Same as outbound vehicle' })}
            control={
              <Checkbox
                checked={isReturnVehicleSame}
                onChange={() => {
                  setIsReturnVehicleSame(!isReturnVehicleSame);
                }}
              />
            }
          />
          {!isReturnVehicleSame && returnVehicle && <VehicleInformationForm state={returnVehicle} setState={setReturnVehicle} setIsValid={setIsReturnValid} />}
        </>
      )}
    </div>
  );
};

export default VehicleStage;
