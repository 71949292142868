import { FC } from 'react';

import {} from '@mui/material';

import './PrefillHeader.css';
import { FormattedMessage } from 'react-intl';

const styles = {
  hintHeader: {
    backgroundColor: '#ffefd2',
    color: '#c1874e',
    marginTop: 8,
    marginBottom: 24,
    padding: 12,
    paddingLeft: 20,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left' as 'left',
    width: '95%',
    borderRadius: 3
  }
};

const PrefillHeader: FC = () => {
  return (
    <div>
      <div style={styles.hintHeader}>
        <FormattedMessage id="booking.prefill.hint.first" defaultMessage="Have you booked here before? Click" />
        &nbsp;
        <span
          className="prefill"
          onClick={() => {
            alert('Not yet implemented');
          }}
        >
          <FormattedMessage id="booking.prefill.hint.second" defaultMessage="here" />
        </span>
        &nbsp;
        <FormattedMessage id="booking.prefill.hint.third" defaultMessage="to register with your previous details" />
      </div>
    </div>
  );
};

export default PrefillHeader;
