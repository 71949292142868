import base64UrlDecode from './base64UrlDecode';

export default class Authenticator {
  token: string | null;
  isAuthenticated: boolean;
  decodedToken: Record<string, string> | null;

  constructor() {
    // is logged in sessionstorage
    this.token = window.sessionStorage.getItem('token');
    this.isAuthenticated = this.token !== null;
    this.decodedToken = null;

    this.parseToken();
  }

  parseToken() {
    if (this.token) {
      const tokenPayload = this.token.split('.')[1];
      try {
        this.decodedToken = JSON.parse(atob(tokenPayload));
      } catch (e) {
        // once in a million the token is base64urlencoded, test once more with decoding
        const urlDecodedToken = base64UrlDecode(tokenPayload);
        this.decodedToken = JSON.parse(atob(urlDecodedToken));
      }
    }
  }

  authenticate(token: string) {
    this.isAuthenticated = true;
    this.token = token;
    this.parseToken();
  }

  signout() {
    this.isAuthenticated = false;
    this.token = null;
  }
}
