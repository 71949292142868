import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { ElementType, FC, useContext, useEffect, useState } from 'react';
import ShopifyService, { GraphProduct } from '../../modules/shopify/ShopifyService';
import { ProductType } from '../../types/PosTypes';
import { PosContext, Trip } from './PosContext';
import QuantitySelector from '../QuantitySelector';
import { inject } from 'saft-react';

interface InjectedProps {
  shopifyService: ShopifyService;
}

interface Props {
  trip: Trip;
  showDate?: boolean;
}

const PosExtraSelector: FC<Props & InjectedProps> = (props) => {
  const { trip, showDate, shopifyService } = props;

  const { cartItems, updateCartItem } = useContext(PosContext);
  const theme = useTheme();

  const [extra, setExtra] = useState<GraphProduct | null>(null);

  useEffect(() => {
    const getExtras = async () => {
      const { ticket, date } = trip;
      const extras = await shopifyService.getProductsByFilter(moment(date), {
        origin: ticket.origin?.value,
        destination: ticket.destination?.value,
        productType: ProductType.TravelExtra
      });
      setExtra(extras.filter((extra) => !extra.node.title.includes('Family day'))[0]?.node);
    };
    getExtras();
  }, [trip, shopifyService]);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1, padding: 2, paddingBottom: 0, color: theme.palette.primary.main }}>
        <div>
          <FontAwesomeIcon icon={faSuitcase} />
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography fontWeight="bold" variant="h6">
            Extra{' '}
          </Typography>
          <Typography variant="body1">
            {showDate && moment(trip.date).format('DD MMM YYYY')} {trip.ticket.origin?.value} - {trip.ticket.destination?.value}
          </Typography>
        </Box>
      </Box>
      {extra?.variants.edges.map((edge) => {
        const variant = edge.node;
        const cartItem = cartItems.find((ct) => ct.variant.id === variant.id);
        return (
          <Box key={variant.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, borderBottom: '1px solid #ddd' }}>
            <div>
              <Typography fontWeight="bold" variant="subtitle1">
                {variant.title}
              </Typography>
              <Typography variant="body1">
                {variant.priceV2?.amount} {variant.priceV2?.currencyCode}
              </Typography>
            </div>
            <div>
              <QuantitySelector
                quantity={cartItem?.quantity ?? 0}
                setQuantity={(newValue) => {
                  updateCartItem({
                    variant: variant,
                    quantity: newValue,
                    productType: extra.productType as ProductType
                  });
                }}
                isAddDisabled={false}
                isRemoveDisabled={(cartItem?.quantity ?? 0) === 0}
                size={6}
              />
            </div>
          </Box>
        );
      })}
    </div>
  );
};

export default inject('shopifyService')(PosExtraSelector as FC<{}>) as ElementType<Props>;
