import { FC, useState, useMemo, useCallback, useRef, useContext } from 'react';
import { Grid, Typography, TextField, InputAdornment, Divider, Container, Autocomplete, useTheme, Button, Menu, MenuItem, Checkbox, Box, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BookingCard from './BookingCard';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInjects } from 'saft-react';
import BookingsService, { Booking } from '../../../modules/bookings/BookingsService';
import useAsync from '../../../useAsync';
import { pick, isEqual, uniqBy } from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { v4 as uuid } from 'uuid';
import { ViewportContext } from '../../../context/ViewportContext';

const Bookings: FC = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [duplicatedBookings, setDuplicatedBookings] = useState<Booking[]>([]);
  const deps = useInjects<{ lxBookingsService: BookingsService }>({ lxBookingsService: undefined });
  const bookings = useAsync<Booking[]>(() => (deps === undefined ? Promise.resolve([]) : deps.lxBookingsService.getAllBookings()), [deps]);
  const bottomElement = useRef<HTMLElement>(null);
  const availableBookings = useMemo(() => [...(bookings?.res ?? []), ...duplicatedBookings], [bookings?.res, duplicatedBookings]);

  const [filters, setFilters] = useState<Partial<Booking>[]>([]);

  const filteredBookings = useMemo(
    () => availableBookings.filter((booking) => !filters.length || filters.some((filter) => isEqual(pick(booking, Object.keys(filter)), filter))),
    [availableBookings, filters]
  );

  const [checkedBoxes, setCheckedBoxes] = useState<boolean[]>([]);

  const addFilter = useCallback(
    (booking: Partial<Booking>) => {
      setFilters([...filters, booking]);
    },
    [filters, setFilters]
  );

  const removeFilter = useCallback(
    (booking: Partial<Booking>) => {
      setFilters(filters.filter((filter) => !isEqual(filter, booking)));
    },
    [filters, setFilters]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateBooking = useCallback(
    async (index: number) => {
      const savedBooking = await (filteredBookings &&
        deps?.lxBookingsService?.save({ ...filteredBookings[index], bookingReference: uuid().split('-')[0].toUpperCase() }));
      savedBooking && setDuplicatedBookings([...duplicatedBookings, savedBooking]);
      bottomElement?.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [filteredBookings, deps?.lxBookingsService, duplicatedBookings]
  );

  const { matchesBreakpoint } = useContext(ViewportContext);

  return (
    <Container maxWidth="xl" sx={{ marginTop: matchesBreakpoint('sm') ? 6 : 11 }}>
      <Grid item container xs={12} spacing={4} alignItems="center" sx={{ marginTop: 0 }}>
        <Grid item xs={12} md="auto" sx={{ textAlign: 'left' }}>
          <Typography variant="h4" color="white">
            <FormattedMessage id="portal.tab.bookings" defaultMessage="Your bookings" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ '& .MuiTextInput-root': { border: '1px solid #EDEDED', borderRadius: 1 } }}>
          <Autocomplete
            disablePortal
            onChange={(event, value) =>
              value
                ? addFilter({ bookingReference: value.bookingReference })
                : removeFilter(filters.find((filter) => filter.hasOwnProperty('bookingReference')) ?? {})
            }
            getOptionLabel={(option) => option.bookingReference}
            options={availableBookings ?? []}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                hiddenLabel
                placeholder={formatMessage({ id: 'portal.bookings.bookingReference', defaultMessage: 'Booking Reference' })}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fill: '#BCBCBC' }} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            fullWidth
            sx={{ padding: 2, justifyContent: 'space-between' }}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
          >
            Status
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
              sx: { paddingRight: 8 }
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {uniqBy(
              availableBookings.filter((booking) => !!booking.status),
              'status'
            ).map((booking, i) => (
              <MenuItem sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }} disableRipple>
                <Checkbox
                  key={i}
                  checked={checkedBoxes[i]}
                  onChange={(event) => {
                    let clone = [...checkedBoxes];
                    clone[i] = event.target.checked;
                    setCheckedBoxes(clone);
                    event.target.checked ? addFilter({ status: booking.status }) : removeFilter({ status: booking.status });
                  }}
                />
                {booking.status}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" sx={{ backgroundColor: 'white', margin: theme.spacing(2.5, 0, 2.5, 0) }} />
      <Grid item container rowGap={2} paddingBottom={8}>
        {filteredBookings?.map((booking, index) => (
          <BookingCard {...booking} onDuplicate={() => duplicateBooking(index)} />
        ))}
        <Box component="span" ref={bottomElement} />
      </Grid>
    </Container>
  );
};

export default Bookings;
