import { LocationSummary } from '../routing/Routes';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Customer } from '../../types/Customer';
import ShopifyService from '../shopify/ShopifyService';
import { Extra, SailingLeg, Ticket } from '../sailing-products/SailingProductsSource';
import { ReturnPrice } from '../../components/travel/TravelBookingContext';

type VesselSummary = {
  name: string;
};

type Product = {
  code: string;
  name: string;
};

type InventorySummary = {
  // [key: string]: InventoryEntry
};

type InventoryEntry = {
  product: Product;
  capacity: number;
  availability: number;
  reserved: number;
};

enum VessselType {
  RORO,
  ROPAX,
  HSC,
  CRUISE,
  LOLO,
  TAXI,
  BULK
}

const vessels = [
  { name: 'Pride of Kalahari', type: 'RORO' },
  { name: 'Desert Rose', type: 'ROPAX' },
  { name: 'Desert Oasis', type: 'ROPAX' },
  { name: 'Safari Express', type: 'HSC' },
  { name: 'Jungle Express', type: 'HSC' },
  { name: 'Savannah Express', type: 'HSC' }
];

export enum TicketType {
  Regular = 'Regular ticket',
  OffPeak = 'Off-peak Ticket'
}

export type ScheduleEntry = {
  id: string;
  origin: LocationSummary;
  destination: LocationSummary;
  sailingDateTime: Date;
  arrivalDateTime: Date;
  vessel?: VesselSummary;
  inventory: InventorySummary;
  price?: number;
  currencyCode?: string;
  ticketType: TicketType;
  isReturn: boolean;
  isBookable: boolean;
  operationName?: string;
  duration?: number;
  isDirect?: boolean;
  tickets?: Ticket[];
  extras?: Extra[];
  sailingLegs?: SailingLeg[];
  returnPrice?: ReturnPrice | null;
};

export default class Schedules {
  constructor(private customer: Customer, private shopifyService: ShopifyService) {}

  findSchedules(origin: LocationSummary, destination: LocationSummary, fromDate: Date, toDate: Date): Promise<ScheduleEntry[]> {
    if (this.customer === Customer.SplitExpress) {
      return Promise.resolve([]);
    }

    return Promise.resolve(
      [-1, 0, 1].flatMap((day) => {
        const startDate = new Date();
        startDate.setDate(fromDate.getDate() + day);

        const endDate = moment(startDate)
          .add(Math.floor(Math.random() * 13 + 2), 'hours')
          .add(Math.floor(Math.random() * 60), 'minutes')
          .toDate();

        const duration = moment(endDate).diff(moment(startDate));
        return [
          {
            id: uuid(),
            origin,
            destination,
            sailingDateTime: startDate,
            arrivalDateTime: endDate,
            inventory: {},
            price: 1650,
            ticketType: TicketType.Regular,
            isReturn: false,
            isBookable: true,
            duration,
            isDirect: true
          },
          {
            id: uuid(),
            origin,
            destination,
            sailingDateTime: startDate,
            arrivalDateTime: endDate,
            inventory: {},
            price: 1600,
            ticketType: TicketType.Regular,
            isReturn: false,
            isBookable: false,
            duration,
            isDirect: true
          },
          {
            id: uuid(),
            origin: destination,
            destination: origin,
            sailingDateTime: startDate,
            arrivalDateTime: endDate,
            inventory: {},
            price: 1700,
            ticketType: TicketType.Regular,
            isReturn: true,
            isBookable: true,
            duration,
            isDirect: true
          }
        ];
      })
    );
  }
}
