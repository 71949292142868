import { FC, useEffect, useMemo } from 'react';
import { Grid, Paper, Typography, TextField, Divider } from '@mui/material';
import { capitalize } from 'lodash';
import { Control, Controller, FormState, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormTypes } from './TravelPassengerDetails';

interface TravelPassengerCardProps {
  passenger: { type: string; number: number };
  index: number;
  control: Control<FormTypes, object>;
  formState: FormState<FormTypes>;
  getValues: UseFormGetValues<FormTypes>;
  setValue: UseFormSetValue<FormTypes>;
}

const TravelPassengerCard: FC<TravelPassengerCardProps> = ({ passenger, index, control, formState, setValue }) => {
  const { errors } = formState;

  const isPassengerAdult = useMemo(() => {
    return passenger.type === 'adult';
  }, [passenger.type]);

  const isPassengerChild = useMemo(() => {
    return passenger.type === 'child';
  }, [passenger.type]);

  const isFirstPassenger = useMemo(() => index === 0, [index]);

  const titleExtra = useMemo(
    () =>
      isFirstPassenger ? (
        <FormattedMessage id="booking.passengerInfo.mandatory" defaultMessage="Mandatory" />
      ) : (
        <FormattedMessage id="booking.passengerInfo.optional" defaultMessage="Optional" />
      ),
    [isFirstPassenger]
  );

  useEffect(() => {
    setValue(`passengers.${index}.type`, passenger.type);
  }, [index, passenger.type, setValue]);

  return (
    <Grid item xs={12} key={`${passenger.type}${passenger.number}`}>
      <Grid container item spacing={2} xs={12} sx={{ width: '100%', margin: 1 }}>
        <Paper sx={{ boxShadow: '0px 3px 12px rgba(172,172,172,0.1)', borderRadius: 1, width: '100%' }}>
          <Typography sx={{ padding: 2, textAlign: 'left' }} variant="h6">
            {`${capitalize(passenger.type)} ${passenger.number + 1} `}({titleExtra})
          </Typography>
          <Divider />
          <Grid container item spacing={4} xs={12} lg={10} sx={{ padding: 4 }}>
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  key="firstName"
                  control={control}
                  name={`passengers.${index}.firstName`}
                  rules={{ required: isFirstPassenger }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus={index === 0}
                      label={<FormattedMessage id="booking.passengerInfo.firstName" defaultMessage="First name" />}
                      required={isFirstPassenger}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  key="lastName"
                  control={control}
                  name={`passengers.${index}.lastName`}
                  rules={{ required: isFirstPassenger }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={<FormattedMessage id="booking.passengerInfo.lastName" defaultMessage="Last name" />}
                      required={isFirstPassenger}
                    />
                  )}
                />
              </Grid>
              {!isPassengerChild && (
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    key="email"
                    control={control}
                    name={`passengers.${index}.email`}
                    rules={{ required: isPassengerAdult && isFirstPassenger, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<FormattedMessage id="booking.passengerInfo.email" defaultMessage="Email" />}
                        required={isPassengerAdult && isFirstPassenger}
                        helperText={
                          errors?.passengers?.[index]?.email && (
                            <FormattedMessage id="booking.passengerInfo.emailError" defaultMessage="Must be a valid email" />
                          )
                        }
                        error={Boolean(errors?.passengers?.[index]?.email)}
                      />
                    )}
                  />
                </Grid>
              )}
              {!isPassengerChild && (
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    key="phoneNumber"
                    control={control}
                    name={`passengers.${index}.phoneNumber`}
                    rules={{ required: false, pattern: /^[+]?[0-9]+$/ }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<FormattedMessage id="booking.passengerInfo.phoneNumber" defaultMessage="Phone number" />}
                        error={Boolean(errors?.passengers?.[index]?.phoneNumber)}
                        helperText={
                          errors?.passengers?.[index]?.phoneNumber && (
                            <FormattedMessage id="booking.passengerInfo.phoneError" defaultMessage="Must be a valid phone number" />
                          )
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TravelPassengerCard;
