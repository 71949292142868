import { FC, useState, useContext } from 'react';
import { IconButton, Menu, MenuItem, SvgIcon, Box } from '@mui/material';
import icons from '../images/LocaleIcons';
import { LocaleContext } from '../context/LocaleContext';
import { FormattedMessage } from 'react-intl';

const localeIcons: Record<string, JSX.Element> = { ...icons };

export const LocaleSelector: FC = () => {
  const { setLocale, locale } = useContext(LocaleContext);

  const [selected, setSelected] = useState(localeIcons[locale || 'en']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLocaleChange = (locale: string) => {
    setSelected(localeIcons[locale]);
    setLocale(locale);
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SvgIcon>{selected}</SvgIcon>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={() => handleLocaleChange('en')}>
          <SvgIcon>{localeIcons['en']}</SvgIcon>
          <Box marginLeft={1}>
            <FormattedMessage id="localeSelector.english" defaultMessage="English" />
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleLocaleChange('hr')}>
          <SvgIcon>{localeIcons['hr']}</SvgIcon>
          <Box marginLeft={1}>
            <FormattedMessage id="localeSelector.croatian" defaultMessage="Croatian" />
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
};
