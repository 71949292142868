import { FC, useCallback, useContext, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Paper, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DetailRow from './DetailRow';
import { Inventory, Package, PackageInventoryType } from '../modules/sailing-products/SailingProductsSource';
import { SelectedExtra, TravelBookingContext, TravelBookingRoute } from './travel/TravelBookingContext';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { PassengerSummary } from './header-selectors/PasssengerSelector';
import { calculatePrice } from './travel/ScheduleHelper';
import { ViewportContext } from 'context/ViewportContext';

interface Props {
  passengerSummary: PassengerSummary;
  selectedExtras: Record<string, SelectedExtra>;
  routes: TravelBookingRoute[];
  selectedPackage?: Package;
}

const TravelBookingFooter: FC<Props> = (props) => {
  const { selectedExtras, routes, passengerSummary, selectedPackage } = props;
  const { travelBookingRoutes } = useContext(TravelBookingContext);
  const { matchesBreakpoint } = useContext(ViewportContext);

  const getExtraPrice = useCallback((inventory: Inventory, amount: number) => {
    if (!inventory) {
      return 'N/A';
    }
    const price = inventory.price?.amount;
    return price ? price * amount : 'N/A';
  }, []);

  const getExtraCurrencyCode = useCallback((inventory: Inventory) => {
    if (!inventory) {
      return 'N/A';
    }
    return inventory.price?.currencyCode ?? 'N/A';
  }, []);

  const getDepartureDate = useCallback((route: TravelBookingRoute) => {
    const date = route.selectedEntry?.sailingDateTime;
    if (!date) {
      return 'Invalid Date';
    }

    return moment(date).format('DD MMM YYYY');
  }, []);

  const getDepartureArrivalTime = useCallback((route: TravelBookingRoute) => {
    const dep = route?.selectedEntry?.sailingLegs?.[0]?.departureTime;
    const numOfLegs = route?.selectedEntry?.sailingLegs?.length ?? 0;
    const arr = route?.selectedEntry?.sailingLegs?.[numOfLegs - 1]?.arrivalTime;

    return `${dep} - ${arr}`;
  }, []);

  const passengerCount = useMemo(() => {
    return Object.values(passengerSummary).reduce((prev, curr) => prev + curr);
  }, [passengerSummary]);

  const singleTicketsTotalPrice = useMemo(
    () => Object.values(routes).reduce((acc, route) => acc + calculatePrice(route.selectedEntry?.tickets ?? [], passengerSummary).amount, 0),
    [routes, passengerSummary]
  );

  const isOneWayTicket = useMemo(() => travelBookingRoutes.length === 1, [travelBookingRoutes]);
  const currencyCode = useMemo(
    () => routes[0]?.selectedEntry?.currencyCode || selectedPackage?.inventory[PackageInventoryType.Ticket]?.price?.currencyCode,
    [routes, selectedPackage]
  );

  const routeWithReturnPrice = useMemo(() => Object.values(routes).find((route) => !!route.selectedEntry?.returnPrice), [routes]);

  const totalReturnPrice = useMemo(() => {
    return routeWithReturnPrice?.selectedEntry?.returnPrice
      ? calculatePrice([routeWithReturnPrice.selectedEntry.returnPrice.ticket], passengerSummary).amount
      : 0;
  }, [passengerSummary, routeWithReturnPrice]);

  const totalPrice = useMemo(() => {
    let routePrice;
    if (selectedPackage?.inventory.Ticket?.price?.amount) {
      routePrice = selectedPackage?.inventory.Ticket?.price?.amount;
    } else if (!isOneWayTicket && routeWithReturnPrice && singleTicketsTotalPrice > totalReturnPrice) {
      routePrice = totalReturnPrice;
    } else {
      routePrice = singleTicketsTotalPrice;
    }

    let extraPrice = 0;

    Object.entries(selectedExtras).forEach(([inventoryId, { inventory, quantity }]) => {
      const price = getExtraPrice(inventory, quantity);
      extraPrice += typeof price === 'number' ? price : 0;
    });

    return extraPrice + routePrice;
  }, [selectedExtras, getExtraPrice, selectedPackage, singleTicketsTotalPrice, totalReturnPrice, isOneWayTicket, routeWithReturnPrice]);

  const returnDiscountRow = useMemo(() => {
    const allReturnTicketsSelected = routes.every((route) => !!route.selectedEntry);
    if (!isOneWayTicket && routeWithReturnPrice && allReturnTicketsSelected && singleTicketsTotalPrice > totalReturnPrice) {
      const diff = singleTicketsTotalPrice - totalReturnPrice;
      return (
        <DetailRow
          key="return-discount"
          leftLabel={{ main: '', sub: '' }}
          middleLabel={{ main: 'Return ticket discount', sub: '' }}
          rightLabel={{
            main: `- ${diff} ${currencyCode}`,
            sub: ''
          }}
        />
      );
    }
    return null;
  }, [routes, singleTicketsTotalPrice, totalReturnPrice, currencyCode, isOneWayTicket, routeWithReturnPrice]);

  return (
    <Paper>
      <Accordion defaultExpanded={matchesBreakpoint('sm')} elevation={0}>
        <AccordionSummary sx={{ '&.MuiAccordionSummary-root': { minHeight: '48px', maxHeight: '48px' } }} expandIcon={<ExpandLessIcon />}>
          <Typography sx={{ color: '#3B9FC3', textDecoration: 'underline' }}>
            <FormattedMessage id="booking.footer.viewDetails" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container>
            {selectedPackage ? (
              <DetailRow
                key={selectedPackage.title}
                leftLabel={{ main: selectedPackage.date }}
                middleLabel={{
                  main: selectedPackage.title,
                  sub: `${selectedPackage.origin} - ${selectedPackage.destination}`
                }}
                rightLabel={{ main: `${selectedPackage.inventory.Ticket?.price?.amount} ${selectedPackage.inventory.Ticket?.price?.currencyCode}` }}
              />
            ) : (
              Object.values(routes).map(
                (route) =>
                  route.selectedEntry && (
                    <DetailRow
                      key={route.selectedEntry.id}
                      leftLabel={{ main: getDepartureDate(route) }}
                      middleLabel={{
                        main: `${route.selectedEntry.origin.name} - ${route.selectedEntry.destination.name}`,
                        sub: getDepartureArrivalTime(route)
                      }}
                      rightLabel={{
                        main: `${calculatePrice(route.selectedEntry?.tickets ?? [], passengerSummary).amount} ${route.selectedEntry.currencyCode}`
                      }}
                    />
                  )
              )
            )}
            {Object.entries(selectedExtras).map(
              ([inventoryId, { inventory, quantity, extra }]) =>
                quantity > 0 && (
                  <DetailRow
                    key={inventoryId}
                    middleLabel={{ main: `${inventory.title} x ${quantity}`, sub: `${extra?.origin} - ${extra?.destination}` }}
                    rightLabel={{ main: `${getExtraPrice(inventory, quantity)} ${getExtraCurrencyCode(inventory)}` }}
                  />
                )
            )}
            {returnDiscountRow}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider variant="middle" />
      <Grid container sx={{ padding: 2 }}>
        <Grid container item xs={6} sx={{ textAlign: 'left' }}>
          <Grid item xs={12} sm="auto" sx={{ marginRight: 1 }}>
            <Typography fontWeight="bold">
              <FormattedMessage id="booking.footer.totalPrice" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography>
              {passengerCount}{' '}
              <FormattedMessage
                id="booking.passenger.traveller"
                defaultMessage="{count, plural, one {Traveller} other {Travellers}}"
                values={{
                  count: passengerCount
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Typography fontWeight="bold">{`${totalPrice} ${currencyCode ?? ''}`}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TravelBookingFooter;
