import { FC, useContext, useState } from 'react';
import { Button, Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { BookingState } from '../../types/BookingState';
import { FormattedMessage } from 'react-intl';
import useTheme from '@mui/material/styles/useTheme';
import BookingFooterContent from './BookingFooterContent';
import { InfoHeader, InfoContainer } from './BookingFooterContent';
import { ViewportContext } from '../../context/ViewportContext';

interface BookingFooterProps {
  onContinue: () => void;
  bookingState: BookingState;
  showValidationWarning: boolean;
  setHeight: (newValue: number) => void;
  pageFooterHeight: number;
}

const BookingFooter: FC<BookingFooterProps> = ({ onContinue, bookingState, showValidationWarning, setHeight, pageFooterHeight }) => {
  const theme = useTheme();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const getTotalPrice = () => {
    let total = 0;
    for (const value of Object.values(bookingState)) {
      const stagePrice = value?.totalPrice;
      if (stagePrice) {
        total += stagePrice;
      }
    }
    return total;
  };

  const { matchesBreakpoint } = useContext(ViewportContext);

  return (
    <>
      {!matchesBreakpoint('sm') ? (
        <Grid
          container
          sx={{ backgroundColor: '#ddd', position: 'fixed', bottom: 0, zIndex: 1 }}
          ref={(el) => {
            if (!el) {
              return;
            }
            setHeight(el.getBoundingClientRect().height);
          }}
        >
          <Grid container item sx={{ display: 'flex', justifyContent: 'space-between', padding: 1, '& .MuiGrid-root': { justifyContent: 'center' } }}>
            <Grid container item xs={6}>
              <Button sx={{ color: theme.palette.primary.main }} onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
                <FormattedMessage id="booking.footer.viewDetails" defaultMessage="View Details" />
              </Button>
              <Dialog fullWidth maxWidth="xl" open={isDetailsOpen} onClose={() => setIsDetailsOpen(false)}>
                <DialogTitle>
                  <FormattedMessage id="booking.footer.title.details" defaultMessage="Trip Details" />
                </DialogTitle>
                <DialogContent>
                  <Grid container rowSpacing={2}>
                    <BookingFooterContent bookingState={bookingState} />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsDetailsOpen(false)} variant="text" sx={{ color: theme.palette.primary.main, margin: 2 }}>
                    <FormattedMessage id="booking.footer.close" defaultMessage="Close" />
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container item columnSpacing={1} xs={6} sx={{ alignItems: 'center' }}>
              <Grid item>
                <Typography>
                  <FormattedMessage id="booking.footer.title.total" defaultMessage="TOTAL" />:
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontSize={20} fontWeight="medium">
                  {`${getTotalPrice()} SEK`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button disabled={showValidationWarning} onClick={onContinue} sx={{ borderRadius: 0, padding: 2 }} fullWidth variant="contained">
              <FormattedMessage id="booking.footer.continue" defaultMessage="Continue" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            marginTop: 4,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: pageFooterHeight
          }}
          ref={(el) => {
            if (!el) {
              return;
            }
            setHeight(el.getBoundingClientRect().height);
          }}
        >
          <Grid container item xs={8} columnSpacing={4}>
            <BookingFooterContent bookingState={bookingState} />
          </Grid>
          <Grid container item xs={4}>
            <InfoContainer container item xs={5}>
              <InfoHeader>
                <FormattedMessage id="booking.footer.title.total" defaultMessage="TOTAL" />
              </InfoHeader>
              <Typography fontSize={24} fontWeight="bold">{`${getTotalPrice()} SEK`}</Typography>
            </InfoContainer>
            <Grid container item xs={7} sx={{ color: '#CF0000DE', display: 'flex', flexDirection: 'column' }}>
              <Button onClick={onContinue} sx={{ padding: 2, margin: 2 }} variant="contained" disabled={showValidationWarning}>
                <FormattedMessage id="booking.footer.continue" defaultMessage="Continue" />
              </Button>
              {showValidationWarning && (
                <Grid item>
                  <FormattedMessage id="booking.footer.requiredText" defaultMessage="Please fill in all required fields" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BookingFooter;
