import { FC, useContext, useState, useMemo } from 'react';
import { Button, Grid, Paper, Typography, useTheme, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import LegCard from '../../schedule-stage/LegCard';
import LegsHeader from '../../schedule-stage/LegsHeader';
import { BookingTitle } from './BookingTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Booking } from '../../../modules/bookings/BookingsService';
import { ScheduleEntry } from '../../../modules/scheduling/Schedules';
import { shortenDate } from '../../../utils/DateUtils';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ViewportContext } from '../../../context/ViewportContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface BookingCardProps {
  onDuplicate: () => void;
}

const BookingCard: FC<BookingCardProps & Booking> = ({ bookingReference, licensePlate, bookingDate, routes, status, onDuplicate }) => {
  const { formatMessage } = useIntl();
  const [uploadBtnLabel, setUploadBtnLabel] = useState(
    formatMessage({ id: 'portal.bookings.uploadText', defaultMessage: 'Drag and drop file here to upload' })
  );

  const theme = useTheme();
  const { matchesBreakpoint } = useContext(ViewportContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid item container sx={{ alignItems: 'center', textAlign: 'left', flexWrap: 'nowrap' }}>
        <Grid item container columnSpacing={4} sx={{ paddingLeft: 1 }}>
          <BookingTitle subtitle={formatMessage({ id: 'portal.bookings.bookingRef', defaultMessage: 'Booking Ref' })} title={bookingReference} />
          <BookingTitle subtitle={formatMessage({ id: 'portal.bookings.licensePlate', defaultMessage: 'License plate' })} title={licensePlate} />
          <BookingTitle
            subtitle={formatMessage({ id: 'portal.bookings.bookingDate', defaultMessage: 'Booking date' })}
            title={new Date(bookingDate).toLocaleDateString('en')}
          />
        </Grid>
        {matchesBreakpoint('md') ? (
          <Grid
            item
            container
            xs="auto"
            columnGap={2}
            sx={{
              alignSelf: 'flex-start',
              margin: 2,
              '& .MuiButton-root': { flex: '1 1 0', padding: theme.spacing(1, 3) },
              flexWrap: 'wrap'
            }}
          >
            <Button variant="contained" startIcon={<ContentCopyIcon />} onClick={onDuplicate}>
              <FormattedMessage id="portal.bookings.duplicate" defaultMessage="Duplicate" />
            </Button>
            <Button variant="contained" startIcon={<PrintIcon />}>
              <FormattedMessage id="portal.bookings.print" defaultMessage="Print" />
            </Button>
            <Button variant="contained" startIcon={<DownloadIcon />}>
              <FormattedMessage id="portal.bookings.download" defaultMessage="Download" />
            </Button>
          </Grid>
        ) : (
          <>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} sx={{ margin: 2, alignSelf: 'flex-start' }}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              open={isMenuOpen}
              anchorEl={anchorEl}
              onClick={handleClose}
              onClose={handleClose}
              sx={{ '& .MuiMenuItem-root': { justifyContent: 'space-between', '& .MuiListItemIcon-root': { justifyContent: 'flex-end' } } }}
            >
              <MenuItem>
                Duplicate
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem>
                Print
                <ListItemIcon>
                  <PrintIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem>
                Download
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </>
        )}
      </Grid>
      <Grid item>
        <LegsHeader
          leftLabel={formatMessage({ id: 'booking.schedule.label.outbound', defaultMessage: 'OUTBOUND ROUTE' })}
          rightLabel={matchesBreakpoint('sm') ? '' : shortenDate(new Date(routes?.[0]?.sailingDateTime))}
        />
      </Grid>
      <Grid item container wrap="nowrap">
        {matchesBreakpoint('sm') && (
          <Grid item sx={{ backgroundColor: '#EDEDED', padding: 2.5, borderBottomLeftRadius: theme.spacing(0.5) }}>
            <Typography variant="subtitle1" fontWeight="medium" noWrap>
              {shortenDate(new Date(routes?.[0]?.sailingDateTime))}
            </Typography>
          </Grid>
        )}

        <Grid item container flexDirection="column">
          <Grid item container sx={{ justifyContent: 'space-between', flexWrap: 'nowrap', padding: 2, paddingLeft: 0, alignItems: 'center' }}>
            <Grid item xs={8}>
              <LegCard origin={routes?.[0]?.origin} destination={routes?.[0]?.destination} scheduleEntry={routes?.[0]} />
            </Grid>
            {status?.toLowerCase() === 'check-in' && (
              <Grid container xs={4} sx={{ justifyContent: 'center', '& .MuiGrid-root': { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" sx={{ color: '#0047B1DE', fontWeight: 'bold' }}>
                    <FormattedMessage id="portal.bookings.checkInAvailable" defaultMessage="CHECK-IN AVAILABLE" />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="outlined" sx={{ color: '#00A2E1', whiteSpace: 'nowrap', padding: theme.spacing(1, 4, 1, 4) }}>
                    <FormattedMessage id="portal.bookings.checkIn" defaultMessage="CHECK-IN" />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ backgroundColor: '#FFEFD2', textAlign: 'left', padding: 2.5, margin: theme.spacing(0, 2.5, 2.5, 2.5), flexDirection: 'column' }}>
            <Typography variant="subtitle1" sx={{ color: '#9C4900', fontWeight: 'medium' }}>
              <FormattedMessage id="portal.bookings.documents" defaultMessage="Documents required" />
            </Typography>
            <Grid
              item
              sx={{
                marginLeft: 7,
                '& .MuiButton-root': {
                  border: '2px solid #707070',
                  borderStyle: 'dashed',
                  backgroundColor: 'rgba(145, 145, 145, 0.3)',
                  color: '#434343',
                  width: '100%',
                  textTransform: 'none',
                  borderRadius: 0
                }
              }}
            >
              <Typography variant="subtitle1" fontWeight="medium">
                <FormattedMessage id="portal.bookings.documentsTitle" defaultMessage="Dangerous Goods document" />
              </Typography>
              <label htmlFor="dangerous-goods-upload" style={{ position: 'relative' }}>
                <input
                  type="file"
                  id="dangerous-goods-upload"
                  onChange={(e) => {
                    const fileName = e.target.webkitEntries[0].name;
                    fileName && setUploadBtnLabel(fileName);
                  }}
                  style={{ position: 'absolute', opacity: 0, zIndex: 100, backgroundColor: 'black', width: '100%', height: '200%' }}
                />
                <Button variant="contained" component="span">
                  {uploadBtnLabel}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {((returnRoute: ScheduleEntry | undefined) =>
        returnRoute && (
          <>
            <Grid item>
              <LegsHeader
                leftLabel={formatMessage({ id: 'booking.schedule.label.return', defaultMessage: 'RETURN ROUTE' })}
                rightLabel={matchesBreakpoint('sm') ? '' : shortenDate(new Date(returnRoute.sailingDateTime))}
              />
            </Grid>
            <Grid item container wrap="nowrap">
              {matchesBreakpoint('sm') && (
                <Grid item sx={{ backgroundColor: '#EDEDED', padding: 2.5, borderBottomLeftRadius: theme.spacing(0.5) }}>
                  <Typography variant="subtitle1" fontWeight="medium" noWrap>
                    {shortenDate(new Date(returnRoute.sailingDateTime))}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={8} padding={2.5}>
                <LegCard origin={returnRoute.origin} destination={returnRoute.destination} scheduleEntry={returnRoute} />
              </Grid>
            </Grid>
          </>
        ))(routes?.find((leg) => leg.isReturn))}
    </Paper>
  );
};

export default BookingCard;
