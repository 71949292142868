import { FC, useContext, useState } from 'react';
import { Tab, Tabs, Theme, styled, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Bookings from './bookings/Bookings';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ViewportContext } from '../../context/ViewportContext';

const StyledTab = styled(Tab)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(0, 3),
  width: '100%',
  alignItems: 'flex-start'
}));

interface PortalProps {
  footerHeight: number;
}

const Portal: FC<PortalProps> = ({ footerHeight }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('your-bookings');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setIsDrawerOpen(false);
    setCurrentTab(newValue);
    navigate(newValue);
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  const { matchesBreakpoint } = useContext(ViewportContext);

  return (
    <div style={{ display: 'flex', marginBottom: footerHeight }}>
      <IconButton
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        sx={{
          color: 'white',
          position: 'fixed',
          left: 5,
          top: 5,
          zIndex: 1200
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={isDrawerOpen} onClick={handleClose} onClose={handleClose} hideBackdrop>
        <Tabs
          orientation="vertical"
          onChange={handleChange}
          sx={{
            backgroundColor: '#F5F5F5',
            minWidth: '220px',
            position: 'fixed',
            top: matchesBreakpoint('sm') ? 48 : 92,
            bottom: 0
          }}
          value={currentTab}
          TabIndicatorProps={{ style: { opacity: 0 } }}
        >
          <StyledTab value="profile" label={formatMessage({ id: 'portal.tab.profile', defaultMessage: 'Profile' })} />
          <StyledTab value="your-bookings" label={formatMessage({ id: 'portal.tab.bookings', defaultMessage: 'Your bookings' })} />
          <StyledTab value="saved-information" label={formatMessage({ id: 'portal.tab.saved', defaultMessage: 'Saved information' })} />
          <StyledTab value="invoices" label={formatMessage({ id: 'portal.tab.invoices', defaultMessage: 'Invoices' })} />
          <StyledTab value="terminal-visit" label={formatMessage({ id: 'portal.tab.terminal', defaultMessage: 'Terminal visit' })} />
        </Tabs>
      </Drawer>
      <Routes>
        <Route path="profile" element={<h1>example</h1>} />
        <Route path="your-bookings" element={<Bookings />} />
        <Route path="saved-information" />
        <Route path="invoices" />
        <Route path="terminal-visit" />
      </Routes>
    </div>
  );
};

export default Portal;
