import { createContext, useState, ReactNode } from 'react';

export const LoadingContext = createContext({ loading: false, setLoading: (loading: boolean) => {} });

const LoadingContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingContextProvider;
