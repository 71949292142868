import { FC, useContext, useMemo } from 'react';
import { Typography, Grid, styled, Theme } from '@mui/material';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { BookingState } from '../../types/BookingState';
import { shortenDate } from '../../utils/DateUtils';
import { VehicleTypeContext } from '../../context/VehicleTypeContext';
import passengerTypes from '../../config/PassengerTypes';
import { FormattedMessage } from 'react-intl';
import { flatten } from 'lodash';

export const InfoHeader = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium
}));

export const InfoContent = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightMedium
}));

export const InfoContainer = styled(Grid)(() => ({
  textAlign: 'left' as 'left',
  display: 'flex',
  flexDirection: 'column' as 'column'
}));

interface BookingFooterContentProps {
  bookingState: BookingState;
}

const BookingFooterContent: FC<BookingFooterContentProps> = ({ bookingState }) => {
  const passengerSummary: PassengerSummary | undefined = useMemo(() => {
    return bookingState.passengerState?.passengerSummary;
  }, [bookingState]);

  const routeSummaries = useMemo(() => {
    return bookingState.scheduleState.routeSummaries;
  }, [bookingState]);

  const vehicleType = useContext(VehicleTypeContext).vehicleType;

  return (
    <>
      <InfoContainer container item xs={12} sm="auto">
        <InfoHeader>
          <FormattedMessage id="booking.footer.title.trip" defaultMessage="TRIP" />
        </InfoHeader>
        {bookingState.scheduleState.routes?.map((leg) => <InfoContent key={leg.id}>{`${leg.origin.code} - ${leg.destination.code}`}</InfoContent>) ||
          routeSummaries?.map((route) => <InfoContent>{`${route.origin.code} - ${route.destination.code}`}</InfoContent>)}
      </InfoContainer>
      {bookingState.scheduleState.routes?.[0]?.sailingDateTime && (
        <InfoContainer container item xs={12} sm="auto">
          <InfoHeader>
            <FormattedMessage id="booking.footer.title.date" defaultMessage="DATE" />
          </InfoHeader>

          {bookingState.scheduleState.routes?.map((leg) => (
            <InfoContent key={leg.id}>{`${shortenDate(leg.sailingDateTime)}`}</InfoContent>
          ))}
        </InfoContainer>
      )}
      {passengerSummary && (
        <InfoContainer container item xs={12} sm="auto">
          <InfoHeader>
            <FormattedMessage id="booking.footer.title.travellers" defaultMessage="TRAVELLERS" />
          </InfoHeader>
          {Object.entries(passengerTypes).map(([type]) => {
            const typeCount = passengerSummary[type];
            return (
              typeCount > 0 && (
                <InfoContent key={type}>
                  {`${typeCount} `}
                  <FormattedMessage values={{ count: typeCount }} id={`booking.passenger.${type}`} defaultMessage={type} />
                </InfoContent>
              )
            );
          })}
        </InfoContainer>
      )}
      {bookingState.vehicleState && (
        <InfoContainer container item xs={12} sm="auto">
          <InfoHeader>
            <FormattedMessage id="booking.footer.title.vehicle" defaultMessage="VEHICLE" />
          </InfoHeader>
          <InfoContent>
            {vehicleType}
            {bookingState.vehicleState.outboundVehicle.hazardous && '🔶'}
          </InfoContent>
        </InfoContainer>
      )}
      {bookingState.cabinState && (
        <>
          {flatten(Object.values(bookingState.cabinState.selectedAccommodations)).length > 0 && (
            <InfoContainer container item xs={12} sm="auto">
              <InfoHeader>
                <FormattedMessage id="booking.footer.title.cabins" defaultMessage="CABINS" />
              </InfoHeader>
              {flatten(Object.values(bookingState.cabinState.selectedAccommodations)).map(
                (el) => el.quantity > 0 && <InfoContent key={el.name}>{`${el.quantity} x ${el.name}`}</InfoContent>
              )}
            </InfoContainer>
          )}
        </>
      )}
      {bookingState.extraState.selectedExtras.length > 0 && (
        <InfoContainer container item xs={12} sm="auto">
          <InfoHeader>
            <FormattedMessage id="booking.footer.title.extra" defaultMessage="EXTRA" />
          </InfoHeader>
          {bookingState.extraState.selectedExtras.map((el) => (
            <InfoContent key={el.name}>{el.name}</InfoContent>
          ))}
        </InfoContainer>
      )}
    </>
  );
};

export default BookingFooterContent;
