import { FC } from 'react';
import { Button, ButtonGroup, Box, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

interface QuantitySelectorProps {
  quantity: number;
  setQuantity: (newValue: number) => void;
  isRemoveDisabled: boolean;
  isAddDisabled: boolean;
  size: number;
}

const QuantitySelector: FC<QuantitySelectorProps> = ({ quantity, setQuantity, isRemoveDisabled, isAddDisabled, size }) => {
  return (
    <ButtonGroup sx={{ textAlign: 'center' }}>
      <Button
        variant="contained"
        disabled={isRemoveDisabled}
        onClick={() => {
          setQuantity(quantity - 1);
        }}
        sx={{ padding: 0 }}
        disableElevation
      >
        <RemoveIcon fontSize="small" />
      </Button>
      <Box sx={{ backgroundColor: '#fafafa', padding: `${size}px ${size * 7}px` }}>
        <Typography variant="subtitle1" fontWeight="bold">
          {quantity}
        </Typography>
      </Box>
      <Button
        variant="contained"
        disabled={isAddDisabled}
        onClick={() => {
          setQuantity(quantity + 1);
        }}
        sx={{ padding: 0 }}
        disableElevation
      >
        <AddIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  );
};

export default QuantitySelector;
