import { FC } from 'react';
import { LocationSummary } from '../modules/routing/Routes';
import { getConfigs } from '../settings/AppConstants';

type LocationNameProps = {
  location: LocationSummary;
};

const config = getConfigs();

const LocationName: FC<LocationNameProps> = ({ location }) => (
  <>
    {!config.hideCountryCode ? `${location.countryCode}, ` : ''} {location.title ?? location.name}
  </>
);

export default LocationName;
