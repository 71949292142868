import { Box, Grid, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { PassengerSummaryContext } from '../../context/PassengerSummaryContext';
import { Package } from '../../modules/sailing-products/SailingProductsSource';
import { TravelBookingContext } from '../travel/TravelBookingContext';
import PackageCard from './PackageCard';
import { FormattedMessage } from 'react-intl';

interface PackageCardListProps {
  packages: Package[];
}

const PackageCardList: FC<PackageCardListProps> = (props) => {
  const { packages } = props;
  const { passengerSummary } = useContext(PassengerSummaryContext);
  const { travelBookingRoutes } = useContext(TravelBookingContext);
  return (
    <Grid container flexDirection="column" marginBottom={8} height="auto">
      <Box sx={{ backgroundColor: 'black', color: 'white', padding: 1 }}>
        <Typography variant="body1" sx={{ float: 'left' }}>
          <FormattedMessage id="package.recommended" defaultMessage="Recommended Packages" />
        </Typography>
      </Box>
      {travelBookingRoutes &&
        packages.map((packageItem) => (
          <PackageCard packageItem={packageItem} travelBookingRoute={travelBookingRoutes[0]} passengerSummary={passengerSummary} />
        ))}
    </Grid>
  );
};

export default PackageCardList;
