import { useEffect, useState, FC, ElementType } from 'react';
import { Box, FormControl, Select, Typography, InputLabel, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import moment from 'moment';
import ShopifyService, { GraphProduct, GraphProductEdge } from '../../modules/shopify/ShopifyService';
import { compact, sortBy, uniq } from 'lodash';
import { ProductType } from '../../types/PosTypes';
import TicketList from './TicketList';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDisplayLocation } from '../../utils/PosUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker } from '@mui/lab';
import { inject } from 'saft-react';

interface InjectedProps {
  shopifyService: ShopifyService;
}

interface Props {
  onSelectTicket: (ticket: GraphProduct, date: Date, capacityLeft?: number | null) => void;
  defaultDate?: Date;
  defaultOrigin?: string;
  defaultDestination?: string;
  isReturnTrip?: boolean;
  onOriginChange?: (value: string) => void;
}

const ALL_DEST = 'all_destinations';

const PosScheduleSelector: FC<Props & InjectedProps> = (props) => {
  const { onSelectTicket, defaultDate, defaultOrigin, defaultDestination, isReturnTrip, onOriginChange, shopifyService } = props;

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [origin, setOrigin] = useState(defaultOrigin ?? '');
  const [destination, setDestination] = useState(defaultDestination ?? '');

  const [openPicker, setOpenPicker] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [sailingLegs, setSailingLegs] = useState<GraphProductEdge[]>([]);
  const [origins, setOrigins] = useState<string[]>([]);
  const [destinations, setDestinations] = useState<string[]>([ALL_DEST]);
  const [tickets, setTickets] = useState<GraphProductEdge[]>([]);

  const [selectedTicket, setSelectedTicket] = useState<GraphProduct | null>(null);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(defaultDate);
      setDate(defaultDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    if (defaultOrigin) {
      setOrigin(defaultOrigin);
    }
  }, [defaultOrigin]);

  useEffect(() => {
    if (defaultDestination) {
      setDestination(defaultDestination);
    }
  }, [defaultDestination]);

  useEffect(() => {
    const getSailingsByDate = async () => {
      const sailingLegs = await shopifyService.getProductsByFilter(moment(selectedDate), { productType: ProductType.SailingLeg });
      setSailingLegs(sailingLegs);
      setIsLoading(false);
    };
    setTickets([]);
    setIsLoading(true);
    getSailingsByDate();
  }, [selectedDate, shopifyService]);

  useEffect(() => {
    if (sailingLegs.length === 0) {
      return;
    }
    const locations = compact(uniq(sailingLegs.map((sailingLeg) => sailingLeg.node?.origin?.value)));
    setOrigins(locations);

    if (!origin && !defaultOrigin && !isReturnTrip) {
      setOrigin(locations[0]);
    }

    setDestinations([ALL_DEST, ...locations]);
  }, [sailingLegs, origin, defaultOrigin, isReturnTrip]);

  useEffect(() => {
    if (!origin) {
      return;
    }
    // get tickets
    const getTickets = async () => {
      const tickets = await shopifyService.getProductsByFilter(moment(selectedDate), {
        productType: ProductType.Ticket,
        origin,
        destination: destination === ALL_DEST ? '' : destination
      });
      setTickets(sortBy(tickets, (ticket) => ticket.node.departureTime?.value ?? ''));
    };
    getTickets();
  }, [selectedDate, origin, destination, shopifyService]);

  return (
    <Box>
      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            display: 'flex',
            columnGap: 2,
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            padding: 2,
            alignItems: 'center',
            boxSizing: 'border-box'
          }}
          onClick={() => setOpenPicker(!openPicker)}
        >
          <FontAwesomeIcon icon={faCalendarDay} />
          <Typography variant="body1">{moment(selectedDate).format('DD MMM YYYY')}</Typography>
        </Box>
        <DatePicker
          value={date}
          onChange={(date) => setDate(date || new Date())}
          onAccept={(date) => setSelectedDate(date || new Date())}
          renderInput={(props: TextFieldProps) => <TextField {...props} style={{ display: 'none' }} />}
          open={openPicker}
          onClose={() => setOpenPicker(false)}
          desktopModeMediaQuery="@media not all"
        />
        <Box sx={{ height: 8 }} />
        <FormControl fullWidth>
          <InputLabel variant="filled" id="lx-booking-origin-select-label" shrink>
            <FormattedMessage id="origin" defaultMessage="Origin" />
          </InputLabel>
          <Select
            fullWidth
            variant="filled"
            disableUnderline
            disabled={isReturnTrip}
            sx={{ backgroundColor: '#DFE8F1' }}
            labelId="lx-booking-origin-select-label"
            id="lx-booking-origin-select"
            native
            label={<FormattedMessage id="origin" defaultMessage="Origin" />}
            value={origin}
            onChange={(event) => {
              const value = event.target.value as string;
              setOrigin(value);
              onOriginChange?.(value);
            }}
          >
            {origins.map((location) => (
              <option value={location} key={location}>
                {location === ALL_DEST ? formatMessage({ id: 'all_destinations', defaultMessage: 'All destinations' }) : getDisplayLocation(location)}
              </option>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ height: 1 }} />
        <FormControl fullWidth>
          <InputLabel variant="filled" id="lx-booking-destination-select-label">
            <FormattedMessage id="destination" defaultMessage="Destination" />
          </InputLabel>
          <Select
            fullWidth
            native
            variant="filled"
            disabled={isReturnTrip}
            disableUnderline
            sx={{ backgroundColor: '#DFE8F1' }}
            labelId="lx-booking-destination-select-label"
            id="lx-booking-destination-select"
            value={destination}
            label="Destination"
            onChange={(event) => {
              const value = event.target.value as string;
              setDestination(value);
            }}
          >
            {destinations.map((location) => (
              <option value={location} key={location}>
                {location === ALL_DEST ? formatMessage({ id: 'all_destinations', defaultMessage: 'All destinations' }) : getDisplayLocation(location)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      {isLoading && (
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {!isLoading && (
        <TicketList
          onSelectTicket={(ticket, capacityLeft) => {
            setSelectedTicket(ticket);
            onSelectTicket(ticket, selectedDate, capacityLeft);
          }}
          tickets={tickets}
          sailingLegs={sailingLegs}
          origin={origin}
        />
      )}
    </Box>
  );
};

export default inject('shopifyService')(PosScheduleSelector as FC<{}>) as ElementType<Props>;
