import { sortBy, uniq } from 'lodash';
import moment from 'moment';
import { RouteSummary } from '../../modules/routing/Routes';
import { Extra, ReturnTicket, SailingLeg, Ticket } from '../../modules/sailing-products/SailingProductsSource';
import { ScheduleEntry, TicketType } from '../../modules/scheduling/Schedules';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { ReturnPrice } from './TravelBookingContext';

// TODO: To make schedule helper an interface that generate schedules based on different strategy/logic
export enum SailingLocation {
  Split = 'Split',
  Bol = 'Bol',
  Hvar = 'Hvar',
  Airport = 'Airport',
  Sutivan = 'Sutivan',
  SplitAirport = 'Split Airport'
}

export interface RouteNode {
  node: SailingLocation;
}

export interface Leg {
  from: SailingLocation;
  to: SailingLocation;
}

const ROUTE_CONNECTIONS = [
  [{ node: SailingLocation.Split }, { node: SailingLocation.Bol }],
  [{ node: SailingLocation.Split }, { node: SailingLocation.Bol }, { node: SailingLocation.Hvar }],
  // [{ node: SailingLocation.Split }, { node: SailingLocation.Bol }, { node: SailingLocation.Hvar }, { node: SailingLocation.Airport }],
  [{ node: SailingLocation.Bol }, { node: SailingLocation.Hvar }],
  [{ node: SailingLocation.Bol }, { node: SailingLocation.Hvar }, { node: SailingLocation.Airport }],
  [{ node: SailingLocation.Hvar }, { node: SailingLocation.Airport }],
  [{ node: SailingLocation.Airport }, { node: SailingLocation.Hvar }],
  [{ node: SailingLocation.Airport }, { node: SailingLocation.Hvar }, { node: SailingLocation.Bol }],
  [{ node: SailingLocation.Hvar }, { node: SailingLocation.Bol }],
  [{ node: SailingLocation.Hvar }, { node: SailingLocation.Bol }, { node: SailingLocation.Split }],
  [{ node: SailingLocation.Bol }, { node: SailingLocation.Split }],
  // [{ node: SailingLocation.Airport }, { node: SailingLocation.Hvar }, { node: SailingLocation.Bol }, { node: SailingLocation.Split }],
  [{ node: SailingLocation.Split }, { node: SailingLocation.Sutivan }],
  [{ node: SailingLocation.Sutivan }, { node: SailingLocation.Split }]
];

const isConnectionStartFromAndEndTo = (routeConnection: RouteNode[], from: SailingLocation, to: SailingLocation) => {
  const matchFirstNode = routeConnection[0]?.node === from;
  const matchLastNode = routeConnection[routeConnection.length - 1]?.node === to;

  return matchFirstNode && matchLastNode;
};

const getLegsFromRouteConnection = (routeConnection: RouteNode[]) => {
  return routeConnection.reduce<Leg[]>((legs, routeNode, index) => {
    const nextNode = routeConnection[index + 1];
    if (!nextNode) {
      return legs;
    }
    legs.push({ from: routeNode.node, to: nextNode.node });
    return legs;
  }, []);
};

const findRouteConnections = (routeSummary: RouteSummary) => {
  const from = routeSummary.origin.name as SailingLocation;
  const to = routeSummary.destination.name as SailingLocation;
  const routeConnections = ROUTE_CONNECTIONS.filter((routeConnection) => isConnectionStartFromAndEndTo(routeConnection, from, to));

  return routeConnections;
};

export const getRouteIdentifier = (routeSummary: RouteSummary) => {
  const from = routeSummary.origin.name as SailingLocation;
  const to = routeSummary.destination.name as SailingLocation;
  const date = moment(routeSummary.departureDate).format('YYYY-MM-DD');
  return [from, to, date].join('_');
};

// ScheduleEntry will represent one whole schedule and for now for each ticket price
export const getScheduleEntries = (
  routeSummary: RouteSummary,
  passengerSummary: PassengerSummary,
  allSailingLegs: SailingLeg[] = [],
  allTickets: Ticket[] = [],
  allExtras: Extra[] = [],
  isReturn?: boolean,
  returnPrice?: ReturnPrice | null,
  departureTime?: string
): ScheduleEntry[] => {
  if (allSailingLegs.length === 0 || allTickets.length === 0) {
    return [];
  }

  // Find possible "pre-defined" routes from origin to destination
  const routeConnections = findRouteConnections(routeSummary);
  const scheduleEntries: ScheduleEntry[] = [];
  const date = moment(routeSummary.departureDate).format('YYYY-MM-DD');

  routeConnections.forEach((routeConnection) => {
    // transform routes to leg: { from, to } form
    const legs = getLegsFromRouteConnection(routeConnection);
    const sailingLegsCombination = sortBy(
      getSailingLegsCombinations(legs, allSailingLegs, departureTime),
      (sailingLegsComb) => sailingLegsComb[0].departureTime
    );

    sailingLegsCombination.forEach((sailingLegs) => {
      // each entry
      const origin = routeSummary.origin.name as SailingLocation;
      const destination = routeSummary.destination.name as SailingLocation;

      const firstLeg = sailingLegs[0];
      const lastLeg = sailingLegs[sailingLegs.length - 1];

      const sailingDateTime = moment(`${date}T${firstLeg.departureTime}`).toDate();
      const arrivalDateTime = moment(`${date}T${lastLeg.arrivalTime}`).toDate();

      // Assume that there is only one ticket combination to select for each sailing leg routes
      const ticketCombinations = getTickets(firstLeg, lastLeg, sailingLegs, allTickets);
      const hasReturnPrice = isReturn && returnPrice;

      ticketCombinations.forEach((tickets) => {
        const totalPrice = calculatePrice(tickets, passengerSummary);
        const extras = getExtras(firstLeg, lastLeg, sailingLegs, allExtras);

        const entry: ScheduleEntry = {
          id: tickets.map((ticket) => ticket.id).join('_'),
          origin: {
            name: origin,
            code: origin,
            countryCode: 'HR'
          },
          destination: {
            name: destination,
            code: destination,
            countryCode: 'HR'
          },
          sailingDateTime,
          arrivalDateTime,
          inventory: {},
          price: totalPrice.amount,
          currencyCode: totalPrice.currencyCode,
          ticketType: TicketType.Regular,
          isReturn: false,
          duration: moment(arrivalDateTime).diff(moment(sailingDateTime), 'minutes'),
          isBookable: true,
          operationName: 'Splitexpress',
          sailingLegs,
          tickets,
          extras: extras[0],
          returnPrice: hasReturnPrice ? returnPrice : null
        };

        // sort schedule entry by duration time

        scheduleEntries.push(entry);
      });
    });
  });

  return scheduleEntries;
};

// routes example: Split@10:00->Bol|Bol@16:45->Split
export const getRouteInfo = (routes: string) => {
  const sailingLegCodes = routes.split('|');
  const routeInfos = sailingLegCodes.map((sailingLegCode: string) => {
    const sailingLegIds = sailingLegCode.split(',');
    const firstLegId = sailingLegIds[0];
    const lastLegId = sailingLegIds[sailingLegIds.length - 1];

    const firstLocation = firstLegId.split('->')[0];
    const lastLegLocations = lastLegId.split('->');
    const lastLocation = lastLegLocations[lastLegLocations.length - 1];

    const [origin, departureTime] = firstLocation.split('@');
    const [destination] = lastLocation.split('@');

    return {
      origin,
      destination,
      departureTime
    };
  });

  return routeInfos;
};

export const calculateTotalEntryPrice = (entry: ScheduleEntry, passengerSummary: PassengerSummary) => {
  const totalPrice = calculatePrice(entry.tickets ?? [], passengerSummary);
  if (!entry.returnPrice) {
    return totalPrice;
  }
  const totalReturnPrice = calculatePrice([entry.returnPrice.ticket], passengerSummary);
  return { ...totalPrice, amount: Math.max(totalReturnPrice.amount - totalPrice.amount, 0) };
};

export const calculatePrice = (tickets: Ticket[] | ReturnTicket[], passengerSummary: PassengerSummary) => {
  let currencyCode = '';
  let sum = 0;

  tickets.forEach((ticket) => {
    const totalPrice = Object.entries(ticket.inventory).reduce((totalPrice, entry) => {
      const [ticketType, inventory] = entry;

      if (!currencyCode && inventory.price?.currencyCode) {
        currencyCode = inventory.price?.currencyCode;
      }
      const key = ticketType.toLowerCase();
      const price = parseInt((inventory.price?.amount ?? 0) + '');
      const totalPriceForType = (passengerSummary[key] ?? 0) * price;

      return totalPrice + totalPriceForType;
    }, 0);
    sum += totalPrice;
  });

  return {
    amount: sum,
    currencyCode
  };
};

const isMatchSailingLeg = (product: Ticket | Extra, sailingLeg: SailingLeg) => {
  const origin = sailingLeg.origin;
  const destination = sailingLeg.destination;
  const departureTime = sailingLeg.departureTime;
  return product.origin === origin && product.destination === destination && product.departureTime === departureTime;
};

const getTickets = (firstLeg: SailingLeg, lastLeg: SailingLeg, sailingLegs: SailingLeg[], allTickets: Ticket[]): Ticket[][] => {
  const tickets: Ticket[][] = [];
  const directTicket = allTickets.find((product) =>
    isMatchSailingLeg(product, { ...firstLeg, destination: lastLeg.destination, arrivalTime: lastLeg.arrivalTime })
  );

  if (directTicket) {
    // tickets.push([directTicket]); // if we want to have more option (price) for user, we can do this
    return [[directTicket]];
  }

  const separatedTickets: Ticket[] = [];
  sailingLegs.forEach((sailingLeg) => {
    const directTicket = allTickets.find((product) => isMatchSailingLeg(product, sailingLeg));
    if (directTicket) {
      separatedTickets.push(directTicket);
    }
  });

  tickets.push(separatedTickets);

  // TODO ticket should consider different choices as well --> we can say that price 'from' XXX SEK like in stenaline or SJ website
  // then click on 'entry', the choices will be expanded
  // or we can make it different entries as well (for different ticket combinations)

  return tickets;
};

const getExtras = (firstLeg: SailingLeg, lastLeg: SailingLeg, sailingLegs: SailingLeg[], allExtras: Extra[]): Extra[][] => {
  const extras: Extra[][] = [];

  const extra = allExtras.find((product) => isMatchSailingLeg(product, { ...firstLeg, destination: lastLeg.destination, arrivalTime: lastLeg.arrivalTime }));

  if (extra) {
    return [[extra]];
  }

  const separatedExtras: Extra[] = [];
  sailingLegs.forEach((sailingLeg) => {
    const extra = allExtras.find((product) => isMatchSailingLeg(product, sailingLeg));
    if (extra) {
      separatedExtras.push(extra);
    }
  });

  extras.push(separatedExtras);

  return extras;
};

const getSailingLegsCombinations = (legs: Leg[], allSailingLegs: SailingLeg[], departureTime?: string): SailingLeg[][] => {
  const possibleSailingsAtEachLeg: SailingLeg[][] = [];
  legs.forEach((leg, index) => {
    possibleSailingsAtEachLeg.push(
      allSailingLegs.filter(
        (sailingLeg) =>
          sailingLeg.origin === leg.from &&
          sailingLeg.destination === leg.to &&
          (departureTime && index === 0 ? sailingLeg.departureTime === departureTime : true)
      )
    );
  });

  const result: SailingLeg[][] = [];
  getAllCombinations(possibleSailingsAtEachLeg, result);

  return result;
};

const getAllCombinations = (levels: SailingLeg[][], accumulatedResult: SailingLeg[][] = [], currentRoute: SailingLeg[] = []) => {
  if (levels.length === 0) {
    accumulatedResult.push(currentRoute);
    return currentRoute;
  }

  const startNodes = levels[0];
  startNodes.forEach((node) => {
    // Time can be checked at this line
    getAllCombinations(levels.slice(1, levels.length), accumulatedResult, [...currentRoute, node]);
  });
};

export const hasTicketDateAlreadyPassed = (entry: ScheduleEntry) => {
  const dateWithOffset = moment().subtract(60, 'minutes');
  const ticketDepartureDateTime = moment(entry.sailingDateTime);

  return ticketDepartureDateTime.isBefore(dateWithOffset);
};

export const filterDestinationBasedOnDefinedRoute = (origin: string) => {
  const nodes = ROUTE_CONNECTIONS.filter((routes) => routes.some((route) => route.node === origin)).flatMap((routes) => routes.map((route) => route.node));
  return uniq(nodes.filter((node) => node !== origin));
};
