import React, { FC, useCallback, useContext } from 'react';
import { Dialog, Slide, Box, Button, Typography, styled, Theme, SvgIcon, useTheme, Grid } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PaymentMethod, PosContext } from './PosContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { ReactComponent as bankPosIcon } from '../../images/bank_pos.svg';

const PaymentOption = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexGrow: 1,
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  marginBottom: theme.spacing(0.2)
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open?: boolean;
  onClose?: () => void;
  price: number | string;
  currencyCode: string;
}

const PosPaymentSelectionDialog: FC<Props> = (props) => {
  const { open, onClose, price, currencyCode } = props;
  const { setPaymentSummary } = useContext(PosContext);
  const theme = useTheme();

  const handleSelection = useCallback(
    (method: PaymentMethod) => {
      onClose?.();
      setPaymentSummary({ method, currencyCode, price });
    },
    [currencyCode, onClose, price, setPaymentSummary]
  );
  return (
    <Dialog fullScreen open={!!open} onClose={onClose} TransitionComponent={Transition} PaperProps={{ sx: { backgroundColor: '#F8F8F8' } }}>
      <Grid container padding={1}>
        <Grid item xs={4}>
          <Button variant="text" color="primary" onClick={onClose} sx={{ color: theme.palette.primary.main }}>
            Close
          </Button>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography fontWeight="bold">{`${currencyCode} ${price}`}</Typography>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Box>
        <Typography variant="h5" fontWeight="bold" sx={{ textAlign: 'center', margin: theme.spacing(2.5) }}>
          Select payment option
        </Typography>
        <PaymentOption onClick={() => handleSelection(PaymentMethod.Cash)}>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <FontAwesomeIcon icon={faMoneyBill1} color="#9ea7ae" />
            <Typography fontWeight="medium">{PaymentMethod.Cash}</Typography>
          </Box>
          <SvgIcon component={ArrowForwardIosIcon} />
        </PaymentOption>
        <PaymentOption onClick={() => handleSelection(PaymentMethod.BankPOS)}>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <SvgIcon component={bankPosIcon} sx={{ width: '18px' }} viewBox="0 0 18 24" />
            <Typography fontWeight="medium">{PaymentMethod.BankPOS}</Typography>
          </Box>
          <SvgIcon component={ArrowForwardIosIcon} />
        </PaymentOption>
        <PaymentOption onClick={() => handleSelection(PaymentMethod.CreditCard)}>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <FontAwesomeIcon icon={faCreditCard} color="#9ea7ae" />
            <Typography fontWeight="medium">{`${PaymentMethod.CreditCard} (manual entry)`}</Typography>
          </Box>
          <SvgIcon component={ArrowForwardIosIcon} />
        </PaymentOption>
      </Box>
    </Dialog>
  );
};

export default PosPaymentSelectionDialog;
