import { FC, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { AppBar, Tab, Tabs, Toolbar, Container, Grid, styled, Theme, useTheme, IconButton, Menu, Box, Button } from '@mui/material';
import { BookingType, BookingTypeContext } from '../context/BookingTypeContext';
import { useNavigate, useLocation, To } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import { ResetStatesContext } from '../context/ResetStatesContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { ViewportContext } from '../context/ViewportContext';
import { LocaleSelector } from './LocaleSelector';
import { inject } from 'saft-react';
import { Node } from '../modules/static-page-service/StaticPageService';
import { StaticPageService } from '../modules/static-page-service/StaticPageService';
import { Customer } from '../types/Customer';
import SocialMediaLink from './SocialMediaLink';
import { snakeCase } from 'lodash';
import { getConfigs } from '../settings/AppConstants';
import LoginDialog from './Login';

const TabButton = styled(Tab)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexGrow: 1,
  padding: theme.spacing(1)
}));

interface HeaderInjectedProps {
  staticPageService: StaticPageService;
  customer: Customer;
}

interface Props {
  hideTabs?: boolean;
  onLogoClick?: () => void;
  onBookNowClick?: () => void;
  hideBookNow?: boolean;
}

const Header: FC<Props & HeaderInjectedProps> = ({ hideTabs, onLogoClick, staticPageService, onBookNowClick, customer, hideBookNow }) => {
  const theme = useTheme();
  const { matchesBreakpoint } = useContext(ViewportContext);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const intl = useIntl();

  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const [shopifyHandles, setShopifyHandles] = useState<Node[]>([]);
  const tabOptions = useMemo(
    (): Record<string, string> => shopifyHandles?.reduce((prev, { handle, title }) => ({ ...prev, [`page/${handle}`]: title }), {}),
    [shopifyHandles]
  );
  const [selectedTab, setSelectedTab] = useState<string | false>(tabOptions[pathname.slice(1)] ? pathname.slice(1) : false);
  const { setBookingType } = useContext(BookingTypeContext);
  const resetStates = useContext(ResetStatesContext);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [socialLinks, setSocialLinks] = useState<Record<string, string>>({});

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigation = useCallback(
    (path: To, tabValue: string | false = false) => {
      anchorElNav && handleCloseNavMenu();
      setSelectedTab(tabValue);
      navigate(path);
      resetStates();
    },
    [anchorElNav, navigate, resetStates]
  );

  useEffect(() => {
    const getSocialLinks = async () => {
      const socialLinks = await staticPageService.getSocialMediaLinks();
      setSocialLinks(socialLinks);
    };
    getSocialLinks();
  }, [staticPageService]);

  const clearCheckoutState = () => {
    localStorage.removeItem('cartId');
    localStorage.removeItem('checkoutState');
  };

  const ExtraButtons = (
    <Grid item xs sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 1, columnGap: 2 }}>
      {matchesBreakpoint('sm') && <SocialMediaLink socialLinks={socialLinks} />}
      {customer === Customer.SplitExpress && !hideBookNow && (
        <Button
          onClick={() => {
            handleNavigation({ pathname: '/travel', search: window.location.search });
            clearCheckoutState();
            onBookNowClick?.();
          }}
          size="small"
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          <FormattedMessage id="booking.header.bookNow" defaultMessage="Book now" />
        </Button>
      )}
      {customer === Customer.SplitExpress && <LocaleSelector />}{' '}
      {getConfigs()?.useLogin && (
        <IconButton onClick={() => setShowLoginDialog(true)}>
          <PersonIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      {!customer && (
        <IconButton onClick={() => handleNavigation('portal/your-bookings')}>
          <PersonIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
    </Grid>
  );

  useEffect(() => {
    if (customer === Customer.SplitExpress) {
      (async () => {
        const pageHandles = await staticPageService.getPageInformation(10);
        setShopifyHandles(pageHandles);
      })();
    }
  }, [customer, staticPageService]);

  const TabsElement = useMemo(
    () => (
      <Tabs
        value={selectedTab}
        orientation={matchesBreakpoint('sm') ? 'horizontal' : 'vertical'}
        sx={{ '& .MuiTab-root': { color: 'white !important', textTransform: 'none', alignItems: 'flex-start', marginLeft: 2 }, width: '100%' }}
        TabIndicatorProps={{ style: { backgroundColor: matchesBreakpoint('sm') ? theme.palette.secondary.main : 'rgba(0,0,0,0)', height: theme.spacing(0.5) } }}
        onChange={(e, value) => {
          const path = `/${value}`;
          if (customer) handleNavigation(`${path}${window.location.search}`, value);
          else handleNavigation(path, value);
        }}
      >
        {!hideTabs && !customer
          ? [
              <TabButton
                value="travel"
                label={intl.formatMessage({ id: 'header.travel', defaultMessage: 'Travel' })}
                onClick={() => setBookingType(BookingType.Travel)}
              />,
              <TabButton
                value="freight"
                label={intl.formatMessage({ id: 'header.freight', defaultMessage: 'Freight' })}
                onClick={() => setBookingType(BookingType.Freight)}
              />,
              <TabButton
                value="door-to-door-freight"
                label={intl.formatMessage({ id: 'header.doorToDoor', defaultMessage: 'Door to door freight' })}
                onClick={() => setBookingType(BookingType.DoorToDoorFreight)}
              />
            ]
          : Object.entries(tabOptions)?.map(([path, title]) => {
              return (
                path && (
                  <TabButton
                    key={path}
                    value={path}
                    label={<FormattedMessage id={`pages.${snakeCase(title)}`} defaultMessage={title} />}
                    onClick={() => {
                      setSelectedTab(path);
                    }}
                  />
                )
              );
            })}
        {staticPageService.showContactUs() && (
          <Button
            onClick={() => {
              staticPageService.onClickContactUs();
            }}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start', marginLeft: 2 }}
          >
            {intl.formatMessage({ id: 'footer.contactUs', defaultMessage: 'Contact us' })}
          </Button>
        )}
      </Tabs>
    ),
    [selectedTab, matchesBreakpoint, theme, hideTabs, customer, intl, tabOptions, staticPageService, handleNavigation, setBookingType]
  );

  return (
    <Container maxWidth={false} disableGutters>
      <AppBar
        sx={{
          backgroundColor: theme.background?.appbar
        }}
        position="fixed"
      >
        <Toolbar variant="dense" disableGutters>
          <Grid container alignItems="center">
            <Grid item xs="auto" sx={{ padding: 1, paddingLeft: { xs: 0, sm: 2 }, marginLeft: { xs: 0, sm: 5 } }}>
              {!matchesBreakpoint('sm') && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              )}
              <img
                style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                src={theme.logo?.img ?? 'img/logo.png'}
                height={theme.logo?.height}
                width={theme.logo?.width}
                alt="Logo"
                onClick={() => {
                  handleNavigation({ pathname: '/travel', search: window.location.search });
                  clearCheckoutState();
                  onLogoClick?.();
                }}
              />
            </Grid>
            {!matchesBreakpoint('sm') && ExtraButtons}
            <Grid container item xs={12} sm="auto">
              {matchesBreakpoint('sm') ? (
                TabsElement
              ) : (
                <Box component="span" sx={{ '& .MuiPaper-root': { background: theme.background?.appbar } }}>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    PaperProps={{ sx: { background: '#2F5173', borderRadius: '0', maxWidth: '100%', left: '0!important', width: '100%' } }}
                    sx={{
                      display: { xs: 'block' }
                    }}
                  >
                    {TabsElement}
                    {!matchesBreakpoint('sm') && (
                      <Box sx={{ marginLeft: 3 }}>
                        <SocialMediaLink socialLinks={socialLinks} columnGap={3} />
                      </Box>
                    )}
                  </Menu>
                </Box>
              )}
            </Grid>
            {matchesBreakpoint('sm') && ExtraButtons}
          </Grid>
        </Toolbar>
      </AppBar>
      {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} onLoggedIn={() => setShowLoginDialog(false)} />}
    </Container>
  );
};

export default inject('staticPageService', 'customer')(Header as FC<{}>) as React.ElementType<Props>;
