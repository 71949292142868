import { useContext, useEffect, useState } from 'react';
import './App.css';
//@ts-ignore
import { Injector, Provides, Singleton, Inject } from 'saft';
import Routes from './modules/routing/Routes';
import 'reflect-metadata';
import { Box, Container, Grid, useTheme } from '@mui/material';
import Schedules from './modules/scheduling/Schedules';
import Vehicles from './modules/vehicles/Vehicles';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import 'date-fns';
import Header from './components/Header';
import BookingForm from './components/header-selectors/BookingForm';
import Footer from './components/Footer';
import { BookingType, BookingTypeContext } from './context/BookingTypeContext';
import VehicleTypeContextProvider from './context/VehicleTypeContext';
import FreightSelector from './components/FreightSelector';
import Portal from './components/portal-page/Portal';
import JourneyTypeContextProvider from './context/JourneyTypeContext';
import PassengerSummaryContextProvider from './context/PassengerSummaryContext';
import { RouteSummaryContext } from './context/RouteSummaryContext';
import { BrowserRouter as Router, Route, Routes as RouterRoutes, Navigate } from 'react-router-dom';
import BookingStageContextProvider from './context/BookingStageContext';
import { ViewportContext } from './context/ViewportContext';
import HazardousGoodsService from './modules/hazardous-goods/HazardousGoodsService';
import CountriesService from './modules/countries/CountriesService';
import ResetStatesContextProvider from './context/ResetStatesContext';
import { IntlProvider } from 'react-intl';
import { flattenObject } from './utils/Utils';
import * as translationFiles from './translations/translations';
import * as dateLocales from 'date-fns/locale';
import Extras from './modules/extras/ExtrasService';
import Bookings from './modules/bookings/BookingsService';
import Accommodations from './modules/accommodations/AccommodationsService';
import { PresetBooking } from './components/PresetBooking';
import BookingStateContextProvider from './context/BookingStateContext';
import ShopifyService from './modules/shopify/ShopifyService';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import TravelPage from './components/travel/TravelPage';
import TravelBookingContextProvider from './components/travel/TravelBookingContext';
import ShopifySailingProductsSource from './modules/shopify/ShopifySailingProductsSource';
import ShopifyCheckoutService from './modules/shopify/ShopifyCheckoutService';
import DynamicContent from './components/DynamicContent';
import { LocaleContext } from './context/LocaleContext';
import BookingConfirmation from './components/confirmation-stage/BookingConfirmation';
import ShopifyStaticPageService from './modules/shopify/ShopifyStaticPageService';
import { isEmpty } from 'lodash';
import PackageBookingContextProvider from './components/packages/PackageBookingContext';
import { CUSTOMER, getFavIcon, getStorefrontAccessInfo } from './settings/AppConstants';
import { Customer } from './types/Customer';
import AuthenticatorProvider, { AuthenticatorContext } from './components/login/AuthenticatorProvider';
import PosTicketContainer from './components/pos/PosTicketContainer';
import { PosProvider } from './components/pos/PosContext';
import { Saft } from 'saft-react';

const API_VERSION = '2022-04';
const locales: Record<string, Locale> = { ...dateLocales };
const translations: Record<string, object> = { ...translationFiles };

const storefrontApiAccessInfo = getStorefrontAccessInfo(CUSTOMER);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: `https://${storefrontApiAccessInfo.domain}/api/${API_VERSION}/graphql.json`,
    headers: {
      'X-Shopify-Storefront-Access-Token': storefrontApiAccessInfo.accessToken,
      Accept: 'application/graphql'
    }
  })
});

const shopifyService = new ShopifyService(client);

class Apis {
  @Provides('customer')
  getCustomer() {
    return CUSTOMER;
  }

  @Provides('lxRouteService')
  @Inject('customer', 'shopifyService')
  getRouteService(customer: Customer, shopifyService: ShopifyService) {
    return new Routes(customer, shopifyService);
  }

  @Provides('lxScheduleService')
  @Inject('customer', 'shopifyService')
  getScheduleService(customer: Customer, shopifyService: ShopifyService) {
    return new Schedules(customer, shopifyService);
  }

  @Provides('sailingProductsSource')
  @Inject('customer', 'shopifySailingProductsSource')
  @Singleton()
  getSailingProductsSource(customer: Customer, shopifySailingProductsSource: ShopifySailingProductsSource) {
    return shopifySailingProductsSource;
  }

  @Provides('shopifySailingProductsSource')
  @Inject('shopifyService')
  getShopifySailingProductsSource(shopifyService: ShopifyService) {
    return new ShopifySailingProductsSource(shopifyService);
  }

  @Provides('lxVehicleService')
  getVehiclesService() {
    return new Vehicles();
  }

  @Provides('lxHazardousGoodsService')
  getHazardousGoodsService() {
    return new HazardousGoodsService();
  }

  @Provides('lxExtrasService')
  getExtrasService() {
    return new Extras();
  }

  @Provides('lxBookingsService')
  getBookingsService() {
    return new Bookings();
  }

  @Provides('lxAccommodationsService')
  getAccommodationsService() {
    return new Accommodations();
  }

  @Provides('shopifyService')
  @Singleton()
  getShopifyService() {
    return shopifyService;
  }

  @Provides('lxCountriesService')
  getCountriesService() {
    return new CountriesService();
  }

  @Provides('checkoutService')
  @Inject('shopifyCheckoutService')
  @Singleton()
  getCheckoutService(shopifyCheckoutService: ShopifyCheckoutService) {
    return shopifyCheckoutService;
  }

  @Provides('shopifyCheckoutService')
  @Inject('shopifyService')
  getShopifyCheckoutService(shopifyService: ShopifyService) {
    return new ShopifyCheckoutService(shopifyService);
  }

  @Provides('staticPageService')
  @Inject('shopifyService')
  @Singleton()
  getStaticPageService(shopifyService: ShopifyService) {
    return new ShopifyStaticPageService(shopifyService);
  }
}

const injector = new Injector(new Apis());

function App() {
  const { bookingType } = useContext(BookingTypeContext);
  const { routes } = useContext(RouteSummaryContext);
  const { matchesBreakpoint } = useContext(ViewportContext);
  const { locale } = useContext(LocaleContext);
  const [messages, setMessages] = useState(translations[locale]);
  const [footerHeight, setFooterHeight] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = getFavIcon();
    }
  }, []);

  useEffect(() => {
    setMessages(translations[locale]);
  }, [locale]);

  return (
    <Box className="App" sx={{ background: (routes ? theme?.background?.content : theme?.background?.main) || '#f1f1f1', paddingBottom: 15 }}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={locales[locale] || locales['enUS']}>
        <Saft injector={injector}>
          <IntlProvider locale={locale} messages={flattenObject(messages)} onError={(err) => console.warn(`Missing translations: ${err.message}`)}>
            <AuthenticatorProvider>
              <ApolloProvider client={client}>
                <BookingStateContextProvider>
                  <VehicleTypeContextProvider>
                    <JourneyTypeContextProvider>
                      <PassengerSummaryContextProvider>
                        <BookingStageContextProvider>
                          <ResetStatesContextProvider>
                            <Router>
                              <RouterRoutes>
                                <Route path="/" element={<Navigate to="/travel" />} /> {/* Redirect to travel when at root url */}
                                <Route
                                  path="/travel"
                                  element={
                                    <TravelBookingContextProvider>
                                      <PackageBookingContextProvider>
                                        <TravelPage footerHeight={footerHeight} />
                                      </PackageBookingContextProvider>
                                    </TravelBookingContextProvider>
                                  }
                                />
                                <Route
                                  path="/freight"
                                  element={
                                    <>
                                      <Header />
                                      <BookingForm />
                                      <Container maxWidth="xl" sx={{ padding: 0 }} disableGutters>
                                        <Box>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              {bookingType === BookingType.Freight &&
                                                (routes && !isEmpty(routes) ? (
                                                  <FreightSelector footerHeight={footerHeight} routes={routes} />
                                                ) : (
                                                  <PresetBooking />
                                                ))}
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Container>
                                    </>
                                  }
                                />
                                <Route path="/portal/*" element={<Portal footerHeight={footerHeight} />} />
                                <Route path="/page/:handle" element={<DynamicContent footerHeight={footerHeight} type="page" />} />
                                <Route path="/page/booking-confirm/" element={<BookingConfirmation />} />
                                <Route path="/blogs/:blogHandle/:handle" element={<DynamicContent footerHeight={footerHeight} type="blog" />} />
                                <Route path="/*" element={<Navigate to="/travel" />} />
                                <Route
                                  path="/pos"
                                  element={
                                    <PosProvider>
                                      <AuthenticatorContext.Consumer>
                                        {({ authenticator }) => authenticator.isAuthenticated && <PosTicketContainer />}
                                      </AuthenticatorContext.Consumer>
                                    </PosProvider>
                                  }
                                />
                              </RouterRoutes>
                            </Router>
                          </ResetStatesContextProvider>
                        </BookingStageContextProvider>
                      </PassengerSummaryContextProvider>
                    </JourneyTypeContextProvider>
                  </VehicleTypeContextProvider>
                </BookingStateContextProvider>
              </ApolloProvider>
              {matchesBreakpoint('sm') && <Footer setHeight={setFooterHeight} shopifyService={shopifyService} />}
            </AuthenticatorProvider>
          </IntlProvider>
        </Saft>
      </LocalizationProvider>
    </Box>
  );
}

export default App;
