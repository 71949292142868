import { CartItem } from '../components/pos/PosContext';
import { SailingLocation } from '../components/travel/ScheduleHelper';
import { ProductType } from '../types/PosTypes';

export const isNoSeatCount = (title: string) => title.toLowerCase() === 'baby';

export const getTicketsByVariantMapping = (cartItems: CartItem[]) => {
  const mapping = cartItems
    .filter((ct) => [ProductType.Ticket, ProductType.ReturnTicket, ProductType.TravelExtra].includes(ct.productType))
    .reduce<Record<string, number>>((result, cartItem) => {
      result[cartItem.variant.title] = cartItem.quantity + (result[cartItem.variant.title] ?? 0);
      return result;
    }, {});

  return mapping;
};

export const getTotalSelectedAmount = (ticketTypeQuantity: Record<string, number>) =>
  Object.entries(ticketTypeQuantity).reduce((sum, obj) => {
    const [key, quantity] = obj;
    if (isNoSeatCount(key)) {
      return sum;
    }
    return sum + quantity;
  }, 0);

export const getDisplayLocation = (variantTitle?: string) => {
  // TODO: the name should be correct in Shopify
  if (variantTitle === SailingLocation.Airport) {
    return SailingLocation.SplitAirport;
  }
  return variantTitle;
};

export const getFixedNumber = (number: number | string) => Number(number).toFixed(2);
