import { compact, flatMap, forEach } from 'lodash';
import { PassengerSummary } from '../../components/header-selectors/PasssengerSelector';
import { ReturnPrice, SelectedExtra, TravelBookingRoute } from '../../components/travel/TravelBookingContext';
import ShopifyService, { CartAttribute, CartLineItem, ShopifyProduct } from './ShopifyService';
import UrlResolver from '../server-communication/UrlResolver';
import { Passenger } from '../../components/travel/TravelPassengerDetails';
import { Package } from '../sailing-products/SailingProductsSource';
import moment from 'moment';
import { calculatePrice } from '../../components/travel/ScheduleHelper';

class ShopifyCheckoutService {
  constructor(private shopifyService: ShopifyService) {}

  async verifyAvailability(travelBookingRoutes: TravelBookingRoute[], passengerSummary: PassengerSummary) {
    const dates = travelBookingRoutes.map((t) => moment(t.routeSummary.departureDate));

    const sailingLegs: ShopifyProduct[] = [];
    for (const date of dates) {
      const legs = await this.shopifyService.getSailingsByDate(date);
      const products = (legs.data.collection?.products?.edges ?? []).map((edge: any) => ({
        ...edge.node,
        variants: edge.node.variants.edges.map((vEdge: any) => vEdge.node)
      })) as ShopifyProduct[];
      sailingLegs.push(...products);
    }

    const relatedLegs = compact(travelBookingRoutes.flatMap((t) => t.selectedEntry?.sailingLegs?.map((l) => l.id)));
    const totalQuantity = Object.entries(passengerSummary).reduce((count, [type, quantity]) => count + quantity, 0);

    return relatedLegs.every((relatedLegId) => {
      const sailingLeg = sailingLegs.find((product) => product.id === relatedLegId);

      if (!sailingLeg) {
        return false;
      }

      // availableForSales
      const seatsVariant = sailingLeg.variants.find((variant) => variant.title === 'Seats');

      if (!seatsVariant?.availableForSale) {
        return false;
      }

      if ((seatsVariant?.quantityAvailable ?? 0) < totalQuantity) {
        return false;
      }

      return true;
    });
  }

  travelCheckout(
    travelBookingRoutes: TravelBookingRoute[],
    passengerSummary: PassengerSummary,
    extraValues: Record<string, SelectedExtra>,
    passengerList: Passenger[],
    travelPackage: Package | undefined,
    returnPrice: ReturnPrice | null,
    note?: string
  ) {
    const url = UrlResolver.getHomePageUrl();
    const cartItems: CartLineItem[] = [];

    const cartAttributes: CartAttribute[] = [];

    const singleTicketsTotalPrice = Object.values(travelBookingRoutes).reduce(
      (acc, route) => acc + calculatePrice(route.selectedEntry?.tickets ?? [], passengerSummary).amount,
      0
    );
    const routeWithReturnPrice = Object.values(travelBookingRoutes).find((route) => !!route.selectedEntry?.returnPrice);
    const returnTicketTotalPrice = routeWithReturnPrice?.selectedEntry?.returnPrice
      ? calculatePrice([routeWithReturnPrice.selectedEntry.returnPrice.ticket], passengerSummary).amount
      : 0;
    const shouldGoWithReturnTicket = singleTicketsTotalPrice > returnTicketTotalPrice;

    if (travelPackage) {
      const ticket = travelPackage.inventory.Ticket;
      if (ticket && ticket.amount > 0) {
        cartItems.push({
          merchandiseId: ticket.id,
          quantity: 1,
          attributes: [{ key: '__returnTo', value: url }]
        });
      }
    } else if (returnPrice && shouldGoWithReturnTicket) {
      const seatsVariantIds = flatMap(travelBookingRoutes, (travelBookingRoute) => {
        const seatsVariantIds = travelBookingRoute.selectedEntry?.sailingLegs?.map((sailingLeg) => sailingLeg.inventory.Seats?.id);
        return seatsVariantIds;
      });

      const seatsVariantIdsAttribute: CartAttribute = {
        key: 'seatsVariantIds',
        value: JSON.stringify(seatsVariantIds)
      };

      const routes = travelBookingRoutes.map((travelBookingRoute) => {
        if (!travelBookingRoute.selectedEntry) {
          return '';
        }
        const sailingLegs = travelBookingRoute.selectedEntry.sailingLegs ?? [];
        const firstLeg = sailingLegs[0];
        const lastLeg = sailingLegs[sailingLegs.length - 1];
        if (!firstLeg || !lastLeg) {
          return '';
        }
        return `${firstLeg.origin}@${firstLeg.departureTime}->${lastLeg.destination}@${lastLeg.arrivalTime}`;
      });

      const routesCode = routes.filter((route) => !!route).join('|');
      const dates = travelBookingRoutes.map((travelBookingRoute) => moment(travelBookingRoute.routeSummary.departureDate).format('YYYY-MM-DD'));
      const datesCode = dates.join('|');
      cartAttributes.push(seatsVariantIdsAttribute);
      cartAttributes.push({
        key: 'routes',
        value: routesCode
      });
      cartAttributes.push({
        key: 'dates',
        value: datesCode
      });

      const returnTicket = returnPrice.ticket;
      Object.entries(returnTicket.inventory).forEach(([ticketType, inventory]) => {
        const key = ticketType.toLowerCase();
        const quantity = passengerSummary[key] ?? 0;
        const variantId = inventory.id;

        if (quantity > 0) {
          cartItems.push({
            merchandiseId: variantId,
            quantity,
            attributes: [
              { key: '__returnTo', value: url },
              { key: 'Depart', value: dates[0] && routes[0] ? `${moment(dates[0]).format('DD MMM YYYY')} ${routes[0]}` : '' },
              { key: 'Return', value: dates[1] && routes[1] ? `${moment(dates[1]).format('DD MMM YYYY')} ${routes[1]}` : '' }
            ]
          });
        }
      }, []);
    } else {
      forEach(travelBookingRoutes, (traveBookingRoute) => {
        const selectedEntry = traveBookingRoute.selectedEntry;
        if (!selectedEntry) {
          return;
        }
        const tickets = selectedEntry?.tickets;

        if (!tickets) {
          console.log('No tickets selected!!!');
          return;
        }

        tickets.forEach((ticket) => {
          Object.entries(ticket.inventory).forEach(([ticketType, inventory]) => {
            const key = ticketType.toLowerCase();
            const quantity = passengerSummary[key] ?? 0;
            const variantId = inventory.id;

            if (quantity > 0) {
              cartItems.push({
                merchandiseId: variantId,
                quantity,
                attributes: [{ key: '__returnTo', value: url }]
              });
            }
          }, []);
        });
      });
    }

    // extra
    if (extraValues) {
      Object.entries(extraValues).forEach(([inventoryId, { inventory, quantity }]) => {
        const variantId = inventory.id;
        if (quantity > 0) {
          cartItems.push({
            merchandiseId: variantId,
            quantity
          });
        }
      });
    }

    // passengers
    cartAttributes.push(this.getPassengersAttribute(passengerList));

    // create cart
    return this.shopifyService
      .createCart({
        lines: cartItems,
        attributes: cartAttributes,
        note
      })
      .then(async (res) => {
        const cartId = res.data.cartCreate.cart.id;
        localStorage.setItem('cartId', cartId);
        const checkoutUrl = await this.shopifyService.getCheckoutUrl(cartId);
        window.location.href = checkoutUrl;
      })
      .catch((err) => console.log(err));
  }

  private getPassengersAttribute = (passengerList: Passenger[]): CartAttribute => {
    return {
      key: 'passengers',
      value: JSON.stringify(passengerList)
    };
  };
}

export default ShopifyCheckoutService;
