import { FC, useState, useCallback, useContext, useEffect } from 'react';
import { Box, Grid, Typography, Collapse, Card, CardContent, CardMedia } from '@mui/material';
import SailingProductsSource, { ExtraInventoryType, Package } from '../../modules/sailing-products/SailingProductsSource';
import PackageTicket from './PackageTicket';
import { SelectedExtra, TravelBookingContext, TravelBookingRoute } from '../travel/TravelBookingContext';
import { PassengerSummary } from '../header-selectors/PasssengerSelector';
import { inject } from 'saft-react';
import useScheduleEntries from '../travel/useScheduleEntries';
import { RouteSummary } from '../../modules/routing/Routes';
import { PackageBookingContext } from './PackageBookingContext';
import { isEqual } from 'lodash';
import { getRouteIdentifier, getRouteInfo } from '../travel/ScheduleHelper';
import moment from 'moment';
import { ProductType } from '../../modules/shopify/ShopifyService';

interface InjectedProps {
  sailingProductsSource: SailingProductsSource;
}

interface PackageCardProps {
  packageItem: Package;
  travelBookingRoute: TravelBookingRoute;
  passengerSummary: PassengerSummary;
}

const PackageCard: FC<PackageCardProps & InjectedProps> = (props) => {
  const { packageItem, travelBookingRoute, passengerSummary, sailingProductsSource } = props;
  const [expanded, setExpanded] = useState(false);
  const { setSelectedExtra } = useContext(TravelBookingContext);
  const { selectedPackage, setSelectedPackage } = useContext(PackageBookingContext);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  const routeInfos = getRouteInfo(packageItem.routes);
  const departureEntry = useScheduleEntries(travelBookingRoute, passengerSummary, sailingProductsSource, false, null, routeInfos[0]?.departureTime);
  const returnRoute = {
    origin: travelBookingRoute.routeSummary.destination,
    destination: travelBookingRoute.routeSummary.origin,
    departureDate: travelBookingRoute.routeSummary.departureDate
  } as RouteSummary;
  const returnEntry = useScheduleEntries({ routeSummary: returnRoute, identifier: getRouteIdentifier(returnRoute) }, passengerSummary, sailingProductsSource, false, null, routeInfos[1]?.departureTime);
  const [extraPaxPrice, setExtraPaxPrice] = useState(0);
  const [selectedExtraPax, setSelectedExtraPax] = useState<SelectedExtra | null>(null);

  useEffect(() => {
    const getExtraPaxes = async () => {
      const extras = await sailingProductsSource.getPackageExtras(moment(travelBookingRoute.routeSummary.departureDate), packageItem, ProductType.ExtraPax);
      const extraPax = extras.find((extra) => extra.type === ProductType.ExtraPax);
      if (!extraPax) {
        return;
      }
      const numberOfExtraChild = passengerSummary.child - packageItem.requirement.minimumChild;
      const inventory = Object.entries(extraPax.inventory).find(([, inventory]) => inventory.title === ExtraInventoryType.ExtraChild)?.[1];
      if (inventory) {
        const quantity = Math.max(numberOfExtraChild, 0);
        setSelectedExtraPax({
          inventory,
          quantity,
          extra: extraPax
        });
        setExtraPaxPrice((inventory.price?.amount ?? 0) * quantity);
      }
    };
    getExtraPaxes();
  }, [packageItem, travelBookingRoute.routeSummary.departureDate, sailingProductsSource, setSelectedExtra, passengerSummary.child]);

  useEffect(() => {
    if (!selectedExtraPax) {
      return;
    }
    const { inventory, extra, quantity } = selectedExtraPax;
    setSelectedExtra(inventory, selectedPackage ? quantity : 0, extra);
  }, [selectedPackage, selectedExtraPax, setSelectedExtra]);

  return (
    <Grid container flexDirection="column" paddingBottom={2}>
      <Card sx={{ display: 'flex' }} onClick={toggleExpanded}>
        <CardMedia component="img" image={packageItem.image} alt={packageItem.title} sx={{ width: '180px' }} />
        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
          <CardContent sx={{ textAlign: 'left', maxWidth: '300px' }}>
            <Typography variant="h5">{packageItem.title}</Typography>
            <Typography variant="body1">{packageItem.description}</Typography>
          </CardContent>
        </Box>
      </Card>
      {departureEntry.scheduleEntries[0] && returnEntry.scheduleEntries[0] && (
        <Collapse in={expanded}>
          <PackageTicket
            departureEntry={departureEntry.scheduleEntries[0]}
            returnEntry={returnEntry.scheduleEntries[0]}
            selected={isEqual(selectedPackage, packageItem)}
            onSelectTicket={(select) => {
              setSelectedPackage(select ? packageItem : undefined);
            }}
            packageItem={packageItem}
            price={packageItem.inventory.Ticket?.price?.amount ?? 0}
            currencyCode={packageItem.inventory.Ticket?.price?.currencyCode}
            extraPaxPrice={extraPaxPrice}
          />
        </Collapse>
      )}
    </Grid>
  );
};

export default inject('sailingProductsSource')(PackageCard as FC<{}>) as React.ElementType<PackageCardProps>;
