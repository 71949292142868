import { ReactNode, FC } from 'react';
import { Tooltip, Box, TooltipProps } from '@mui/material';

interface DisabledTooltipProps {
  title: NonNullable<ReactNode>;
  disabled: boolean;
}

export const DisabledTooltip: FC<DisabledTooltipProps & TooltipProps> = ({ children, disabled, ...props }) => (
  <Tooltip {...props} title={disabled ? '' : props.title}>
    <Box component="span">{children}</Box>
  </Tooltip>
);
