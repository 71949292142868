import { FC, useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import { AccommodationInfo, DropdownLabel } from './CabinsStage';
import { capitalize } from 'lodash';
import { ViewportContext } from '../../context/ViewportContext';
import QuantitySelector from '../QuantitySelector';

const styles = {
  selectionSuperContainer: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'flex-end'
  },
  selectionContainer: {
    paddingBottom: 2,
    paddingLeft: 2
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 2
  },
  buttonGroupLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

interface AccomodationQuantitySelectProps {
  isReturnJourney: boolean;
  option: AccommodationInfo;
  selectedQuantity: { outboundRoute: number; returnRoute: number };
  setSelectedQuantity: (newValue: number, key: 'outboundRoute' | 'returnRoute') => void;
  unallocatedPassengers: (key: 'outboundRoute' | 'returnRoute') => number;
}

const AccomodationQuantitySelect: FC<AccomodationQuantitySelectProps> = ({
  isReturnJourney,
  option,
  selectedQuantity,
  setSelectedQuantity,
  unallocatedPassengers
}) => {
  const { matchesBreakpoint } = useContext(ViewportContext);
  const smallerThanLarge = useMemo(() => {
    return !matchesBreakpoint('lg');
  }, [matchesBreakpoint]);

  return (
    <Grid container item xs={12} lg={5}>
      {smallerThanLarge && (
        <Grid container item xs={4}>
          <Grid item xs={12} sx={styles.buttonGroupLabel}>
            <DropdownLabel>
              <FormattedMessage id="booking.cabins.outbound" defaultMessage="Outbound" />
            </DropdownLabel>
          </Grid>
          {isReturnJourney && (
            <Grid item xs={12} sx={styles.buttonGroupLabel}>
              <DropdownLabel>
                <FormattedMessage id="booking.cabins.return" defaultMessage="Return" />
              </DropdownLabel>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item xs={8} lg={12} sx={styles.selectionSuperContainer}>
        <Grid container item xs={12} lg={5} sx={styles.selectionContainer}>
          <Grid item xs={12} sx={styles.optionContainer}>
            <Typography variant="h6">{`${option.price} SEK /  ${capitalize(option.type)}`}</Typography>
          </Grid>
          <Grid container item xs={12} sx={styles.optionContainer}>
            <QuantitySelector
              quantity={selectedQuantity.outboundRoute}
              setQuantity={(newValue) => setSelectedQuantity(newValue, 'outboundRoute')}
              isRemoveDisabled={selectedQuantity.outboundRoute <= 0}
              isAddDisabled={option.capacity > unallocatedPassengers('outboundRoute')}
              size={3}
            />
          </Grid>
        </Grid>
        {isReturnJourney && (
          <Grid container item xs={12} lg={5} sx={styles.selectionContainer}>
            <Grid item xs={12} sx={styles.optionContainer}>
              <Typography variant="h6">{`${option.price} SEK /  ${capitalize(option.type)}`}</Typography>
            </Grid>
            <Grid container item xs={12} sx={styles.optionContainer}>
              <QuantitySelector
                quantity={selectedQuantity.returnRoute}
                setQuantity={(newValue) => setSelectedQuantity(newValue, 'returnRoute')}
                isRemoveDisabled={selectedQuantity.returnRoute <= 0}
                isAddDisabled={option.capacity > unallocatedPassengers('returnRoute')}
                size={3}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AccomodationQuantitySelect;
